<template>
  <div class="import-headers">
    <div
    v-for="header in headers"
    :key="header.value"
    :class="[header.value === 'name' && 'import-headers__element_name', header.wide && 'import-headers__element_wide']"
    class="import-headers__element">
      <span class="import-headers__text">
        {{ header.text }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImportHeaders',
  props: {
    isAllImports: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { name: 'name2', value: 'name', text: 'Название' },
      { name: 'subscribers', value: 'subscribers', text: 'Подписчики' },
      { name: 'views', value: 'views', text: 'Охват' },
      { name: 'oldnew', value: 'oldnew', text: 'Новая / Старая' },
      {
        name: 'manager', value: 'manager', text: 'Менеджер', wide: true,
      },
      {
        name: 'buttons', value: 'buttons', text: '', wide: true,
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.import-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  z-index: 1;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  height: 52px;
  border-bottom: 1px solid $primary-group-border;
}

.import-headers__element {
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &_name {
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    width: 220px;
    @include tablet-screen {
      min-width: 400px;
      padding-left: 40px;
    }
  }
  &_wide {
    width: 240px;
  }
}

.import-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
    width: 300px;
  }
}

</style>
