<template>
  <div class="modal-content">
    <form
    id="contacts_form"
    class="contactus__form contactus__inner">
      <div class="contactus__form__textblock">
        <h3>Оставьте заявку на получение доступа</h3>
        <p>С вами свяжется менеджер в ближайшее время</p>
      </div>
      <div class="form-group modal-content-name">
        <input
        id="requestName"
        v-model="formName"
        name="username"
        :class="inputNameError && 'input-error'"
        placeholder="Имя"
        type="text">
      </div>
      <div class="form-group modal-content-phone">
        <input
        id="requestPhone"
        v-model="formPhone"
        :class="inputPhoneError && 'input-error'"
        name="tel"
        placeholder="Телефон"
        type="text">
      </div>
      <div class="form-group modal-content-phone">
        <input
        v-model="formCompany"
        placeholder="Ваша компания"
        type="text">
      </div>
      <div class="form__radiogroup">
        <input
        id="call"
        v-model="formContact"
        class="form__radio"
        name="contact_type"
        value="call"
        type="radio"
        checked>
        <label
        class="form__label"
        for="call">Позвоните мне</label>
      </div>
      <div class="form__radiogroup">
        <input
        id="telegram"
        v-model="formContact"
        class="form__radio"
        value="telegram"
        name="contact_type"
        type="radio">
        <label
        class="form__label"
        for="telegram">Напишите мне в Телеграм</label>
      </div>
      <button
      class="btns-primary"
      @click.prevent="sendForm">
        Отправить
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LandingForm',
  data: () => ({
    inputNameError: false,
    inputPhoneError: false,
    formName: '',
    formPhone: null,
    formContact: 'call',
    formCompany: '',
    partnersBlock: 'agency',
  }),
  methods: {
    sendForm() {
      this.inputNameError = false;
      this.inputPhoneError = false;

      const isValidName = this.formName.trim().length;
      const isValidPhone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(this.formPhone);
      if (!isValidName || !isValidPhone) {
        this.inputNameError = !isValidName;
        this.inputPhoneError = !isValidPhone;
        setTimeout(() => {
          this.inputNameError = false;
          this.inputPhoneError = false;
        }, 1500);
        return;
      }
      const data = new FormData();
      data.append('url', 'data.socialjet.pro');
      data.append('username', this.formName);
      data.append('tel', this.formPhone);
      data.append('contact', this.formContact);
      data.append('company', this.formCompany);
      axios.post('https://socialjet.pro/api/feedback', data)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Данные успешно отправлены', error: false });
        })
        .catch(() => {
          // NEED TRANSLATE
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'Что-то пошло не так. Пожалуйста, попробуйте позже',
              error: true,
            });
        })
        .finally(() => {
          this.closeFormModal();
        });
    },
    closeFormModal() {
      this.formName = '';
      this.formPhone = null;
      this.formContact = 'call';
      this.formCompany = '';
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  position: relative;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px auto;
  border-radius: 36px;
  min-height: 560px;
}

.modal-content-video {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 36px;
  height: 100vh;
}

.modal-content-form {
  width: 410px;
}

.modal-content h3 {
  margin: 20px 0;
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: black;
}

.modal-content p {
  text-align: center;
}

.modal-content img {
  margin: auto;
}

.modal-content span {
  z-index: 999;
  font-size: 44px;
  display: block;
  flex-basis: 100%;
  text-align: end;
  margin-top: 6px;
  margin-right: 7px;
}

.modal-content form {
  max-width: 292px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
  color: #738185;
}

.modal-content form .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.modal-content form .form-group input {
  padding: 15px 10px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.25);
  max-width: 291px;
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

.input-error {
  border: 2px solid red !important;
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.modal-content form .form__radiogroup {
  margin-top: 15px;
}

.form__radio {
  margin-right: 5px;
}

.modal-content form button {
  font-size: 20px;
  font-size: 18px;
  padding: 20px 72px;
  border-radius: 73px;
  max-width: 290px;
  background: #00aee8;
  margin-top: 40px;
}

.btns-primary {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#52d7f8),
    to(#00aee8)
  );
  background: linear-gradient(to right, #52d7f8, #00aee8);
  -webkit-box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  color: white;
  border-width: 0;
  cursor: pointer;
}
</style>
