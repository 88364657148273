<template>
  <div
  v-click-outside="closeOutside"
  class="sidebar"
  :class="getOpenSidebar && 'sidebar_open'">
    <div
    class="sidebar-wrapper"
    :class="getOpenSidebar && 'sidebar-wrapper_open'">
      <div class="sidebar__menu">
        <div class="sidebar__pages">
          <div
          v-for="page in getLinksData"
          :key="page.id"
          v-tooltip.right="!page.link && 'Скоро'"
          class="sidebar__page"
          :class="!page.link && 'sidebar__page_disabled'"
          @click="goTo(page)">
            <img
            class="sidebar__icon"
            :class="!page.link && 'sidebar__icon_disabled'"
            :src="require(`./assets/${$route.name === page.link ? `${page.icon}-active` : page.icon}.svg`)">
            <span
            class="sidebar__text"
            :class="[
              $route.name === page.link && 'sidebar__text_active',
              !page.link && 'sidebar__text_disabled'
            ]">
              {{ page.name }}
            </span>
          </div>
        </div>
        <div class="sidebar__helps">
          <div
          v-for="page in helps"
          :key="page.id"
          v-title="!page.link && 'Скоро'"
          class="sidebar__page"
          :class="!page.link && 'sidebar__page_disabled'"
          @click="goTo(page)">
            <img
            class="sidebar__icon"
            :class="!page.link && 'sidebar__icon_disabled'"
            :src="require(`./assets/${$route.name === page.link ? `${page.icon}-active` : page.icon}.svg`)">
            <span
            class="sidebar__text"
            :class="[
              $route.name === page.link && 'sidebar__text_active',
              !page.link && 'sidebar__text_disabled'
            ]">
              {{ page.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="sidebar__tarifs">
        <div class="sidebar__tarif-title">
          <img
          class="sidebar__icon"
          src="./assets/rate.svg">
          <span
          class="sidebar__text">
            Тариф
          </span>
        </div>
        <div class="sidebar__tarif">
          <span class="sidebar__text sidebar__text_white sidebar__text_bold">Скоро!</span>
          <span class="sidebar__text sidebar__text_white sidebar__text_small">Большое обновление SocialData</span>
          <div class="sidebar__tarif-button">
            <span class="sidebar__text sidebar__text_white sidebar__text_small">Подробнее</span>
            <div class="sidebar__update-info">
              <div class="sidebar__update-info-check">
                <img src="./assets/check-icon.png">
              </div>
              <span class="sidebar__text sidebar__text_bold">В ближайшее время будут добавлены новые разделы:</span>
              <div class="sidebar__update-info-block">
                <p class="sidebar__text">
                  1. Ваши запросы
                </p>
                <p class="sidebar__text">
                  2. Ваши избранные подборки и каналы
                </p>
                <p class="sidebar__text">
                  3. Созданные и запущенные рекламные кампании
                </p>
                <p class="sidebar__text">
                  4. Доступы, тарифы и роли для агентств
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    pages: [
      {
        id: 1, name: 'Каталог', icon: 'catalog', link: 'main-groups',
      },
      {
        id: 2, name: 'Мои каналы', icon: 'mygroups', link: 'my-groups',
      },
      {
        id: 3, name: 'Импорты', icon: 'import', link: 'imports-screen', admin: true,
      },
      {
        id: 4, name: 'Запросы', icon: 'request', link: '',
      },
      {
        id: 5, name: 'Кампании', icon: 'campaign', link: '',
      },
      {
        id: 6, name: 'Избранное', icon: 'favorites', link: '',
      },
    ],
    helps: [
      {
        id: 1, name: 'Настройки', icon: 'settings', link: '',
      },
      {
        id: 2, name: 'Помощь', icon: 'help', link: '',
      },
    ],
  }),
  methods: {
    goTo(page) {
      if (this.$route.name === page.link || !page.link) return;
      if (this.getOpenSidebar) {
        this.closeSidebar();
      }
      if (page.link === 'main-groups') {
        this.goToMain();
        return;
      }
      this.$router.push({ name: page.link });
    },
    goToMain() {
      this.$store.commit('collections/resetAllShow');
      this.$store.commit('collections/setShowCollectionGroups', false);
      this.$store.commit('groups/setInitial', true);
      this.$store.commit('groups/resetGroupsSearchParams');
      this.$store.commit('groups/setUserIdForGroups', null);
      this.$router.push({ name: 'main-groups' });
    },
    closeSidebar() {
      this.$store.commit('app/setShowSidebar', false);
    },
    closeOutside(event) {
      if (event.target.className.includes('data-burger-button')) return;
      if (this.getOpenSidebar) {
        this.closeSidebar();
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    getLinksData() {
      return this.isAdmin ? this.pages : this.pages.filter((item) => !item.admin);
    },
    getOpenSidebar() {
      return this.$store.getters['app/getShowSidebar'];
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: block;
  pointer-events: none;
  width: 0;
  z-index: 15;
  top: 0;
  left: 0;
  &_open {
    pointer-events: auto;
  }
  @include desktop-screen {
    pointer-events: auto;
    width: 65px;
  }
}

.sidebar-wrapper {
  opacity: 0;
  position: sticky;
  overflow: hidden;
  left: 0px;
  width: 0px;
  top: 0px;
  height: 100%;
  max-height: 100vh;
  transition: 0.2s;
  background: white;
  border-top: 1px solid #eaf8fc;;
  padding: 20px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include desktop-screen {
    opacity: 1;
    max-height: 100vh;
    width: 65px;
    &:hover {
      opacity: 1;
      box-shadow: 0px 0px 10px 3px rgb(34 60 80 / 0.1);
      width: 250px;
      .sidebar__menu {
        padding: 0px 30px;
      }
      .sidebar__text,
      .sidebar__tarif,
      .sidebar__tarif-title {
        opacity: 1;
      }
      .sidebar__tarifs {
        transform: scaleX(1);
        opacity: 1;
        transition: opacity 0.7s;
        transition-delay: 0.2s;
      }
    }
  }
  &_open {
    opacity: 1;
    box-shadow: 0px 0px 10px 3px rgb(34 60 80 / 0.1);
    width: 250px;
    .sidebar__menu {
      padding: 0px 30px;
    }
    .sidebar__text,
    .sidebar__tarif,
    .sidebar__tarif-title {
      opacity: 1;
    }
    .sidebar__tarifs {
      transform: scaleX(1);
    }
  }
}

.sidebar__menu {
  transition: 0.2s;
}

.sidebar__page {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &_disabled {
    cursor: default;
  }
}

.sidebar__pages {
  @include between-elements {
    margin-bottom: 32px;
  }
  padding-bottom: 32px;
  border-bottom: 1px solid #D8F2FA;
}

.sidebar__helps {
  @include between-elements {
    margin-bottom: 32px;
  }
  padding-top: 32px;
}

.sidebar__icon {
  width: 25px;
  margin-right: 20px;
  &_disabled {
    opacity: 0.5;
  }
}

.sidebar__text {
  display: block;
  opacity: 0;
  transition: opacity 0.1s;
  font-size: 14px;
  color: $primary-gray;
  white-space: nowrap;
  margin: 0px;
  &_disabled {
    color: rgb(211, 211, 211);
    font-weight: 400;
  }
  &_active {
    color: $primary-blue;
  };
  &_white {
    color: white;
  }
  &_bold {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    white-space: pre-wrap;
  }
  &_small {
    font-size: 12px;
    white-space: pre-wrap;
  }
}

.sidebar__tarifs {
  display: none;
  @include desktop-screen {
    position: fixed;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transform: scaleX(0);
    opacity: 0;
  }
}

.sidebar__tarif-title {
  display: flex;
  padding-left: 50px;
  opacity: 0;
  transition: 0.2s;
  transform-origin:left center;
}

.sidebar__tarif {
  padding: 20px;
  margin-top: 20px;
  background: $primary-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: 0.2s;
  width: 210px;
  border-radius: 16px;
  transform-origin:left center;
}

.sidebar__tarif-button {
  width: 100%;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(255, 255, 255, 0.31);
  border-radius: 33px;
  margin-top: 14px;
  position: relative;
  cursor: pointer;
  &:hover {
    .sidebar__update-info {
      opacity: 1;
    }
  }
}

.sidebar__update-info {
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
  position: absolute;
  background: white;
  border-radius: 20px;
  right: -410px;
  top: -310px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 450px;
}

.sidebar__update-info-check {
  width: 84px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F1F8F1;
  border-radius: 8px;
  margin-bottom: 28px;
}

.sidebar__update-info-block {
  text-align: left;
  width: 100%;
  @include between-elements {
    margin-bottom: 5px;
  }
}
</style>
