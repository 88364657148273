<template>
  <div
  ref="priceChart"
  class="group-price-chart" />
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import am5localeRUS from '@amcharts/amcharts5/locales/ru_RU';

export default {
  name: 'GroupPriceChart',
  props: {
    // данные для графика
    chartData: {
      type: Array,
      default: () => [],
    },
    // Отображение мини графика под основным
    withBrush: {
      type: Boolean,
      default: false,
    },
    // Плавная линия
    smoothed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: true,
    root: null,
  }),
  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    addCustomTooltip(series, root, chart) {
      const tooltip = series.set('tooltip', am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        autoTextColor: false,
        pointerOrientation: 'horizontal',
      }));

      tooltip.get('background').setAll({
        fill: am5.color('#ffffff'),
        fillOpacity: 1,
      });

      tooltip.label.adapters.add('text', (text) => {
        chart.series.each((ser) => {
          text += `\n[${ser.get('stroke').toString()}width: 20px]●[/][bold width:100px]{${ser.get('valueYField')}}`;
        });
        return text;
      });

      tooltip.label.setAll({
        text: '{valueX.formatDate("dd.MM.YYYY")}',
        fontSize: 14,
        fill: am5.color(0x000000),
      });

      return tooltip;
    },
    createChart() {
      const root = am5.Root.new(this.$refs.priceChart);
      root.locale = am5localeRUS;
      root.setThemes([am5themesAnimated.new(root)]);

      const data = [...this.chartData];

      // создание графика
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          paddingRight: this.withBrush ? 30 : 0,
          paddingTop: 30,
          paddingBottom: 20,
          paddingLeft: 0,
        }),
      );

      // создание осей
      const xAxis = chart.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          maxDeviation: 0,
          baseInterval: {
            timeUnit: 'day',
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 50,
          }),
          tooltip: am5.Tooltip.new(root, {
            forceHidden: true,
          }),
        }),
      );
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );

      // установка цвета сетки грида
      const yRenderer = yAxis.get('renderer');
      yRenderer.grid.template.setAll({
        stroke: am5.color('#F2F2F2'),
        strokeOpacity: 1,
        strokeWidth: 1,
      });
      yRenderer.labels.template.setAll({
        fontSize: 12,
        fill: '#7B7B7B',
      });
      const xRenderer = xAxis.get('renderer');
      xRenderer.grid.template.setAll({
        stroke: am5.color('#F2F2F2'),
        strokeOpacity: 1,
        strokeWidth: 1,
      });
      xRenderer.labels.template.setAll({
        fontSize: 12,
        fill: '#7B7B7B',
      });

      // создание линии
      const lineType = this.smoothed ? 'SmoothedXLineSeries' : 'LineSeries';
      const series = chart.series.push(
        am5xy[lineType].new(root, {
          minBulletDistance: 10,
          xAxis,
          yAxis,
          valueYField: 'value',
          valueXField: 'date',
          // tooltip: am5.Tooltip.new(root, {
          // tooltipHTML: '<div style="width: 200px; background-color: #555; padding: 0;">{categoryX}</strong> {valueY}</div>',
          // }),
        }),
      );

      // скрытие кнопки зума
      chart.zoomOutButton.set('forceHidden', true);

      // настройка линии
      series.set('stroke', am5.color('#6AB16F'));
      series.strokes.template.setAll({
        strokeWidth: 3,
      });

      // настройка курсора
      const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
        xAxis,
      }));
      cursor.lineX.setAll({
        stroke: am5.color('#CCEFFA'),
        strokeWidth: 2,
        strokeDasharray: [],
      });
      cursor.lineY.set('visible', false);

      // настойка тултипа
      series.set('tooltip', this.addCustomTooltip(series, root, chart));

      // настойка точек на линии
      series.bullets.push((item) => am5.Bullet.new(item, {
        sprite: am5.Circle.new(item, {
          radius: 4,
          fill: am5.color('#FFFFFF'),
          stroke: am5.color('#6AB16F'),
          strokeWidth: 2,
        }),
      }));

      series.data.setAll(data);
      if (data.length > 8) {
        series.events.once('datavalidated', () => {
          xAxis.zoomToDates(new Date(data[data.length - 6].date), new Date(data[data.length - 1].date + 100000000), 100);
        });
      }

      if (this.withBrush) {
        // ---- НИЖИНЙ СКРОЛЛБАР ---- //
        const scrollbarX = am5xy.XYChartScrollbar.new(root, {
          orientation: 'horizontal',
          height: 40,
        });
        // кастомизация стрелочек нижнего скроллбара
        const customizeGrip = (grip) => {
          scrollbarX[grip].setAll({
            fillOpacity: 0,
          });
          scrollbarX[grip].get('background').setAll({
            fillOpacity: 0,
          });
        };
        customizeGrip('startGrip');
        customizeGrip('endGrip');
        // добавление скролбара к основному графику
        chart.set('scrollbarX', scrollbarX);
        // добавление скроллбара в низ
        chart.bottomAxesContainer.children.push(scrollbarX);
        scrollbarX.get('background').setAll({
          fill: am5.color('#1194CE'),
          fillOpacity: 0.3,
          cornerRadiusTR: 5,
          cornerRadiusBR: 5,
          cornerRadiusTL: 5,
          cornerRadiusBL: 5,
        });

        const sbxAxis = scrollbarX.chart.xAxes.push(am5xy.ValueAxis.new(root, {
          opacity: 0, // прозрачность лейблов по оси Y
          renderer: am5xy.AxisRendererX.new(root, {}),
        }));

        const sbyAxis = scrollbarX.chart.yAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        }));

        const sbseries = scrollbarX.chart.series.push(am5xy.LineSeries.new(root, {
          xAxis: sbxAxis,
          yAxis: sbyAxis,
          valueYField: 'value',
          valueXField: 'date',
        }));

        sbseries.set('stroke', am5.color('#a8a8a8'));

        sbseries.strokes.template.setAll({
          strokeWidth: 2,
        });

        series.set('tooltip', this.addCustomTooltip(series, root, chart));
        // ser2.set('tooltip', this.addCustomTooltip(ser2, root, chart));
        sbseries.data.setAll(data);
      //
      }

      // пред-анимация
      series.appear(1000, 100);
      chart.appear(1000, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-price-chart {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 12px;
  border: 1px solid #D8F2FA;
  box-shadow: 0px 5px 12px -5px rgb(0 174 232 / 5%);
  &::after {
    position: absolute;
    content: '';
    height: 17px;
    background: white;
    pointer-events: none;
    bottom: 1px;
    left: 4px;
    width: 55px;
    z-index: 10;
    opacity: 1;
    border-radius: 4px;
  }
}
</style>
