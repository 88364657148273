<template>
  <div
  class="group-actions"
  :class="[
    showActions && 'group-actions_show',
    mode === 'noauth' && 'group-actions_show_no-buttons'
  ]">
    <div
    v-if="mode !== 'noauth'"
    class="group-actions__button-block">
      <ActionButton />
      <button
      class="group-actions__button"
      @click="openSendCompilationModal">
        {{ $t('send_a_request') }}
      </button>
    </div>
    <GroupStats
    :mode="mode"
    :selected-groups="selectedGroups" />
    <transition name="fade">
      <div
      v-if="isAllSelectedForCompilation"
      class="group-actions__all-selected"
      :class="mode === 'noauth' && 'group-actions__all-selected_noauth'">
        <span class="group-actions__text group-actions__text_all-selected">{{ getAllSelectedTitle }}</span>
        <div
        class="group-actions__all-selected-btn"
        :class="getSelectedAllGroups && 'group-actions__all-selected-btn_active'">
          <span
          class="group-actions__text"
          :class="getSelectedAllGroups && 'group-actions__text_all-selected'"
          @click="toggleAllGroupsToSelected">{{ getTitleForSelectAllButton }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ActionButton from './components/ActionButton/ActionButton.vue';
// import SendCollection from './components/SendCollection.vue';
import GroupStats from './components/GroupsStats.vue';

export default {
  name: 'GroupActions',
  components: { ActionButton, GroupStats },
  props: {
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: null,
    },
    groupsData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    toggleAllGroupsToSelected() {
      const selected = this.getSelectedAllGroups;
      this.$store.commit('collections/setSelectedAllGroups', !selected);
    },
    openSendCompilationModal() {
      const modalParams = { name: 'SendCollection', props: { selectedGroups: this.selectedGroups } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    isAllSelectedForCompilation() {
      if (this.isLastPage || !this.isShowCollectionGroups) {
        return false;
      }
      return this.groupsData.every((item) => this.selectedGroups.some((el) => el.name === item.name));
    },
    isLastPage() {
      if (this.isShowCollectionGroups) {
        return this.$store.getters['collections/getCurrentPage'] === (+this.$store.getters['collections/getTotalPageCounts'] + 1);
      }
      return this.$store.getters['groups/getCurrentPage'] === (+this.$store.getters['groups/getTotalPageCounts'] + 1);
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
    getAllSelectedTitle() {
      // NEED TRANSLATE
      if (this.getSelectedAllGroups && this.getCurrentCollectionStats.countSelectedGroups) {
        return `Выбрано ${this.getCurrentCollectionStats.countSelectedGroups} каналов`;
      }
      return `Выбрано ${this.selectedGroups.length} каналов`;
    },
    getTitleForSelectAllButton() {
      // NEED TRANSLATE
      return this.getSelectedAllGroups ? 'Выбраны все' : 'Выбрать все';
    },
    getCurrentCollectionStats() {
      return this.$store.getters['collections/getCurrentCollection']?.stats;
    },
    getSelectedAllGroups() {
      return this.$store.getters['collections/getSelectedAllGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.group-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  bottom: 0px;
  background: $primary-blue;
  padding: 0px 10px;
  height: 0px;
  transition: height .2s;
  overflow: hidden;
  z-index: 4;
  &_show {
    height: 160px;
    margin-top: 5px;
    overflow: visible;
    @include desktop-screen {
      height: 88px;
    }
    &_no-buttons {
      height: 148px;
      @include tablet-screen {
        height: 44px;
      }
    }
  }
  @include desktop-screen {
    padding: 0px 40px;
    justify-content: space-between;
  }
}

.group-actions__content {
  display: flex;
  width: 100%;
  padding: 0px 30px;
  align-items: center;
  justify-content: space-between;
}

.group-actions__button-block {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  @include between-elements {
    margin-bottom: 15px;
  }
  @include tablet-screen {
    flex-direction: row;
    margin-right: 20px;
    @include between-elements {
      margin-right: 15px;
      margin-bottom: 0px;
    }
  }
}

.group-actions__button {
  cursor: pointer;
  width: 140px;
  height: 40px;
  outline: none;
  border: none;
  background: $primary-white;
  border-radius: 40px;
  @include tablet-screen {
    width: 180px;
  }
}

.group-actions__white-bar {
  height: 10px;
  background: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.group-actions__text {
  color: $primary-black;
  font-size: 14px;
  &_all-selected {
    color: white;
  }
}

.group-actions__all-selected {
  display: flex;
  max-width: 350px;
  justify-content: center;
  align-items: center;
  height: 0px;
  background: $primary-blue;
  border-radius: 12px;
  padding: 20px 20px;
  font-size: 12px;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  bottom: 140px;
  right: 0;
  text-align: center;
  @include tablet-screen {
    bottom: 100px;
  }
  &_noauth {
    bottom: 63px;
  }
}

.group-actions__all-selected-btn {
  margin-left: 10px;
  padding: 3px 10px;
  width: 140px;
  background: white;
  border-radius: 6px;
  &_active {
    background: $primary-darkblue;
    color: white;
  }
}
</style>
