<template>
  <div
  class="group"
  :class="[
    !hideGroupDetails && 'group_show-all',
    showMenu && 'group_hover'
  ]"
  @mouseenter="setShowMenu($event, true)"
  @mouseleave="setShowMenu($event, false)">
    <div
    class="group__sticky-elements"
    :class="showMenu && 'group__sticky-elements_hover'">
      <div class="group__checkbox">
        <DataCheckbox
        v-model="groupChecked" />
      </div>
      <GroupName
      :group="group"
      :hover="showMenu"
      :hide-details="hideGroupDetails"
      @openEditNameModal="openEditNameModal" />
    </div>

    <div class="group__elements">
      <GroupSubscribers
      v-if="inShowList('subscribers')"
      :style="{ order: getOrder('subscribers') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :group="group"
      :subscribers="group.subscribersStatistic" />

      <GroupViews
      v-if="inShowList('views-er')"
      :style="{ order: getOrder('views-er') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :group="group"
      :views="group.viewsStatistic" />

      <GroupGeo
      v-if="inShowList('geo')"
      :style="{ order: getOrder('geo') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :countries="group.subscribersStatistic.countries"
      :cities="group.subscribersStatistic.cities"
      :group="group" />

      <GroupGender
      v-if="inShowList('gender')"
      :style="{ order: getOrder('gender') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :male-percent="group.subscribersStatistic.malePercent"
      :female-percent="group.subscribersStatistic.femalePercent"
      :ages="group.subscribersStatistic.age"
      :group="group" />

      <GroupInterests
      v-if="inShowList('interests')"
      :style="{ order: getOrder('interests') }"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :interests="group.interests"
      :group="group" />

      <GroupPostCount
      v-if="inShowList('posts_count')"
      :style="{ order: getOrder('posts_count') }"
      :group="group"
      :is-admin="isAdmin"
      :hide-details="hideGroupDetails"
      :posts="group.postsStatistic" />

      <div
      v-if="isAdmin && isShowCollectionGroups && inShowList('margin')"
      :style="{ order: getOrder('margin') }"
      class="group__element">
        <span
        v-if="tradeOffer && tradeOffer.value"
        class="group__text_small">{{ `${tradeOffer.value}%` }}</span>
        <span
        v-else
        class="group__text_light">25%</span>
      </div>

      <GroupPrice
      v-if="inShowList('price_sell')"
      :style="{ order: getOrder('price_sell') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :selected-type-of-groups="selectedTypeOfGroups"
      :trade-offer="tradeOffer"
      :post-price="group.postPrice"
      :integration-price="group.integrationPrice"
      :story-price="group.storyPrice"
      :reel-price="group.reelPrice"
      :placement-in-top="group.placementInTop"
      :placement-in-feed="group.placementInFeed"
      :advertising-label="group.advertisingLabel"
      :price-type="'sell'" />

      <GroupPrice
      v-if="isAdmin && inShowList('price_sale')"
      :style="{ order: getOrder('price_sale') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :selected-type-of-groups="selectedTypeOfGroups"
      :trade-offer="tradeOffer"
      :post-price="group.postPrice"
      :integration-price="group.integrationPrice"
      :story-price="group.storyPrice"
      :reel-price="group.reelPrice"
      :placement-in-top="group.placementInTop"
      :placement-in-feed="group.placementInFeed"
      :advertising-label="group.advertisingLabel"
      :price-type="'sale'" />

      <GroupPrice
      v-if="isAdmin && inShowList('price_buy')"
      :style="{ order: getOrder('price_buy') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :selected-type-of-groups="selectedTypeOfGroups"
      :trade-offer="tradeOffer"
      :post-price="group.postPrice"
      :integration-price="group.integrationPrice"
      :story-price="group.storyPrice"
      :reel-price="group.reelPrice"
      :placement-in-top="group.placementInTop"
      :placement-in-feed="group.placementInFeed"
      :advertising-label="group.advertisingLabel"
      :price-type="'buy'" />

      <GroupCpm
      v-if="inShowList('cpm')"
      :style="{ order: getOrder('cpm') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :cpm="group.cpm"
      :cpm-from-buy="group.cpmFromBuy" />

      <GroupCpc
      v-if="inShowList('cpc')"
      :style="{ order: getOrder('cpc') }"
      :group="group"
      :is-admin="isAdmin"
      :cpc="group.cpc" />

      <GroupContentAnalysis
      v-if="inShowList('rating_sji')"
      :style="{ order: getOrder('rating_sji') }"
      :data="group.contentAnalysis"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :group="group" />

      <GroupRobot
      v-if="inShowList('robot')"
      :style="{ order: getOrder('robot') }"
      :is-admin="isAdmin"
      :group="group"
      :robot="group.roboticity" />

      <GroupAdmins
      v-if="isAdmin && inShowList('Other')"
      :style="{ order: getOrder('Other') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :in-social-jet="group.inSocialjet"
      :payment-type="group.paymentType"
      :is-blogger="group.isBlogger"
      :in-black-list="group.inBlackList" />

      <GroupContact
      v-if="isAdmin && inShowList('contacts')"
      :style="{ order: getOrder('contacts') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :contact="group.contact" />

      <GroupComment
      v-if="isAdmin && inShowList('comment')"
      :style="{ order: getOrder('comment') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :comment="group.comment" />

      <GroupRedactor
      v-if="isAdmin && inShowList('redactor')"
      :style="{ order: getOrder('redactor') }"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :redactor="group.botName" />

      <div
      v-if="isAdmin && inShowList('create_date')"
      :style="{ order: getOrder('create_date') }"
      class="group__element">
        <span
        v-if="group.createdAt"
        class="group__text_small">{{ getDate(group.createdAt.date) }}</span>
        <span
        v-else
        class="group__text_light">—</span>
      </div>

      <div
      v-if="inShowList('update')"
      :style="{ order: getOrder('update') }"
      class="group__element">
        <span
        v-if="group.dataUpdatedAt"
        class="group__text_small">{{ getDate(group.dataUpdatedAt.date) }}</span>
        <span
        v-else
        class="group__text_light">—</span>
      </div>

      <div
      v-if="isAdmin && inShowList('update_price')"
      :style="{ order: getOrder('update_price') }"
      class="group__element">
        <div v-if="group.postPrice && group.postPrice.manualUpdatedAt">
          <span class="group__text_light">{{ getDate(group.postPrice.manualUpdatedAt.date) }} </span>
          <span
          v-if="group.postPrice && group.postPrice.updaterUser && group.postPrice.updaterUser.email"
          class="group__text_light">{{ group.postPrice.updaterUser.email }} </span>
        </div>
        <span
        v-else
        class="group__text_light">—</span>
      </div>

      <!-- Причина бана -->
      <div
      v-if="isAdmin && isBannedGroup && isHiddenMode && inShowList('bad_reason')"
      :style="{ order: getOrder('bad_reason') }"
      class="group__element">
        <span
        v-for="reason in getBanReasons"
        :key="reason"
        class="group__text group__text_small group__text_no-wrap">{{ reason }}</span>
        <span
        class="group__text group__text_light pointer"
        @click="restoreGroup">{{ $t('Restore channel') }}</span>
      </div>
    </div>

    <div class="group__sticky-menu">
      <GroupMenu
      v-if="showMenu && !showGroupMenu"
      ref="groupmenu"
      :group="group"
      :hide-details="hideGroupDetails"
      :is-admin="isAdmin"
      :mode="mode"
      class="group__menu"
      :checked.sync="groupChecked"
      @openDeleteModal="openApproveModal('delete')"
      @openEditNameModal="openEditNameModal"
      @restoreGroup="restoreGroup"
      @addToBanned="openApproveModal('addToBan')" />
    </div>
  </div>
</template>

<script>
import GroupName from './components/GroupName.vue';
import GroupSubscribers from './components/GroupSubscribers.vue';
import GroupViews from './components/GroupViews.vue';
import GroupPostCount from './components/GroupPostCount.vue';
import GroupPrice from './components/GroupPrice.vue';
import GroupGeo from './components/GroupGeo.vue';
import GroupContentAnalysis from './components/GroupContentAnalysis.vue';
import GroupAdmins from './components/GroupAdmins.vue';
import GroupContact from './components/GroupContact.vue';
import GroupMenu from './components/GroupMenu.vue';
import GroupCpm from './components/GroupCpm.vue';
import GroupCpc from './components/GroupCpc.vue';
import GroupRedactor from './components/GroupRedactor.vue';
import GroupComment from './components/GroupComment.vue';
import GroupInterests from './components/GroupInterests.vue';
import GroupRobot from './components/GroupRobot.vue';
import GroupGender from './components/GroupGender.vue';

export default {
  name: 'Group',
  components: {
    GroupName,
    GroupSubscribers,
    GroupViews,
    GroupPostCount,
    GroupPrice,
    GroupGeo,
    GroupRedactor,
    GroupContentAnalysis,
    GroupAdmins,
    GroupContact,
    GroupCpm,
    GroupCpc,
    GroupMenu,
    GroupComment,
    GroupInterests,
    GroupRobot,
    GroupGender,
  },
  props: {
    group: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    tradeOffer: {
      type: Object,
      default: null,
    },
    selectedGroups: {
      type: Array,
      default: null,
    },
    selectedTypeOfGroups: {
      type: [String, Number],
      default: null,
    },
    hideGroupDetails: {
      type: Boolean,
      default: false,
    },
    isHiddenMode: {
      type: [Boolean, String],
      default: false,
    },
    columns: {
      type: Array,
      default: null,
    },
    showGroupMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: false,
  }),
  methods: {
    setShowMenu(event, value) {
      if (this.showGroupMenu) {
        this.showMenu = false;
        return;
      }
      // обработка исключения для элемента v-title (ложное срабатывание при наведении)
      if (event.relatedTarget && event.relatedTarget.className.includes('v-title-block')) return;
      this.showMenu = value;
    },
    getOrder(element) {
      return this.columns.findIndex((item) => item.value === element || item.value2 === element);
    },
    getDate(date) {
      return this.moment(date).format('LLL');
    },
    inShowList(value) {
      try {
        return this.columns.find((column) => column.value === value || column.value2 === value).show;
      } catch {
        return false;
      }
    },
    openEditNameModal() {
      const modalParams = { name: 'GroupEditorModal', props: { group: this.group } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openApproveModal(action) {
      const modalParams = { name: 'GroupApproveModal', props: { group: this.group, action } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    restoreGroup() {
      this.$store.dispatch('groups/restoreGroup', { id: this.group.id, social: this.group.social })
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('successfully_the_channel_will_be_restored') });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while restoring the channel. Please refresh the page and try again'),
              error: true,
            });
        });
    },
  },
  computed: {
    groupChecked: {
      get() {
        return this.selectedGroups.length > 0
          ? this.selectedGroups.some((item) => (item.name === this.group.name && item.id === this.group.id))
          : false;
      },
      set(value) {
        if (value) {
          const newSelectedGroups = [...this.selectedGroups, this.group];
          this.$store.commit('collections/setSelectedGroups', newSelectedGroups);
          return;
        }
        const newSelectedGroups = this.selectedGroups.filter((item) => !(item.id === this.group.id && item.name === this.group.name));
        this.$store.commit('collections/setSelectedGroups', newSelectedGroups);
      },
    },
    isBannedGroup() {
      return this.group.status === 3;
    },
    getBanReasons() {
      if (!this.group.inspectReport?.cheatings.length && !this.group.badReason) {
        return ['—'];
      }

      const cheatings = this.group.inspectReport?.cheatings.map((item) => item.message).slice(0, 2) || [];

      if (this.group.badReason && this.group.badReason.length) {
        const badreason = this.group.badReason.split(', ');
        cheatings.unshift(...badreason);
      }

      return new Set(cheatings);
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  display: flex;
  align-items: center;
  height: 70px;
  transition: 0.2s;
  border-bottom: 1px solid #CCEFFA;
  min-width: 100%;
  &_show-all {
    transition: 0.2s;
    height: 125px;
  }
  &_hover {
    background: #fcfcfc;
  }
}

.group__sticky-elements {
  position: sticky;
  left: 0px;
  top: 0px;
  background: white;
  padding-left: 20px;
  display: flex;
  align-items: center;
  z-index: 3;
  transition: 0.2s;
  height: 100%;
  @include tablet-screen {
    width: 422px;
  }
  &_hover {
    background: #fcfcfc;
  }
  &:after {
    content: '';
    width: 1px;
    height: 50px;
    background: $primary-group-border;
  }
}

.group__checkbox {
  width: 18px;
}

.group__elements {
  display: flex;
  align-items: center;
  width: 100%;
  @include between-elements {
    border-right: 1px solid $primary-group-border;
  }
}

.group__sticky-menu {
  position: sticky;
  top: 0px;
  right: 0px;
  z-index: 4;
}

.group__menu {
  display: none;
  @include tablet-screen {
    display: flex;
    position: absolute;
    z-index: 1;
    transition: .2s;
    top: -70px;
    right: 30px;
  }
}

.group__element {
  width: 180px;
  min-height: 50px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  @include between-elements {
    margin-bottom: 2px;
  }
}

.group__text {
  font-size: 14px;
  color: $primary-gray;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
    line-height: 12px;
    padding-right: 0px;
    overflow-wrap: break-word;
  }
  &_small {
    font-size: 12px;
    color: $primary-black;
    padding-right: 0px;
  }
  &_no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
