<template>
  <div
  v-if="!isLoading"
  class="login">
    <span class="login__text">
      {{ $t('Authorization') }}
    </span>
    <LoginForm @submitForm="submitForm" />
    <a
    href="https://telejet.socialjet.pro"
    class="login__text login__text_link">
      {{ $t('Go to Telejet') }}
    </a>
  </div>
</template>

<script>
import LoginForm from './components/LoginForm.vue';

export default {
  name: 'Login',
  components: { LoginForm },
  methods: {
    submitForm(loginData) {
      this.$store.dispatch('auth/login', loginData)
        .then(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('Successful authorization'),
              error: false,
            });
          setTimeout(() => {
            this.$router.push({ name: 'main-groups', query: { group: 'telegram' } });
          }, 500);
        })
        .catch((error) => {
          if (error.response.status && error.response.status === 422) {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('Incorrect login or password'),
                error: true,
              });
          }
        });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['groups/getGroupsIsLoading'] || this.$store.getters['collections/getCollectionsIsLoading'];
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
}

.login__text {
  font-size: 18px;
  margin-bottom: 20px;
  &_link {
    text-decoration: none;
    font-size: 14px;
    margin-top: 10px;
  }
}
</style>
