var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff-full-burn"},[_c('div',{staticClass:"tariff-full-burn__container"},[_c('div',{staticClass:"tariff-full-burn__info"},[_c('div',{staticClass:"tariff-full-burn__label-container"},[_c('span',{staticClass:"tariff-full-burn__text"},[_vm._v("Ваш бюджет за 365 дней")]),_c('span',{staticClass:"tariff-full-burn__text tariff-full-burn__text_label"},[_vm._v(_vm._s(_vm.tariffLabel))])]),_c('div',{staticClass:"tariff-full-burn__label-container pointer",staticStyle:{"align-items":"center"},on:{"click":_vm.goToTariffs}},[_c('img',{staticClass:"tariff-full-burn__ok-icon",attrs:{"src":require("../assets/ok-icon.svg")}}),_c('span',{staticClass:"tariff-full-burn__text"},[_vm._v("Доступ до 1 марта, commit выполнен ")])])]),_c('div',{staticClass:"tariff-full-burn__progress"},[_vm._m(0),_c('div',{ref:"progress",staticClass:"tariff-full-burn__container-progress"},[_c('div',{ref:"progress",staticClass:"tariff-full-burn__progress-active-points"},_vm._l((_vm.points),function(point,index){return _c('div',{key:point.value,staticClass:"tariff-full-burn__progress-active-point-container",style:({
            left: `${point.left}% `,
            opacity: (index === 0 || index === _vm.points.length - 1) ? 0 : 1,
            justifyContent: index === 0 ? 'flex-start' : index === _vm.points.length - 1 ? 'flex-end' : 'center',
          })},[_c('div',{staticClass:"tariff-full-burn__progress-active-point"})])}),0),_c('div',{staticClass:"tariff-full-burn__progress-active-line",style:({width: `${_vm.activeLineWidth}%`})}),_c('div',{staticClass:"tariff-full-burn__progress-text-points"},_vm._l((_vm.points),function(point){return _c('span',{key:point.value,staticClass:"tariff-full-burn__text tariff-full-burn__text_text-point",style:({
            marginLeft: `${point.margin}px`,
            left: `${point.left}%`,
          })},[_vm._v(" "+_vm._s(point.name)+" ")])}),0)])]),_c('div',{staticClass:"tariff-full-burn__close",on:{"click":_vm.changeTariffBar}},[_c('div',{staticClass:"tariff-full-burn__close-button"},[_c('TJIcon',{staticClass:"tariff-full-burn__menu-arrow",attrs:{"name":"expand_more","arrow-open":true}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff-full-burn__label-container",staticStyle:{"align-items":"center"}},[_c('span',{staticClass:"tariff-full-burn__text tariff-full-burn__text"},[_vm._v("Burn Rate")]),_c('img',{staticClass:"tariff-full-burn__ok-icon",attrs:{"src":require("../assets/burn-icon.svg")}})])
}]

export { render, staticRenderFns }