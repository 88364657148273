import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    newGroupsForCompilation: null,
    // AppSnackBar
    appSnackBarText: null,
    appSnackBarError: false,
    appSnackBarTimeout: 2500,
    // Sidebar
    showSidebar: false,
    // Modals
    currentModal: false,
    showFullTariffRate: true,
  },
  getters: {
    getNewGroupsForCompilation: (state) => state.newGroupsForCompilation,
    // AppSnackBar
    getAppSnackBarText: (state) => state.appSnackBarText,
    getAppSnackBarError: (state) => state.appSnackBarError,
    getAppSnackBarTimeout: (state) => state.appSnackBarTimeout,
    // SideBar
    getShowSidebar: (state) => state.showSidebar,
    // Modals
    getCurrentModal: (state) => state.currentModal,
    getShowFullTariffRate: (state) => state.showFullTariffRate,
  },
  mutations: {
    setNewGroupsToCompilation: (state, value) => { state.newGroupsForCompilation = value; },
    // AppSnackBar
    setAppSnackBarText: (state, value) => { state.appSnackBarText = value; },
    setAppSnackBarError: (state, value) => { state.appSnackBarError = value; },
    setAppSnackBarTimeout: (state, value) => { state.appSnackBarTimeout = value; },
    // Sidebar
    setShowSidebar: (state, value) => { state.showSidebar = value; },
    // Modals
    setCurrentModal: (state, value) => { state.currentModal = value; },
    setShowFullTariffRate: (state, value) => { state.showFullTariffRate = value; },
  },
  actions: {
    setSnackBar({ commit }, params) {
      commit('setAppSnackBarText', params.text);
      commit('setAppSnackBarError', params.error);
    },
  },
};
