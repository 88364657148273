<template>
  <div class="groups-filter">
    <SocialSwitcher
    :selected-type="getSelectedTypeOfGroups"
    :disable="isLoading" />
    <div
    class="group-filter__main-container">
      <div
      class="group-filter__filters"
      :class="showAllFilters && 'group-filter__filters_show-all'">
        <div class="group-filter__filters-container">
          <AccountFilter
          :name.sync="searchParams.nameOrUrl"
          :url.sync="searchParams.byUrl"
          :contact.sync="searchParams.contact"
          :description.sync="searchParams.description"
          :disabled="isLoading"
          :reset="paramsIsEnabled" />
          <CategoriesFilter
          :selected-categories.sync="searchParams.categoriesId"
          :categories="filtersData.categories"
          :categories-count="getGroupsAggregateValues.totalCountByCategories"
          :without-category.sync="searchParams.withoutCategory"
          :reset="paramsIsEnabled" />
          <NumericFilter
          :title="$t('subscribers')"
          :min.sync="searchParams.subscribersMin"
          :max.sync="searchParams.subscribersMax"
          :disabled="isLoading"
          :to-max="getGroupsAggregateValues.subscribersMax"
          :reset="paramsIsEnabled"
          with-interval />
          <NumericFilter
          :title="$t('views')"
          :search-type.sync="searchParams.viewsType"
          :min.sync="searchParams.viewsMin"
          :max.sync="searchParams.viewsMax"
          :to-max="getAggregateForViews"
          :reset="paramsIsEnabled"
          :disabled="isLoading"
          with-interval
          type="views" />
          <GeographyFilter
          :countries.sync="searchParams.subscriberCountries"
          :without-country.sync="searchParams.withoutCountry"
          :disabled="isLoading"
          :reset="paramsIsEnabled" />
          <NumericFilter
          :title="$t('prices')"
          :search-type.sync="searchParams.priceType"
          :min.sync="searchParams.priceMin"
          :max.sync="searchParams.priceMax"
          :placeholder="$t('any_price')"
          with-interval
          type="price"
          :to-max="getGroupsAggregateValues.postPriceSellMax"
          :disabled="isLoading"
          :reset="paramsIsEnabled" />
          <AuditoryFilter
          :male-min.sync="searchParams.malePercentMin"
          :male-max.sync="searchParams.malePercentMax"
          :female-min.sync="searchParams.femalePercentMin"
          :female-max.sync="searchParams.femalePercentMax"
          :interests-id.sync="searchParams.interestsId"
          :age-min.sync="searchParams.ageMin"
          :age-max.sync="searchParams.ageMax"
          :disabled="isLoading"
          :reset="paramsIsEnabled" />
          <NumericFilter
          :title="$t('posts_count')"
          :search-type.sync="searchParams.postsType"
          :min.sync="searchParams.postsMin"
          :max.sync="searchParams.postsMax"
          :to-max="getAggregateForPosts"
          :reset="paramsIsEnabled"
          :disabled="isLoading"
          type="posts" />
        </div>
        <transition
        name="fade"
        :duration="{ enter: 600, leave: 200 }">
          <div
          v-if="showAllFilters"
          class="group-filter__filters-container">
            <NumericFilter
            :title="$t('growth')"
            :search-type.sync="searchParams.growType"
            :min.sync="searchParams.growMin"
            :max.sync="searchParams.growMax"
            :to-max="getAggregateForGrow"
            :reset="paramsIsEnabled"
            :disabled="isLoading"
            type="grow" />
            <NumericFilter
            title="CPM"
            :min.sync="searchParams.cpmMin"
            :max.sync="searchParams.cpmMax"
            :to-max="getGroupsAggregateValues.cpmMax"
            :disabled="isLoading"
            :placeholder="$t('any_cpm-er')"
            :reset="paramsIsEnabled"
            :menu-width="220" />
            <NumericFilter
            title="CPС"
            :min.sync="searchParams.cpсMin"
            :max.sync="searchParams.cpсMax"
            :to-max="50000"
            :disabled="isLoading"
            :placeholder="$t('any_cpm-er')"
            :reset="paramsIsEnabled"
            :menu-width="220" />
            <NumericFilter
            title="ER"
            :min.sync="searchParams.erMin"
            :max.sync="searchParams.erMax"
            :to-max="getGroupsAggregateValues.erMax"
            :disabled="isLoading"
            :placeholder="$t('any_cpm-er')"
            :reset="paramsIsEnabled"
            :menu-width="220" />
            <DatesFilter
            v-if="isAdmin"
            :updated-at-min.sync="searchParams.dataUpdatedAtFrom"
            :updated-at-max.sync="searchParams.dataUpdatedAtTo"
            :created-at-min.sync="searchParams.createdAtFrom"
            :created-at-max.sync="searchParams.createdAtTo"
            :post-price-updated-at-min.sync="searchParams.postPriceUpdatedAtFrom"
            :post-price-updated-at-max.sync="searchParams.postPriceUpdatedAtTo"
            :disabled="isLoading"
            :reset="paramsIsEnabled" />
            <AdminFilters
            v-if="isAdmin"
            :disabled="isLoading"
            :in-social-jet.sync="searchParams.inSocialjet"
            :in-black-list.sync="searchParams.inBlackList"
            :is-blogger.sync="searchParams.isBlogger"
            :bot-name.sync="searchParams.botName"
            :payment-type.sync="searchParams.paymentType"
            :only-bad-groups.sync="searchParams.onlyBadGroups"
            :post-sale-bigger-than-buy.sync="searchParams.postSaleBiggerThanBuy"
            :warnings.sync="searchParams.inspectReportWarningCodes"
            :reset="paramsIsEnabled" />
            <OtherFilters
            :advertising-label.sync="searchParams.advertisingLabel"
            :placement-in-feed.sync="searchParams.placementInFeed"
            :placement-in-top.sync="searchParams.placementInTop"
            :roboticity-min.sync="searchParams.roboticityMin"
            :roboticity-max.sync="searchParams.roboticityMax"
            :disabled="isLoading"
            :reset="paramsIsEnabled" />
          </div>
        </transition>
      </div>
      <FilterResults
      :price-stats="getGroupsAggregateValues"
      :disabled="isLoading"
      :with-price.sync="searchParams.containsPostPriceSell"
      :reset="paramsIsEnabled" />
    </div>
    <FilterControls
    :show-all-filters="showAllFilters"
    :params-is-enabled="paramsIsEnabled"
    @resetAllFilters="resetAllFilters"
    @toggleAllFilters="showAllFilters = !showAllFilters" />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import groupsData from '@/assets/groupsData.json';

import SocialSwitcher from './components/SocialSwitcher.vue';
import FilterControls from './components/FilterControls.vue';
import FilterResults from './components/FilterResults.vue';
import AccountFilter from './components/AccountFilter.vue';
import CategoriesFilter from './components/CategoriesFilter.vue';
import NumericFilter from './components/NumericFilter.vue';
import AuditoryFilter from './components/AuditoryFilter.vue';
import DatesFilter from './components/DatesFilter.vue';
import AdminFilters from './components/AdminFilters.vue';
import GeographyFilter from './components/GeographyFilter.vue';
import OtherFilters from './components/OtherFilters.vue';

export default {
  name: 'GroupsFilter',
  components: {
    SocialSwitcher,
    FilterControls,
    AccountFilter,
    FilterResults,
    CategoriesFilter,
    NumericFilter,
    AuditoryFilter,
    DatesFilter,
    AdminFilters,
    GeographyFilter,
    OtherFilters,
  },
  data: () => ({
    showAllFilters: false,
    searchParams: null,
  }),
  created() {
    this.searchParams = { ...this.getSearchParams };
    this.filtersData = groupsData;
  },
  methods: {
    resetAllFilters() {
      this.$store.commit('groups/setInitial', true);
      this.$store.commit('groups/resetGroupsSearchParams');
      this.searchParams = { ...this.getSearchParams };
      this.$router.push({ path: this.$router.currentPath, query: {} });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['groups/getGroupsIsLoading'];
    },
    getGroupsAggregateValues() {
      return this.$store.getters['groups/getGroupsAggregateValues'];
    },
    getSearchParams() {
      return this.$store.getters['groups/getGroupsSearchParams'];
    },
    getSearchNameOrUrl() {
      return this.getSearchParams.nameOrUrl;
    },
    paramsIsEnabled() {
      return !isEqual(this.searchParams, this.$store.getters['groups/getGroupsSearchParamsTemplate']);
    },
    isResetParams() {
      return isEqual(this.getSearchParams, this.$store.getters['groups/getGroupsSearchParamsTemplate']);
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    getAggregateForViews() {
      if (this.searchParams.viewsType === 'last48h') {
        return this.getGroupsAggregateValues.viewsLast48hMax;
      }
      if (this.searchParams.viewsType === 'last72h') {
        return this.getGroupsAggregateValues.viewsLast72hMax;
      }
      return this.getGroupsAggregateValues.viewsLast24hMax;
    },
    getAggregateForPosts() {
      if (this.searchParams.postsType === 'lastMonth') {
        return this.getGroupsAggregateValues.postsLastMonthMax;
      }
      if (this.searchParams.postsType === 'lastWeek') {
        return this.getGroupsAggregateValues.postsLastWeekMax;
      }
      return this.getGroupsAggregateValues.postsLast24hMax;
    },
    getAggregateForGrow() {
      if (this.searchParams.growType === 'month') {
        return this.getGroupsAggregateValues.monthGrowMax;
      }
      return this.getGroupsAggregateValues.dayGrowMax;
    },
  },
  watch: {
    searchParams: {
      deep: true,
      handler(newValue, prevValue) {
        if (this.isLoading) {
          return;
        }
        if (newValue !== null && prevValue === null) {
          return;
        }
        const params = pickBy(this.searchParams, (param) => param !== null);
        const query = { group: this.getSelectedTypeOfGroups, ...params };
        this.$router.push({ path: this.$router.currentPath, query });
        this.$store.commit('collections/resetAllShow');
        this.$store.commit('collections/setShowCollectionGroups', false);
        this.$store.commit('groups/setLoadPage', 1);
        this.$store.commit('groups/setGroupsSearchParams', this.searchParams);
        this.$store.commit('groups/setGroupsData', []);
        this.$store.dispatch('groups/loadGroupsData', { aggregate: true });
      },
    },
    isResetParams(newValue, prevValue) {
      if (!prevValue && newValue) {
        this.resetAllFilters();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-filter {
  width: 100%;
  max-width: 1418px;
  padding: 0px 20px;
  margin: 63px auto 36px;
  border-radius: 12px;
  position: relative;
}

.groups-filter__nav {
  width: 100%;
  height: 30px;
}

.group-filter__main-container {
  width: 100%;
  border-radius: 12px;
  padding: 30px 20px 25px;
  background: $primary-white;
  transition: 0.2s;
  margin-bottom: 13px;
  position: relative;
  justify-content: center;
  display: flex;
  @include max-screen {
    padding: 30px 20px 15px;
    justify-content: space-between;
  }
}

.group-filter__filters {
  height: 40px;
  transition: 0.2s;
  display: block;
  overflow: hidden;
  &_show-all {
    height: 100%;
    transition: 0.2s;
    overflow: visible;
  }
  @include max-screen {
    height: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: visible;
    flex-wrap: nowrap;
    &_show-all {
      height: 105px;
      transition: 0.2s;
    }
  }
}

.group-filter__filters-container {
  display: flex;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 5px;
  }
  @include max-screen {
    flex-wrap: nowrap;
    @include between-elements {
    padding-bottom: 10px;
  }
  }
}

.groups-filter__text {
  font-size: 14px;
  color: $primary-black;
  line-height: 16px;
}

.groups-filter__show-all-button {
  position: absolute;
  left: 40px;
  bottom: -30px;
  cursor: pointer;
}
</style>
