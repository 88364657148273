import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // если имеется сохраненная позиция скролла на конкретном роуте, открываем страницу с этой позиции
    // если нет, открываем с верха
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // если неавторизованный переходит по ссылке с подборкой (не основная ссылка, а ссылка с groups)
  if (!localStorage.getItem('userToken') && to.name === 'main-groups' && to.query.compilation && to.query.shareToken) {
    const query = {
      id: to.query.compilation,
      shareToken: to.query.shareToken,
    };
    next({ name: 'compilation', query });
    return;
  }

  // если неавторизованный, то отправляем на лендинг
  const noAuthAccess = ['login', 'main-landing', 'publishers', 'group', 'compilation'].includes(to.name);
  if (!to.query['passthrough-token'] && !localStorage.getItem('userToken') && !noAuthAccess) {
    next({ name: 'main-landing' });
    return;
  }

  if (to.query['passthrough-token']) {
    // в случае переавторизации в любом случае чистим local storage
    localStorage.clear();
    await store.dispatch('auth/getToken', to.query['passthrough-token'])
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'main-landing' });
        setTimeout(() => {
          store.dispatch('app/setSnackBar', {
            text: 'Ошибка при авторизации. Проверьте правильность ссылки и токена',
            error: true,
          });
        }, 1000);
      });
  } else {
    next();
  }
});

export default router;
