<template>
  <div
  v-if="!isLoading"
  class="not-found">
    <span class="not-found__text">404</span>
    <span class="not-found__text">{{ $t('page_not_found') }}</span>
    <TJButton
    text="Перейти в каталог"
    :width="250"
    class="not-found__button"
    @handler="goToMain" />
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goToMain() {
      this.$router.push({ name: 'main-groups' });
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['groups/getGroupsIsLoading'] || this.$store.getters['collections/getCollectionsIsLoading'];
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
}

.not-found__text {
  font-size: 26px;
}

.not-found__button {
  margin-top: 30px;
}

</style>
