<template>
  <div
  class="group-price">
    <div
    class="group-price__content pointer"
    @click="openMenu">
      <div
      class="group-price__post"
      :class="postPrice[priceType] && 'group-price__post_price'">
        <span
        class="group-price__text group-price__text_price">
          {{ getLabel() }}
        </span>
        <TJIcon
        v-if="postPrice[priceType]"
        name="expand_more"
        :size="20"
        class="group-price__text_price"
        :arrow-open="showMenu" />
      </div>
    </div>

    <!-- MENU -->

    <transition name="fade">
      <div
      v-if="showMenu"
      v-click-outside="onClickOutside"
      class="group-price__menu">
        <span class="group-price__text">Тип публикации</span>
        <div class="group-price__price-container">
          <div class="group-price__price-radios">
            <div
            v-for="type in getPriceTypes()"
            :key="type.name"
            class="group-price__price-radio"
            :class="!type.disabled && 'pointer'"
            @click="changePriceType(type)">
              <DataRadio
              :disabled="type.disabled"
              :checked="selectedPriceType === type.name" />
              <span
              class="group-price__text"
              :class="type.disabled && 'group-price__text_light'">
                {{ $t(type.name) }}
              </span>
            </div>
          </div>

          <div class="group-price__prices">
            <div
            v-for="variant in priceVariants"
            :key="variant.name"
            :class="isAdmin && priceType !== 'sell' && variant.editable ? 'pointer' : ''"
            class="group-price__price"
            @click.stop="!variant.showInput && openPriceInput(variant)">
              <span class="group-price__text">{{ variant.name }}</span>
              <span
              v-if="!variant.showInput"
              class="group-price__text group-price__text_price"
              :class="!getPrice(variant.value) && 'group-price__text_light'">
                {{ getPrice(variant.value) ? getPrice(variant.value) : $t('no_data_available') }}
              </span>
              <input
              v-if="variant.showInput"
              ref="priceVariants"
              v-model="inputPrice"
              class="group-price__price-input"
              type="number"
              @keyup.enter="savePrice(variant)">
              <TJIcon
              v-if="isAdmin && variant.editable && priceType !== 'sell'"
              :name="variant.showInput ? 'done' : 'edit'"
              class="group-price__icon"
              :class="variant.showInput && 'group-price__icon_save'"
              :size="14"
              pointer
              @click.native.stop="variant.showInput ? savePrice(variant) : openPriceInput(variant)" />
            </div>
          </div>
        </div>

        <div class="group-price__checkboxes">
          <div class="group-price__price-radio">
            <DataCheckbox
            v-model="advertisingLabelValue"
            :nochange="!isAdmin"
            with-null
            @input="changeCheckbox" />
            <span class="group-price__text">{{ $t('advertising_label') }}</span>
          </div>
          <div class="group-price__price-radio">
            <DataCheckbox
            v-model="withoutDelete"
            :nochange="!isAdmin"
            @input="changeCheckbox" />
            <span class="group-price__text">{{ $t('without_delete') }}</span>
          </div>
        </div>

        <GroupPriceChart
        v-if="!loadingHistory && priceData.length"
        class="group-price__chart"
        :chart-data="priceData" />

        <!-- История изменений -->
        <div
        v-if="!loadingHistory && historyData.length"
        class="group-price__history">
          <span class="group-price__text">
            Журнал изменений цены
          </span>
          <div
          v-for="data in historyData"
          :key="data.id"
          class="group-price__history-element"
          :class="isAdmin && 'group-price__history-element_user'">
            <span class="group-price__text group-price__text_light">
              {{ data.fulldate }}
            </span>
            <span class="group-price__text group-price__text_small">
              {{ getHistoryValue(data.value) }}
            </span>
            <span
            v-if="isAdmin"
            style="text-align: right;"
            class="group-price__text group-price__text_light">
              {{ data.user }}
            </span>
          </div>
        </div>
        <div
        v-if="!loadingHistory && !historyData.length"
        class="group-price__history group-price__history_empty">
          <span class="group-price__text group-price__text_light">
            Нет данных об изменении цены
          </span>
        </div>

        <div
        v-if="loadingHistory"
        class="group-price__loading">
          <img
          class="group-price__loading-img"
          src="@/assets/images/rocket.gif">
          <span class="group-price__text group-price__text_loading">Загрузка истории...</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import GroupPriceChart from './GroupPriceChart.vue';

export default {
  name: 'GroupPrice',
  components: { GroupPriceChart },
  props: {
    postPrice: {
      type: Object,
      default: null,
    },
    integrationPrice: {
      type: Object,
      default: null,
    },
    storyPrice: {
      type: Object,
      default: null,
    },
    reelPrice: {
      type: Object,
      default: null,
    },
    priceType: {
      type: String,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    tradeOffer: {
      type: Object,
      default: null,
    },
    selectedTypeOfGroups: {
      type: [String, Number],
      default: null,
    },
    placementInTop: {
      type: [String, Number],
      default: null,
    },
    placementInFeed: {
      type: [String, Number],
      default: null,
    },
    advertisingLabel: {
      type: [Boolean, String],
      default: null,
    },
  },
  data: () => ({
    showMenu: false,
    // FUTURE перенести currency в APP state
    currencySymbols: [
      {
        name: '₽',
        value: 'rub',
      },
      {
        name: 'USD',
        value: 'usd',
      },
      {
        name: 'KZT',
        value: 'kzt',
      },
      {
        name: 'UZS',
        value: 'uzs',
      },
    ],
    priceVariants: [
      {
        name: '1/24ч', value: 'post24', editable: true, showInput: false,
      }, // default sell : buy : sale
      {
        name: '48ч', value: 'post48', editable: false, showInput: false,
      }, // данных еще нет
      {
        name: '72ч', value: 'post72', editable: false, showInput: false,
      }, // данных еще нет
      { name: 'Без удаления', value: 'without-delete', editable: false }, // данных еще нет
    ],
    selectedPriceType: 'postPrice',
    advertisingLabelValue: false,
    withoutDelete: false,
    loadingHistory: false,
    showInput: false,
    inputPrice: '',
    oldPrice: null,
    topValue: null,
    feedValue: null,
    historyData: [],
    priceData: [],
  }),
  methods: {
    getCurrencySymbol() {
      // FUTURE перенести currency в APP state
      const currency = this.$store.getters['groups/getCurrency'];
      return this.currencySymbols.find((item) => item.value === currency).name;
    },
    getLabel() {
      // основной label для колонки
      if (this.priceType === 'sell' && this.tradeOffer) {
        return `${this.setNumberSpaces(Math.round(this.tradeOffer.price))} ${this.getCurrencySymbol()}`;
      }
      if (!this.$props.postPrice[this.priceType]) {
        return 'Узнать +';
      }
      return `${this.setNumberSpaces(Math.round(this.$props.postPrice[this.priceType]))} ${this.getCurrencySymbol()}`;
    },
    getPrice(value) {
      // prices - объект с ценой - пост, интеграция, сторис и тд
      const prices = this.$props[this.selectedPriceType];
      if (!prices) {
        return false;
      }
      if (value === 'post24') {
        const price = prices[this.priceType];
        return price ? `${this.setNumberSpaces(Math.round(price))} ${this.getCurrencySymbol()}` : false;
      }
      return prices[value] || false;
    },
    getPriceTypes() {
      // список типов цены, доступных для конкретной соц сети
      const allTypes = ['postPrice', 'integrationPrice', 'storyPrice', 'Закреп'];
      const socialsPriceTypes = [
        { social: 'telegram', priceTypes: ['postPrice', 'integrationPrice'] },
        { social: 'vk', priceTypes: ['postPrice', 'integrationPrice', 'storyPrice'] },
        { social: 'instagram', priceTypes: ['postPrice', 'integrationPrice', 'storyPrice'] },
        { social: 'odnoklassniki', priceTypes: ['postPrice', 'integrationPrice'] },
        { social: 'youtube', priceTypes: ['postPrice', 'integrationPrice'] },
        { social: 'yandex-zen', priceTypes: ['postPrice', 'integrationPrice'] },
      ];
      const currentSocial = socialsPriceTypes.find((item) => item.social === this.selectedTypeOfGroups);
      return allTypes.map((item) => ({ name: item, disabled: !currentSocial.priceTypes.includes(item) }));
    },
    changePriceType(type) {
      if (type.disabled) return;
      this.closeAllInputs();
      this.selectedPriceType = type.name;
    },
    openMenu() {
      // при открытии редактора отключаем возможность показывать мини-меню при наведении на канал
      this.$store.commit('groups/setShowGroupMenu', true);

      this.getPriceHistory();

      this.advertisingLabelValue = this.advertisingLabel;
      if (this.placementInFeed === 0 && this.placementInTop === 0) {
        this.withoutDelete = true;
      }
      this.topValue = this.placementInTop;
      this.feedValue = this.placementInFeed;

      this.showMenu = true;
    },
    closeEditor() {
      // при закрытии редактора включить возможность показывать мини-меню при наведении на канал
      this.$store.commit('groups/setShowGroupMenu', false);
      this.closeAllInputs();
      this.selectedPriceType = 'postPrice';
      this.historyData = [];
      this.showMenu = false;
    },
    onClickOutside() {
      this.closeEditor();
    },
    getPriceHistory() {
      if (this.historyData.length) return;
      this.loadingHistory = true;

      const params = {
        params: {
          groupIdentity: { id: this.group.id, social: this.group.social },
          propertiesFilter: [`postPrice.${this.priceType}`],
        },
      };

      this.$store.dispatch('group/getPriceHistory', params)
        .then((data) => {
          const items = data.map((item, index) => {
            const price = item.changes.find((change) => change.propertyPath === `postPrice.${this.priceType}`);
            return {
              id: index,
              date: new Date(item.dateTime.date).getTime(),
              fulldate: this.moment(item.dateTime.date).format('DD MMM YYYY'),
              value: price.newValue ? Math.trunc(price.newValue) : null,
              oldValue: price.oldValue ? Math.trunc(price.oldValue) : null,
              user: item.user?.email || item.botName || null,
            };
          });
          // uniqBy - убираем дубликаты по датам (выпилить, если это будет делать сервер)
          // reverse чтобы в уникальных оставалась последняя указанная на день цена
          const filtered = uniqBy(items.reverse(), (item) => item.fulldate).reverse();
          this.priceData = [...filtered];
          this.historyData = [...this.priceData].reverse();
          this.loadingHistory = false;
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('An error occurred while get the data'), error: true });
          this.loadingHistory = false;
        });
    },
    getHistoryValue(value) {
      // FUTURE валюта ?
      return `${this.setNumberSpaces(Math.round(value))} ₽`;
    },
    closeAllInputs() {
      this.priceVariants.forEach((item) => {
        item.showInput = false;
      });
    },
    openPriceInput(variant) {
      if (!this.isAdmin || variant.showInput || !variant.editable || this.priceType === 'sell') return;
      this.closeAllInputs();
      const price = this.getPrice(variant.value);
      this.inputPrice = price ? price.replace(/[\D]+/g, '') : '';
      this.oldPrice = this.inputPrice;
      this.priceVariants.find((item) => item.value === variant.value).showInput = true;
      this.$nextTick(() => {
        this.$refs.priceVariants[0].focus();
      });
    },
    changeCheckbox() {
      const newData = {
        advertisingLabel: this.advertisingLabelValue,
      };
      if (this.withoutDelete) {
        newData.placementInTop = 0;
        newData.placementInFeed = 0;
      } else {
        newData.placementInTop = this.topValue ? this.topValue : null;
        newData.placementInFeed = this.feedValue ? this.feedValue : null;
      }

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
          this.group.advertisingLabel = this.advertisingLabelValue;
          if (this.withoutDelete) {
            this.group.placementInTop = 0;
            this.group.placementInFeed = 0;
          } else {
            this.group.placementInTop = this.topValue ? this.topValue : null;
            this.group.placementInFeed = this.feedValue ? this.feedValue : null;
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
    savePrice() {
      // не сохраняем, если поле не поменялось
      if (this.inputPrice === this.oldPrice) {
        this.closeAllInputs();
        return;
      }

      const newData = {
        [this.selectedPriceType]: {
          [this.priceType]: this.inputPrice ? +this.inputPrice : null,
        },
      };
      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
          this.group[this.selectedPriceType][this.priceType] = this.inputPrice ? +this.inputPrice : null;
          this.closeAllInputs();
        })
        .catch(() => {
          this.closeAllInputs();
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.group-price {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 180px;
}

.group-price__post {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  padding: 5px 10px;
  border: 1px solid rgba(#00AEE8, 0.20);
  border-radius: 8px;
  @include between-elements {
    margin-right: 4px;
  }
  &_price {
    border: none;
    min-width: 130px;
    background: rgba(#00AEE8, 0.15);
  }
}

.group-price__text {
  font-size: 14px;
  color: $primary-black;
  &_price {
    color: $primary-blue;
  }
  &_light {
    color: $primary-lightgray;
    font-weight: 400;
  }
  &_loading {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

// MENU
.group-price__menu {
  position: absolute;
  top: -10px;
  left: 25px;
  background-color: white;
  width: 550px;
  z-index: 1;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  border-radius: 12px;
  padding: 20px;
}

// PRICE
.group-price__price-container {
  margin-top: 20px;
  display: flex;
}

.group-price__price-radios {
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  border-right: 1px solid #CCEFFA;
}

.group-price__price-radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-price__prices {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}

.group-price__price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  @include between-elements {
    margin-right: 10px;
  }
  &:hover {
    .group-price__icon {
      opacity: 1;
    }
  }
}

.group-price__checkboxes {
  margin: 20px 0px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-price__history {
  display: flex;
  flex-direction: column;
  max-height: 130px;
  overflow: auto;
  &_empty {
    border-radius: 10px;
    height: 50px;
    border: 1px solid rgb(240, 240, 240);
    justify-content: center;
    align-items: center;
  }
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-price__history-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_user {
    display: grid;
    grid-template-columns: 25% 20% 50%;
    justify-content: flex-start;
    gap: 10px;
  }
}

.group-price__price-input {
  outline: 1px solid rgb(241, 241, 241);
  padding: 2px;
  border: none;
  width: 120px;
  background: white;
  font-size: 14px;
  color: $primary-blue;
  -webkit-appearance: none;
}

.group-price__icon {
  opacity: 0;
  color: rgb(199, 199, 199);
  &_save {
    opacity: 1;
    color: $primary-lightgray;
  }
}

.group-price__chart {
  margin-bottom: 20px;
  height: 250px;
  border-radius: 8px;
  border: 1px solid #D8F2FA;
  box-shadow: 0px 5px 12px -5px rgba(0, 174, 232, 0.05);
}

.group-price__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-price__loading-img {
  width: 30px;
  height: 30px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
