import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    clientsData: [],
    clientsLoading: false,
  },
  getters: {
    getClientsData: (state) => state.clientsData,
  },
  mutations: {
    setClientsData: (state, value) => { state.clientsData = value; },
    setClientsLoading: (state, value) => { state.clientsLoading = value; },
  },
  actions: {
    getClientsData({ commit }) {
      return api.get('/agency')
        .then(({ data: { data } }) => {
          commit('setClientsData', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getClientsData --; Error -', error);
          return Promise.reject(error);
        });
    },
    createClient({ state, commit }, client) {
      return api.post('/agency', client)
        .then((data) => {
          commit('setClientsData', [data, ...state.clientsData]);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- createClient --; Error -', error);
          return Promise.reject();
        });
    },
    updateClient({ state, commit }, params) {
      return api.patch(`/agency/${params.id}`, { name: params.name, rate: params.rate })
        .then(() => {
          const newData = state.clientsData.map((item) => {
            if (item.id === params.id) {
              item.name = params.name;
              item.rate = params.rate;
            }
            return item;
          });
          console.log(newData);
          commit('setClientsData', newData);
          Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- updateClient --; Error -', error);
          return Promise.reject(error);
        });
    },
    createUserAndAdd({ state, commit }, userData) {
      return api.post('/agency/create-user-and-add', userData)
        .then(({ data: { data } }) => {
          const newData = state.clientsData.map((item) => {
            if (item.id === userData.agencyId) {
              item.employees.push(data);
            }
            return item;
          });
          commit('setClientsData', newData);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- createUserAndAdd --; Error -', error);
          return Promise.reject();
        });
    },
  },
};
