export default {

  install(Vue) {
    // Добавляем пробел к каждой тысяче в числе
    Vue.prototype.setNumberSpaces = (number) => new Intl.NumberFormat('ru-RU').format(number);

    // Формируем окончание для множественных форм слова
    // Example getPluralWord(5, ['канал', 'канала', 'каналов']) // 5 каналов
    Vue.prototype.getPluralWord = (number, words) => {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    };

    // Копирование в буфер обмена (при ошибке вывод в promt);
    Vue.prototype.copyToBuffer = (text) => {
      const el = document.createElement('textarea');
      el.textContent = text;
      document.body.appendChild(el);
      el.select();
      const result = document.execCommand('copy');
      if (!result) {
        // for iOS / Safari and other errors
        prompt('Скопируйте ссылку', text); // eslint-disable-line no-alert
        return true;
      }
      document.body.removeChild(el);
      return result;
    };
  },
};
