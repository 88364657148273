<template>
  <div
  class="app-loader">
    <img
    class="app-loader__rocket"
    src="@/assets/images/rocket.gif">
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
};
</script>

<style lang="scss" scoped>
.app-loader {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  z-index: 99;
  bottom: 0;
  padding-left: -20px;
  background: rgba(255, 255, 255, 0.7);
}
.app-loader__rocket {
  top: 50%;
  left: 50%;
  margin-left: -40px;
  position: absolute;
  width: 64px;
  height: 64px;
}
</style>
