import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import moment from 'moment';
import { VTooltip } from 'floating-vue';
import vTitle from 'vuejs-title';
import App from './App.vue';
import router from './router';
import store from './store';
import appComponents from './plugins/app-components';
import appHelpers from './plugins/app-helpers';
import i18n from './plugins/i18n';
import 'floating-vue/dist/style.css';

Vue.directive('tooltip', VTooltip);
Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.use(vClickOutside);
Vue.use(appComponents);
Vue.use(appHelpers);
Vue.use(vTitle, {
  fontSize: '12px',
  bgColor: '#00AEE8',
  maxHeight: '400px',
  maxWidth: '300px',
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
