<template>
  <div
  class="nav-actions"
  :class="emptyData && 'nav-actions_hide-details'">
    <div
    v-show="!emptyData"
    class="nav-actions__details">
      <span
      class="nav-actions__text"
      @click="toggleAllGroupsToSelected">{{ textForSetAllGroupsButton }}</span>
      <span
      class="nav-actions__text"
      @click="setHideGroupDetails">{{ getHideButtonText }}</span>
      <span
      v-if="!isAdmin && isShowCollectionGroups"
      class="nav-actions__text"
      @click="openExportModal">{{ $t('export') }}</span>
      <ShareGroups
      v-if="mode !== 'noauth'"
      :is-admin="isAdmin"
      :selected-groups="getSelectedGroups"
      :is-empty-selected="isEmptySelected" />
    </div>
    <div
    v-if="mode !== 'noauth'"
    class="nav-actions__actions">
      <div class="nav-actions__collections">
        <Compilations
        v-if="mode !== 'my-groups'"
        :is-show-favorite-groups="isShowFavoriteGroups"
        :is-show-collection-groups="isShowCollectionGroups" />
        <Favorites
        v-if="mode !== 'my-groups'"
        :is-show-favorite-groups="isShowFavoriteGroups"
        :is-show-collection-groups="isShowCollectionGroups" />
      </div>
      <AdminButtons v-if="isAdmin" />
      <NavSettings />
    </div>
  </div>
</template>

<script>
import Compilations from './components/Compilations.vue';
import Favorites from './components/Favorites.vue';
import AdminButtons from './components/AdminButtons.vue';
import NavSettings from './components/NavSettings.vue';
import ShareGroups from './components/ShareGroups.vue';

export default {
  name: 'NavActions',
  components: {
    Compilations, Favorites, AdminButtons, NavSettings, ShareGroups,
  },
  props: {
    emptyData: {
      type: Boolean,
      default: false,
    },
    groupsData: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    allSelected: false,
  }),
  created() {
    const isHideDetails = JSON.parse(localStorage.getItem('hideDetails'));
    this.$store.commit('groups/setHideGroupDetails', isHideDetails === null ? true : isHideDetails);
  },
  methods: {
    setHideGroupDetails() {
      localStorage.setItem('hideDetails', !this.getHideGroupDetails);
      this.$store.commit('groups/setHideGroupDetails', !this.getHideGroupDetails);
    },
    toggleAllGroupsToSelected() {
      if (this.allSelected) {
        // если нажимаем сбросить, то удаляем из выбранных только текущие каналы на странице
        const groupsToUnselected = this.getSelectedGroups.filter((item) => !this.groupsData.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('collections/setSelectedGroups', groupsToUnselected);
      } else {
        // если нажимаем выбрать все, то добавляем в выбранные каналы со страницы, за исключением тех, которые уже есть в выбранных
        const groupsToSelected = this.groupsData.filter((item) => !this.getSelectedGroups.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('collections/setSelectedGroups', [...this.getSelectedGroups, ...groupsToSelected]);
      }
      this.allSelected = !this.allSelected;
      if (!this.allSelected) {
        this.$store.commit('collections/setSelectedAllGroups', this.allSelected);
      }
    },
    openExportModal() {
      const modalParams = { name: 'ExportGroupsModal', props: { mode: this.mode } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    getHideButtonText() {
      return this.getHideGroupDetails ? this.$t('show_details') : this.$t('hide_details');
    },
    getHideGroupDetails() {
      return this.$store.getters['groups/getHideGroupDetails'];
    },
    getSelectedGroups() {
      return this.$store.getters['collections/getSelectedGroups'];
    },
    isEmptySelected() {
      return this.getSelectedGroups.length === 0;
    },
    textForSetAllGroupsButton() {
      return this.isAllSelected ? this.$t('reset') : this.$t('select_all');
    },
    isAllSelected() {
      return this.groupsData.every((item) => this.getSelectedGroups.some((el) => el.name === item.name));
    },
    isShowFavoriteGroups() {
      return this.$store.getters['collections/getShowFavoriteGroups'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
  },
  watch: {
    isAllSelected(newValue) {
      this.allSelected = newValue;
      if (!newValue) {
        this.$store.commit('collections/setSelectedAllGroups', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 10px 0px;

  @include between-elements {
    padding-bottom: 10px;
  }

  @include tablet-screen {
    margin: 10px 20px 0px;
      @include between-elements {
      padding-bottom: 0px;
    }
  }
  padding-bottom: 10px;
  &_hide-details {
    justify-content: flex-end;
  }
}

.nav-actions__details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 25px;
  }
}

.nav-actions__actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 25px;
    padding-bottom: 10px;
    @include desktop-screen {
      padding-bottom: 0px;
    }
  }
}

.nav-actions__collections {
  display: flex;
  justify-content: center;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.nav-actions__text {
  font-size: 13px;
  cursor: pointer;
}

</style>
