<template>
  <div class="publishers">
    <section class="intro intro--landing">
      <header class="header container">
        <nav class="header__nav nav">
          <a
          class="logo logo--landing"
          href="/">
            <img
            class="logo__img"
            src="@/assets/images/publishers/logo.svg"
            alt="логотип"
            width="120"
            height="56">
          </a>
        </nav>
      </header>

      <div class="intro__inner container">
        <div class="intro__description">
          <h1 class="intro__title">
            Hello! Ты <span id="ubuntu" class="bright">паблишер?</span>
          </h1>
          <p class="intro__subtitle">
            Получи <b>бесплатный</b> доступ к SJ DATA -  крупнейшей базе данных <br> по соц.сетям и мессенджерам
          </p>
          <p class="intro__text">
            Аналитика по сотням тысяч каналов и&nbsp;заявки от сотен <br>рекламодателей федерального уровня
          </p>
          <a
          class="intro__btn btn btn--bright"
          href="http://t.me/sj_welcome_bot">Получить доступ</a>
        </div>
        <figure class="intro__picture">
          <picture>
            <source
            shape-rendering="auto"
            media="(min-width: 500px)"
            srcset="@/assets/images/publishers/datascreen.png">
            <img
            src="@/assets/images/publishers/intro-svg-mobile.svg"
            alt="">
          </picture>
        </figure>
      </div>
    </section>

    <section class="how-look container">
      <h2 class="how-look__title">
        Что внутри
      </h2>
      <p class="how-look__subtitle">
        В первую очередь - глубокая аналитика по сотням тысяч каналов, <br> а также заявки от рекламодателей и коллег по цеху
      </p>
      <ul class="how-look__list">
        <li class="how-look__item">
          <p>Доступ к более чем 200 000 каналам</p>
        </li>
        <li class="how-look__item">
          <p>Топ&nbsp;100 рекламодателей на&nbsp;рынке СНГ</p>
        </li>
        <li class="how-look__item">
          <p>Более 500 рекламных кампаний в&nbsp;месяц</p>
        </li>
      </ul>
      <a
      class="how-look__btn btn btn--bright"
      href="http://t.me/sj_welcome_bot">Получить доступ</a>
    </section>

    <footer
    id="footer"
    class="footer">
      <section class="ask-question">
        <div class="ask-question__inner">
          <header class="ask-question__header">
            <h2 class="ask-question__title">
              Остались вопросы?
            </h2>

            <p class="ask-question__subtitle">
              Свяжись с нами в мессенджерах, соц.сетях или по почте <a
              href="mailto:partners@socialjet.pro">partners@socialjet.pro</a>
            </p>
            <ul class="ask-question__social social">
              <li>
                <a
                class="social__link social__link--vk"
                href="https://vk.com/socialjet"
                aria-label="ВКонтакте"
                target="_blank" />
              </li>
              <li>
                <a
                class="social__link social__link--telegram"
                href="https://t.me/socialjet"
                aria-label="Telegram"
                target="_blank" />
              </li>
              <li>
                <a
                class="social__link social__link--zen"
                href="https://zen.yandex.ru/id/6023a5d824c236744ae451e3"
                aria-label="Yandex zen"
                target="_blank" />
              </li>
            </ul>
          </header>
          <div class="ask-question__map-wrap">
            <p class="ask-question__address">
              123003, г. Москва, ул. Большая Садовая, дом 5, корпус 1, офис 515
            </p>
            <div
            class="map"
            style="position:relative;overflow:hidden;">
              <a
              href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
              style="color:#eee;font-size:12px;position:absolute;top:0px;">Москва</a>
              <a
              href="https://yandex.ru/maps/213/moscow/house/bolshaya_sadovaya_ulitsa_5k1/Z04Ycw5hT0IDQFtvfXt3eH1jbQ==/?ll=37.592712%2C55.768730&utm_medium=mapframe&utm_source=maps&z=16.84"
              style="color:#eee;font-size:12px;position:absolute;top:14px;">г. Москва, ул. Большая Садовая, дом 5, корпус 1, офис 515 — Яндекс.Карты</a>
              <iframe
              src="https://yandex.ru/map-widget/v1/?pt=37.591730,55.769582&z=18&l=map"
              style="position:relative; width: 100%; height: 100%; border: none" />
            </div>
          </div>
        </div>
      </section>

      <div
      id="contacts"
      class="footer__inner container">
        <a
        class="footer__logo logo logo--landing"
        href="/">
          <img
          class="logo__img"
          src="@/assets/images/publishers/logo.svg"
          alt=""
          width="107"
          height="51">
        </a>

        <section class="footer__social-block">
          <h5 class="footer__social-title">
            Мы в социальных сетях
          </h5>
          <ul class="footer__social social">
            <li>
              <a
              class="social__link social__link--vk"
              href="https://vk.com/socialjet"
              aria-label="ВКонтакте"
              target="_blank" />
            </li>
            <li>
              <a
              class="social__link social__link--telegram"
              href="https://t.me/socialjet"
              aria-label="Telegram"
              target="_blank" />
            </li>
            <li>
              <a
              class="social__link social__link--zen"
              href="https://zen.yandex.ru/id/6023a5d824c236744ae451e3"
              aria-label="Yandex zen"
              target="_blank" />
            </li>
          </ul>
        </section>

        <ul class="footer__address">
          <!--      <li>ООО «СошелДжет рус»</li>-->
          <li class="footer__street">
            123001, г.&nbsp;Москва, ул.&nbsp;Большая<br> Садовая, дом&nbsp;5, корпус&nbsp;1, офис&nbsp;515
          </li>
          <li>
            <a
            class="footer__tel"
            href="tel:+74957685865">+7 (495) 768 58 65</a>
          </li>
          <li>
            <a
            class="footer__mailto"
            href="mailto:partners@socialjet.pro">partners@socialjet.pro</a>
          </li>
        </ul>

        <p class="footer__copyright">
          © 2015–2022 Socialjet
        </p>

        <a
        class="footer__doc-link"
        href="https://socialjet.pro/u_agreement"
        target="_blank">Публичная оферта</a>

        <a
        class="footer__doc-link"
        href="https://socialjet.pro/privacy"
        target="_blank">Политика конфиденциальности</a>
      </div>
    </footer>

    <a
    class="to-top"
    href="#"
    aria-label="Наверх" />

    <div
    id="ask-question"
    class="modal-block modal-block--ask-question hide">
      <div class="modal">
        <button class="modal__btn btn btn--close">
          ╳
        </button>
        <div class="modal__content ask-question">
          <h2>Задать вопрос</h2>
          <form class="ask-question__form form">
            <div class="form__input-wrap">
              <input
              class="form__input"
              type="text"
              placeholder="Имя">
            </div>
            <div class="form__input-wrap">
              <input
              class="form__input"
              type="text"
              placeholder="+7 (999) 999 99 99">
            </div>
            <input
            id="call2"
            type="radio"
            name="communication"
            checked>
            <label for="call2"><span aria-hidden="true" />Позвоните мне</label>
            <input
            id="tg2"
            type="radio"
            name="communication">
            <label for="tg2"><span aria-hidden="true" />Напишите в Телеграм</label>
            <button
            class="ask-question__btn btn btn--bright"
            type="submit">
              Отправить
            </button>
            <p class="form__conditions">
              Нажимая на кнопку, вы даете согласие на&nbsp;обработку <a href="#">персональных данных</a> и&nbsp;соглашаетесь c&nbsp;<a
              href="#">политикой конфиденциальности</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
/* eslint-disable */
@font-face {
  font-family: "Ubuntu";
  src: url("~@/assets/images/publishers/fonts/ubuntubold.woff2") format("woff2"), url("~@/assets/images/publishers/fonts/ubuntubold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.publishers {
  background: white;
}

img {
  max-width: 100%;
  vertical-align: top;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

input::-moz-placeholder {
  color: #ffffff;
}

input::placeholder {
  color: #ffffff;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}

a {
  text-decoration: none;
  transition: 0.5s;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

figure {
  margin: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

.visually-hidden {
  position: fixed;
  transform: scale(0);
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--x-padding);
  padding-right: var(--x-padding);
}

#ubuntu {
  font-family: Ubuntu !important;
}

h1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Ubuntu !important;
  font-size: clamp(30px, 3.7894736842vw, 72px);
  line-height: 1.1;
  font-weight: 700;
  color: #333333;
}

.page h1 {
  margin-bottom: 1em;
}

h2 {
  margin-top: 0;
  margin-bottom: 0.6em;
  font-family: Ubuntu !important;
  font-size: clamp(30px, 3.1578947368vw, 60px);
  font-weight: 400;
  line-height: 1.1;
  text-align: center;
}

h3 {
  font-weight: 400;
}

h4,
h5,
h6 {
  font-weight: 400;
}

.title-animation {
  -webkit-animation: slideLeft 1s forwards;
          animation: slideLeft 1s forwards;
}

.bright {
  color: #00AEE8;
}

.btn {
  display: inline-block;
  vertical-align: middle;
  min-width: 160px;
  max-width: 100%;
  padding: 1.5625em 3.1875em;
  border: none;
  border-radius: 2.17em;
  font-size: 18px;
  font-family: Ubuntu !important;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.btn:disabled {
  opacity: 0.8;
  cursor: auto;
}

.btn:hover,
.btn:focus {
  outline: none;
  opacity: 0.9;
}

.btn--bright {
  position: relative;
  border: none;
  background: radial-gradient(232.67% 446.04% at 21.73% -103.33%, rgba(255, 255, 255, 0.58) 0%, rgba(255, 255, 255, 0) 100%), #00aee8;
  box-shadow: 0 17px 37px -12px rgba(66, 144, 247, 0.47);
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s background-position;
}

.btn--bright:hover,
.btn--bright:focus {
  outline: none;
  opacity: 1;
  background-position: left center;
}

.btn--bright:active {
  opacity: 1;
}

.btn--ok {
  background: #00AEE8 linear-gradient(96.13deg, #15c7ff 1%, #1266cf 46%, #1266cf 55%, #15c7ff 99.09%) no-repeat right center/200% 100%;
  color: #ffffff;
  transition: 0.5s background-position;
}

.btn--ok:hover,
.btn--ok:focus {
  outline: none;
  opacity: 1;
  background-position: left center;
}

.logo {
  width: 81px;
}

.form__input {
  display: block;
  width: 100%;
}

.form__btn {
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 36px;
}

.social {
  --w: 44px;
  --g: 15px;
  padding-left: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, var(--w));
  gap: var(--g);
}

.social__link {
  --color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--w);
  height: var(--w);
  border-radius: 9px;
  background-color: rgba(255, 255, 255, 0.08);
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
}

.social__link::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  -webkit-mask-image: var(--img);
          mask-image: var(--img);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
}

.social__link:hover {
  background-color: rgba(255, 255, 255, 0.04);
}

.social__link:active {
  background-color: rgba(255, 255, 255, 0.06);
  opacity: 0.7;
}

.social__link--vk {
  --img: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.5533 17.37C26.036 16.7167 26.184 16.426 26.5533 15.842C26.56 15.8353 30.8307 9.93402 31.2707 7.93268L31.2733 7.93135C31.492 7.20202 31.2733 6.66602 30.216 6.66602H26.7173C25.8267 6.66602 25.416 7.12602 25.196 7.64068C25.196 7.64068 23.4147 11.9047 20.8947 14.6687C20.0813 15.4673 19.7053 15.7233 19.2613 15.7233C19.0427 15.7233 18.7027 15.4673 18.7027 14.738V7.93135C18.7027 7.05668 18.4533 6.66602 17.716 6.66602H12.2147C11.656 6.66602 11.324 7.07402 11.324 7.45402C11.324 8.28335 12.584 8.47402 12.7147 10.8073V15.87C12.7147 16.9793 12.5133 17.1833 12.0667 17.1833C10.8773 17.1833 7.99067 12.902 6.28 8.00202C5.93467 7.05135 5.59733 6.66735 4.7 6.66735H1.2C0.201333 6.66735 0 7.12735 0 7.64202C0 8.55135 1.18933 13.0727 5.53067 19.046C8.424 23.1234 12.4987 25.3327 16.2053 25.3327C18.4333 25.3327 18.7053 24.842 18.7053 23.998C18.7053 20.102 18.504 19.734 19.62 19.734C20.1373 19.734 21.028 19.99 23.108 21.9567C25.4853 24.2887 25.876 25.3327 27.2067 25.3327H30.7053C31.7027 25.3327 32.208 24.842 31.9173 23.874C31.252 21.838 26.756 17.65 26.5533 17.37Z' fill='%2300AEE8'/%3E%3C/svg%3E%0A");
}

.social__link--intagram {
  --img: url("data:image/svg+xml,%3Csvg width='32' height='36' viewBox='0 0 32 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.3333 6.36279H10.6666C7.72107 6.36279 5.33325 8.98454 5.33325 12.2186V23.9303C5.33325 27.1644 7.72107 29.7861 10.6666 29.7861H21.3333C24.2788 29.7861 26.6666 27.1644 26.6666 23.9303V12.2186C26.6666 8.98454 24.2788 6.36279 21.3333 6.36279Z' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 22.4664C18.2091 22.4664 20 20.5001 20 18.0745C20 15.6489 18.2091 13.6826 16 13.6826C13.7909 13.6826 12 15.6489 12 18.0745C12 20.5001 13.7909 22.4664 16 22.4664Z' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22 11.4863V11.4873' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.social__link--whatsapp {
  --img: url("data:image/svg+xml,%3Csvg width='32' height='36' viewBox='0 0 32 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 31.2499L6.2 25.6869C4.51688 23.0635 3.76356 19.856 4.08139 16.6661C4.39923 13.4762 5.76638 10.5234 7.92628 8.36166C10.0862 6.19995 12.8903 4.978 15.8125 4.92513C18.7347 4.87226 21.574 5.99211 23.7976 8.07451C26.0212 10.1569 27.4762 13.0587 27.8896 16.2353C28.303 19.412 27.6463 22.6451 26.0428 25.3279C24.4393 28.0106 21.9993 29.9587 19.1805 30.8065C16.3618 31.6542 13.3582 31.3434 10.7333 29.9323L4 31.2499' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 15.1466C12 15.3408 12.0702 15.5269 12.1953 15.6642C12.3203 15.8015 12.4899 15.8786 12.6667 15.8786C12.8435 15.8786 13.013 15.8015 13.1381 15.6642C13.2631 15.5269 13.3333 15.3408 13.3333 15.1466V13.6827C13.3333 13.4885 13.2631 13.3023 13.1381 13.1651C13.013 13.0278 12.8435 12.9507 12.6667 12.9507C12.4899 12.9507 12.3203 13.0278 12.1953 13.1651C12.0702 13.3023 12 13.4885 12 13.6827V15.1466ZM12 15.1466C12 17.088 12.7024 18.9498 13.9526 20.3225C15.2028 21.6952 16.8985 22.4664 18.6666 22.4664H20C20.1768 22.4664 20.3463 22.3893 20.4714 22.252C20.5964 22.1148 20.6666 21.9286 20.6666 21.7344C20.6666 21.5403 20.5964 21.3541 20.4714 21.2169C20.3463 21.0796 20.1768 21.0025 20 21.0025H18.6666C18.4898 21.0025 18.3203 21.0796 18.1952 21.2169C18.0702 21.3541 18 21.5403 18 21.7344C18 21.9286 18.0702 22.1148 18.1952 22.252C18.3203 22.3893 18.4898 22.4664 18.6666 22.4664' stroke='black' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.social__link--telegram {
  --img: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.27812 9.12061L6.01345 12.8433C6.39212 12.8433 6.55612 12.6806 6.75278 12.4853L8.52812 10.7886L12.2068 13.4826C12.8815 13.8586 13.3568 13.6606 13.5388 12.8619L15.9535 1.54728L15.9541 1.54661C16.1681 0.549278 15.5935 0.159278 14.9361 0.403945L0.742785 5.83794C-0.225881 6.21394 -0.211215 6.75394 0.578119 6.99861L4.20678 8.12728L12.6355 2.85328C13.0321 2.59061 13.3928 2.73594 13.0961 2.99861L6.27812 9.12061Z' fill='white'/%3E%3C/svg%3E%0A");
}

.social__link--zen {
  --img: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.56114e-09 15.6759C6.5805 15.6002 10.2636 15.1695 12.7151 12.718C15.1666 10.2665 15.5973 6.58343 15.673 0.00292969C7.09356 0.173242 0.170312 7.09649 2.56114e-09 15.6759ZM16.6284 0.0119571C16.7044 6.5868 17.1358 10.2676 19.5862 12.718C21.9992 15.131 25.6054 15.5862 31.9935 15.672C31.8232 7.19724 25.0637 0.339417 16.6284 0.0119571ZM31.9844 16.6352C25.602 16.7213 21.998 17.1772 19.5862 19.5891C17.1743 22.001 16.7184 25.6049 16.6323 31.9873C24.9635 31.6619 31.659 24.9664 31.9844 16.6352ZM15.6691 31.9964C15.5833 25.6084 15.1281 22.0021 12.7151 19.5891C10.2647 17.1387 6.58387 16.7073 0.00902743 16.6313C0.336488 25.0667 7.19434 31.8262 15.6691 31.9964Z' fill='%2300AEE8'/%3E%3C/svg%3E%0A");
}

.social__link--facebook {
  --img: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 8.04467C0 12.022 2.88867 15.3293 6.66667 16V10.222H4.66667V8H6.66667V6.222C6.66667 4.222 7.95533 3.11133 9.778 3.11133C10.3553 3.11133 10.978 3.2 11.5553 3.28867V5.33333H10.5333C9.55533 5.33333 9.33333 5.822 9.33333 6.44467V8H11.4667L11.1113 10.222H9.33333V16C13.1113 15.3293 16 12.0227 16 8.04467C16 3.62 12.4 0 8 0C3.6 0 0 3.62 0 8.04467Z' fill='white'/%3E%3C/svg%3E%0A");
}

.to-top {
  position: fixed;
  left: 20px;
  bottom: 50px;
  display: block;
  width: 40px;
  height: 40px;
  border: none;
  background: #00aee8;
  -webkit-mask: var(--arrow-down) no-repeat center/contain;
          mask: var(--arrow-down) no-repeat center/contain;
  transform: scaleY(-1);
  cursor: pointer;
  z-index: 100;
  visibility: hidden;
}

.header {
  --header-padding: 20px;
  width: 100%;
  padding-left: var(--header-padding);
  padding-right: var(--header-padding);
  z-index: 10;
}

.header__nav {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer__inner {
  display: grid;
  -moz-column-gap: 20px;
  column-gap: 20px;
  row-gap: 40px;
  grid-template-columns: 1fr;
  justify-content: center;
  width: calc(100% - 2 * (var(--x-padding)));
  padding-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
  margin-left: var(--x-padding);
  margin-right: var(--x-padding);
  border-radius: 60px 60px 0 0;
  font-size: clamp(12px, 0.8421052632vw, 16px);
  background: rgba(233, 246, 251, 0.3);
  text-align: center;
}

.footer__logo {
  grid-column: 1/-1;
  justify-self: center;
}

.footer__social-title {
  margin: 0;
  font-size: clamp(16px, 1.0526315789vw, 20px);
  font-weight: 500;
}

.footer__social {
  grid-column: 1/-1;
  justify-content: center;
  align-items: center;
}

.footer__social .social__link::before {
  width: 32px;
  height: 32px;
  background: #00AEE8;
}

.footer__address {
  display: grid;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  list-style-type: none;
}

.footer__address li {
  margin-bottom: 11px;
}

.footer__street br {
  display: none;
}

.footer__tel {
  color: inherit;
}

.footer__mailto {
  color: #00AEE8;
}

.footer__copyright {
  order: 2;
  grid-column: 1/-1;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.2em;
  opacity: 0.5;
}

.footer__doc-link {
  color: rgba(0, 174, 232, 0.4);
  text-decoration: underline;
}

.modal-block {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: var(--x-padding);
  padding-right: var(--x-padding);
  background: rgba(0, 0, 0, 0.9);
  z-index: 20;
  overflow-y: auto;
}

.modal-block.hide {
  display: none;
}

.modal {
  position: relative;
  width: 100%;
  max-width: 952px;
  padding: 50px 60px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 14px;
  background: #322C52;
}

.modal::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border-radius: 15px;
  background: radial-gradient(122.78% 113.91% at -20.93% -15.58%, #5498ff 0%, rgba(211, 154, 255, 0) 100%);
  z-index: -1;
}

.modal__btn {
  position: absolute;
  top: 50px;
  right: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: unset;
  padding: 0.5em;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1;
  color: #333333;
  background: transparent;
  border: none;
}

.modal__btn:hover {
  color: #00AEE8;
}

@-webkit-keyframes center-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes center-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes slideLeft {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@-webkit-keyframes slideLeftRight {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }

  40% {
    transform: translateX(-10%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideLeftRight {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }

  40% {
    transform: translateX(-10%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@-webkit-keyframes slideLeftRightRelative {
  0% {
    left: 0;
    opacity: 0;
  }

  40% {
    left: -10%;
    opacity: 1;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideLeftRightRelative {
  0% {
    left: 0;
    opacity: 0;
  }

  40% {
    left: -10%;
    opacity: 1;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes slideBottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slideBottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes lighten {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lighten {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes soar-anim-1 {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(40px) rotate(5deg);
  }
}

@keyframes soar-anim-1 {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(40px) rotate(5deg);
  }
}

@-webkit-keyframes soar-anim-2 {
  35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(20px) rotate(-3deg);
  }
}

@keyframes soar-anim-2 {
  35% {
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(20px) rotate(-3deg);
  }
}

.map {
  height: 300px;
}

.intro {
  --intro-gap-header: 170px;
  --wave-height: 80px;
  --intro-bg-width: 100% auto;
  position: relative;
  padding-bottom: calc(10px + var(--wave-height));
  background: url("~@/assets/images/publishers/intro-bg-mobile.svg") no-repeat top center, url("~@/assets/images/publishers/wave-bottom-white.svg") no-repeat bottom center/100% var(--wave-height), radial-gradient(600px 600px at left -200px top -200px, rgba(0, 174, 232, 0.3), transparent), radial-gradient(600px 600px at right -200px top -200px, rgba(0, 174, 232, 0.7), transparent), radial-gradient(1084px 1274px at left calc(50% - 1000px) bottom 260px, rgba(7, 176, 233, 0.4), transparent), radial-gradient(1084px 1274px at right calc(50% - 1000px) bottom 260px, rgba(7, 176, 233, 0.4), transparent), radial-gradient(500px 500px at left 50% top -200px, #ffffff, transparent), radial-gradient(300px 300px at left calc(50% + 400px) top 500px, white, transparent), #e9f6fb;
}

.intro__inner {
  align-self: center;
  display: grid;
  grid-template-columns: 1fr;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 10px;
  padding-top: 40px;
  text-align: center;
}

.intro__picture {
  --arrow-size: 98px;
  padding-bottom: 100px;
  padding-top: var(--arrow-size);
  background: url("~@/assets/images/publishers/intro-arrow-mobile.svg") no-repeat top right calc(50% - 80px)/var(--arrow-size) var(--arrow-size);
}

.intro__subtitle {
  font-size: clamp(14px, 1.1578947368vw, 22px);
  font-weight: 500;
}

.intro__subtitle::after {
  content: "";
  display: block;
  height: 70px;
  margin-top: 22px;
  background: url("~@/assets/images/publishers/intro-bg-3-mobile.svg") no-repeat top center;
}

.intro__subtitle br {
  display: none;
}

.intro__description {
  font-size: clamp(14px, 1.0526315789vw, 20px);
}

.intro__text {
  max-width: 380px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  font-size: clamp(13px, 1.0526315789vw, 20px);
  color: #5e5e5e;
}

.intro__text br {
  display: none;
}

.intro__btn {
  margin-top: 24px;
}

.how-look {
  padding-top: 50px;
  padding-bottom: 100px;
  background: url("~@/assets/images/publishers/how-look-bg-mobile.svg") no-repeat bottom 50px center;
}

.how-look__subtitle {
  margin-bottom: clamp(40px, 3.1578947368vw, 60px);
  font-size: clamp(14px, 1.1578947368vw, 22px);
  font-weight: 500;
  text-align: center;
}

.how-look__list {
  display: grid;
  -moz-column-gap: 16px;
       column-gap: 16px;
  row-gap: 16px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.how-look__item {
  display: grid;
  row-gap: 20px;
  padding: 40px;
  font-size: clamp(16px, 1.0526315789vw, 20px);
  font-weight: 500;
  text-align: center;
  background: #f8fbff;
  box-shadow: inset 0 0 15px #ffffff, 0 18px 50px -10px rgba(66, 144, 247, 0.3);
  border-radius: 30px;
}

.how-look__item::before {
  content: "";
  display: block;
  width: 122px;
  height: 122px;
  margin: auto;
  border-radius: 50%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 56px 56px;
}

.how-look__item:nth-child(1)::before {
  background-image: url("~@/assets/images/publishers/how-look-1.svg");
}

.how-look__item:nth-child(2)::before {
  background-image: url("~@/assets/images/publishers/how-look-2.svg");
}

.how-look__item:nth-child(3)::before {
  background-image: url("~@/assets/images/publishers/how-look-3.svg");
}

.how-look__btn {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 86px;
  margin-left: auto;
  margin-right: auto;
}

.ask-question {
  padding-top: 50px;
  padding-bottom: 50px;
}

.modal .ask-question {
  padding-top: 0;
  padding-bottom: 0;
}

.ask-question__inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--x-padding);
  padding-right: var(--x-padding);
}

.ask-question__header {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  text-align: center;
}

.ask-question__title {
  text-align: center;
}

.ask-question__subtitle {
  font-size: clamp(14px, 1.1578947368vw, 22px);
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}

.ask-question__subtitle a {
  color: #00AEE8;
}

.ask-question__btn {
  align-self: center;
  padding-left: 1.3em;
  padding-right: 1.3em;
}

.modal .ask-question__btn {
  grid-column: 1/-1;
}

.ask-question__social {
  justify-content: center;
}

.ask-question__social .social__link {
  --w: 52px;
  margin-top: 40px;
  border-radius: 50%;
  background: #00AEE8;
}

.ask-question__social .social__link::before {
  width: 25px;
  height: 25px;
}

.ask-question__address {
  margin-top: 20px;
  margin-bottom: 25px;
  font-size: clamp(16px, 1.0526315789vw, 20px);
  font-weight: 500;
  text-align: center;
}

.svg-bg {
  width: 50px;
  height: 50px;
  border: 1px gold solid;
  background: url("~@/assets/images/publishers/icons/vk.svg") no-repeat center/cover;
}

@media (min-width: 720px) {
  .logo {
    width: 120px;
  }

  .footer__social {
    grid-column: span 1;
    justify-self: center;
  }

  .footer__street br {
    display: inline;
  }

  .footer__copyright {
    order: unset;
    grid-column: unset;
  }

  .intro__inner {
    row-gap: 10px;
    padding-top: 80px;
  }

  .intro__picture {
    padding-top: 60px;
    --arrow-size: 214px;
    padding-top: calc(var(--arrow-size) - 20px);
    margin: 0;
    background: url("~@/assets/images/publishers/intro-arrow.svg") no-repeat top right calc(50% - 80px)/var(--arrow-size) auto;
  }

  .intro__title span {
    display: inline;
  }

  .intro__subtitle {
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
  }

  .intro__subtitle br {
    display: initial;
  }

  .intro__text {
    max-width: 790px;
    margin-top: 20px;
  }

  .intro__text br {
    display: initial;
  }
}

@media (min-width: 1000px) {
  .container {
    max-width: clamp(770px, 69.4736842105vw, 1320px);
  }

  .header__nav {
    max-width: unset;
  }

  .footer__inner {
    grid-template-columns: repeat(3, 1fr);
    width: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .footer__social-block {
    display: flex;
    text-align: center;
    flex-direction: column;
  }

  .footer__logo {
    grid-column: span 1;
    justify-self: unset;
  }

  .footer__address {
    max-width: unset;
  }


  .modal__btn {
    font-size: 20px;
  }

  .intro {
    --wave-height: 103px;
    display: grid;
    grid-template-rows: -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content min-content;
    padding-bottom: calc(40px + var(--wave-height));
    background: url("~@/assets/images/publishers/intro-bg.svg") no-repeat top center/var(--intro-bg-width), url("~@/assets/images/publishers/intro-bg-ellipsys.svg") no-repeat top center, url("~@/assets/images/publishers/wave-bottom-white.svg") no-repeat bottom center/100% var(--wave-height), radial-gradient(1084px 1274px at left calc(50% - 1000px) bottom 260px, rgba(7, 176, 233, 0.4), transparent), radial-gradient(1084px 1274px at right calc(50% - 1000px) bottom 260px, rgba(7, 176, 233, 0.4), transparent), radial-gradient(500px 500px at left 50% top -200px, #ffffff, transparent), radial-gradient(300px 300px at left calc(50% + 400px) top 500px, white, transparent), #e9f6fb;
  }

  .intro__subtitle::after {
    display: none;
  }

  .how-look {
    padding-top: clamp(50px, 7.8947368421vw, 150px);
    padding-bottom: clamp(50px, 5.2631578947vw, 100px);
    background: url("~@/assets/images/publishers/how-look-bg.svg") no-repeat bottom 90px center;
  }

  .how-look__list {
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 40px;
         column-gap: 40px;
    max-width: unset;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .how-look__btn {
    margin-top: 100px;
  }

  .ask-question {
    padding-top: min( 150px, 7.8947368421vw);
    padding-bottom: min( 200px, 10.5263157895vw);
  }

  .ask-question__inner {
    max-width: clamp(770px, 69.4736842105vw, 1320px);
  }

  .ask-question__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 50px;
         column-gap: 50px;
  }

  .ask-question__header {
    max-width: unset;
    margin-bottom: 0;
    text-align: left;
  }

  .ask-question__title {
    text-align: revert;
  }

  .ask-question__social {
    justify-content: start;
  }

  .ask-question__address {
    text-align: left;
  }

}

@media (min-width: 1428px) {
  .intro {
    --intro-bg-width: auto;
  }
}

@media (min-width: 1430px) {
  .nav {
    display: flex;
    align-items: center;
  }

  .header__nav.nav {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
  }

  .header__nav.nav .nav__list {
    order: initial;
    display: flex;
    align-items: center;
    width: auto;
    margin: 0 auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
</style>
