import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    userToken: null,
    userInfo: null,
  },
  getters: {
    getUserToken: () => localStorage.getItem('userToken'),
    getUserInfo: (state) => state.userInfo || JSON.parse(localStorage.getItem('userInfo')),
    getIsAdmin: (state) => (state.userInfo?.role === 2 || state.userInfo?.role === 4)
      || (JSON.parse(localStorage.getItem('userInfo'))?.role === 2 || JSON.parse(localStorage.getItem('userInfo'))?.role === 4),
    getIsSuperAdmin: (state) => state.userInfo?.role === 4 || JSON.parse(localStorage.getItem('userInfo'))?.role === 4,
  },
  mutations: {
    setUserToken: (state, value) => {
      state.userToken = value;
      localStorage.setItem('userToken', value);
    },
    setUserInfo: (state, value) => {
      state.userInfo = value;
      localStorage.setItem('userInfo', JSON.stringify(value));
    },
  },
  actions: {
    getToken({ commit, dispatch }, token) {
      return api.post('/auth', {
        token,
      }).then(({ data: { data } }) => {
        commit('setUserToken', data['x-api-token']);
        dispatch('loadUserInfo')
          .then(() => Promise.resolve())
          .catch((error) => {
            console.warn('Error in action -- getToken --; Error -', error);
            return Promise.reject(error);
          });
      });
    },
    loadUserInfo({ commit }) {
      return api.get('/user/me')
        .then(({ data: { data } }) => {
          commit('setUserInfo', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- loadUserInfo --; Error -', error);
        });
    },
    login({ commit, dispatch }, loginData) {
      return api.post('/auth/login',
        {
          login: loginData.login,
          password: loginData.password,
        })
        .then(({ data: { data } }) => {
          commit('setUserToken', data['x-api-token']);
          dispatch('loadUserInfo')
            .then(() => Promise.resolve())
            .catch((error) => Promise.reject(error));
        }).catch((error) => {
          console.warn('Error in action -- login --; Error -', error);
          return Promise.reject(error);
        });
    },
    logout({ commit }) {
      commit('setUserToken', null);
      commit('setUserInfo', null);
      localStorage.clear();
    },
  },

};
