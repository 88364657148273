<template>
  <form class="login-form">
    <div class="login-form__inputs">
      <div class="login-form__input-block">
        <input
        v-model.trim="login"
        :placeholder="$t('Login')"
        class="login-form__input">
        <span
        v-if="!login && showError"
        class="login-form__error">{{ $t('this_is_a_required_field') }}</span>
      </div>
      <div class="login-form__input-block">
        <input
        ref="passwordInput"
        v-model.trim="password"
        type="password"
        :placeholder="$t('Password')"
        class="login-form__input">
        <span
        v-if="!password && showError"
        class="login-form__error">{{ $t('this_is_a_required_field') }}</span>
        <TJIcon
        name="visibility"
        class="login-form__pass-icon"
        :size="20"
        pointer
        @mouseup.native="$refs.passwordInput.type = 'password'"
        @mousedown.native="$refs.passwordInput.type = 'text'" />
      </div>
    </div>
    <button
    class="login-form__button"
    @click.prevent="submitForm">
      {{ $t('Login') }}
    </button>
  </form>
</template>

<script>
export default {
  name: 'LoginForm',
  data: () => ({
    login: null,
    password: null,
    showError: false,
  }),
  methods: {
    submitForm() {
      if (!this.login || !this.password) {
        this.showError = true;
        return;
      }
      this.showError = false;
      const loginData = {
        login: this.login,
        password: this.password,
      };
      this.$emit('submitForm', loginData);
      this.resetInputs();
    },
    resetInputs() {
      this.login = null;
      this.password = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 300px;
}

.login-form__inputs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.login-form__input {
  width: 300px;
  padding: 7px 40px 7px 16px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  font-size: 14px;
  &:focus {
    outline: none;
  }
}

.login-form__pass-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  color: $primary-lightgray;
}

.login-form__input-block {
  position: relative;
}

.login-form__button {
  cursor: pointer;
  width: 100%;
  padding: 10px 40px;
  font-size: 14px;
  outline: none;
  border: none;
  color: $primary-white;
  background: $primary-blue;
  border-radius: 40px;
}

.login-form__error {
  position: absolute;
  top: 35px;
  left: 0px;
  font-size: 12px;
  color: $primary-red;
}
</style>
