<template>
  <div class="add-group">
    <div class="add-group__header-block">
      <span class="add-group__text add-group__text_title">{{ $t('creating a channel') }}</span>
      <span class="add-group__text add-group__text_subtitle">{{ $t('It is necessary to fill in all required fields') }}</span>
    </div>
    <div class="add-group__elements">
      <!-- Название -->
      <div
      class="add-group__element">
        <span class="add-group__text add-group__text_require">{{ $t('name2') }}</span>
        <input
        v-model.trim="groupTemplate.name"
        :placeholder="$t('name2')"
        class="add-group__input">
      </div>
      <!-- Название -->
      <div
      class="add-group__element">
        <span class="add-group__text add-group__text_require">{{ $t('link') }}</span>
        <input
        v-model.trim="groupTemplate.url"
        :placeholder="$t('Link to the channel')"
        class="add-group__input">
      </div>
      <!-- Соц сеть -->
      <div
      class="add-group__element">
        <span class="add-group__text add-group__text_require">{{ $t('Social network') }}</span>
        <select
        v-model="currentSocial"
        class="add-group__input">
          <option
          value="null"
          disabled
          selected>
            {{ $t('Choose a social network') }}
          </option>
          <option
          v-for="option in groupsData.socials"
          :key="option.name"
          :value="option.value">
            {{ $t(option.name) }}
          </option>
        </select>
      </div>
      <!-- Количество подписчиков -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('Subscribers count') }}</span>
        <input
        v-model="groupTemplate.subscribersStatistic.count"
        type="number"
        placeholder="100000"
        class="add-group__input">
      </div>
      <!-- Контакты -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('contacts') }}</span>
        <input
        v-model.trim="groupTemplate.contact"
        placeholder="client@gmail.com"
        class="add-group__input">
      </div>
      <!-- Стоимость поста -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('Сost of the post') }}</span>
        <input
        v-model="groupTemplate.postPrice.sell"
        type="number"
        placeholder="100000"
        class="add-group__input">
      </div>
      <!-- Прайс -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('price_sale') }}</span>
        <input
        v-model="groupTemplate.postPrice.buy"
        type="number"
        placeholder="100000"
        class="add-group__input">
      </div>
      <!-- Тип оплаты -->
      <div
      class="add-group__element">
        <span class="add-group__text">{{ $t('payment_type') }}</span>
        <select
        v-model="groupTemplate.paymentType"
        class="add-group__input">
          <option
          value="null"
          disabled
          selected>
            {{ $t('Select the payment type') }}
          </option>
          <option
          v-for="option in getPaymentOptions"
          :key="option.value"
          :value="option.value">
            {{ option.name }}
          </option>
        </select>
      </div>
      <!-- Категории -->
      <div class="add-group__element add-group__element_categories">
        <span class="add-group__text">{{ $t('categories') }}</span>
        <div class="add-group__categories">
          <div
          v-for="category in selectedCategories"
          :key="category.value"
          class="add-group__category">
            <span class="add-group__text">{{ $t(category.name) }}</span>
            <TJIcon
            name="close"
            :size="14"
            pointer
            @click.native="removeCategory(category.value)" />
          </div>
        </div>
        <span
        v-if="!showCategorySelect && selectedCategories.length < 3"
        class="add-group__text add-group__text_light pointer"
        @click="showCategorySelect = true">{{ $t('add_new_category') }}</span>
        <select
        v-if="showCategorySelect"
        ref="categorySelect"
        v-model="currentCategory"
        class="add-group__input"
        @change="addCategory">
          <option
          value="null"
          disabled
          selected>
            {{ $t('Select a new category') }}
          </option>
          <option
          v-for="option in categoriesOptions"
          :key="option.value"
          :value="option">
            {{ $t(option.name) }}
          </option>
        </select>
      </div>
    </div>
    <span
    v-show="showError"
    class="add-group__text add-group__text_error">{{ $t('Not all required fields are filled in') }}</span>
    <div class="add-group__button-block">
      <TJButton
      :text="$t('save')"
      @handler="sendGroup" />
      <TJButton
      :text="$t('cancel')"
      type="outlined"
      @handler="close" />
    </div>
  </div>
</template>

<script>
import groupsData from '@/assets/groupsData.json';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'AddGroup',
  data: () => ({
    groupTemplate: null,
    currentSocial: null,
    showError: false,
    currentCategory: null,
    selectedCategories: [],
    categoriesOptions: null,

    showCategorySelect: true,
  }),
  created() {
    this.groupsData = cloneDeep(groupsData);
    this.groupTemplate = cloneDeep(groupsData.groupTemplate);
    this.categoriesOptions = this.groupsData.categories.filter((item) => item.value !== null && item.value !== 'Without category');
  },
  methods: {
    close() {
      this.$emit('close');
    },
    sendGroup() {
      if (this.isError) {
        this.showError = true;
        return;
      }
      this.showError = false;
      this.groupTemplate.categoriesId = this.selectedCategories.length ? this.selectedCategories.map((item) => item.value) : null;

      this.$store.dispatch('groups/createGroup', { group: this.groupTemplate, social: this.currentSocial })
        .then(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('Successfully. Group added', { group: this.groupTemplate.name }),
              error: false,
            });
          this.close();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('Mistake! Such group already exists'),
                error: true,
              });
            this.close();
            return;
          }
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_when_creating_the_group_reload_the_page_and_try_again'),
              error: true,
            });
          this.close();
        });
    },
    addCategory() {
      const isSelected = this.selectedCategories.find((item) => item.value === this.currentCategory.value);
      if (isSelected) {
        this.showCategorySelect = false;
        this.currentCategory = null;
        return;
      }
      this.selectedCategories.push(this.currentCategory);
      this.currentCategory = null;
      this.showCategorySelect = false;
    },
    removeCategory(value) {
      this.selectedCategories = this.selectedCategories.filter((item) => item.value !== value);
    },
  },
  computed: {
    getPaymentOptions() {
      return this.groupsData.paymentType.filter((option) => option.value !== null);
    },
    isError() {
      return !this.groupTemplate.name || !this.groupTemplate.url || !this.currentSocial;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-group {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 800px;
}

.add-group__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px;
}

.add-group__text {
  font-size: 14px;
  &_title {
    font-size: 24px;
    line-height: 44px;
  }
  &_light {
    color: $primary-lightgray;
  }
  &_error {
    color: $primary-red;
  }
  &_require::after {
    color: red;
    content: " *";
    font-size: 18px;
  }
}

.add-group__elements {
  display: flex;
  flex-wrap: wrap;
  max-height: 500px;
  overflow-Y: scroll;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.add-group__element {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  &_categories {
    min-height: 60px;
  }
}

.add-group__categories {
  margin-top: 5px;
}

.add-group__category {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  @include between-elements {
    margin-right: 5px;
  }
}

.add-group__input {
  padding: 0px 10px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  width: 30vw;
  min-width: 200px;
  max-width: 300px;
  font-size: 14px;
  margin-top: 5px;
  height: 40px;
  &:focus {
    outline: none;
    border-color: $border-color-focus;
  }
  &:hover {
    outline: none;
  }
}

.add-group__button-block {
  padding-top: 30px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}
</style>
