<template>
  <div class="tariff-progress">
    <div class="tariff-progress__header">
      <span class="tariff-progress__text tariff-progress__text_header">Комиссия SJ в этом месяце</span>
      <span
      class="tariff-progress__text tariff-progress__text_header"
      style="text-align: right">Комиссия SJ  в след. месяце</span>
    </div>

    <!-- SALE -->
    <div class="tariff-progress__sale-block">
      <div class="tariff-progress__sale-element">
        <div class="tariff-progress__sale-point">
          <img src="../assets/light.svg">
        </div>
        <span class="tariff-progress__text tariff-progress__text_sale-green">25%</span>
      </div>
      <div class="tariff-progress__sale-middle">
        <span class="tariff-progress__text tariff-progress__text_sale">До следущего порога скидки (20%) не хватает 67 400р</span>
        <hr class="tariff-progress__sale-line">
      </div>
      <div class="tariff-progress__sale-element">
        <div class="tariff-progress__sale-point tariff-progress__sale-point_blue">
          <img src="../assets/time.svg">
        </div>
        <span class="tariff-progress__text tariff-progress__text_sale-blue">25%</span>
      </div>
    </div>

    <!-- RATE -->
    <div class="tariff-progress__rate">
      <span class="tariff-progress__text tariff-progress__text_rate">Burn market rate</span>
      <img
      v-tooltip.top="'Шкала оборота средств за последние 365 дней через SocialData. Чем больше оборот, тем ниже комиссия'"
      src="@/assets/svg/icons/info.svg"
      class="tariff-progress__info-icon pointer">
    </div>
    <div class="tariff-progress__container">
      <div class="tariff-progress__container-header">
        <span class="tariff-progress__text tariff-progress__text_white">Ваш бюджет за 365 дней</span>
        <span
        ref="tariffValue"
        class="tariff-progress__text tariff-progress__text_sum">{{ tariffValueEdit }}</span>
      </div>
      <div
      ref="progress"
      class="tariff-progress__container-progress">
        <div
        ref="progress"
        class="tariff-progress__progress-active-points">
          <div
          v-for="(point, index) in points"
          :key="point.value"
          :style="{
            left: `${point.left}% `,
            opacity: (index === 0 || index === points.length - 1) ? 0 : 1,
            justifyContent: index === 0 ? 'flex-start' : index === points.length - 1 ? 'flex-end' : 'center',
          }"
          class="tariff-progress__progress-active-point-container">
            <div class="tariff-progress__progress-active-point" />
          </div>
        </div>
        <div
        class="tariff-progress__progress-active-line"
        :style="{width: `${activeLineWidth}%`}" />
        <div class="tariff-progress__progress-text-points">
          <span
          v-for="point in points"
          :key="point.value"
          :style="{
            marginLeft: `${point.margin}px`,
            left: `${point.left}%`,
          }"
          class="tariff-progress__text tariff-progress__text_white-point">
            {{ point.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffProgress',
  props: {
    tariffValue: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    points: [
      {
        name: '0к - 35%', value: 0, margin: 0, left: 0,
      },
      {
        name: '100к - 25%', value: 100000, margin: -37, left: 20,
      },
      {
        name: '200к - 22%', value: 200000, margin: -39, left: 40,
      },
      {
        name: '300к - 20%', value: 300000, margin: -40, left: 60,
      },
      {
        name: '500к - 15%', value: 500000, margin: -39, left: 80,
      },
      {
        name: '1кк - 10%', value: 1000000, margin: -60, left: 100,
      },
    ],
    activeLineWidth: 0,
    tariffValueEdit: '',
  }),
  mounted() {
    setTimeout(() => {
      if (!this.tariffValue) return;
      this.getTariffLabelProgress();
      this.activeLineWidth = this.getActiveLineWidth() || 0;
    }, 100);
  },
  methods: {
    getActiveLineWidth() {
      if (this.tariffValue >= 1000000) {
        return 100;
      }
      if (this.tariffValue === 0) {
        return 0;
      }
      let fromElement;
      const toElement = this.points.find((item, index) => {
        if (item.value >= this.tariffValue) {
          fromElement = this.points[index - 1];
        }
        return item.value >= this.tariffValue;
      });
      const pointsDifferent = (toElement.value - fromElement.value); // разница значения между поинтами, внутри которого искомое значение
      const fromToDif = this.tariffValue - fromElement.value; // разница между заданной ценой и ближайшей максимальной точкой
      const percent = 20 / (pointsDifferent / fromToDif); // процент, который нужно добавить между поинтами
      const width = fromElement.left + percent + 0.5; // 0.5 чтобы полоса захватывала точку
      return width;
    },
    getTariffProgressLabel() {
      return `${this.setNumberSpaces(+this.tariffValue) || 0} ₽`;
    },
    getTariffLabelProgress() {
      const time = 2000;
      const step = Math.round(this.tariffValue / 100);
      let n = 0;
      const t = Math.round(time / (this.tariffValue / step));
      const interval = setInterval(() => {
        n += step;
        this.tariffValueEdit = this.setNumberSpaces(+n);
        if (n >= this.tariffValue) {
          this.tariffValueEdit = this.getTariffProgressLabel();
          clearInterval(interval);
        }
      }, t);
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-progress {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 20px;
  padding: 32px;
  flex: 1;
}

.tariff-progress__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tariff-progress__sale-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.tariff-progress__sale-element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.tariff-progress__sale-point {
  border-radius: 8px;
  background: rgba(106, 177, 111, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  &_blue {
    background: rgba(0, 174, 232, 0.1);
  }
}

.tariff-progress__sale-middle {
  display: none;
  @include tablet-screen {
    padding: 0px 20px;
    display: flex;
    min-width: 50%;
    flex-direction: column;
    text-align: center;
    position: relative;
    @include between-elements {
      margin-bottom: 20px
    }
    &:after {
      content: '';
      position: absolute;
      right: 7px;
      top: 96.5%;
      display: block;
      border-right: 2px solid $primary-blue;
      border-bottom: 2px solid $primary-blue;
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.tariff-progress__sale-line {
  display: none;
  @include tablet-screen {
    display: block;
    padding: 0;
    margin: 0px;
    height: 2px;
    border: none;
    color: $primary-blue;
    background-image: linear-gradient(90deg, $primary-blue, $primary-blue, 70%, transparent 30%, transparent 100%);
    border-image: 3px;
    background-size: 15px 100%;
    position: relative;
  }
}

.tariff-progress__text {
  font-size: 12px;
  color: black;
  @include tablet-screen {
    font-size: 14px;
  }
  &_header {
    color: #7B7B7B;
  }
  &_sale-green {
    font-size: 28px;
    color: #6AB16F;
    font-weight: 700;
  }
  &_sale-blue {
    font-size: 28px;
    color: $primary-blue;
    font-weight: 700;
  }
  &_white {
    color: white;
  }
  &_white-point {
    color: white;
    text-align: left;
    position: absolute;
    top: 20px;
    width: 80px;
  }
  &_sum {
    font-size: 28px;
    color: white;
    font-weight: 600;
    margin-left: 10px;
  }
  &_rate {
    font-size: 16px;
    font-weight: 600;
  }
  &_sale {
    display: none;
    @include tablet-screen {
      display: block;
    }
  }
}

.tariff-progress__rate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.tariff-progress__container {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 40px;
  background: $primary-blue;
  border-radius: 20px;
  margin-top: 30px;
}

.tariff-progress__container-progress {
  width: 100%;
  background: #D8F2FA;
  height: 10px;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
  display: flex;
  justify-content: center;
}

.tariff-progress__progress-active-line {
  position: absolute;
  left: -1px;
  top: 0;
  width: 0px;
  transition: width 2s ease-out;
  // transition-timing-function: cubic-bezier(.66,.39,.21,.67);
  height: 10px;
  background: linear-gradient(90deg,#7ace80, #82DA88);
  border-radius: 10px;
}

.tariff-progress__progress-text-points {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tariff-progress__progress-active-points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
}

.tariff-progress__progress-active-point-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
}

.tariff-progress__progress-active-point {
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 100%;
  z-index: 1;
}

.tariff-progress__info-icon {
  width: 22px;
  height: 22px;
}
</style>
