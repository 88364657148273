<template>
  <div class="add-client">
    <div
    v-if="type === 'addClient' || type === 'editClient'"
    class="add-client__container">
      <span class="add-client__text add-client__text_title">{{ type === 'addClient' ? 'Создание кампании' : 'Редактирование кампании' }}</span>
      <div class="add-client__client-inputs">
        <div class="add-client__input-element">
          <span class="add-client__text add-client__text_light">Название</span>
          <input
          v-model.trim="clientName"
          placeholder="Название кампании"
          type="text"
          class="add-client__input">
        </div>
        <div class="add-client__input-element">
          <span class="add-client__text add-client__text_light">Комиссия</span>
          <input
          v-model="clientRate"
          maxlength="3"
          step="1"
          class="add-client__input add-client__input_rate">
          <span class="add-client__input-percent">%</span>
        </div>
      </div>
      <div class="add-client__buttons">
        <TJButton
        :text="type === 'addClient' ? 'Создать' : 'Обновить'"
        :width="230"
        :height="48"
        @handler="clientHandler" />
        <TJButton
        text="Отмена"
        :width="230"
        :height="48"
        type="outlined"
        @handler="cancelAddClient" />
      </div>
    </div>

    <div
    v-if="type === 'addUser'"
    class="add-client__container">
      <span class="add-client__text add-client__text_title">Добавление пользователя</span>
      <p class="add-client__text add-client__text_light">
        Пользователь будет добавлен в кампанию {{ client.name }}
      </p>
      <div class="add-client__client-inputs add-client__client-inputs_column">
        <!-- <div class="add-client__input-element">
          <span class="add-client__text add-client__text_light">ФИО</span>
          <input
          v-model.trim="userFio"
          placeholder="ФИО"
          class="add-client__input">
        </div> -->
        <div class="add-client__input-element">
          <span class="add-client__text add-client__text_light">Почта</span>
          <input
          v-model="userEmail"
          placeholder="mail@gmail.com"
          class="add-client__input">
        </div>
        <div class="add-client__input-element">
          <span class="add-client__text add-client__text_light">Пароль</span>
          <input
          v-model="userPassword"
          type="password"
          placeholder="Задайте пароль"
          class="add-client__input">
        </div>
      </div>
      <div class="add-client__buttons">
        <TJButton
        text="Добавить"
        :width="220"
        :height="48"
        @handler="createUserAndAdd" />
        <TJButton
        text="Отмена"
        :width="220"
        :height="48"
        type="outlined"
        @handler="cancelAddClient" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddClientModal',
  props: {
    type: {
      type: String,
      default: 'addClient',
    },
    client: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    clientName: null,
    clientRate: '',
    isLoading: false,
    userFio: null,
    userEmail: null,
    userPassword: null,
  }),
  created() {
    this.clientName = this.client ? this.client.name : '';
    this.clientRate = this.client ? this.client.rate : '';
  },
  methods: {
    cancelAddClient() {
      this.clientName = '';
      this.clientRate = '';
      this.isLoading = false;
      this.$emit('close');
    },
    clientHandler() {
      if (this.type === 'addClient') {
        this.addClient();
        return;
      }
      this.updateClient();
    },
    addClient() {
      if (this.isLoading || this.disableAddClintButton) return;
      this.isLoading = true;
      const client = { name: this.clientName, rate: this.clientRate };
      this.$store.dispatch('clients/createClient', client)
        .then(() => {
          // загрузку ниже выпилить после того как в ответе будем получать объект юзера
          this.$store.dispatch('clients/getClientsData')
            .then(() => {
              this.isLoading = false;
              this.cancelAddClient();
              this.$store.dispatch('app/setSnackBar', { text: 'Кампания создана' });
            });
        })
        .catch(() => {
          this.isLoading = false;
          this.cancelAddClient();
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при создании кампании. Попробуйте позже', error: true });
        });
    },
    updateClient() {
      if (this.isLoading || this.disableAddClintButton) return;
      this.isLoading = true;
      const params = { id: this.client.id, name: this.clientName, rate: this.clientRate };
      this.$store.dispatch('clients/updateClient', params)
        .then(() => {
          this.isLoading = false;
          this.cancelAddClient();
          this.$store.dispatch('app/setSnackBar', { text: 'Кампания обновлена' });
        })
        .catch(() => {
          this.isLoading = false;
          this.cancelAddClient();
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при обновлении кампании. Попробуйте позже', error: true });
        });
    },
    createUserAndAdd() {
      if (this.isLoading || this.disabledUserButton) return;
      this.isLoading = true;
      const userData = { login: this.userEmail, password: this.userPassword, agencyId: this.client.id };
      this.$store.dispatch('clients/createUserAndAdd', userData)
        .then(() => {
          this.isLoading = false;
          this.cancelAddClient();
          this.$store.dispatch('app/setSnackBar', { text: 'Пользователь добавлен' });
        })
        .catch(() => {
          this.isLoading = false;
          this.cancelAddClient();
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при создании пользователя. Попробуйте позже', error: true });
        });
    },
  },
  computed: {
    disableAddClintButton() {
      return !this.clientName || !this.clientRate;
    },
    disabledUserButton() {
      return !this.userEmail || !this.userPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-client {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 480px;
}

.add-client__text {
  font-size: 14px;
  color: #0E092A;
  &_title {
    font-size: 24px;
    font-weight: 600;
  }
  &_light {
    color: $primary-lightgray;
  }
}

.add-client__client-inputs {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 10px;
  &_column {
    display: block;
    @include between-elements {
      margin-bottom: 20px;
    }
  }
}

.add-client__input-element {
  display: flex;
  flex-direction: column;
  position: relative;
  @include between-elements {
    margin-bottom: 10px;
  }
}
.add-client__input {
  width: 100%;
  background: #FAFEFF;
  border-radius: 8px;
  border: 1px solid #D8F2FA;
  height: 48px;
  outline: none;
  font-size: 16px;
  padding: 0px 10px;
  &::placeholder {
    color: rgba(black, 0.3);
  }
  &_rate {
    padding: 0px 16px;
  }
}

.add-client__buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
  @include between-elements {
    margin-right: 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.add-client__input-percent {
  position: absolute;
  right: 14px;
  top: 43px;
  font-size: 16px;
}
</style>
