<template>
  <transition name="fade">
    <div
    v-if="showModal"
    class="tj-modal flex-center"
    :class="iframe && 'tj-modal_iframe'">
      <div
      v-click-outside="close"
      class="tj-modal__modal"
      :class="iframe && 'tj-modal__modal_iframe'">
        <slot name="modal-content" />
        <TJIcon
        v-if="!iframe"
        name="close"
        class="tj-modal__close"
        :size="26"
        pointer
        @click.native="close" />
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'TJModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    iframe: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      if (this.showModal) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tj-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  backdrop-filter: blur(1px);
  background-color: rgba(56, 56, 56, 0.4);
  &_iframe {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.tj-modal__modal {
  background: #FFFFFF;
  // box-shadow: -1px 2px 18px -2px rgba(34, 60, 80, 0.2);
  border-radius: 12px;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include tablet-screen {
    padding: 40px;
  }
  &_iframe {
    background: transparent;
    box-shadow: none;
  }
}

.tj-modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
