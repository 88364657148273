<template>
  <div class="my-groups">
    <div class="my-groups__tabs">
      <span
      v-for="social in getTypesOfGroups"
      :key="social.value"
      :class="social.value === selectedTab && 'my-groups__tab_selected'"
      class="my-groups__tab"
      @click="changeSelectedTab(social.value)">
        {{ $t(social.value) }}
      </span>
    </div>
    <MainGroups
    mode="my-groups"
    :groups-data="getGroupsData" />
  </div>
</template>

<script>
import MainGroups from '@/components/MainGroups/MainGroups.vue';

export default {
  name: 'MyGroupsScreen',
  components: { MainGroups },
  data: () => ({
    selectedTab: 'telegram',
  }),
  created() {
    this.$store.commit('collections/setShowCollectionGroups', false);
    this.$store.commit('collections/setShowFavoriteGroups', false);
    this.$store.commit('groups/setSelectedTypeOfGroups', 'telegram');
    this.$store.commit('collections/setSelectedGroups', []);

    this.$store.dispatch('groups/loadGroupsData', { reset: true, aggregate: true, userId: this.getUserInfo.id });
  },
  methods: {
    changeSelectedTab(tab) {
      this.selectedTab = tab;
      this.$store.commit('groups/setSelectedTypeOfGroups', tab);
      const query = { group: tab };
      this.$router.push({ path: this.$router.currentPath, query });
      this.$store.dispatch('groups/loadGroupsData', { reset: true, aggregate: true, userId: this.getUserInfo.id });
    },
  },
  computed: {
    getGroupsData() {
      if (this.isShowSelectedGroups) {
        return this.$store.getters['collections/getSelectedGroupsData'];
      }
      if (this.isShowFavoriteGroups) {
        return this.$store.getters['collections/getFavoriteGroupsData'];
      }
      if (this.isShowCollectionGroups) {
        return this.$store.getters['collections/getCurrentCollection']?.groups;
      }
      return this.$store.getters['groups/getGroupsData'];
    },
    getUserInfo() {
      return this.$store.getters['auth/getUserInfo'];
    },
    getTypesOfGroups() {
      return this.$store.getters['groups/getTypesOfGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.my-groups {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.my-groups__tabs {
  margin: 50px 0px 10px 20px;
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    padding: 0;
  }
  @include between-elements {
    margin-right: 20px;
  }
}

.my-groups__tab {
  color: $primary-gray;
  font-size: 16px;
  padding-bottom: 3px;
  transition: 0.2s;
  cursor: pointer;
  &_selected {
    box-shadow: 0px -2px 0px 0px $primary-blue inset;
  }
}

</style>
