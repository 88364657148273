<template>
  <div class="groups-moderation-screen">
    <div class="groups-moderation-screen__title">
      <span
      v-for="tab in tabs"
      :key="tab.name"
      class="groups-moderation-screen__tab"
      :class="tab.name === selectedTab.name && 'groups-moderation-screen__tab_selected'"
      @click="changeTab(tab)">
        {{ $t(tab.name) }}
      </span>
    </div>
    <GroupsModeration
    :is-loading-groups="isLoadingGroups"
    :groups="getGroupsForModerate"
    @getModerateGroups="getModerateGroups" />
  </div>
</template>

<script>
import GroupsModeration from './components/GroupsModeration.vue';

export default {
  name: 'GroupsModerationScreen',
  components: {
    GroupsModeration,
  },
  data: () => ({
    isLoadingGroups: true,
    selectedTab: { name: 'Модерация', value: ['wait'] },
    tabs: [{ name: 'Модерация', value: ['wait'] }, { name: 'История', value: ['applied', 'rejected'] }],
  }),
  beforeDestroy() {
    this.$store.commit('moderate/setGroupsForModerate', []);
  },
  created() {
    this.getModerateGroups({ reset: true });
  },
  methods: {
    getModerateGroups(params) {
      this.isLoadingGroups = true;
      this.$store.dispatch('moderate/getModerateGroups', { reset: params.reset, status: this.selectedTab.value })
        .finally(() => {
          this.isLoadingGroups = false;
        });
    },
    changeTab(tab) {
      if (tab.name === this.selectedTab.name || this.isLoadingGroups) return;
      this.selectedTab = tab;
      this.getModerateGroups({ reset: true });
    },
  },
  computed: {
    getGroupsForModerationLabel() {
      if (this.isLoadingGroups) return '';
      const length = this.getGroupsForModerate.length || 0;
      return ` - ${length} ${this.getPluralWord(length, ['канал', 'канала', 'каналов'])}`;
    },
    getGroupsForModerate() {
      return this.$store.getters['moderate/getGroupsForModerate'];
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-moderation-screen {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}

.groups-moderation-screen__title {
  margin: 30px 20px 10px;
  @include between-elements {
    margin-right: 20px;
  }
}

.groups-moderation-screen__text {
  font-size: 14px;
  font-weight: 600;
  color: #575757;
  &_light {
    font-weight: 500;
  }
}

.groups-moderation-screen__tab {
  color: $primary-gray;
  font-size: 16px;
  padding-bottom: 4px;
  transition: 0.2s;
  cursor: pointer;
  &_selected {
    box-shadow: 0px -2px 0px 0px $primary-blue inset;
  }
}

</style>
