import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);
const groupsSearchParamsTemplate = {
  nameOrUrl: null,
  byUrl: null,
  contact: null,
  categoriesId: null,
  withoutCategory: null,
  withoutCountry: null,
  subscriberCountries: null,
  description: null,
  containsPostPriceSell: null,

  subscribersMin: null,
  subscribersMax: null,

  viewsMin: null,
  viewsMax: null,
  viewsType: 'last24h',

  priceMin: null,
  priceMax: null,
  priceType: 'post',

  cpmMin: null,
  cpmMax: null,
  cpсMin: null,
  cpсMax: null,

  erMin: null,
  erMax: null,

  postsMin: null,
  postsMax: null,
  postsType: 'last24h',

  growMin: null,
  growMax: null,
  growType: 'month',
  interestsId: null,

  roboticityMin: null,
  roboticityMax: null,

  malePercentMin: null,
  malePercentMax: null,
  femalePercentMin: null,
  femalePercentMax: null,
  ageMin: null,
  ageMax: null,

  advertisingLabel: null,
  placementInFeed: null,
  placementInTop: null,
  // admin/
  botName: null,
  inSocialjet: null,
  isBlogger: null,
  inBlackList: null,
  dataUpdatedAtFrom: null,
  dataUpdatedAtTo: null,
  createdAtFrom: null,
  createdAtTo: null,
  postPriceUpdatedAtFrom: null,
  postPriceUpdatedAtTo: null,
  paymentType: null,
  onlyBadGroups: null,
  inspectReportWarningCodes: null,
  postSaleBiggerThanBuy: null,
  // user owner
  userIdOwner: null,
};

export default {
  namespaced: true,
  state: {
    initial: true,
    typesOfGroups: [
      { value: 'telegram', name: 'Телеграм' },
      { value: 'vk', name: 'Вконтакте' },
      { value: 'instagram', name: 'Инстаграм' },
      { value: 'odnoklassniki', name: 'Одноклассники' },
      { value: 'youtube', name: 'YouTube' },
      { value: 'yandex-zen', name: 'Я.Дзен' },
      // { value: 'tiktok', name: 'ТикТок' },
      // { value: 'telegram-bot', name: 'Телеграм боты' },
      // { value: 'facebook', name: 'Facebook' },
    ],
    aggregateOptions: [
      'viewsLast24h',
      'viewsLast48h',
      'viewsLast72h',
      'postsLast24h',
      'postsLastMonth',
      'subscribers',
      'dayGrow',
      'monthGrow',
      'postsTotal',
      'cpm',
      'er',
      'malePercent',
      'femalePercent',
      'postPriceSell',
      'placementTypes',
      'totalCountByCategories',
    ],
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalPageCounts: null,
    groupsSearchParams: { ...groupsSearchParamsTemplate },
    groupsSearchParamsTemplate: { ...groupsSearchParamsTemplate },
    selectedTypeOfGroups: null,
    groupsIsloading: false,
    //
    groupsData: [],
    groupsAggregateValues: [],
    hideGroupDetails: null,
    totalNewGroups: null,

    sortDir: 'desc',
    sortBy: 'subscribersCount',

    currency: localStorage.getItem('appCurrency') || 'rub',
    showGroupMenu: false,
  },
  getters: {
    getGroupsData: (state) => state.groupsData,
    getTypesOfGroups: (state) => state.typesOfGroups,
    getGroupsIsLoading: (state) => state.groupsIsloading,
    getAggregateIsLoading: (state) => state.aggregateIsLoading,
    getSelectedTypeOfGroups: (state) => state.selectedTypeOfGroups,
    getGroupsSearchParams: (state) => state.groupsSearchParams,
    getGroupsSearchParamsTemplate: (state) => state.groupsSearchParamsTemplate,
    getHideGroupDetails: (state) => state.hideGroupDetails,
    getOptionsForAggregate: (state) => state.aggregateOptions,
    getGroupsAggregateValues: (state) => state.groupsAggregateValues,
    getTotalNewGroups: (state) => state.totalNewGroups,
    getIsInitial: (state) => state.initial,
    getGroupsSortBy: (state) => state.sortBy,
    getGroupsSortDir: (state) => state.sortDir,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getCurrentPage: (state) => state.loadParams.page,
    getIsBannerCountries: (state) => state.groupsSearchParams?.subscriberCountries?.some((item) => ['Казахстан', 'Узбекистан', 'Киргизия'].includes(item)),
    getCurrency: (state) => state.currency,
    getShowGroupMenu: (state) => state.showGroupMenu,
  },
  mutations: {
    setGroupsData: (state, value) => { state.groupsData = value; },
    setGroupsIsLoading: (state, value) => { state.groupsIsloading = value; },
    setAggregateIsLoading: (state, value) => { state.aggregateIsLoading = value; },
    setSelectedTypeOfGroups: (state, value) => { state.selectedTypeOfGroups = value; },
    setGroupsSearchParams: (state, value) => { state.groupsSearchParams = { ...value }; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setHideGroupDetails: (state, value) => { state.hideGroupDetails = value; },
    setGroupsAggregateValues: (state, value) => { state.groupsAggregateValues = value; },
    resetGroupsSearchParams: (state) => { state.groupsSearchParams = { ...groupsSearchParamsTemplate }; },
    setInitial: (state, value) => { state.initial = value; },
    setTotalNewGroups: (state, value) => { state.totalNewGroups = value; },
    setSortBy: (state, value) => { state.sortBy = value; },
    setSortDir: (state, value) => { state.sortDir = value; },
    setUserIdForGroups: (state, value) => { state.groupsSearchParams.userIdOwner = value; },
    setCurrency: (state, value) => { state.currency = value; },
    setShowGroupMenu: (state, value) => { state.showGroupMenu = value; },
  },
  actions: {
    async loadGroupsData({
      state, commit, getters, dispatch,
    }, params = { reset: false, aggregate: false, userId: null }) {
      commit('setGroupsIsLoading', true);

      const headers = {};
      if (state.currency !== 'rub') {
        headers.currency = state.currency;
      }

      if (params.reset) {
        commit('setLoadPage', 1);
        commit('setGroupsData', []);
        commit('setGroupsAggregateValues', {});
        commit('setInitial', true);
        commit('setSortBy', 'subscribersCount');
        commit('setSortDir', 'desc');
        commit('setTotalPageCounts', null);
        commit('setUserIdForGroups', null);
      }

      commit('setUserIdForGroups', params.userId ? params.userId : null);

      if (params.aggregate) {
        dispatch('getAggregateValues', { aggregateValues: ['containsPostPriceSell'], searchParams: state.groupsSearchParams });
      }

      if (params.aggregate && state.initial) {
        dispatch('getAggregateValues', { aggregateValues: [...state.aggregateOptions], searchParams: state.groupsSearchParamsTemplate }).then(() => {
          commit('setInitial', false);
        });
      }

      return api.get(`/group-${state.selectedTypeOfGroups}`, {
        params: {
          ...state.groupsSearchParams, ...state.loadParams, sortBy: state.sortBy, sortDirection: state.sortDir,
        },
        headers,
      })
        .then((response) => {
          commit('setGroupsData', [...getters.getGroupsData, ...response.data.data]);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setGroupsIsLoading', false);
          return Promise.resolve(response.data.data);
        })
        .catch((error) => {
          commit('setGroupsData', []);
          commit('setGroupsIsLoading', false);
          console.warn('Error in action loadGroupsData; Error -', error);
        });
    },
    getAggregateValues({ state, commit }, params) {
      return api.get(`/group-${state.selectedTypeOfGroups}/aggregate-values`, {
        params: { aggregate: params.aggregateValues, ...params.searchParams },
      })
        .then(({ data: { data } }) => {
          const newAggregates = { ...state.groupsAggregateValues, ...data };
          commit('setGroupsAggregateValues', newAggregates);
        })
        .catch((error) => {
          console.warn('Error in action -- loadGroupsData(groupsAggregateValues); Error -', error);
        });
    },
    getTotalNewGroups({ state, commit }) {
      if (!state.totalNewGroups) {
        api.get('/statistic/total-by-period', { params: { period: ['lastMonth', 'lastDay'] } })
          .then(({ data: { data } }) => {
            commit('setTotalNewGroups', data);
          })
          .catch((error) => {
            console.warn('Error in action -- getTotalNewGroups; Error -', error);
          });
      }
    },
    groupUpdate({ commit }, params) {
      return api.put(`/group-${params.social}/${params.id}`, { ...params.newData })
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('setGroupsIsLoading', false);
          console.warn('Error in action -- groupUpdate --; Error -', error);
          return Promise.reject(error);
        });
    },
    exportGroups({ state }, params) {
      if (!params.groupIdentities) {
        params.social = state.selectedTypeOfGroups;
      }

      return api.post('/export-groups/to-tmp-file', params, { params: { shareToken: params.shareToken } })
        .then(({ data: { data } }) => {
          window.open(`https://data-api.socialjet.pro${data.url}`, '_self');
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- exportGroups --; Error -', error);
          return Promise.reject();
        });
    },
    createGroup({ commit }, { group, social }) {
      commit('setGroupsIsLoading', true);
      return api.post(`/group-${social}`, group)
        .then(() => {
          commit('setGroupsIsLoading', false);
          return Promise.resolve();
        })
        .catch((error) => {
          commit('setGroupsIsLoading', false);
          console.warn('Error in action -- createGroup--; Error -', error);
          return Promise.reject(error);
        });
    },
    deleteGroup({ commit, getters }, { id, social }) {
      commit('setGroupsIsLoading', true);
      return api.patch(`/group-${social}/bulk-delete`, { id })
        .then(() => {
          const newGroupsData = getters.getGroupsData.filter((group) => group.id !== id[0]);
          commit('setGroupsData', newGroupsData);
          commit('setGroupsIsLoading', false);
          return Promise.resolve();
        })
        .catch((error) => {
          commit('setGroupsIsLoading', false);
          console.warn('Error in action -- deleteGroup--; Error -', error);
          return Promise.reject(error);
        });
    },
    restoreGroup({ commit, getters }, { id, social }) {
      return api.patch(`/group-${social}/unmark-as-bad/${id}`, { id })
        .then(() => {
          const newGroupsData = getters.getGroupsData.filter((group) => group.id !== id);
          commit('setGroupsData', newGroupsData);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- restoreGroup --; Error -', error);
          return Promise.reject(error);
        });
    },
    addToBan({ commit, getters }, params) {
      return api.patch(`/group-${params.social}/mark-as-bad/${params.id}`, { reason: params.reason })
        .then(() => {
          const newGroupsData = getters.getGroupsData.filter((group) => group.id !== params.id);
          commit('setGroupsData', newGroupsData);
          Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- addToBan --; Error -', error);
          return Promise.reject(error);
        });
    },
    addWarning({ commit }, { id, social, codeNumber }) {
      return api.patch(`/group-${social}/add-warning/${id}`, { code: codeNumber })
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('setGroupsIsLoading', false);
          console.warn('Error in action -- addWarning --; Error -', error);
          return Promise.reject(error);
        });
    },
    removeWarning({ commit }, { id, social, codeNumber }) {
      return api.patch(`/group-${social}/remove-warning/${id}`, { code: codeNumber })
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('setGroupsIsLoading', false);
          console.warn('Error in action -- removeWarning --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
