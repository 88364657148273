<template>
  <div
  class="group-views">
    <div
    :class="isAdmin && 'pointer'"
    class="group-views__content"
    @click="openEditor">
      <div class="group-views__views">
        <div class="group-views__element">
          <span
          class="group-views__text"
          :class="!views.countLast24h && 'group-views__text_light'">{{ getLabelForViewsCount(views.countLast24h) }}</span>
          <span class="group-views__text group-views__text_light">{{ $t('per24') }}</span>
        </div>
        <div
        v-if="!hideDetails"
        class="group-views__element">
          <span
          class="group-views__text"
          :class="!views.countLast48h && 'group-views__text_light'">{{ getLabelForViewsCount(views.countLast48h) }}</span>
          <span class="group-views__text group-views__text_light">{{ $t('per48') }}</span>
        </div>
        <div
        v-if="!hideDetails"
        class="group-views__element">
          <span
          class="group-views__text"
          :class="!views.countLast72h && 'group-views__text_light'">{{ getLabelForViewsCount(views.countLast72h) }}</span>
          <span class="group-views__text group-views__text_light">{{ $t('per72') }}</span>
        </div>
      </div>
      <div
      class="group-views__element">
        <span
        class="group-views__text"
        :class="[
          isErrorEr && 'group-views__text_error',
          !group.er && 'group-views__text_light'
        ]">
          {{ group.er ? group.er + '%' : '—' }}
        </span>
        <span
        class="group-views__text group-views__text_light"
        :class="isErrorEr && 'group-views__text_error'">{{ $t('er') }}</span>
        <TJIcon
        v-if="isErrorEr"
        v-title="`${$t('Suspicious ER, possible cheating')}`"
        name="help_outline"
        class="group-views__text group-views__text_error"
        :size="12"
        pointer />
      </div>
    </div>
    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-views__editor">
        <!-- TRANSLATE -->
        <div class="group-views__editor-element">
          <span class="group-views__text_light">
            Просмотры за 24ч
          </span>
          <input
          v-model="countLast24hValue"
          type="number"
          placeholder="Укажите кол-во просмотров"
          class="group-views__input">
        </div>
        <!-- TRANSLATE -->
        <div class="group-views__editor-element">
          <span class="group-views__text_light">
            Просмотры за 48ч
          </span>
          <input
          v-model="countLast48hValue"
          type="number"
          placeholder="Укажите рост в месяц"
          class="group-views__input">
        </div>
        <!-- TRANSLATE -->
        <div class="group-views__editor-element">
          <span class="group-views__text_light">
            Просмотры за 72ч
          </span>
          <input
          v-model="countLast72hValue"
          type="number"
          placeholder="Укажите рост в день"
          class="group-views__input">
        </div>
        <div class="group-views__editor-element">
          <span class="group-views__text_light">
            ER
          </span>
          <input
          v-model="erValue"
          type="number"
          :placeholder="$t('Specify the er')"
          class="group-views__input">
        </div>
        <TJButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupViews',
  props: {
    views: {
      type: Object,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    countLast24hValue: null,
    countLast48hValue: null,
    countLast72hValue: null,
    erValue: null,
  }),
  methods: {
    getLabelForViewsCount(count) {
      if (count === null) {
        return '—';
      }
      count = Math.abs(count);

      if (count > 999 && count < 100000) {
        return `${parseFloat((count / 1000).toFixed(1))}k`;
      }
      if (count >= 100000 && count < 1000000) {
        return `${(count / 1000).toFixed(0)}k`;
      }
      if (count >= 1000000) {
        return `${parseFloat((count / 1000000).toFixed(1))}m`;
      }
      return count;
    },
    openEditor() {
      if (!this.isAdmin) {
        return;
      }
      this.countLast24hValue = this.views.countLast24h;
      this.countLast48hValue = this.views.countLast48h;
      this.countLast72hValue = this.views.countLast72h;
      this.erValue = this.group.er;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.countLast24hValue = null;
      this.countLast48hValue = null;
      this.countLast72hValue = null;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        er: this.erValue === '' ? null : +this.erValue,
        viewsStatistic: {
          countLast24h: this.countLast24hValue === '' ? null : this.countLast24hValue,
          countLast48h: this.countLast48hValue === '' ? null : this.countLast48hValue,
          countLast72h: this.countLast72hValue === '' ? null : this.countLast72hValue,
        },
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.views.countLast24h = this.countLast24hValue === '' ? null : this.countLast24hValue;
          this.views.countLast48h = this.countLast48hValue === '' ? null : this.countLast48hValue;
          this.views.countLast72h = this.countLast72hValue === '' ? null : this.countLast72hValue;
          this.group.er = this.erValue === '' ? null : this.erValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    isEmptyData() {
      return !this.views.countLast24h && !this.views.countLast48h && !this.views.countLast72h;
    },
    isErrorEr() {
      return this.group.er && this.group.er >= 40;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-views {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  width: 180px;
  height: 50px;
}

.group-views__views {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-views__content {
  display: flex;
  flex-direction: column;
  position: relative;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-views__element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.group-views__text {
  font-size: 14px;
  margin-right: 5px;
  color: $primary-gray;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_small {
    font-size: 12px;
  }
  &_error {
    color: $primary-red;
  }
}

.group-views__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-views__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-views__input {
  padding: 5px 10px;
  border: 1px solid $primary-lightgray;
  border-radius: 8px;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
}
</style>
