<template>
  <div
  class="group-subscribers"
  :class="isEmptyData && 'group-subscribers_empty'">
    <div
    v-if="!isEmptyData"
    :class="isAdmin && 'pointer'"
    class="group-subscribers__content"
    @click="openEditor">
      <span class="group-subscribers__text">{{ getTotalSubscribers(subscribers.count) }}</span>
      <div
      v-if="subscribers.monthGrow !== null && subscribers.monthGrow !== 0"
      class="group-subscribers__grow">
        <span
        class="group-subscribers__text"
        :class="isNegativeCount(subscribers.monthGrow) ? 'group-subscribers__text-down' : 'group-subscribers__text-up'">
          {{ isNegativeCount(subscribers.monthGrow)
            ? '-' + getTotalSubscribers(subscribers.monthGrow)
            : '+' + getTotalSubscribers(subscribers.monthGrow)
          }}
        </span>
        <div
        v-if="getGrowPercent"
        class="group-subscribers__grow">
          <TJIcon
          :class="isNegativeCount(subscribers.monthGrow) ? 'group-subscribers__text-down' : 'group-subscribers__text-up'"
          :size="12"
          :name="isNegativeCount(subscribers.monthGrow) ? 'arrow_downward' : 'arrow_upward'" />
          <span
          clas="group-subscribers__text"
          :class="isNegativeCount(subscribers.monthGrow) ? 'group-subscribers__text-down' : 'group-subscribers__text-up'">
            {{ getGrowPercent }}
          </span>
        </div>
      </div>
      <div
      v-if="!hideDetails">
        <span
        :class="[
          subscribers.dayGrow && isNegativeCount(subscribers.dayGrow) ? 'group-subscribers__text-down' : 'group-subscribers__text-up',
          !subscribers.dayGrow && 'group-subscribers__text-light'
        ]">
          {{ getLabelForDayGrow }}
        </span>
        <span class="group-subscribers__text-light">{{ $t('per24') }}</span>
      </div>
    </div>
    <span
    v-if="isEmptyData"
    :class="isAdmin && 'pointer'"
    class="group-subscribers__text-light"
    @click="openEditor">—</span>
    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-subscribers__editor">
        <div class="group-subscribers__editor-element">
          <span class="group-subscribers__text-light">
            {{ $t('subscribers_count') }}
          </span>
          <input
          v-model="subscribersValue"
          type="number"
          :placeholder="$t('Specify the number of subscribers')"
          class="group-subscribers__input">
        </div>
        <!-- TRANSLATE -->
        <div class="group-subscribers__editor-element">
          <span class="group-subscribers__text-light">
            Рост в месяц
          </span>
          <input
          v-model="monthGrowValue"
          type="number"
          placeholder="Укажите рост в месяц"
          class="group-subscribers__input">
        </div>
        <!-- TRANSLATE -->
        <div class="group-subscribers__editor-element">
          <span class="group-subscribers__text-light">
            Рост в день
          </span>
          <input
          v-model="dayGrowValue"
          type="number"
          placeholder="Укажите рост в день"
          class="group-subscribers__input">
        </div>
        <TJButton
        :text="$t('save')"
        type="text"
        class=""
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupSubscribers',
  props: {
    subscribers: {
      type: Object,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    monthGrowValue: null,
    dayGrowValue: null,
    subscribersValue: null,
    preventClickOutside: false,
  }),
  methods: {
    isNegativeCount(count) {
      return +count < 0;
    },
    openEditor() {
      if (!this.isAdmin) return;
      this.showEditor = true;
      this.monthGrowValue = this.subscribers.monthGrow;
      this.dayGrowValue = this.subscribers.dayGrow;
      this.subscribersValue = this.subscribers.count;
      window.addEventListener('mousedown', this.onMouseDown);
    },
    closeEditor() {
      this.showEditor = false;
      this.monthGrowValue = null;
      this.dayGrowValue = null;
      this.subscribersValue = null;
      window.removeEventListener('mousedown', this.onMouseDown);
    },
    onClickOutside() {
      if (this.preventClickOutside) {
        this.preventClickOutside = false;
        return;
      }
      this.closeEditor();
    },
    onMouseDown(mouseEvent) {
      this.preventClickOutside = mouseEvent.target.className === 'group-subscribers__input';
    },
    getTotalSubscribers(count) {
      count = Math.abs(count);

      if (count > 999 && count < 100000) {
        return `${parseFloat((count / 1000).toFixed(1))}k`;
      }
      if (count >= 100000 && count < 1000000) {
        return `${(count / 1000).toFixed(0)}k`;
      }
      if (count >= 1000000) {
        return `${parseFloat((count / 1000000).toFixed(1))}m`;
      }
      return count;
    },
    saveChanges() {
      const newData = {
        subscribersStatistic: {
          count: this.subscribersValue === '' ? null : this.subscribersValue,
          dayGrow: this.dayGrowValue === '' ? null : this.dayGrowValue,
          monthGrow: this.monthGrowValue === '' ? null : this.monthGrowValue,
        },
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.subscribers.count = this.subscribersValue === '' ? null : this.subscribersValue;
          this.subscribers.dayGrow = this.dayGrowValue === '' ? null : this.dayGrowValue;
          this.subscribers.monthGrow = this.monthGrowValue === '' ? null : this.monthGrowValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    getLabelForDayGrow() {
      if (this.subscribers.dayGrow === null) {
        return '—';
      }
      return this.isNegativeCount(this.subscribers.dayGrow)
        ? this.setNumberSpaces(this.subscribers.dayGrow)
        : `+${this.setNumberSpaces(this.subscribers.dayGrow)}`;
    },
    isEmptyData() {
      return this.subscribers.count === null || this.subscribers.count === 0;
    },
    getGrowPercent() {
      return `${Math.abs(Math.round((this.subscribers.monthGrow / this.subscribers.count) * 100))}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-subscribers {
  position: relative;
  display: flex;
  align-items: center;
  width: 180px;
  height: 50px;
  &_empty {
    justify-content: center;
  }
}

.group-subscribers__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  text-align: left;
  margin-left: 50px;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-subscribers__grow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-subscribers__text {
  font-size: 14px;
  margin-right: 5px;
  color: $primary-black;
  &-up {
    font-size: 12px;
    color: $primary-green;
  }
  &-down {
    font-size: 12px;
    color: $primary-red;
  }
  &-light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-subscribers__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-subscribers__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-subscribers__input {
  padding: 5px 10px;
  border: 1px solid $primary-lightgray;
  border-radius: 8px;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
}
</style>
