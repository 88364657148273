import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    groupIsloading: false,
    currentGroup: null,
    currentGroupLogs: null,
  },
  getters: {
    getGroupsForModerate: (state) => state.groupsForModerate,
    getCurrentGroup: (state) => state.currentGroup,
    getCurrentGroupLogs: (state) => state.currentGroupLogs,
  },
  mutations: {
    setGroupIsLoading: (state, value) => { state.groupIsloading = value; },
    setCurrentGroup: (state, value) => { state.currentGroup = value; },
    setCurrentGroupLogs: (state, value) => { state.currentGroupLogs = value; },
  },
  actions: {
    getCurrentGroup({ commit }, { id, social, shareToken }) {
      return api.get(`/group-${social}/${id}`, {
        params: {
          shareToken,
        },
      })
        .then(({ data: { data } }) => {
          commit('setCurrentGroup', data);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action getCurrentGroup; Error -', error);
          return Promise.reject(error);
        });
    },
    getPriceHistory({ commit }, params) {
      return api.post('/change-log/find-change', params.params, { params: { shareToken: params.shareToken } })
        .then(({ data: { data } }) => {
          commit('setGroupIsLoading', false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('setGroupIsLoading', false);
          console.warn('Error in action -- getPriceHistory --; Error -', error);
          return Promise.reject();
        });
    },
    getGroupStatistic({ commit }, params) {
      return api.get('/statistic/group-statistic-report', {
        params: { groupId: params.groupId, social: params.social, shareToken: params.shareToken },
      })
        .then(({ data: { data } }) => {
          commit('setGroupIsLoading', false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getGroupStatistic; Error -', error);
        });
    },
    createCurrentGroupLink({ commit }, params) {
      return api.post(`/group-${params.social}/share`, { id: params.id })
        .then(({ data: { data } }) => Promise.resolve(data))
        .catch((error) => {
          commit('setGroupIsLoading', false);
          console.warn('Error in action -- createCurrentGroupLink --; Error -', error);
          return Promise.reject();
        });
    },
  },
};
