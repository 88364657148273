<template>
  <div class="share-groups">
    <transition name="fade">
      <div
      v-if="!isEmptySelected"
      class="share-groups__share-container"
      @click="openShareModal">
        <span class="share-groups__text">
          {{ $t('share') }}
        </span>
        <div class="share-groups__share-button">
          <TJIcon
          name="share"
          :size="14"
          class="share-groups__share-icon" />
        </div>
      </div>
    </transition>
    <TJ-Modal
    :show-modal="showShareModal"
    @close="closeShareModal">
      <template #modal-content>
        <div class="share-groups__modal">
          <div
          v-if="step === 1"
          class="share-groups__share-block">
            <span
            class="share-groups__text share-groups__text_title"
            :class="showPriceBlock && 'share-groups__text_title_show-price'">{{ $t('share_collection') }}</span>
            <input
            v-model.trim="nameCompilation"
            :placeholder="$t('Come up with a name2')"
            class="share-groups__input share-groups__input_name">
            <TJButton
            :text="$t('share')"
            @handler="shareGroups" />
          </div>

          <div
          v-if="step === 2 && isAdmin"
          class="share-groups__share-block">
            <span
            class="share-groups__text share-groups__text_title"
            :class="showPriceBlock && 'share-groups__text_title_show-price'">{{ $t('share_collection2') }}</span>
            <TJButton
            :text="$t('copy_link')"
            :width="240"
            @handler="copyLink" />
            <span
            class="share-groups__text share-groups__text_disabled share-groups__text_small">
              {{ collectionLink }}
            </span>
            <span
            v-if="saveResultText"
            :class="saveResultError ? 'share-groups__text_error' : 'share-groups__text_success' "
            class="share-groups__text share-groups__text_small">
              {{ saveResultText }}
            </span>
            <div class="share-groups__price-change">
              <span
              class="share-groups__text share-groups__text_small"
              @click="togglePriceBlock">
                {{ $t('change_the_margin') }}
              </span>
              <TJIcon
              name="expand_more"
              :arrow-open="showPriceBlock"
              @click.native="togglePriceBlock" />
            </div>
          </div>

          <div
          v-if="step === 2 && isAdmin"
          class="share-groups__price-block"
          :class="[
            showPriceBlock && 'share-groups__price-block_show',
            showIndividualPrice && 'share-groups__price-block_show-individual'
          ]">
            <div class="share-groups__all-margin">
              <span
              class="share-groups__text">
                {{ $t('margin_for_the_entire_collection') + ':' }}
              </span>
              <input
              v-model="allPriceMargin"
              type="number"
              placeholder="%"
              :class="isCorrectValue(allPriceMargin) ? 'share-groups__input_correct' : 'share-groups__input_incorrect'"
              class="share-groups__input">
              <TJIcon
              v-title="`${$t('The markup will be applied to all channels in the selection')}`"
              :size="14"
              name="help_outline"
              class="share-groups__text share-groups__text_disabled share-groups__text_small share-groups__text_touch" />
              <TJIcon
              v-if="allPriceMargin"
              v-title="`${$t('reset_all')}`"
              :size="14"
              name="close"
              class="share-groups__text share-groups__text_disabled share-groups__text_small share-groups__text_touch"
              @click.native="resetAllPrices" />
            </div>
            <div
            v-if="!isShowCollectionGroups"
            class="share-groups__individual-price">
              <div
              class="share-groups__text__individual-header">
                <span
                class="share-groups__text"
                @click="showIndividualPrice = !showIndividualPrice">
                  {{ $t('per_channel_margin') }}
                </span>
                <TJIcon
                name="expand_more"
                class="share-groups__text_disabled"
                :arrow-open="showIndividualPrice"
                @click.native="showIndividualPrice = !showIndividualPrice" />
                <TJIcon
                v-title="`${$t('for_each_channel_in_the_selection_you_can_specify_an_individual_margin')}`"
                :size="14"
                name="help_outline"
                class="share-groups__text share-groups__text_touch share-groups__text_disabled" />
              </div>
              <div
              v-if="showIndividualPrice"
              class="share-groups__table-container"
              :class="showIndividualPrice && 'share-groups__table-container_show'">
                <input
                v-model="searchInput"
                :placeholder="$t('search_by_name')"
                class="share-groups__input share-groups__input_search">
                <div
                :class="getFilteredGroups.length <= 8 && 'share-groups__table_column'"
                class="share-groups__table">
                  <div
                  v-for="group in getFilteredGroups"
                  :key="group.group.id + group.group.name"
                  class="share-groups__table-element">
                    <span
                    class="share-groups__text share-groups__text_table">
                      {{ group.group.name }}
                    </span>
                    <input
                    v-model="group.price"
                    placeholder="%"
                    type="number"
                    :class="isCorrectValue(group.price) ? 'share-groups__input_correct' : 'share-groups__input_incorrect'"
                    class="share-groups__input">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span
          v-show="errorInInputs && showPriceBlock"
          class="share-groups__text share-groups__text_small share-groups__text_error share-groups__error-text">
            {{ $t('check_the_fields_are_correct') }}
          </span>
          <TJButton
          v-if="showPriceBlock"
          :disabled="isEmptyInputs"
          :text="$t('change_the_margin')"
          @handler="savePrice" />
        </div>
      </template>
    </TJ-Modal>
  </div>
</template>

<script>

export default {
  name: 'ShareGroups',
  props: {
    isEmptySelected: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    selectedGroups: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showShareModal: false,
    newCollectionId: null,
    collectionLink: null,
    showPriceBlock: false,
    showIndividualPrice: false,
    compilationGroups: null,
    allPriceMargin: null,
    searchInput: '',
    saveResultText: null,
    saveResultError: null,
    nameCompilation: '',
    step: 1,
  }),
  methods: {
    savePrice() {
      if (this.errorInInputs || this.isEmptyInputs) {
        return;
      }

      const positions = [];

      this.compilationGroups.forEach((item) => {
        if (item.price) {
          positions.push({
            groupIdentity: {
              id: item.group.id,
              social: item.group.social,
            },
            value: item.price,
            type: 'margin',
          });
        }
      });

      const compilationData = {
        compilationId: this.newCollectionId.toString(),
        value: +this.allPriceMargin,
        type: 'margin',
      };

      // для подборок индивидуальную наценку не отправляем
      if (!this.isShowCollectionGroups) {
        compilationData.positions = positions;
      }

      this.$store.dispatch('collections/changeCollectionPrice', compilationData)
        .then(() => {
          this.togglePriceBlock();
          this.saveResultText = this.$t('the_margin_for_the_selection_has_been_changed');
          this.saveResultError = false;
        })
        .catch(() => {
          this.saveResultText = this.$t('error_when_changing_the_margin_reload_the_page_and_try_again');
          this.saveResultError = true;
        });
    },
    togglePriceBlock() {
      if (!this.showPriceBlock) {
        this.showPriceBlock = true;
      } else {
        this.showPriceBlock = false;
        this.showIndividualPrice = false;
      }
    },
    resetData() {
      this.newCollectionId = null;
      this.collectionLink = null;
      this.showPriceBlock = false;
      this.showIndividualPrice = false;
      this.allPriceMargin = null;
      this.compilationGroups = null;
      this.searchInput = '';
      this.saveResultText = null;
      this.saveResultError = false;
      this.nameCompilation = '';
      this.step = 1;
    },
    closeShareModal() {
      this.resetData();
      this.showShareModal = false;
    },
    shareGroups() {
      const collectionForShare = {
        name: this.nameCompilation ? this.nameCompilation : 'noname',
      };

      if (this.isShowCollectionGroups && this.getSelectedAllGroups) {
        collectionForShare.compilationId = this.getCurrentCollectionId;
      } else {
        const groupsForShare = [];
        this.selectedGroups.forEach((item) => {
          const group = {
            id: item.id,
            social: item.social,
          };
          groupsForShare.push(group);
        });
        collectionForShare.groupIdentities = groupsForShare;
      }

      this.$store.dispatch('collections/shareCollection', collectionForShare)
        .then((data) => {
          this.newCollectionId = data.compilation.id;
          // this.collectionLink = `https://data.socialjet.pro/compilation?id=${this.newCollectionId}&shareToken=${data.shareToken}`;
          this.collectionLink = `https://socialjet.tech/kn1XZb?id=${this.newCollectionId}&shareToken=${data.shareToken}`;
          if (!this.isAdmin) {
            this.copyLink();
            this.closeShareModal();
            return;
          }
          this.compilationGroups = this.selectedGroups.map((group) => ({ group, price: null }));
          this.step = 2;
        })
        .catch(() => {
          this.closeShareModal();
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while sending the selection. Reload the page and try again'),
              error: true,
            });
        });
    },
    copyLink() {
      if (!this.collectionLink) {
        this.showShareModal = false;
        this.$store.dispatch('app/setSnackBar', {
          text: this.$t('An error occurred when forming the link. Please reload the page and try again'),
          error: true,
        });
        return;
      }
      this.copyToBuffer(this.collectionLink);
      this.showShareModal = false;
      this.resetData();
      this.$store.dispatch('app/setSnackBar', { text: this.$t('the_link_is_copied_to_the_clipboard') });
    },
    isCorrectValue(value) {
      if (!value) return true;
      return Number.isInteger(+value);
    },
    resetAllPrices() {
      this.allPriceMargin = null;
      this.compilationGroups.forEach((item) => {
        item.price = null;
      });
    },
    openShareModal() {
      this.resetData();
      this.showShareModal = true;
    },

  },
  computed: {
    getFilteredGroups() {
      return this.compilationGroups?.filter((item) => item.group.name.toUpperCase().includes(this.searchInput.toUpperCase())
        || item.group.url.toUpperCase().includes(this.searchInput.toUpperCase()));
    },
    errorInInputs() {
      return this.compilationGroups?.some((group) => !Number.isInteger(+group.price)) || !Number.isInteger(+this.allPriceMargin);
    },
    isEmptyInputs() {
      const emptyMargin = this.allPriceMargin === null || this.allPriceMargin === '';
      return emptyMargin && this.compilationGroups.every((group) => group.price === null || group.price === '');
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
    getSelectedAllGroups() {
      return this.$store.getters['collections/getSelectedAllGroups'];
    },
    getCurrentCollectionId() {
      return this.$store.getters['collections/getCurrentCollectionId'];
    },
  },
  watch: {
    allPriceMargin: {
      handler(newValue, prevValue) {
        if (this.compilationGroups) {
          this.compilationGroups.forEach((item) => {
            if (!item.price || prevValue === item.price) {
              item.price = newValue;
            }
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.share-groups__share-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.share-groups__share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  @include tablet-screen {
    width: 20px;
    height: 20px;
  }
}

.share-groups__text {
  font-size: 13px;
  word-wrap: break-word;
  max-width: 300px;
  @include tablet-screen {
    max-width: 100%;
  }
  &_title {
    font-size: 22px;
    transition: 0.2s;
    padding-bottom: 40px;
    &_show-price {
      padding-bottom: 10px;
    }
  }
  &_small {
    font-size: 12px;
  }
  &_touch {
    cursor: pointer;
  }
  &_table {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_disabled {
    color: $primary-lightgray;
  }
  &_success {
    color: $primary-green;
  }
  &_error {
    color: $primary-red;
  }
}

.share-groups__share-icon {
  color: $primary-white;
  padding-right: 2px;
}

.share-groups__modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.share-groups__individual-price {
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  position: relative;
}

.share-groups__share-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.share-groups__price-change {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.share-groups__all-margin {
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.share-groups__price-block {
  height: 0;
  transition: 0.2s;
  overflow: hidden;
  border: 1px solid white;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  position: relative;
  &_show {
    height: 95px;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
  }
  &_show-individual {
    height: 380px;
  }
}

.share-groups__table-container {
  height: 0;
  overflow: hidden;
  &_show {
    height: 300px;
  }
}

.share-groups__text__individual-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.share-groups__table {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 260px;
  margin-top: 10px;
  transition: 0.2s;
  &_column {
    flex-direction: column;
  }
}

.share-groups__table-element {
  width: 95%;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
  height: 30px;
  justify-content: space-between;
  padding-bottom: 5px;
  @include tablet-screen {
    width: 45%;
    margin: 5px 15px 0px 0px;
  }
}

.share-groups__input {
  width: 50px;
  padding: 0px 3px;
  margin-left: 5px;
  border: 1px solid $primary-lightgray;
  text-align: center;
  border-radius: 5px;
  background: $primary-white;
  box-sizing: border-box;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  &_search {
    margin-left: 0px;
    text-align: left;
    width: 200px;
  }
  &_name {
    padding: 0px 5px;
    border: 1px solid $border-color-primary;
    border-radius: 8px;
    background: $primary-white;
    box-sizing: border-box;
    width: 350px;
    font-size: 14px;
    height: 40px;
    margin-bottom: 20px;
    &:focus {
      outline: none;
      border-color: $border-color-focus;
    }
    &:hover {
      outline: none;
    }
  }
  &_correct {
    border: 1px solid $primary-green;
  }
  &_incorrect {
    border: 1px solid $primary-red;
  }
}

.share-groups__error-text {
  position: absolute;
  bottom: 43px;
  left: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
