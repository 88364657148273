import axios from 'axios';

export const API_URL = process.env.VUE_APP_API_BASE_URL ?? 'https://data-api.socialjet.pro/api-dev/v1/';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('userToken');
  if (token) {
    config.headers['X-API-KEY'] = token;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (err) => {
    const status = err.response?.status || 500;
    if (status === 401) {
      // localStorage.removeItem('userToken');
      // localStorage.removeItem('userInfo');
      // window.location.href = 'https://data.socialjet.pro';
    }
    return Promise.reject(err);
  },
);

export default api;
