<template>
  <div
  ref="mainGroups"
  class="main-groups"
  :class="[
    isEmptyGroupsData && !isLoading && 'main-groups_empty',
    mode === 'noauth' && 'main-groups_noauth'
  ]">
    <NavActions
    :groups-data="groupsData"
    :mode="mode"
    :is-admin="isAdmin"
    :empty-data="isEmptyGroupsData" />
    <div
    ref="groupContent"
    class="main-groups__content"
    :class="isEmptyGroupsData && !isLoading && 'main-groups__content_empty'"
    @scroll="onScroll"
    @mousedown.left="mouseDown"
    @mouseup.left="mouseUp"
    @mouseleave="mouseLeave"
    @mousemove="mouseMove">
      <div
      v-if="!isEmptyGroupsData"
      ref="groupElements"
      class="main-groups__elements">
        <GroupsHeaders
        :groups-data="groupsData"
        :is-admin="isAdmin"
        :is-hidden-mode="isHiddenMode"
        :mode="mode"
        :selected-groups="getSelectedGroups"
        :columns="columns"
        @scrollToTop="scrollToTop" />
        <Group
        v-for="group in groupsData"
        :key="group.name + group.id"
        :group="group"
        :selected-groups="getSelectedGroups"
        :selected-type-of-groups="getSelectedTypeOfGroups"
        :hide-group-details="getHideGroupDetails"
        :is-hidden-mode="isHiddenMode"
        :trade-offer="getTradeOffer(group)"
        :is-admin="isAdmin"
        :mode="mode"
        :show-group-menu="showGroupMenu"
        :columns="columns" />
      </div>
    </div>
    <div
    v-if="isEmptyGroupsData && !isLoading"
    class="main-groups__groups-empty flex-center">
      <span class="main-groups__text">{{ labelForEmptyGroups }}</span>
      <span
      v-if="!isShowFavoriteGroups && !isShowCollectionGroups && $route.name !== 'my-groups'"
      class="main-groups__text">{{ $t('try_updating_the_search_parameters') }}</span>
    </div>
    <transition name="fade">
      <div
      v-show="scrollPosition > 1320"
      class="main-groups__up-button"
      :class="showActions && 'main-groups__up-button_show-actions'"
      @click="scrollToTop">
        <span class="main-groups__up-button-text">{{ $t('up') }}</span>
        <TJIcon
        name="north"
        class="main-groups__up-button-icon"
        :size="16"
        pointer />
      </div>
    </transition>
    <GroupActions
    v-if="!isEmptyGroupsData || isShowCollectionGroups"
    :mode="mode"
    :groups-data="groupsData"
    :selected-groups="getSelectedGroups"
    :show-actions="showActions" />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import groupsData from '@/assets/groupsData.json';

import NavActions from './components/NavActions/NavActions.vue';
import GroupsHeaders from './components/GroupsHeaders.vue';
import Group from './components/Group/Group.vue';
import GroupActions from './components/GroupActions/GroupActions.vue';

export default {
  components: {
    NavActions, GroupsHeaders, Group, GroupActions,
  },
  props: {
    groupsData: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    scrollPosition: 0,
    columns: null,
    isScroll: false,
    startX: null,
    scrollLeft: null,
  }),
  created() {
    // FUTURE удаляем предыдущие релизы колонок
    localStorage.removeItem('savedGroupsColumns211022');
    localStorage.removeItem('savedGroupsColumns271022');
    localStorage.removeItem('savedGroupsColumns281022');

    const savedGroupsColumns = JSON.parse(localStorage.getItem('savedGroupsColumns031122'));
    if (savedGroupsColumns) {
      savedGroupsColumns.unshift({
        value: 'name',
        show: true,
        sort: 'name',
      });
    }
    this.columns = savedGroupsColumns || cloneDeep(groupsData.columns);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.$refs.groupContent.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onScroll() {
      const position = this.$refs.groupContent.scrollTop;
      const { clientHeight } = this.$refs.groupContent;

      if (position > this.scrollPosition) {
        this.$refs.mainGroups.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      this.scrollPosition = position;

      if (this.$refs.groupContent.scrollHeight <= clientHeight
      || this.isLastPage
      || this.isShowSelectedGroups
      || this.isShowFavoriteGroups
      || this.isLoading) {
        return;
      }

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.groupContent.scrollHeight);

      if (endScroll && this.isShowCollectionGroups) {
        this.$store.dispatch('collections/getCurrentCompilation', { isNew: false, isLoaded: false, noauth: this.mode === 'noauth' });
        return;
      }

      if (endScroll) {
        this.$store.dispatch('groups/loadGroupsData');
      }
    },
    getTradeOffer(group) {
      if (!this.$store.getters['collections/getLoadCompilation'].length) {
        return null;
      }
      return this.$store.getters['collections/getLoadCompilation']
        .find((item) => item.group.name === group.name && item.group.id === group.id).tradeOffer;
    },
    mouseDown(e) {
      if (e.target.className === 'group-name__block'
      || e.target.className === 'group-name'
      || e.target.className.includes('input')
      || e.target.className.includes('editor')) return;
      this.isScroll = true;
      this.startX = e.pageX - this.$refs.groupContent.offsetLeft;
      this.scrollLeft = this.$refs.groupContent.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      if (!this.isScroll || this.showGroupMenu) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.groupContent.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.groupContent.scrollLeft = this.scrollLeft - walk;
    },
  },
  computed: {
    isEmptyGroupsData() {
      return this.groupsData.length === 0;
    },
    isEmptySelectedGroups() {
      return this.getSelectedGroups.length === 0;
    },
    getSelectedGroups() {
      return this.$store.getters['collections/getSelectedGroups'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getHideGroupDetails() {
      return this.$store.getters['groups/getHideGroupDetails'];
    },
    isShowSelectedGroups() {
      return this.$store.getters['collections/getShowSelectedGroups'];
    },
    isShowFavoriteGroups() {
      return this.$store.getters['collections/getShowFavoriteGroups'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
    isLoading() {
      return this.$store.getters['groups/getGroupsIsLoading'] || this.$store.getters['collections/getCollectionsIsLoading'];
    },
    labelForEmptyGroups() {
      if (this.isShowCollectionGroups) {
        return this.$t('Empty compilation');
      }
      return this.isShowFavoriteGroups ? this.$t('Favorites is empty') : this.$t('Nothing found');
    },
    isCompilationScreen() {
      return this.$route.name === 'compilation';
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    isLastPage() {
      if (this.isShowCollectionGroups) {
        return this.$store.getters['collections/getCurrentPage'] === (+this.$store.getters['collections/getTotalPageCounts'] + 1);
      }
      return this.$store.getters['groups/getCurrentPage'] === (+this.$store.getters['groups/getTotalPageCounts'] + 1);
    },
    isHiddenMode() {
      return this.$store.getters['groups/getGroupsSearchParams'].onlyBadGroups;
    },
    showActions() {
      return !this.isEmptySelectedGroups || this.isShowSelectedGroups || this.isShowCollectionGroups;
    },
    showGroupMenu() {
      return this.$store.getters['groups/getShowGroupMenu'];
    },
  },
  watch: {
    groupsData() {
      if (this.groupsData.length < 15) {
        this.scrollPosition = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-groups {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  position: relative;
  margin: 20px 0px 0px;
  @include tablet-screen {
    margin: 20px;
  }
  &_noauth {
    max-height: calc(100vh - 70px);
    @include tablet-screen {
      max-height: calc(100vh - 110px);
    }
  }
  &_empty {
    height: 60vh;
  }
}

.main-groups__content {
  background: white;
  z-index: 1;
  overflow: auto;
  overflow-x: scroll;
  width: 100%;
  height: calc(100vh - 90px);
  &_empty {
    height: 0px;
  }
}

.main-groups__groups-empty {
  flex-direction: column;
  height: 100%;
}

.main-groups__text {
  color: $primary-black;
  font-size: 14px;
  &_all-selected {
    color: white;
  }
}

.main-groups__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-groups__up-button {
  display: none;
    @include desktop-screen {
    position: absolute;
    padding: 5px 20px;
    bottom: 40px;
    right: 40px;
    color: $primary-white;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    background: $primary-blue;
    align-items: center;
    &_show-actions {
      bottom: 100px;
    }
  }
}

</style>
