<template>
  <div
  class="data-radio"
  :class="[checked && 'data-radio_checked', disabled && 'disabled']"
  @click="toggleCheck">
    <div
    class="data-radio__icon"
    :class="[checked && !disabled ? 'data-radio__icon_show' : '', disabled && 'disabled']" />
  </div>
</template>

<script>

export default {
  name: 'DataRadio',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCheck() {
      if (this.disabled) {
        return;
      }
      this.$emit('input');
    },
  },
};
</script>

<style lang="scss" scoped>
// rounded
.data-radio {
  width: 18px;
  height: 18px;
  border: 1px solid $primary-blue;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: 0px 0px 1px 2px rgba(rgb(26, 144, 199), 0.2);
  }
}

.data-radio__icon {
  width: 10px;
  height: 10px;
  background: $primary-blue;
  border-radius: 100%;
  transform: scale(0);
  transition: transform 0.1s;
  &_show {
    transform: scale(1);
    transition: transform 0.1s;
  }
}

.disabled {
  pointer-events: none;
  border: 1px solid #A1A1A1;
  background: #fafafa;
}
</style>
