<template>
  <div class="import-headers">
    <div
    v-for="header in getFilteredHeaders"
    :key="header.value"
    :class="[header.value === 'name' && 'import-headers__element_name', header.value === 'user' && 'import-headers__element_user']"
    class="import-headers__element">
      <span class="import-headers__text_light">
        {{ $t(header.name) }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImportHeaders',
  props: {
    isAllImports: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { name: 'name', value: 'name' },
      { name: 'user', value: 'user', all: true },
      { name: 'source', value: 'source' },
      { name: 'result', value: 'status' },
      { name: 'total_channels', value: 'total' },
      { name: 'added', value: 'added' },
      { name: 'update', value: 'update' },
      { name: 'not_added', value: 'error' },
      { name: 'link', value: 'link' },
      { name: 'compilation', value: 'compilation' },
    ],
  }),
  computed: {
    getFilteredHeaders() {
      if (this.isAllImports) return this.headers;
      return this.headers.filter((header) => !header.all);
    },
  },
};
</script>

<style lang="scss" scoped>
.import-headers {
  display: flex;
  align-items: center;
  min-width: 100%;
  z-index: 2;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  border-top: 1px solid $primary-group-border;
  border-bottom: 1px solid $primary-group-border;
}

.import-headers__element {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_name {
    padding: 12px 0px;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    @include tablet-screen {
      min-width: 250px;
    }
  }
  &_user {
    width: 220px;
  }
}

.import-headers__text {
  font-size: 14px;
  color: $primary-gray;
  &_name {
    color: $primary-blue;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
