<template>
  <div class="group-moderation-element">
    <div
    class="group-moderation-element__column group-moderation-element__column_name"
    :class="getGroupStatus() !== 'wait' && getGroupStatus()">
      <img
      class="group-moderation-element__avatar"
      :src="group.entity.avatar ? group.entity.avatar : require('@/assets/svg/empty-avatar.svg')"
      @click="routeToCurrentGroupLink">
      <div class="group-moderation-element__name-text">
        <span
        v-title="group.entity.name"
        class="group-moderation-element__text group-moderation-element__text_name">
          {{ group.entity.name }}
        </span>
        <span
        v-if="getCategories()"
        class="group-moderation-element__text group-moderation-element__text_category">
          {{ getCategories() }}
        </span>
      </div>
    </div>

    <!-- Subscribers -->
    <div class="group-moderation-element__column">
      <span class="group-moderation-element__text">
        {{ getSubscribers() }}
      </span>
    </div>

    <!-- Views -->
    <div class="group-moderation-element__column">
      <span class="group-moderation-element__text">
        {{ getViews() }}
      </span>
    </div>

    <!-- Price -->
    <div class="group-moderation-element__column group-moderation-element__column_row">
      <span class="group-moderation-element__text group-moderation-element__text_red">
        {{ getPrices('new') }}
      </span>
      <span class="group-moderation-element__text">
        /
      </span>
      <span class="group-moderation-element__text group-moderation-element__text_green">
        {{ getPrices('old') }}
      </span>
    </div>

    <!-- Manager -->
    <div class="group-moderation-element__column group-moderation-element__column_wide">
      <span class="group-moderation-element__text group-moderation-element__text_light">
        {{ getManager() }}
      </span>
      <span class="group-moderation-element__text group-moderation-element__text_light">
        {{ getDate() }}
      </span>
    </div>

    <div
    v-if="getGroupStatus() === 'wait'"
    class="group-moderation-element__buttons">
      <span
      class="group-moderation-element__text group-moderation-element__text_blue pointer"
      @click="approveGroup(true)">
        Принять
      </span>
      <span
      class="group-moderation-element__text group-moderation-element__text_red pointer"
      @click="approveGroup(false)">
        Отклонить
      </span>
    </div>
    <div
    v-else
    class="group-moderation-element__statuses">
      <TJIcon
      :name="getGroupStatus() === 'applied' ? 'done' : 'close'"
      class="group-moderation-element__text"
      :class="getGroupStatus() === 'applied' ? 'group-moderation-element__text_green' : 'group-moderation-element__text_red'"
      :size="20"
      pointer />
      <span class="group-moderation-element__text group-moderation-element__text_light">
        {{ getGroupStatus() === 'applied' ? 'Подтверждено' : 'Отклонено' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupElement',
  props: {
    group: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getGroupStatus() {
      return this.group.status;
    },
    getPrices(type) {
      if (type === 'old') {
        const price = this.group.entity?.postPrice?.sale || null;
        return price ? this.setNumberSpaces(Math.round(price)) : '-';
      }
      const price = this.group.changes[0]?.value || null;
      return price ? this.setNumberSpaces(price) : '-';
    },
    getCategories() {
      const categories = this.group.entity.categories.map((category) => this.$t(category.name));
      return categories.length ? categories.join(', ') : false;
    },
    routeToCurrentGroupLink() {
      window.open(this.group.entity.url);
    },
    getSubscribers() {
      return this.group.entity.subscribersStatistic.count
        ? this.setNumberSpaces(this.group.entity.subscribersStatistic.count)
        : 'Нет данных';
    },
    getViews() {
      return this.group.entity.viewsStatistic.countLast24h
        ? `${this.setNumberSpaces(this.group.entity.viewsStatistic.countLast24h)} / день`
        : 'Нет данных';
    },
    getManager() {
      return this.group.userChanger.email;
    },
    getDate() {
      return this.moment(this.group.createdAt.date).format('LLL');
    },
    approveGroup(value) {
      if (this.group.status === 'wait') {
        this.$emit('approveGroup', value, this.group.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group-moderation-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  height: 58px;
  border-bottom: 1px solid $primary-group-border;
  position: relative;
}

.group-moderation-element__column {
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40px;
  border-right: 1px solid $primary-group-border;
  &_name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    @include tablet-screen {
      min-width: 400px;
      padding-left: 40px;
    }
  }
  &_wide {
    width: 240px;
  }
  &_row {
    flex-direction: row;
    @include between-elements {
      margin-right: 5px;
    }
  }
}

.group-moderation-element__text {
  font-size: 14px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_name {
    width: 200px;
    @include tablet-screen {
      width: 300px;
    }
    font-size: 16px;
    color: $primary-blue;
  }
  &_category {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 12px;
    text-align: center;
  }
  &_red {
    color: $primary-red;
  }
  &_green {
    color: $primary-green;
  }
  &_blue {
    color: $primary-blue;
  }
}

.group-moderation-element__avatar {
  display: none;
  @include tablet-screen {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 20px;
    box-sizing: border-box;
    border-radius: 50%;
  }
}

.group-moderation-element__name-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-moderation-element__buttons {
  display: flex;
  width: 240px;
  padding: 0px 20px 0px 40px;
  @include between-elements {
    margin-right: 30px;
  }
}

.group-moderation-element__statuses {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 240px;
  padding-left: 30px;
  @include between-elements {
    margin-right: 10px;
  }
}
</style>
