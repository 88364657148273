<template>
  <div class="group-gender">
    <div
    class="group-gender__content"
    :class="isAdmin && 'pointer'"
    @click="openEditor">
      <div
      v-if="!isEmptyGender"
      class="group-gender__gender">
        <img
        class="group-gender__avatar group-gender__avatar_gender"
        :src="+malePercent >= +femalePercent ? require('@/assets/images/male-icon.png') : require('@/assets/images/female-icon.png')"
        alt="group-avatar">
        <span
        class="group-gender__text group-gender__text_small">{{ getLabelForGender() }}</span>
        <img
        v-if="+malePercent === +femalePercent"
        class="group-gender__avatar group-gender__avatar_gender"
        src="@/assets/images/female-icon.png"
        alt="group-avatar">
      </div>
      <div v-if="isEmptyGender">
        <span class="group-gender__text_light">{{ $t('gender') + ':' }}</span>
        <span class="group-gender__text_light">Нет данных</span>
      </div>

      <div
      v-if="!isEmptyAges"
      class="group-gender__ages">
        <span
        v-for="age in filteredAges"
        :key="age.ageRange"
        class="group-gender__text group-gender__text_small">{{ getLabelAge(age) }}</span>
      </div>
      <div v-if="isEmptyAges">
        <span class="group-gender__text_light">Возраст: Нет данных</span>
      </div>
    </div>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-gender__editor pointer">
        <span class="group-gender__text group-gender__text_light">{{ $t('gender') }}</span>
        <div class="group-gender__editor-element">
          <div class="group-gender__editor-element group-gender__editor-element_row">
            <span
            class="group-gender__text group-gender__text_small"
            :style="'width: 30px'">{{ $t('male') }}</span>
            <input
            id="male"
            v-model="maleValue"
            type="number"
            max="100"
            class="group-gender__input group-gender__input_small"
            :class="isInvalidMaleValue && 'group-gender__input_error'"
            @input="changeGenderValue">
            <span class="group-gender__text group-gender__text_small">%</span>
          </div>
          <div class="group-gender__editor-element group-gender__editor-element_row">
            <span
            class="group-gender__text group-gender__text_small"
            :style="'width: 30px'">{{ $t('female') }}</span>
            <input
            id="female"
            v-model="femaleValue"
            type="number"
            class="group-gender__input group-gender__input_small"
            :class="isInvalidFemaleValue && 'group-gender__input_error'"
            @input="changeGenderValue">
            <span class="group-gender__text group-gender__text_small">%</span>
          </div>
          <span
          v-if="isInvalidMaleValue || isInvalidFemaleValue"
          class="group-gender__text group-gender__text_error">Сумма двух полей больше 100%</span>
        </div>
        <TJButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupGender',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    malePercent: {
      type: [Number, String],
      default: null,
    },
    femalePercent: {
      type: [Number, String],
      default: null,
    },
    ages: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showEditor: false,
    maleValue: null,
    femaleValue: null,
  }),
  methods: {
    openEditor() {
      if (!this.isAdmin || this.showEditor) return;
      this.maleValue = +this.malePercent;
      this.femaleValue = +this.femalePercent;
      this.showEditor = true;
    },
    changeGenderValue(event) {
      const { id } = event.target;
      if (id === 'male') {
        this.maleValue = this.maleValue > 100 ? 100 : this.maleValue;
        return;
      }
      this.femaleValue = this.femaleValue > 100 ? 100 : this.femaleValue;
    },
    saveChanges() {
      const newData = {
        subscribersStatistic: {
          malePercent: this.maleValue,
          femalePercent: this.femaleValue,
        },
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.subscribersStatistic.malePercent = this.maleValue;
          this.group.subscribersStatistic.femalePercent = this.femaleValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
    closeEditor() {
      this.showEditor = false;
    },
    onClickOutside() {
      this.closeEditor();
    },
    getLabelAge(age) {
      const value = age.value ? `${Math.round(+age.value)}%` : `${Math.round(parseInt(age.ageRange.split(':')[1], 10))}%`;
      return `${age.value ? age.ageRange : age.ageRange.split(':')[0]} лет: ${value}`;
    },
    getLabelForGender() {
      const male = Math.round(this.malePercent);
      const female = Math.round(this.femalePercent);

      if (male >= female) {
        return `${this.$t('M')} ${male || 0}% / ${this.$t('F')} ${female || 0}%`;
      }
      return `${this.$t('F')} ${female || 0}% / ${this.$t('M')} ${male || 0}%`;
    },
  },
  computed: {
    isEmptyGender() {
      return this.malePercent === null && this.femalePercent === null;
    },
    isEmptyAges() {
      return this.ages === null || !this.ages.length;
    },
    isInvalidFemaleValue() {
      return this.femaleValue === 100 || this.femaleValue > (100 - this.maleValue);
    },
    isInvalidMaleValue() {
      return this.maleValue === 100 || this.maleValue > (100 - this.femaleValue);
    },
    filteredAges() {
      const ages = this.ages.filter((item) => item.value !== null);
      return this.hideDetails ? ages.slice(0, 1) : ages;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-gender {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
  width: 180px;
  padding-left: 23px;
  position: relative;
  &_empty {
    padding-left: 0px;
    align-items: center;
  }
}

.group-gender__text {
  font-size: 14px;
  color: $primary-gray;
  &_small {
    font-size: 12px;
  }
  &_light {
    font-size: 12px;
    margin-right: 4px;
    color: $primary-lightgray;
  }
  &_error {
    font-size: 10px;
    color: $primary-red;
  }
}

.group-gender__empty {
  padding-left: 20px;
}

.group-gender__element {
  display: flex;
  align-items: center;
  &_other {
    flex-direction: column;
  }
}

.group-gender__gender {
  display: flex;
  align-items: center;
  margin-left: -4px;
}

.group-gender__ages {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 3px;
  }
}

.group-gender__editor {
  position: absolute;
  top: 0px;
  left: 25px;
  width: 220px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-gender__editor-element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_row {
    flex-direction: row;
    align-items: center;
    @include between-elements {
    margin-bottom: 0px;
    margin-right: 5px;
  }
  }
}

.group-gender__input {
  border: 1px solid $primary-lightgray;
  font-size: 12px;
  padding: 2px 5px;
  width: 160px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_small {
    width: 38px;
    padding: 1px 5px;
  }
  &_error {
    border: 1px solid $primary-red;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
