<template>
  <div class="tariffs-banner">
    <span class="tariffs-banner__text tariffs-banner__text_title">
      Доступ к SocialData можно получить двумя способами:
    </span>
    <ul class="tariffs-banner__options">
      <li
      v-for="option in options"
      :key="option.id"
      class="tariffs-banner__text">
        {{ option.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    options: [
      // eslint-disable-next-line max-len
      { id: 1, text: 'Бесплатно: при условии выполнения stay tuned commit (минимального бюджета, потраченного на рекламные кампании в течение месяца через сервис SocialData в размере 100 000р' },
      { id: 2, text: 'Платно: оплатив тариф в размере 25 000р' },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.tariffs-banner {
  width: 100%;
  background: $primary-blue;
  padding: 24px 40px;
  border-radius: 20px;
}

.tariffs-banner__text {
  color: white;
  font-weight: 400;
  font-size: 16px;
  &_title {
    font-weight: 500;
    font-size: 24px;
  }
}

.tariffs-banner__options {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  margin: 20px 0px 0px;
  @include between-elements {
    margin-bottom: 10px;
  }
}
</style>
