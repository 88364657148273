<template>
  <div
  class="compilations"
  :class="[ isOpen && 'compilations_open', isShowCollectionGroups && 'compilations_show-collection']"
  @click="openMenu">
    <span
    :key="getLabel"
    v-title="showTitle && `${getLabel}`"
    class="compilations__text"
    :class="isShowCollectionGroups && 'compilations__text_show-collection'">
      {{ getLabel }}
    </span>
    <div class="compilations__actions">
      <span
      v-if="!isShowCollectionGroups"
      class="compilations__text compilations__text_light"
      :class="isShowCollectionGroups && 'compilations__text_show-collection'">
        {{ getData.length }}
      </span>
      <TJIcon
      v-if="isShowCollectionGroups"
      name="close"
      :size="16"
      class="compilations__text compilations__text_light"
      :class="isShowCollectionGroups && 'compilations__text_show-collection'"
      @click.native.stop="setShowCurrentCollection(null)" />
      <TJIcon
      name="expand_more"
      :size="20"
      class="compilations__text compilations__text_light"
      :class="isShowCollectionGroups && 'compilations__text_show-collection'"
      :arrow-open="isOpen" />
    </div>
    <div
    v-if="isOpen"
    v-click-outside="onClickOutside"
    class="compilations__menu"
    :class="getData.length === 0 && 'flex-center'">
      <span
      v-if="getData.length === 0"
      class="compilations__text compilations__text_small compilations__text_light">{{ $t('The collections are empty') }}
      </span>
      <span
      v-for="collection in getData"
      :key="collection.id"
      class="compilations__item"
      @click="setShowCurrentCollection(collection)">
        {{ collection.name }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Compilations',
  props: {
    isShowFavoriteGroups: {
      type: Boolean,
      default: false,
    },
    isShowCollectionGroups: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  created() {
    // FUTURE позже убрать очистку локал storage
    localStorage.removeItem('collectionsData');
    this.$store.dispatch('collections/getUserCompilationsList');
  },
  methods: {
    onClickOutside() {
      this.isOpen = false;
    },
    openMenu() {
      this.isOpen = !this.isOpen;
    },
    setShowCurrentCollection(collection) {
      if (this.isShowFavoriteGroups) {
        this.$store.commit('collections/setShowFavoriteGroups', false);
      }

      if (collection === null) {
        this.$store.commit('collections/setCurrentCollectionId', null);
        this.$store.commit('collections/setShowCollectionGroups', false);
        return;
      }

      if (collection.id === this.getCurrentCollectionId) {
        this.isOpen = false;
        return;
      }

      this.$store.dispatch('collections/getCurrentCompilation', {
        id: collection.id, isLoaded: true, name: collection.name, isNew: true,
      });
    },
  },
  computed: {
    getData() {
      return this.$store.getters['collections/getCollectionsList'];
    },
    getLabel() {
      return this.isShowCollectionGroups
        ? this.$store.getters['collections/getCurrentCollection']?.name
        : this.$t('my_compilations');
    },
    getCurrentCollectionId() {
      return this.$store.getters['collections/getCurrentCollectionId'];
    },
    showTitle() {
      return !this.isOpen && this.getLabel?.length > 15;
    },
  },
};
</script>

<style lang="scss" scoped>
.compilations {
  width: 180px;
  height: 30px;
  border: 1px solid $border-color-primary;
  box-sizing: border-box;
  border-radius: 6px;
  background: $primary-white;
  padding: 0px 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid transparent;
  }
  &_show-collection {
    background: $primary-blue;
  }
}

.compilations__actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.compilations__text {
  font-size: 13px;
  color: $primary-gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_light {
    color: $primary-lightgray;
  }
  &_small {
    font-size: 12px;
  }
  &_show-collection {
    color: $primary-white;
  }
}

.compilations__arrow {
  pointer-events: none;
  color: $primary-gray;
  &_show-collection {
    color: $primary-white;
  }
  &_close {
    pointer-events: fill;
  }
}

.compilations__menu {
  position: absolute;
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background-color: $primary-white;
  width: 180px;
  left: -1px;
  top: 28px;
  z-index: 5;
  border: 1px solid $border-color-primary;
  border-top: none;
  height: 300px;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.compilations__item {
  color: black;
  font-size: 12px;
  padding: 2px 10px;
  display: block;
  text-align: left;
  &:hover {
    transition: .2s;
    background: #ebebeb;
  }
}
</style>
