import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/locales/en.json';
import ru from '@/locales/ru.json';
import uz from '@/locales/uz.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'ru',
  fallbackLocale: 'en',
  messages: { en, ru, uz },
  silentTranslationWarn: true,
});
