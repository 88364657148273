<template>
  <div class="save-collection">
    <span
    class="save-collection__header">
      {{ labelForStep }}
    </span>
    <div
    class="save-collection__content">
      <!-- STEP 1 -->
      <div
      v-if="step === 1"
      class="save-collection__step">
        <TJButton
        :text="$t('Create a new compilation2')"
        :width="260"
        @handler="changeStep(2)" />
        <TJButton
        :text="$t('Save to existing')"
        :width="260"
        type="outlined"
        @handler="changeStep(3)" />
      </div>
      <!-- STEP 2 -->
      <div
      v-if="step === 2"
      class="save-collection__step">
        <input
        v-model.trim="nameNewCollection"
        type="text"
        class="save-collection__input"
        :placeholder="$t('Come up with a name')">
      </div>
      <!-- STEP 3 -->
      <div
      v-if="step === 3">
        <div
        v-if="getCollectionsGroups.length > 0"
        class="save-collection__input"
        @click="toggleMenu">
          <span
          class="save-collection__label">{{ lavelForAddinCollection }}</span>
          <div
          v-if="isOpenMenu"
          v-click-outside="toggleMenu"
          class="save-collection__menu">
            <span
            v-for="collection in getCollectionsGroups"
            :key="collection.id"
            class="save-collection__menu-item"
            @click="selectCollection(collection)">{{ collection.name }}</span>
          </div>
          <TJIcon
          name="expand_more"
          class="save-collection__menu-arrow"
          :arrow-open="isOpenMenu" />
        </div>
        <span
        v-else
        class="save-collection__label">{{ $t('The collections are empty. You need to create a new one') }}</span>
      </div>
      <div
      v-if="step !== 1"
      class="save-collection__save-button">
        <TJButton
        :text="$t('save')"
        :disabled="isDisabledButton"
        @handler="saveAction" />
        <span
        class="save-collection__back-text"
        @click="changeStep(1)">{{ $t('Back') }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SaveCollection',
  props: {
    // добавление в подборку из мини-подменю группы (когда выбираем только 1 канал)
    addFromMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 1,
    nameNewCollection: '',
    selectedCollectionName: null,
    selectedCollectionId: null,
    isOpenMenu: false,
  }),
  methods: {
    changeStep(value) {
      this.step = value;
    },
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
    saveAction() {
      // если добавление каналов
      if (this.step === 3) {
        this.$store.dispatch('collections/addGroupsToCompilation', { id: this.selectedCollectionId, groups: this.getGroupsToCompilation })
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('Compilation has been updated', { compilation: this.selectedCollectionName }) });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('An error occurred while updating the collection. Reload the page and try again'),
                error: true,
              });
          })
          .finally(() => {
            this.$emit('close');
            this.reset();
          });

        return;
      }
      // если создание новой подборки
      this.$store.dispatch('collections/createNewCompilation', { name: this.nameNewCollection, groups: this.getGroupsToCompilation })
        .then(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: this.$t('A compilation has been created') });
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: this.$t('An error occurred while saving the selection. Reload the page and try again') });
        });
    },
    reset() {
      this.nameNewCollection = '';
      this.selectedCollectionName = null;
      this.selectedCollectionId = null;
      this.step = 1;
    },
    selectCollection(collection) {
      this.selectedCollectionName = collection.name;
      this.selectedCollectionId = collection.id;
    },
  },
  computed: {
    labelForStep() {
      if (this.step === 2) {
        return this.$t('Create a new compilation');
      }
      if (this.step === 3) {
        return this.$t('Saving to an existing collection');
      }
      return this.$t('Saving the compilation');
    },
    getCollectionsGroups() {
      return this.$store.getters['collections/getCollectionsList'];
    },
    isDisabledButton() {
      return this.selectedCollectionName === null && this.nameNewCollection === '';
    },
    lavelForAddinCollection() {
      return this.selectedCollectionName ? this.selectedCollectionName : this.$t('Select a compilation');
    },
    getGroupsToCompilation() {
      if (this.addFromMenu) {
        return this.$store.getters['app/getNewGroupsForCompilation'];
      }
      return this.$store.getters['collections/getSelectedGroups'];
    },
  },
  watch: {
    step(newValue, prevValue) {
      if (newValue === 1 && prevValue !== 1) {
        this.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.save-collection {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.save-collection__header {
  font-size: 22px;
  color: $primary-black;
  margin: 20px 0px 30px;
  max-width: 400px;
}

.save-collection__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.save-collection__step {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.save-collection__back-text {
  font-size: 14px;
  color: $primary-lightgray;
  margin-top: 5px;
  cursor: pointer;
}

.save-collection__label {
  font-size: 14px;
  color: $primary-black;
}

.save-collection__save-button {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.save-collection__input {
  min-width: 300px;
  max-width: 500px;
  padding: 7px 40px 7px 16px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  text-align: left;
  &:focus {
    outline: none;
  }
}

.save-collection__menu {
  position: absolute;
  min-width: 300px;
  max-width: 500px;
  background-color: $primary-white;
  left: -1px;
  top: 28px;
  max-height: 200px;
  z-index: 3;
  border: 1px solid $border-color-primary;
  border-top: none;
  overflow: auto;
}

.save-collection__menu-item {
  color: $primary-black;
  font-size: 14px;
  padding: 2px 10px;
  display: block;
  text-align: left;
  cursor: pointer;
  &:hover {
    transition: .2s;
    color: $primary-white;
    background: $primary-blue;
  }
}

.save-collection__menu-arrow {
  position: absolute;
  right: 15px;
  top: 8px
}
</style>
