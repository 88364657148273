<template>
  <span
  :class="{
    'pointer': pointer,
    'arrow-open': arrowOpen,
    'menu-open': menuOpen,
  }"
  :style="{
    fontSize: size + 'px',
    maxWidth: size + 'px',
  }"
  class="material-icons tj-icon">
    {{ name }}
  </span>
</template>

<script>

export default {
  name: 'TJIcon',
  props: {
    name: {
      type: String,
      default: 'Help',
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
    arrowOpen: {
      type: Boolean,
      default: false,
    },
    menuOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tj-icon {
  transform: rotate(0deg);
  transition: .2s;
  user-select: none;
}

.arrow-open {
  transition: .2s;
  transform: rotate(-180deg);
}

.menu-open {
  transition: .1s;
  transform: rotate(90deg);
}

.pointer {
  cursor: pointer;
}
</style>
