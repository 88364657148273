<template>
  <div
  class="account-filter"
  @click="openMenu">
    <div class="account-filter__title">
      <span class="account-filter__text account-filter__text_second">
        {{ $t('account_search') }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues"
        class="account-filter__title-active" />
      </transition>
    </div>
    <div class="account-filter__input-block">
      <input
      id="account-text-input-1"
      ref="accountinput"
      v-model.trim="searchNameUrl"
      class="account-filter__input"
      :class="isOpen && 'account-filter__input_active'"
      type="text"
      autocomplete="off"
      :placeholder="isOpen ? $t('search2') : $t('search')"
      :disabled="disabled"
      @input="debounceUpdate('update:name', searchNameUrl)">
      <transition name="fade">
        <TJIcon
        v-if="searchNameUrl"
        name="close"
        class="account-filter__icon"
        :size="16"
        pointer
        @click.native="resetSearchName" />
      </transition>
    </div>
    <transition name="fade">
      <div
      v-if="isOpen"
      ref="menu"
      v-click-outside="onClickOutside"
      class="account-filter__menu">
        <div class="account-filter__container">
          <span class="account-filter__text">
            {{ $t('contacts') }}
          </span>
          <input
          id="account-text-input-2"
          v-model="searchContact"
          type="text"
          autocomplete="off"
          :disabled="disabled"
          :placeholder="$t('search_contacts')"
          class="account-filter__input account-filter__input_other"
          @input="debounceUpdate('update:contact', searchContact)">
        </div>
        <div class="account-filter__container">
          <span class="account-filter__text">
            {{ $t('description') }}
          </span>
          <input
          id="account-text-input-3"
          v-model="searchDescription"
          type="text"
          autocomplete="off"
          :disabled="disabled"
          :placeholder="$t('search_description')"
          class="account-filter__input account-filter__input_other"
          @input="debounceUpdate('update:description', searchDescription)">
        </div>
        <span
        class="account-filter__text account-filter__text_second"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'AccountFilter',
  props: {
    name: {
      type: String,
      default: null,
    },
    url: {
      type: Array,
      default: null,
    },
    contact: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchNameUrl: null,
    searchContact: null,
    searchDescription: null,
    debounceUpdate: null,
    isOpen: false,
    preventClickOutside: false,
  }),
  created() {
    this.searchNameUrl = this.url ? this.url.join(' ') : this.name;
    this.searchContact = this.contact;
    this.searchDescription = this.description;

    this.debounceUpdate = debounce((key, value) => {
      if (key === 'update:name') {
        if (value === '') {
          this.$emit('update:name', null);
          this.$emit('update:url', null);
          return;
        }
        const values = value.split(' ');
        const isLinks = values.some((item) => /^(ftp|http|https):\/\/[^ "]+$/.test(item));

        if (values.length > 1 && isLinks) {
          this.$emit('update:name', null);
          this.$emit('update:url', values);
        } else {
          this.$emit('update:name', values.join(' '));
          this.$emit('update:url', null);
        }
        return;
      }

      if (value === '') {
        this.$emit(key, null);
        return;
      }

      this.$emit(key, value);
    }, 600);
  },
  methods: {
    resetAll() {
      this.searchNameUrl = null;
      this.searchContact = null;
      this.searchDescription = null;
      this.$emit('update:name', this.searchNameUrl);
      this.$emit('update:contact', this.searchContact);
      this.$emit('update:description', this.searchDescription);
      this.$emit('update:url', this.searchDescription);
    },
    resetSearchName() {
      this.searchNameUrl = null;
      this.$emit('update:name', this.searchNameUrl);
      this.$emit('update:url', this.searchNameUrl);
    },
    openMenu(event) {
      if (this.isOpen || event.target.className.includes('tj-icon account-filter__icon')) {
        return;
      }
      // слушаем событие клика на стороннем элементе
      // чтобы v-click-outside не срабатывал при выделении инпута
      window.addEventListener('mousedown', this.onMouseDown);
      this.isOpen = true;
      this.$refs.accountinput.focus();
    },
    onClickOutside() {
      if (this.preventClickOutside) {
        this.preventClickOutside = false;
        return;
      }
      window.removeEventListener('mousedown', this.onMouseDown);
      this.isOpen = false;
    },
    onMouseDown(mouseEvent) {
      const inputs = ['account-text-input-1', 'account-text-input-2', 'account-text-input-3'];
      this.preventClickOutside = inputs.includes(mouseEvent.target.id);
    },
  },
  computed: {
    isNotEmptyValues() {
      return this.searchNameUrl || this.searchContact || this.searchDescription;
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.resetAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-filter {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 50px;
  position: relative;
}

.account-filter__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.account-filter__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 7px;
  margin-top: 1px;
}

.account-filter__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
}

.account-filter__input-block {
  display: flex;
  align-items: center;
  width: 150px;
}

.account-filter__input {
  outline: none;
  border: none;
  width: 138px;
  background: $primary-white;
  &::placeholder {
    color: #565656;
    font-size: 14px;
  }
  &_active {
    &::placeholder {
    color: rgb(161, 161, 161);
  }
  }
  &_other {
    outline: 1px solid $primary-lightgray;
    padding: 5px 3px;
    width: 100%;
    border-radius: 4px;
    &::placeholder {
      color: rgb(161, 161, 161);
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.account-filter__icon {
  color: #9B9B9B;
}

.account-filter__menu {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 215px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 15px;
  }
  @include last-child {
    margin-top: 10px;
  }
}

.account-filter__text-input {
  margin-top: 2px;
  padding: 8px 10px 8px 28px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  line-height: 14px;
  background: $primary-white;
  width: 100%;
  font-size: 14px;
  -moz-appearance: textfield;
  &:focus {
    outline: none;
    border-color: $border-color-focus;
  }
  &::placeholder {
    color: rgb(161, 161, 161);
    font-size: 12px;
    font-weight: 400;
  }
}

.account-filter__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

</style>
