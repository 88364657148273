<template>
  <div class="tariffs-screen">
    <div class="tariffs-screen__header">
      <span class="tariffs-screen__title">
        Доступ
      </span>
    </div>
    <TariffsBanner />
    <div class="tariffs-screen__tariffs">
      <div
      v-for="tariff in tariffs"
      :key="tariff.id"
      class="tariffs-screen__tariff-element">
        <TariffInfo :tariff="tariff" />
        <TariffProgress :tariff-value="tariff.tariffValue" />
      </div>
    </div>
  </div>
</template>

<script>
import TariffsBanner from './components/TariffsBanner.vue';
import TariffProgress from './components/TariffProgress.vue';
import TariffInfo from './components/TariffInfo.vue';

export default {
  components: { TariffsBanner, TariffProgress, TariffInfo },
  data: () => ({
    tariffs: [
      { id: 1, type: 'full-open', tariffValue: 136221 },
      { id: 2, type: 'open', tariffValue: 390000 },
      { id: 3, type: 'trial', tariffValue: 225200 },
      { id: 4, type: 'closed', tariffValue: 710090 },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.tariffs-screen {
  padding: 20px;
  display: flex;
  flex-direction: column;
  @include desktop-screen {
    padding: 48px 30px 20px;
  }
}

.tariffs-screen__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  @include between-elements {
    margin-right: 20px;
  }
}

.tariffs-screen__title {
  color: #575757;
  font-size: 14px;
  font-size: 28px;
  font-weight: 600;
}

.tariffs-screen__tariffs {
  margin-top: 70px;
  width: 100%;
  display: flex;
}

.tariffs-screen__tariffs {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 30px;
  }
}

.tariffs-screen__tariff-element {
  display: block;
  width: 100%;
  @include between-elements {
    margin-bottom: 10px;
  }
  @include max-screen {
    @include between-elements {
      margin-bottom: 0px;
    }
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }
}
</style>
