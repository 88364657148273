<template>
  <div
  class="filter-results"
  @click="openMenu">
    <div class="filter-results__container">
      <span
      class="filter-results__text filter-results__text_second">
        {{ getTitle }}
      </span>
      <transition name="fade">
        <div
        v-if="selectedCount"
        class="filter-results__icon-active" />
      </transition>
    </div>
    <div class="filter-results__container">
      <span
      class="filter-results__text">
        {{ getCount }}
      </span>
      <TJIcon
      name="expand_more"
      :size="16"
      :arrow-open="isOpen" />
    </div>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      class="filter-results__menu">
        <div
        class="filter-results__container"
        @click.stop="setSelected('true')">
          <span
          class="filter-results__text"
          @click="openMenu">
            {{ countWithPrice }}
          </span>
          <span
          class="filter-results__text filter-results__text_second"
          @click="openMenu">
            {{ $t('with_price') }}
          </span>
        </div>
        <div
        class="filter-results__container"
        @click.stop="setSelected('false')">
          <span
          class="filter-results__text"
          @click="openMenu">
            {{ countWithoutPrice }}
          </span>
          <span
          class="filter-results__text filter-results__text_second"
          @click="openMenu">
            {{ $t('without_price') }}
          </span>
        </div>
        <span
        class="filter-results__text filter-results__text_second"
        @click.stop="setSelected(null)">
          {{ $t('reset') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'FilterResults',
  props: {
    priceStats: {
      type: [Array, Object],
      default: null,
    },
    withPrice: {
      type: [Boolean, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    selectedCount: null,
    countAll: null,
    countWithPrice: null,
    countWithoutPrice: null,
  }),
  created() {
    this.selectedCount = this.withPrice || null;
    this.initialCounts();
  },
  methods: {
    openMenu() {
      if (this.isOpen) {
        return;
      }
      this.isOpen = true;
    },
    onClickOutside() {
      this.isOpen = false;
    },
    setSelected(value) {
      if (this.disabled) return;
      this.selectedCount = value;
      this.$emit('update:with-price', this.selectedCount);
      this.isOpen = false;
    },
    initialCounts() {
      this.countAll = this.setNumberSpaces(+this.priceStats.containsPostPriceSell + +this.priceStats.notContainsPostPriceSell) || 0;
      this.countWithPrice = this.setNumberSpaces(+this.priceStats.containsPostPriceSell) || 0;
      this.countWithoutPrice = this.setNumberSpaces(+this.priceStats.notContainsPostPriceSell) || 0;
    },
  },
  computed: {
    getCount() {
      if (this.selectedCount) {
        return this.selectedCount === 'true' ? this.countWithPrice : this.countWithoutPrice;
      }
      return this.countAll;
    },
    getTitle() {
      if (this.selectedCount) {
        return this.selectedCount === 'true' ? this.$t('with_price') : this.$t('without_price');
      }
      return this.$t('found');
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.selectedCount = null;
      }
    },
    priceStats: {
      deep: true,
      handler(newValue) {
        if (!isEmpty(newValue)) {
          this.initialCounts();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-results {
  display: flex;
  flex-direction: column;
  height: 40px;
  padding-left: 20px;
  min-width: 105px;
  max-width: 200px;
  border-left: 2px solid $primary-blue;
  position: relative;
  cursor: pointer;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.filter-results__text {
  font-size: 14px;
  color: #565656;
  font-weight: 600;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
}

.filter-results__icon-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 5px;
  margin-top: 1px;
}

.filter-results__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  @include between-elements {
    margin-right: 5px;
  }
}

.filter-results__menu {
  position: absolute;
  top: 50px;
  left: 0px;
  min-width: 175px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 15px;
  }
}

</style>
