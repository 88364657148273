<template>
  <div class="imports">
    <div class="import__actions">
      <div
      class="imports__element pointer"
      @click="updateImports">
        <span
        class="imports__text_light">
          {{ $t('Update information') }}
        </span>
        <TJIcon
        class="imports__text_light"
        name="autorenew"
        :size="18" />
      </div>
      <div class="imports__element">
        <input
        v-model.trim="groupNameOrUrl"
        type="text"
        autocomplete="off"
        :placeholder="$t('search2')"
        class="imports__search-input"
        :class="groupNameOrUrl && 'imports__search-input_active'"
        @input="debounceUpdate(groupNameOrUrl)">
        <TJIcon
        v-if="groupNameOrUrl"
        class="imports__text_light"
        name="close"
        pointer
        :size="18"
        @click.native="resetSearch" />
      </div>
    </div>
    <div
    v-if="!isEmptyImports"
    ref="importsContent"
    class="imports__content"
    @scroll="onScroll">
      <div class="imports__elements">
        <ImportHeaders :is-all-imports="selectedTab === 'All imports'" />
        <ImportElement
        v-for="element in importsData"
        :key="element.id"
        :is-all-imports="selectedTab === 'All imports'"
        :element="element" />
      </div>
    </div>
    <div
    v-if="isEmptyImports"
    class="imports__elements-empty">
      <span class="imports__text_light">{{ isLoading ? $t('Load imports') : $t('The imports are empty') }}</span>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ImportElement from './ImportElement.vue';
import ImportHeaders from './ImportHeaders.vue';

export default {
  name: 'Imports',
  components: {
    ImportElement, ImportHeaders,
  },
  props: {
    importsData: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    debounceUpdate: null,
    groupNameOrUrl: null,
  }),
  created() {
    this.debounceUpdate = debounce((value) => {
      this.$store.commit('imports/setGroupNameOrUrl', value);
      this.updateImports();
    }, 500);
  },
  methods: {
    updateImports() {
      this.$store.commit('groups/setGroupsIsLoading', true);

      if (this.selectedTab === 'All imports') {
        this.$store.dispatch('imports/getAllImports', { reset: true })
          .finally(() => {
            setTimeout(() => {
              this.$store.commit('groups/setGroupsIsLoading', false);
            }, 1000);
          });
        return;
      }
      this.$store.dispatch('imports/getImports')
        .finally(() => {
          setTimeout(() => {
            this.$store.commit('groups/setGroupsIsLoading', false);
          }, 1000);
        });
    },
    resetSearch() {
      if (!this.groupNameOrUrl) return;
      this.groupNameOrUrl = null;
      this.$store.commit('imports/setGroupNameOrUrl', null);
      this.updateImports();
    },
    onScroll() {
      if (this.isLastPage || this.isLoading) {
        return;
      }

      const position = this.$refs.importsContent.scrollTop;
      const { clientHeight } = this.$refs.importsContent;

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.importsContent.scrollHeight);

      if (endScroll) {
        this.$store.commit('groups/setGroupsIsLoading', true);
        this.$store.dispatch('imports/getAllImports', { reset: false }).finally(() => {
          this.$store.commit('groups/setGroupsIsLoading', false);
        });
      }
    },
  },
  computed: {
    isEmptyImports() {
      return this.importsData.length === 0;
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    isLoading() {
      return this.$store.getters['groups/getGroupsIsLoading'];
    },
    isLastPage() {
      return this.$store.getters['imports/getCurrentPage'] === (+this.$store.getters['imports/getTotalPageCounts'] + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.imports {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  @include tablet-screen {
    margin: 20px;
  }
}

.import__actions {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  @include between-elements {
    margin-right: 30px;
  }
}

.imports__content {
  background: white;
  z-index: 1;
  overflow: auto;
  height: 100%;
}

.imports__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.imports__elements-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100;
  flex: 1;
}

.imports__text {
  color: $primary-black;
  font-size: 14px;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.imports__headers {
  position: sticky;
  background: white;
  top: 0px;
  padding: 10px 0px;
  border-bottom: 1px solid $primary-group-border;
  border-top: 1px solid $primary-group-border;
  display: flex;
  z-index: 1;
  align-items: center;
  min-width: 100%;
}

.imports__header {
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  &_name {
    min-width: 250px;
    position: sticky;
    top: 0;
    left: 0;
    justify-content: flex-start;
    background: white;
  }
}

.imports__element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.imports__search-input {
  width: 150px;
  background: $primary-white;
  border: none;
  border-radius: 4px;
  outline: 1px solid rgb(179, 179, 179);
  padding: 4px 5px;
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_active {
    outline: 1px solid $primary-blue;
  }
}

</style>
