var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tj-button"},[_c('button',{staticClass:"tj-button__button",class:{
    'tj-button__button_outlined': _vm.type === 'outlined',
    'tj-button__button_danger': _vm.type === 'danger',
    'tj-button__button_text': _vm.type === 'text',
    'tj-button__button_disabled': _vm.disabled,
  },style:({
    'width': _vm.width === 'full' ? '100%' : _vm.width + 'px',
    'height': _vm.height ? _vm.height + 'px' : '40px'
  }),attrs:{"title":_vm.text,"disabled":_vm.disabled},on:{"click":_vm.click}},[_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.showRipple)?_c('span',{staticClass:"tj-button__ripple"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }