<template>
  <div class="group-admins">
    <div
    class="group-admins__content"
    :class="isAdmin && 'pointer'"
    @click="openEditor">
      <div class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('payment_type') + ':' }}
        </span>
        <span class="group-admins__text group-admins__text_light">
          {{ getPaymentType }}
        </span>
      </div>
      <div class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('in_sj') + ':' }}
        </span>
        <span class="group-admins__text group-admins__text_light">
          {{ inSocialJet ? $t('yes') : $t('no') }}
        </span>
      </div>
      <div
      v-if="!hideDetails"
      class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('blogger') + ':' }}
        </span>
        <span class="group-admins__text_light">
          {{ isBlogger ? $t('yes') : $t('no') }}
        </span>
      </div>
      <div
      v-if="!hideDetails"
      class="group-admins__element">
        <span
        class="group-admins__text">
          {{ $t('blacklist') + ':' }}
        </span>
        <span class="group-admins__text_light">
          {{ inBlackList ? $t('yes') : $t('no') }}
        </span>
      </div>
    </div>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-admins__editor">
        <span class="group-admins__text group-admins__text_light">
          {{ $t('Parameters') }}
        </span>
        <div class="group-admins__editor-element group-admins__editor-element_row">
          <TJCheckbox
          :checked.sync="inSocialJetValue" />
          <span class="group-admins__text group-admins__text_small">
            {{ $t('In SocialJet') }}
          </span>
        </div>
        <div class="group-admins__editor-element group-admins__editor-element_row">
          <TJCheckbox
          :checked.sync="isBloggerValue" />
          <span class="group-admins__text group-admins__text_small">
            {{ $t('Is blogger') }}
          </span>
        </div>
        <div class="group-admins__editor-element group-admins__editor-element_row">
          <TJCheckbox
          :checked.sync="inBlackListValue" />
          <span class="group-admins__text group-admins__text_small">
            {{ $t('In black list') }}
          </span>
        </div>
        <div class="group-admins__editor-element">
          <span class="group-admins__text group-admins__text_light">
            {{ $t('payment_type') }}
          </span>
          <div
          v-for="payment in paymentTypes"
          :key="payment.value"
          class="admin-filters__interval">
            <label class="group-admins__editor-element group-admins__editor-element_row">
              <input
              v-model="paymentTypeValue"
              type="radio"
              :value="payment.value">
              <span class="group-admins__text">
                {{ payment.name }}
              </span>
            </label>
          </div>
        </div>
        <TJButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'GroupAdmins',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    paymentType: {
      type: [String, Boolean, Number],
      default: null,
    },
    isBlogger: {
      type: [String, Boolean],
      default: null,
    },
    inBlackList: {
      type: [String, Boolean],
      default: null,
    },
    inSocialJet: {
      type: [String, Boolean],
      default: null,
    },
  },
  data: () => ({
    showEditor: false,
    inSocialJetValue: null,
    inBlackListValue: null,
    isBloggerValue: null,
    paymentTypeValue: null,
    paymentTypes: [
      {
        value: null,
        name: 'Все',
      },
      {
        value: '1',
        name: 'ИП',
      },
      {
        value: '2',
        name: 'СЗ',
      },
      {
        value: '3',
        name: 'КЭШ',
      },
      {
        value: '4',
        name: 'Другое',
      },
      {
        value: '5',
        name: 'OOO',
      },
      {
        value: '6',
        name: 'Sollar',
      },
    ],
  }),
  created() {
  },
  methods: {
    openEditor() {
      if (!this.isAdmin) return;
      this.inSocialJetValue = this.inSocialJet;
      this.inBlackListValue = this.inBlackList;
      this.isBloggerValue = this.isBlogger;
      this.paymentTypeValue = this.paymentType;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        inSocialjet: this.inSocialJetValue,
        inBlackList: this.inBlackListValue,
        isBlogger: this.isBloggerValue,
        paymentType: this.paymentTypeValue,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.inSocialjet = this.inSocialJetValue;
          this.group.inBlackList = this.inBlackListValue;
          this.group.isBlogger = this.isBloggerValue;
          this.group.paymentType = this.paymentTypeValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    getPaymentType() {
      const currentPaymentType = this.paymentTypes.find((item) => +item.value === +this.paymentType);
      return currentPaymentType
        ? currentPaymentType.name
        : '—';
    },
  },
};
</script>

<style lang="scss" scoped>
.group-admins {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
  padding-left: 35px;
  position: relative;
}

.group-admins__content {
    @include between-elements {
    margin-bottom: 3px;
  }
}

.group-admins__text {
  font-size: 12px;
  margin-right: 2px;
  color: $primary-black;
  &_small {
    font-size: 12px;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-admins__editor {
  position: absolute;
  top: 0px;
  left: 25px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-admins__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_row {
    flex-direction: row;
    align-items: center;
    @include between-elements {
      margin-right: 5px;
      margin-bottom: 0px;
    }
  }
}
</style>
