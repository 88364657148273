<template>
  <div class="clients-screen">
    <div class="clients-screen__header">
      <span class="clients-screen__text clients-screen__text_title">Клиенты</span>
      <TJButton
      text="Добавить"
      :width="184"
      :height="30"
      class="clients-screen__add-button"
      @handler="openAddClientModal" />
    </div>
    <Clients ref="clients" />
  </div>
</template>

<script>
import Clients from './components/Clients/Clients.vue';

export default {
  name: 'ClientsScreen',
  components: { Clients },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    openAddClientModal() {
      this.$refs.clients.openModal({ name: 'addClient' });
    },
  },
};
</script>

<style lang="scss" scoped>
.clients-screen {
  padding: 20px 10px;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  @include desktop-screen {
    padding: 48px 30px 20px;
  }
}

.clients-screen__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.clients-screen__text {
  color: #575757;
  font-size: 14px;
  &_title {
    font-size: 18px;
    font-weight: 600;
  }
}

.clients-screen__add-button {
  background: $primary-blue;
  border-radius: 8px;
}
</style>
