<template>
  <div class="main-screen">
    <GroupsFilter />
    <MainGroups
    :groups-data="getGroupsData" />
  </div>
</template>

<script>
import GroupsFilter from '@/components/GroupsFilter/GroupsFilter.vue';
import MainGroups from '@/components/MainGroups/MainGroups.vue';

export default {
  name: 'MainScreen',
  components: {
    MainGroups,
    GroupsFilter,
  },
  data: () => ({
  }),
  computed: {
    getGroupsData() {
      if (this.isShowSelectedGroups) {
        return this.$store.getters['collections/getSelectedGroupsData'];
      }
      if (this.isShowFavoriteGroups) {
        return this.$store.getters['collections/getFavoriteGroupsData'];
      }
      if (this.isShowCollectionGroups) {
        return this.$store.getters['collections/getCurrentCollection']?.groups;
      }
      return this.$store.getters['groups/getGroupsData'];
    },
    isShowSelectedGroups() {
      return this.$store.getters['collections/getShowSelectedGroups'];
    },
    isShowFavoriteGroups() {
      return this.$store.getters['collections/getShowFavoriteGroups'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.main-screen {
  display: flex;
  flex-direction: column;
}
</style>
