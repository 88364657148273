<template>
  <div class="group-analytics-el">
    <span class="group-analytics-el__text group-analytics-el__text_title">Подписчики</span>
    <div class="group-analytics-el__info-block">
      <div
      v-for="item in items"
      :key="item.label"
      class="group-analytics-el__info-elem">
        <div class="group-analytics-el__info-texts">
          <span class="group-analytics-el__text">{{ item.label }}</span>
          <span
          class="group-analytics-el__text group-analytics-el__text_bold"
          :class="isNegativeCount(item.value) ? 'group-analytics-el__text_red' : 'group-analytics-el__text_green'">
            {{ getSubscribers(item.value) ? getSubscribers(item.value) : '—' }}
          </span>
        </div>
        <img
        v-if="getSubscribers(item.value)"
        :src="isNegativeCount(item.value)
          ? require('@/screens/GroupScreen/assets/ArrowCircleDown.svg')
          : require('@/screens/GroupScreen/assets/ArrowCircleUp.svg')">
      </div>
    </div>
    <GroupPriceChart
    v-if="chartData.length"
    smoothed
    class="group-analytics-el__chart"
    :with-brush="isDesktop"
    :chart-data="chartData" />
    <div
    v-else
    class="group-analytics-el__empty">
      <img
      v-if="isLoading"
      class="group-analytics-el__loader"
      src="@/assets/images/rocket.gif">
      <p
      class="group-analytics-el__text group-analytics-el__text_small"
      :class="isLoading && 'group-analytics-el__text_light'">
        {{ isLoading ? 'Загрузка истории изменений количества подписчиков...' : 'История изменений по подписчикам недоступна' }}
      </p>
    </div>
  </div>
</template>

<script>
import GroupPriceChart from '@/components/MainGroups/components/Group/components/GroupPriceChart.vue';

export default {
  name: 'GroupAnSubscribers',
  components: { GroupPriceChart },
  props: {
    group: {
      type: Object,
      default: null,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [{ label: 'Сегодня', value: 'dayGrow' }, { label: 'Неделя', value: 'weekGrow' }, { label: 'Месяц', value: 'monthGrow' }],
  }),
  methods: {
    getSubscribers(type) {
      const subscribers = this.group.subscribersStatistic[type] || null;
      if (subscribers === null || subscribers === undefined) {
        return false;
      }
      return subscribers > 0 ? `+${this.setNumberSpaces(subscribers)}` : this.setNumberSpaces(subscribers);
    },
    isNegativeCount(count) {
      return +this.group.subscribersStatistic[count] < 0;
    },
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 1080;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-analytics-el {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  padding: 32px 32px 45px;
  border-radius: 20px;
  box-shadow: 0px 4px 23px rgba(0, 174, 232, 0.12);
  min-height: 550px;
}

.group-analytics-el__text {
  font-size: 14px;
  &_title {
    font-size: 20px;
    font-weight: 700;
  }
  &_light {
    font-size: 14px;
    color: $primary-lightgray;
  }
  &_bold {
    font-size: 24px;
    font-weight: 700;
  }
  &_green {
    color: $primary-green;
  }
  &_red {
    color: $primary-red;
  }
}

.group-analytics-el__info-block {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin-top: 20px;
  @include tablet-screen {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.group-analytics-el__info-elem {
  background: #F0F9FC;
  border-radius: 20px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.group-analytics-el__info-texts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include between-elements {
    margin-bottom: 5px;
  }
  @include desktop-screen {
    @include between-elements {
      margin-bottom: 20px;
    }
  }
}

.group-analytics-el__chart {
  margin-top: 20px;
  width: 100%;
  height: 320px;
}

.group-analytics-el__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 88px;
  border-radius: 20px;
  margin-top: 20px;
  background: white;
  border: 1px solid #D8F2FA;
  padding: 0px 20px;
  text-align: center;
  @include desktop-screen {
    height: 100%;
  }
  @include between-elements {
    margin-bottom: 20px;
  }
}

.group-analytics-el__loader {
  display: none;
  @include desktop-screen {
    display: block;
    width: 50px;
    height: 50px;
  }
}
</style>
