<template>
  <div
  class="tariff-full-burn">
    <div class="tariff-full-burn__container">
      <div class="tariff-full-burn__info">
        <div class="tariff-full-burn__label-container">
          <span class="tariff-full-burn__text">Ваш бюджет за 365 дней</span>
          <span class="tariff-full-burn__text tariff-full-burn__text_label">{{ tariffLabel }}</span>
        </div>
        <div
        class="tariff-full-burn__label-container pointer"
        style="align-items: center"
        @click="goToTariffs">
          <img
          src="../assets/ok-icon.svg"
          class="tariff-full-burn__ok-icon">
          <span class="tariff-full-burn__text">Доступ до 1 марта, commit выполнен </span>
        </div>
      </div>

      <div class="tariff-full-burn__progress">
        <div
        class="tariff-full-burn__label-container"
        style="align-items: center">
          <span class="tariff-full-burn__text tariff-full-burn__text">Burn Rate</span>
          <img
          src="../assets/burn-icon.svg"
          class="tariff-full-burn__ok-icon">
        </div>
        <div
        ref="progress"
        class="tariff-full-burn__container-progress">
          <div
          ref="progress"
          class="tariff-full-burn__progress-active-points">
            <div
            v-for="(point, index) in points"
            :key="point.value"
            :style="{
              left: `${point.left}% `,
              opacity: (index === 0 || index === points.length - 1) ? 0 : 1,
              justifyContent: index === 0 ? 'flex-start' : index === points.length - 1 ? 'flex-end' : 'center',
            }"
            class="tariff-full-burn__progress-active-point-container">
              <div class="tariff-full-burn__progress-active-point" />
            </div>
          </div>
          <div
          class="tariff-full-burn__progress-active-line"
          :style="{width: `${activeLineWidth}%`}" />
          <div class="tariff-full-burn__progress-text-points">
            <span
            v-for="point in points"
            :key="point.value"
            :style="{
              marginLeft: `${point.margin}px`,
              left: `${point.left}%`,
            }"
            class="tariff-full-burn__text tariff-full-burn__text_text-point">
              {{ point.name }}
            </span>
          </div>
        </div>
      </div>

      <div
      class="tariff-full-burn__close"
      @click="changeTariffBar">
        <div class="tariff-full-burn__close-button">
          <TJIcon
          name="expand_more"
          :arrow-open="true"
          class="tariff-full-burn__menu-arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffFullBurn',
  data: () => ({
    tariffValue: 300000,
    tariffLabel: '',
    points: [
      {
        name: '0к - 35%', value: 0, margin: 0, left: 0,
      },
      {
        name: '100к - 25%', value: 100000, margin: -30, left: 20,
      },
      {
        name: '200к - 22%', value: 200000, margin: -34, left: 40,
      },
      {
        name: '300к - 20%', value: 300000, margin: -32, left: 60,
      },
      {
        name: '500к - 15%', value: 500000, margin: -33, left: 80,
      },
      {
        name: '1кк - 10%', value: 1000000, margin: -60, left: 100,
      },
    ],
    activeLineWidth: 0,
  }),
  beforeDestroy() {
    this.$store.commit('app/setShowFullTariffRate', false);
  },
  mounted() {
    this.getTariffLabelProgress();
    setTimeout(() => {
      if (!this.tariffValue) return;
      this.getTariffLabelProgress();
      this.activeLineWidth = this.getActiveLineWidth() || 0;
    }, 100);
  },
  methods: {
    goToTariffs() {
      this.$router.push({ name: 'tariffs' });
    },
    changeTariffBar() {
      this.$emit('setCurrentComponent', 'mini');
      this.$store.commit('app/setShowFullTariffRate', false);
    },
    getTariffProgressLabel() {
      return `${this.setNumberSpaces(+this.tariffValue) || 0} ₽`;
    },
    getActiveLineWidth() {
      if (this.tariffValue >= 1000000) {
        return 100;
      }
      if (this.tariffValue === 0) {
        return 0;
      }
      let fromElement;
      const toElement = this.points.find((item, index) => {
        if (item.value >= this.tariffValue) {
          fromElement = this.points[index - 1];
        }
        return item.value >= this.tariffValue;
      });
      const pointsDifferent = (toElement.value - fromElement.value); // разница значения между поинтами, внутри которого искомое значение
      const fromToDif = this.tariffValue - fromElement.value; // разница между заданной ценой и ближайшей максимальной точкой
      const percent = 20 / (pointsDifferent / fromToDif); // процент, который нужно добавить между поинтами
      const width = fromElement.left + percent + 0.5; // 0.5 чтобы полоса захватывала точку
      return width;
    },
    getTariffLabelProgress() {
      const time = 1000;
      const step = Math.round(this.tariffValue / 100);
      let n = 0;
      const t = Math.round(time / (this.tariffValue / step));
      const interval = setInterval(() => {
        n += step;
        this.tariffLabel = this.setNumberSpaces(+n);
        if (n >= this.tariffValue) {
          this.tariffLabel = this.getTariffProgressLabel();
          clearInterval(interval);
        }
      }, t);
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-full-burn {
  position: absolute;
  width: 100%;
  top: 70px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px 0px 95px;
  background: transparent;
}

.tariff-full-burn__container {
  width: 100%;
  max-width: 1600px;
  height: 105px;
  background: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 20px 20px;
  box-sizing: border-box;
  border: 1px solid #e0f1f7;
  border-top: none;
  display: flex;
}

.tariff-full-burn__info {
  height: 100%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tariff-full-burn__label-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  @include between-elements {
    margin-right: 10px;
  }
}

.tariff-full-burn__text {
  font-size: 14px;
  &_label {
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
  }
  &_small {
    font-size: 12px;
  }
  &_text-point {
    font-size: 12px;
    text-align: left;
    position: absolute;
    top: 17px;
    width: 80px;
  }
}

.tariff-full-burn__ok-icon {
  width: 20px;
  height: 20px;
}

.tariff-full-burn__progress {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.tariff-full-burn__container-progress {
  width: 100%;
  background: #D8F2FA;
  height: 10px;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.tariff-full-burn__progress-active-line {
  position: absolute;
  left: -1px;
  top: 0;
  width: 0px;
  transition: width 1.1s ease-out;
  height: 10px;
  background: linear-gradient(90deg,#7ace80, #82DA88);
  border-radius: 10px;
}

.tariff-full-burn__progress-text-points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
}

.tariff-full-burn__progress-active-points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
}

.tariff-full-burn__progress-active-point-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
}

.tariff-full-burn__progress-active-point {
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 100%;
  z-index: 1;
}

.tariff-full-burn__close {
  padding: 0px 10px 0px 30px;
  height: 100%;
  border-left: 1px solid rgb(243, 243, 243);
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tariff-full-burn__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #D8F2FA;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
}

.tariff-full-burn__menu-arrow {
  color: $primary-darkblue;
}
</style>
