import Vue from 'vue';
import Vuex from 'vuex';

// Vuex modules
import app from './modules/app';
import auth from './modules/auth';
import groups from './modules/groups';
import group from './modules/group';
import collections from './modules/collections';
import imports from './modules/imports';
import moderate from './modules/moderate';
import clients from './modules/clients';

Vue.use(Vuex);
export default new Vuex.Store({
  namespaced: true,
  modules: {
    app,
    auth,
    group,
    groups,
    collections,
    imports,
    moderate,
    clients,
  },
});
