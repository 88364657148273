<template>
  <div class="tj-checkbox">
    <div
    class="tj-checkbox__input"
    :class="[
      getChecked && 'tj-checkbox__input_checked',
      disabled && 'tj-checkbox__input_disabled'
    ]"
    @click="toggleCheck">
      <TJIcon
      v-if="getIconName"
      :name="getIconName"
      :size="16"
      class="tj-checkbox__icon" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'TJCheckbox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: [Boolean, String],
      default: false,
    },
    withNull: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCheck() {
      if (this.withNull && this.checked === null) {
        this.$emit('update:checked', true);
        return;
      }
      if (this.checked) {
        this.$emit('update:checked', false);
        return;
      }
      this.$emit('update:checked', this.withNull ? null : true);
    },
  },
  computed: {
    getIconName() {
      if (this.withNull && this.checked === null) return false;
      if (this.withNull) {
        return this.checked === true ? 'done' : 'close';
      }
      return this.checked ? 'done' : '';
    },
    getChecked() {
      if (this.withNull) {
        return this.checked === true || this.checked === false;
      }
      return this.checked;
    },
  },
};
</script>

<style lang="scss" scoped>

.tj-checkbox__input {
  width: 18px;
  height: 18px;
  border: 1px solid $primary-blue;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  &_checked {
    background: $primary-blue;
  }
  &_disabled {
    pointer-events: none;
    border: 2px solid $primary-lightgray;
    background: #f1f1f1;
  }
}

.tj-checkbox__icon {
  position: absolute;
  top: 0px;
  left: 0px;
  color: $primary-white;
}

</style>
