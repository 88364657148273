<template>
  <transition
  :key="currentComponent"
  name="fade">
    <TariffMiniBurn
    v-if="currentComponent === 'mini'"
    @setCurrentComponent="setCurrentComponent" />
    <TariffFullBurn
    v-else
    @setCurrentComponent="setCurrentComponent" />
  </transition>
</template>

<script>
import TariffMiniBurn from './components/TariffMiniBurn.vue';
import TariffFullBurn from './components/TariffFullBurn.vue';

export default {
  name: 'TariffsRate',
  components: { TariffMiniBurn, TariffFullBurn },
  data: () => ({
    currentComponent: 'full',
  }),
  methods: {
    setCurrentComponent(value) {
      this.currentComponent = value;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
