<template>
  <div
  v-click-outside="onClickOutside"
  class="profile"
  @click="showMenu = !showMenu">
    <div class="profile__avatar flex-center">
      <span
      v-if="getInitialsForAvatar"
      class="profile__avatar-text">{{ getInitialsForAvatar }}</span>
      <div
      class="profile__menu"
      :class="showMenu && 'profile__menu-active'">
        <span
        v-for="link in getFilteredLinks"
        :key="link.name"
        class="profile__link-item"
        @click="link.handler(link.url)">{{ $t(link.name) }}</span>
      </div>
    </div>
    <div class="profile__user">
      <span class="profile__name">{{ getUserEmail }}</span>
    </div>
    <TJIcon
    name="expand_more"
    class="profile__icon"
    :arrow-open="showMenu" />
  </div>
</template>

<script>

export default {
  name: 'Profile',
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showMenu: false,
    links: null,
  }),
  created() {
    this.links = [
      // {
      //   name: 'Catalog', handler: this.goTo, url: 'main-groups',
      // },
      // {
      //   name: 'Мои каналы', handler: this.goTo, url: 'my-groups',
      // },
      // {
      //   name: 'Imports', handler: this.goTo, url: 'imports-screen', admin: true,
      // },
      // {
      //   name: 'Moderation', handler: this.goTo, url: 'moderation', admin: true,
      // },
      {
        name: 'logout', handler: this.logout, url: null,
      },
    ];
  },
  methods: {
    onClickOutside() {
      this.showMenu = false;
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'main-landing' });
      });
    },
    goTo(route) {
      if (this.$route.name === route) return;
      if (route === 'main-groups') {
        this.$emit('goToMain');
        return;
      }
      this.$router.push({ name: route, query: {} });
    },
  },
  computed: {
    getUserEmail() {
      return this.userInfo ? this.userInfo.email : 'client@gmail.com';
    },
    getInitialsForAvatar() {
      return this.userInfo?.avatar ? null : this.getUserEmail.substr(0, 2).toUpperCase();
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    getFilteredLinks() {
      return this.isAdmin ? this.links : this.links.filter((item) => !item.admin);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.profile__avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $primary-lightgray;
}

.profile__avatar-text {
  color: $primary-white;
  font-size: 10px;
}

.profile__user {
  display: none;
  margin: 0px 10px;
  cursor: pointer;
  @include desktop-screen {
    display: block;
  }
}

.profile__name {
  font-size: 13px;
  color: $primary-blue;
  line-height: 24px;
  width: 100%;
}

.profile__menu {
  position: absolute;
  background-color: white;
  min-width: 140px;
  left: -65px;
  top: 51px;
  z-index: 5;
  overflow: hidden;
  max-height: 0;
  transition: max-height .2s;
  &-active {
    max-height: 300px;
  }
  @include max-screen {
    left: 0px;
    width: 100%;
  }
}

.profile__link-item {
  color: black;
  padding: 10px;
  display: block;
  text-align: center;
  transition: .1s;
  font-size: 14px;
  &_exit {
    color: red;
  }
  &:hover {
    background: rgb(241, 241, 241);
  }
}

.profile__icon {
  color: $primary-blue;
}

</style>
