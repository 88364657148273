<template>
  <div>
    <div
    v-click-outside="onClickOutside"
    class="action-button"
    @click="showMenu = !showMenu">
      <button
      class="action-button__button"
      :class="showMenu && 'action-button__button_active'">
        {{ $t('actions') }}
      </button>
      <TJIcon
      name="more_vert"
      :size="20"
      :menu-open="showMenu"
      class="action-button__icon" />
      <div
      v-if="showMenu"
      class="action-button__menu">
        <div v-if="isShowCollectionGroups">
          <span
          class="action-button__action"
          @click="openExportModal">{{ $t('export_compilation') }}</span>
          <span
          v-if="getSelectedGroups.length > 0"
          class="action-button__action"
          @click="openSaveCollectionModal">{{ $t('Save to another compilation') }}</span>
          <span
          class="action-button__action"
          @click="openDeleteCollectionModal">{{ $t('delete_compilation') }}</span>
          <span
          v-if="getSelectedGroups.length > 0"
          class="action-button__action"
          @click="removeFromCollection">{{ labelForRemoveFromCollection }}</span>
        </div>
        <div v-else>
          <span
          v-if="isAdmin"
          class="action-button__action"
          @click="openExportModal">{{ $t('export') }}</span>
          <span
          class="action-button__action"
          @click="openSaveCollectionModal">{{ $t('Save to a compilation') }}</span>
          <span
          class="action-button__action"
          @click="toggleInFavorite(true)">{{ $t('add_to_favorites') }}</span>
          <span
          class="action-button__action"
          @click="toggleInFavorite(false)">{{ $t('remove_from_favorites') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionButton',
  data: () => ({
    showMenu: false,
  }),
  methods: {
    onClickOutside() {
      this.showMenu = false;
    },
    toggleInFavorite(value) {
      if (value) {
        this.$store.dispatch('collections/addGroupsToFavorites', this.getSelectedGroups)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.getSelectedGroups.length > 1 ? this.$t('channels_added_to_favorites') : this.$t('channel_added_to_favorites'),
            });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.$t('an_error_occurred_when_changing_the_favorite_groups_reload_the_page_and_try_again'), error: true,
            });
          });
      } else {
        this.$store.dispatch('collections/removeGroupsFromFavorites', this.getSelectedGroups)
          .then(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.getSelectedGroups.length > 1 ? this.$t('channels_removed_from_favorites') : this.$t('channel_removed_from_favorites'),
            });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar', {
              text: this.$t('an_error_occurred_when_changing_the_favorite_groups_reload_the_page_and_try_again'), error: true,
            });
          });
      }
    },
    removeFromCollection() {
      const selectedLength = this.getSelectedGroups.length;
      this.$store.dispatch('collections/removeGroupsFromCompilation', { groups: this.getSelectedGroups, id: this.getCurrentCollectionId })
        .then(() => {
          if (selectedLength > 1) {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('groups_have_been_removed_from_the_collection') });
          } else {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('group_have_been_removed_from_the_collection') });
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_when_changing_the_selection_reload_the_page_and_try_again'),
              error: true,
            });
        });
    },
    openExportModal() {
      const modalParams = { name: 'ExportGroupsModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openSaveCollectionModal() {
      const modalParams = { name: 'SaveCollectionModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    openDeleteCollectionModal() {
      const modalParams = { name: 'EditCollectionModal', props: { isDelete: true } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    getSelectedGroups() {
      return this.$store.getters['collections/getSelectedGroups'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
    labelForRemoveFromCollection() {
      return this.getSelectedGroups.length > 1 ? this.$t('remove_channels_from_collection') : this.$t('remove_channel_from_collection');
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    getCurrentCollectionId() {
      return this.$store.getters['collections/getCurrentCollectionId'];
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  position: relative;
  cursor: pointer;
}

.action-button__button {
  cursor: pointer;
  width: 140px;
  height: 40px;
  outline: none;
  border: none;
  background: $primary-white;
  border-radius: 20px;
  @include tablet-screen {
    width: 180px;
  }
  &_active {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.action-button__menu {
  position: absolute;
  background-color: white;
  left: 0px;
  bottom: 40px;
  z-index: 1;
  width: 100%;
}

.action-button__icon {
  position: absolute;
  right: 15px;
  top: 11px;
}

.action-button__action {
  color: $primary-black;
  padding: 5px 15px;
  display: block;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: .2s;
  &:hover {
    transition: .2s;
    color: $primary-white;
    background: $primary-blue;
  }
}
</style>
