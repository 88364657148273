<template>
  <div class="user-element">
    <div
    class="user-element__column user-element__column_name">
      <div class="user-element__name-text">
        <span
        class="user-element__text">
          {{ user.email }}
        </span>
        <span
        class="user-element__text user-element__text_light">
          {{ user.email }}
        </span>
      </div>
    </div>

    <!-- Запросы -->
    <div
    class="user-element__column"
    style="width: 150px">
      <span class="user-element__text">
        —
      </span>
    </div>

    <!-- Кампании -->
    <div
    class="user-element__column"
    style="width: 150px">
      <span class="user-element__text">
        —
      </span>
    </div>

    <!-- Потрачено за мес/всего -->
    <div
    class="user-element__column"
    style="width: 300px">
      <span class="user-element__text">
        —
      </span>
    </div>

    <!-- Комиссия -->
    <div
    class="user-element__column user-element__column_noborder"
    style="width: 175px">
      <span class="user-element__text">
        —
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserElement',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
  }),
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.user-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  background: white;
  padding-bottom: 10px;
}

.user-element__column {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  position: relative;
  height: 40px;
  &_name {
    flex-direction: row;
    justify-content: flex-start;
    position: sticky;
    left: 0px;
    z-index: 1;
    padding-left: 20px;
    @include tablet-screen {
      width: 400px;
    }
  }
}

.user-element__name-text {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.user-element__text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_light {
    color: $primary-lightgray;
    font-size: 12px;
  }
}
</style>
