<template>
  <div class="groups-stats">
    <div class="group-stats__container group-stats__container__no-buttons">
      <span
      class="groups-stats__text">{{ getCountSelectedGroups }}</span>
      <div
      v-if="!isSend && !isShowCollectionGroups && mode !== 'noauth'"
      class="groups-stats__text groups-stats__text_touch"
      @click="toggleShowSelectedGroups">
        {{ getTextForSelected }}
      </div>
    </div>
    <div
    class="group-stats__container"
    :class="mode === 'noauth' && 'group-stats__container_no-buttons'">
      <!-- Sell -->
      <span class="groups-stats__text">{{ getTotalSelectedPrice }}</span>
      <!-- Price -->
      <span
      v-if="isAdmin"
      class="groups-stats__text">{{ getTotalSelectedCostPrice }}</span>
      <!-- Buy -->
      <span
      v-if="isAdmin"
      class="groups-stats__text">{{ getTotalSelectedBuyPrice }}</span>
    </div>
    <div
    class="group-stats__container"
    :class="mode === 'noauth' && 'group-stats__container_no-buttons'">
      <span class="groups-stats__text">{{ getTotalSelectedSubscribers }}</span>
      <span class="groups-stats__text">{{ getTotalSelectedViews }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GroupsStats',
  props: {
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    isSend: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  methods: {
    toggleShowSelectedGroups() {
      if (!this.isShowSelectedGroups) {
        this.$store.commit('collections/setGroupsSelectedData', this.selectedGroups);
        this.$store.commit('collections/setShowSelectedGroups', true);
        return;
      }
      this.$store.commit('collections/setGroupsSelectedData', []);
      this.$store.commit('collections/setShowSelectedGroups', false);
    },
  },
  computed: {
    getCountSelectedGroups() {
      if ((!this.selectedGroups.length || this.getSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('Channels')}: ${this.getCurrentCollectionStats.countSelectedGroups}`;
      }
      return `${this.$t('Channels selected')}: ${this.selectedGroups.length}`;
    },
    getTotalSelectedPrice() {
      // (если не выбраны группы или активирована кнопка выбрать все) и есть данные о подборке
      if ((!this.selectedGroups.length || this.getSelectedAllGroups) && this.getCurrentCollectionStats) {
        const totalSum = Math.round(this.getCurrentCollectionStats?.sumPostPriceSell || 0);
        const sumWithNDS = totalSum > 0 ? `(${this.setNumberSpaces(totalSum * ((1 + 0.20)))} ${this.$t('nds')})` : '';
        return this.mode === 'noauth'
          ? `${this.$t('Forecast budget')}: ${this.setNumberSpaces(totalSum)} ${sumWithNDS}`
          : `${this.$t('price_sell')}: ${this.setNumberSpaces(totalSum)} ${sumWithNDS}`;
      }

      // в других случаях считаем сумму из списка выбранных каналов или из трейдоффера
      const newGroups = [];
      this.selectedGroups.forEach((group) => {
        const currentGroup = this.$store.getters['collections/getLoadCompilation'].find((item) => item.group.id === group.id && item.group.name === group.name);
        if (currentGroup && currentGroup.tradeOffer) {
          newGroups.push(currentGroup.tradeOffer.price);
        } else {
          newGroups.push(group.postPrice?.sell);
        }
      });

      const totalSum = Math.round(newGroups.reduce((prev, cur) => prev + +(cur || 0), 0));
      const sumWithNDS = totalSum > 0 ? `(${this.setNumberSpaces(totalSum * ((1 + 0.20)))} ${this.$t('nds')})` : '';

      return this.mode === 'noauth'
        ? `${this.$t('Forecast budget')}: ${this.setNumberSpaces(totalSum)} ${sumWithNDS}`
        : `${this.$t('price_sell')}: ${this.setNumberSpaces(totalSum)} ${sumWithNDS}`;
    },
    getTotalSelectedCostPrice() {
      if ((!this.selectedGroups.length || this.getSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('price_sale')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumPostPriceSale))}`;
      }
      return `${this.$t('price_sale')}: ${this.setNumberSpaces(Math.round(this.selectedGroups.reduce((prev, cur) => prev + +(cur.postPrice?.sale || 0), 0)))}`;
    },
    getTotalSelectedBuyPrice() {
      if ((!this.selectedGroups.length || this.getSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('price_buy')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumPostPriceBuy))}`;
      }
      return `${this.$t('price_buy')}: ${this.setNumberSpaces(Math.round(this.selectedGroups.reduce((prev, cur) => prev + +(cur.postPrice?.buy || 0), 0)))}`;
    },
    getTotalSelectedSubscribers() {
      if ((!this.selectedGroups.length || this.getSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('subscribers')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumSubscribersCount))}`;
      }
      return `${this.$t('subscribers')}: ${this.setNumberSpaces(this.selectedGroups.reduce((prev, cur) => prev + +(cur.subscribersStatistic?.count || 0), 0))}`;
    },
    getTotalSelectedViews() {
      if ((!this.selectedGroups.length || this.getSelectedAllGroups) && this.getCurrentCollectionStats) {
        return `${this.$t('views')}: ${this.setNumberSpaces(Math.round(this.getCurrentCollectionStats.sumViewsLast24hCount))}`;
      }
      return `${this.$t('views')}: ${this.setNumberSpaces(this.selectedGroups.reduce((prev, cur) => prev + +(cur.viewsStatistic?.countLast24h || 0), 0))}`;
    },
    getTextForSelected() {
      return this.isShowSelectedGroups ? this.$t('Show all channels') : this.$t('Show selected');
    },
    isShowSelectedGroups() {
      return this.$store.getters['collections/getShowSelectedGroups'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    getCurrentCollectionStats() {
      return this.$store.getters['collections/getCurrentCollection']?.stats;
    },
    getSelectedAllGroups() {
      return this.$store.getters['collections/getSelectedAllGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-stats {
  display: block;
  @include between-elements {
    margin-right: 15px;
    @include desktop-screen {
      margin-right: 45px;
    }
  }
  @include tablet-screen {
    display: flex;
    flex-wrap: wrap;
  }
}

.group-stats__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_no-buttons {
    flex-direction: column;
    @include tablet-screen {
    flex-direction: row;
    }
    @include between-elements {
      margin-right: 45px;
    }
  }
}

.groups-stats__text {
  color: $primary-white;
  font-size: 12px;
  line-height: 20px;
  @include desktop-screen {
    font-size: 16px;
  }
  &_touch {
    cursor: pointer;
    border-bottom: 1px solid $primary-white;
  }
}
</style>
