<template>
  <vue-slider
  :value="value"
  class="data-range-slider"
  :height="3"
  :min="minRange"
  :disabled="disabled"
  :max="maxRange"
  :contained="true"
  :drag-on-click="false"
  :dot-size="16"
  :enable-cross="false"
  :lazy="true"
  :rail-style="{
    'backgroundColor': '#A1A1A1',
  }"
  :process-style="{
    'backgroundColor': '#00AEE8',
  }"
  @dragging="$emit('drag', $event)"
  @change="$emit('change', $event)">
    <template v-slot:dot>
      <div class="data-range-slider__dot" />
    </template>
  </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
  name: 'DataRangeSlider',
  components: { VueSlider },
  props: {
    /* массив для значений ползунка: [18, 100] */
    value: {
      type: Array,
      default: null,
    },
    /* условие для disable */
    disabled: {
      type: Boolean,
      default: false,
    },
    /* минимальное значение ползунка */
    fromMax: {
      type: [String, Number],
      default: null,
    },
    /* максимальное значение ползунка */
    toMax: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    minRange: null,
    maxRange: null,
  }),
  created() {
    this.minRange = this.fromMax ? this.fromMax : 0;
    this.maxRange = this.toMax ? this.toMax : 100000;
  },
};
</script>

<style lang="scss" scoped>
.data-range-slider {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.data-range-slider__dot {
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, $primary-blue 40%, $primary-white 50%);
  border: 2px solid $primary-blue;
  border-radius: 50%;
}
</style>
