<template>
  <div class="imports-screen">
    <div class="imports-screen__tabs">
      <span
      v-for="tab in getFilteredTabs"
      :key="tab.name"
      class="imports-screen__tab"
      :class="[
        isSuperAdmin && 'pointer',
        (isSuperAdmin && tab.name === selectedTab) && 'imports-screen__tab_selected'
      ]"
      @click="changeTab(tab)">
        {{ $t(tab.name) }}
      </span>
    </div>
    <Imports
    :imports-data="getImportsData"
    :selected-tab="selectedTab" />
  </div>
</template>

<script>
import Imports from './components/Imports.vue';

export default {
  name: 'ImportsScreen',
  components: {
    Imports,
  },
  data: () => ({
    tabs: [
      { name: 'My imports' },
      { name: 'All imports', admin: 'super' },
    ],
    selectedTab: 'My imports',
  }),
  created() {
    this.$store.commit('groups/setGroupsIsLoading', true);

    this.$store.dispatch('imports/getImports')
      .finally(() => {
        this.$store.commit('groups/setGroupsIsLoading', false);
      });
  },
  methods: {
    changeTab(tab) {
      if (tab.name === this.selectedTab) return;

      this.selectedTab = tab.name;

      if (tab.name === 'All imports' && this.isEmptyAllImportsData) {
        this.getAllImports();
      }
    },
    getAllImports() {
      // FUTURE сделать общий на весь APP Loader и ставить загрузку при любом экшене
      this.$store.commit('groups/setGroupsIsLoading', true);
      this.$store.dispatch('imports/getAllImports', { reset: true })
        .finally(() => {
          this.$store.commit('groups/setGroupsIsLoading', false);
        });
    },
  },
  computed: {
    getImportsData() {
      if (this.selectedTab === 'All imports') {
        return this.$store.getters['imports/getAllImportsData'];
      }
      return this.$store.getters['imports/getUserImportsData'];
    },
    isSuperAdmin() {
      return this.$store.getters['auth/getIsSuperAdmin'];
    },
    getFilteredTabs() {
      if (this.isSuperAdmin) return this.tabs;
      return this.tabs.filter((tab) => !tab.admin);
    },
    isEmptyAllImportsData() {
      return this.$store.getters['imports/getAllImportsData'].length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.imports-screen {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}

.imports-screen__tabs {
  margin: 30px 20px 10px;
  @include between-elements {
    margin-right: 20px;
  }
}

.imports-screen__tab {
  color: $primary-gray;
  font-size: 16px;
  padding-bottom: 4px;
  transition: 0.2s;
  cursor: pointer;
  &_selected {
    box-shadow: 0px -2px 0px 0px $primary-blue inset;
  }
}

</style>
