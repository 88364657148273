<template>
  <div
  class="header-banner"
  :class="fullsize && 'header-banner_show'">
    <div class="header-banner__container">
      <div class="header-banner__text-container">
        <!-- NEED TRANSLATE -->
        <span class="header-banner__text">
          По вопросам размещения рекламы в регионах ближневосточного зарубежья
          (Казахстан, Узбекистан, Таджикистан, Киргизия, Армения, Грузия) client@mediagate.agency
        </span>
        <img
        class="header-banner__logo"
        :src="require('@/assets/logos/mediagate-logo.svg')">
      </div>
      <TJIcon
      name="close"
      pointer
      class="header-banner__text"
      @click.native="closeBanner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderBanner',
  data: () => ({
    fullsize: false,
  }),
  mounted() {
    setTimeout(() => {
      this.fullsize = true;
    }, 0);
  },
  beforeDestroy() {
    this.fullsize = false;
  },
  methods: {
    closeBanner() {
      this.fullsize = false;
      setTimeout(() => {
        this.$emit('close');
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-banner {
  background: #AB0887;
  width: 100%;
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  opacity: 0;
  overflow: hidden;
  &_show {
    opacity: 1;
    overflow: visible;
    height: 64px;
    @include tablet-screen {
      height: 44px;
    }
  }
}
.header-banner__text-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-banner__logo {
  display: none;
  @include tablet-screen {
    display: block;
    margin: 0px 20px;
  }
}

.header-banner__text {
  font-size: 12px;
  word-wrap: break-word;
  @include desktop-screen {
    font-size: 14px;
  }
  color: white;
}

.header-banner__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1805px;
  margin: 0px auto;
  padding: 0px 20px;
}
</style>
