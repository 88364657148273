<template>
  <div class="nav-settings">
    <TJIcon
    name="settings"
    pointer
    :size="24"
    class="nav-settings__icon"
    @click.native="showNavSettingModal = true" />
    <TJ-Modal
    :show-modal="showNavSettingModal"
    @close="close">
      <template #modal-content>
        <div class="nav-settings__modal">
          <div class="nav-settings__header-block">
            <span class="nav-settings__text nav-settings__text_title">{{ $t('Channels view settings') }}</span>
            <span class="nav-settings__text">{{ $t('Move the elements to change the view sequence') }}</span>
          </div>
          <Draggable
          v-model="columns"
          v-bind="dragOptions"
          class="nav-settings__columns"
          @start="showArrows = false"
          @end="showArrows = true">
            <div
            v-for="column in columns"
            :key="column.id">
              <div class="nav-settings__column">
                <TJCheckbox
                :checked.sync="column.show"
                class="nav-settings__checkbox" />
                <span class="nav-settings__text nav-settings__text_touch">{{ getColumnName(column) }}</span>
                <TJIcon
                v-if="showArrows"
                class="nav-settings__drag-icon"
                name="unfold_more"
                :size="14"
                pointer />
              </div>
            </div>
          </Draggable>
          <div class="nav-settings__line" />
          <div class="nav-settings__additional">
            <div>
              <span
              class="nav-settings__text nav-settings__text_touch"
              @click="setAll(true)">{{ $t('select_all') }}</span>
              <span
              class="nav-settings__text nav-settings__text_touch"
              @click="setAll(false)">{{ $t('reset_all') }}</span>
            </div>
            <span
            class="nav-settings__text nav-settings__text_touch nav-settings__text_small"
            @click="setBasicSettings">{{ $t('restore_default_dsettings') }}</span>
          </div>
          <div class="nav-settings__button-block">
            <TJButton
            :text="$t('save_changes')"
            :width="240"
            @handler="save" />
            <TJButton
            :text="$t('cancel')"
            type="outlined"
            @handler="close" />
          </div>
        </div>
      </template>
    </TJ-Modal>
  </div>
</template>

<script>
import groupsData from '@/assets/groupsData.json';
import cloneDeep from 'lodash/cloneDeep';
import Draggable from 'vuedraggable';

export default {
  name: 'NavSettings',
  components: { Draggable },
  data: () => ({
    showNavSettingModal: false,
    columns: null,
    showArrows: true,
  }),
  created() {
    this.init();
  },
  methods: {
    save() {
      this.showNavSettingModal = false;
      localStorage.setItem('savedGroupsColumns031122', JSON.stringify(this.columns));
      this.$store.dispatch('app/setSnackBar',
        {
          text: this.$t('Settings saved'),
          error: false,
        });
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    close() {
      this.init();
      this.showNavSettingModal = false;
    },
    init() {
      const savedGroupsColumns = JSON.parse(localStorage.getItem('savedGroupsColumns031122'));
      this.columns = savedGroupsColumns || cloneDeep(groupsData.columns.slice(1));
      this.columns = this.isAdmin ? this.columns : this.columns.filter((column) => !column.admin);
    },
    setAll(value) {
      this.columns.forEach((item) => {
        item.show = value;
      });
    },
    getColumnName(column) {
      return Array.isArray(column.value) ? this.$t(column.value2) : this.$t(column.value);
    },
    setBasicSettings() {
      // FUTURE удаляем все последние релизы колонок
      localStorage.removeItem('savedGroupsColumns211022');
      localStorage.removeItem('savedGroupsColumns271022');
      localStorage.removeItem('savedGroupsColumns031122');

      this.init();
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    getFilteredColumns() {
      return this.isAdmin ? this.columns : this.columns.filter((column) => !column.admin);
    },
    dragOptions() {
      return {
        animation: 100,
        disabled: false,
        ghostClass: 'nav-settings__drag-opacity',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-settings {
  display: none;
  @include desktop-screen {
    display: flex;
    align-items: center;
  }
}

.nav-settings__icon {
  color: $primary-gray;
}

.nav-settings__modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.nav-settings__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
}

.nav-settings__text {
  color: $primary-black;
  font-size: 16px;
  &_title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  &_touch {
    cursor: pointer;
    margin-right: 10px;
  }
  &_error {
    color: $primary-red;
  }
  &_small {
    font-size: 12px;
  }
  &_light {
    color: rgb(112, 112, 112);
  }
}

.nav-settings__columns {
  flex-wrap: wrap;
  display: flex;
  overflow-y: scroll;
  &_opacity {
    opacity: 0.5;
  }
  @include desktop-screen {
    flex-direction: column;
  }
}

.nav-settings__column {
  width: 250px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  &:hover {
    .nav-settings__drag-icon {
      display: block !important;
      transition: 0.2s;
    }
  }
}

.nav-settings__checkbox {
  margin-right: 10px;
}

.nav-settings__button-block {
  padding-top: 20px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.nav-settings__line {
  height: 0;
  border: none;
  margin-top: 10px;
  border-top: 1px solid rgb(212, 212, 212);
}

.nav-settings__additional {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.nav-settings__drag-icon {
  transition: 0.2s;
  display: none !important;
}

.nav-settings__drag-opacity {
  opacity: 0.05;
  background: $primary-blue;
}
</style>
