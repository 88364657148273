<!-- eslint-disable max-len -->
<template>
  <div
  class="body">
    <div
    class="landing">
      <header
      ref="header"
      class="container">
        <nav class="navbar">
          <div class="navbar-logo">
            <img
            class="desktop-view"
            src="./img/logo.svg"
            title="SocialData"
            alt="SocialData">

            <a href="https://socialjet.pro">
              <img
              src="./img/socialjet.png"
              class="mobile-view mobile-logo"
              alt="логотип SocialJet">
            </a>
          </div>
          <ul class="navbar-items desktop-view">
            <li>
              <a
              class="link-inside"
              @click="scrollTo('inside')">О платформе</a>
            </li>
            <li>
              <a
              class="link-posibilities"
              @click="scrollTo('posibilities')">Возможности</a>
            </li>
            <li>
              <a
              href="https://socialjet.pro/publishers.html"
              target="_blank">Паблишерам</a>
            </li>
            <li>
              <a
              class="link-contacts"
              @click="scrollTo('contacts')">Контакты</a>
            </li>
          </ul>
          <div class="navbar-side">
            <button
            class="navbar-enter"
            @click="goTo('https://telejet.socialjet.pro/signin?data')">
              <img
              src="./img/user.svg"
              title="Войти в Личный Кабинет"
              alt="Войти в Личный Кабинет">
              <span class="desktop-view pointer">Войти</span>
            </button>
            <div class="navbar-list mobile-view">
              <div class="menu">
                <div class="menu-active-top">
                  <div class="menu-active-top-wrapper">
                    <img
                    class="menu-active-top-logo"
                    src="./img/mobile/mobile-white-logo.svg"
                    alt="Логотип SocialData"
                    title="SocialData">
                  </div>
                  <button
                  class="menu-active-top-close"
                  type="button" />
                </div>
                <ul class="mobile-view">
                  <li class="menu-item">
                    <a class="link-inside hidden">Как работает</a>
                  </li>
                  <li class="menu-item">
                    <a class="link-posibilities hidden">Блог</a>
                  </li>
                  <li class="menu-item">
                    <a
                    class="hidden"
                    href="link-contacts">Контакты</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <section class="section-intro container">
        <div class="intro-description">
          <img
          class="intro-description-logo"
          src="./img/logo-big.svg"
          title="SocialData"
          alt="SocialData">
          <img
          class="arrow arrow-right arrow-right-top"
          src="./img/background/arrows/arrow-bottom.svg">

          <h1>
            Крупнейшая аналитическая и рекламная платформа для маркетинга и
            бизнеса
          </h1>
          <h3>
            Каталог всех социальных сетей, аналитика источников, запуск
            рекламных кампаний
          </h3>
        </div>
        <ul class="intro-networks">
          <li class="intro-networks-item">
            <div class="intro-networks-item-logo">
              <img
              title="Telegram"
              alt="Telegram"
              src="./img/networks/tg.svg">
            </div>
            <span class="desktop-view">Телеграм</span>
          </li>
          <li class="intro-networks-item">
            <div class="intro-networks-item-logo">
              <img
              title="vk.com"
              alt="vk.com"
              src="./img/networks/vk.svg">
            </div>
            <span class="desktop-view">ВКонтакте</span>
          </li>
          <li class="intro-networks-item">
            <div class="intro-networks-item-logo">
              <img
              title="Яндекс Дзен"
              alt="Яндекс Дзен"
              src="./img/networks/zen.svg">
            </div>
            <span class="desktop-view">Я.Дзен</span>
          </li>
          <li class="intro-networks-item">
            <div class="intro-networks-item-logo">
              <img
              class="intro-networks-item-logo-ok"
              title="Одноклассники"
              alt="Одноклассники"
              src="./img/networks/odnocl.svg">
            </div>
            <span class="desktop-view">Одноклассники</span>
          </li>
          <li class="intro-networks-item">
            <div class="intro-networks-item-logo">
              <img
              title="YouTube"
              alt="YouTube"
              src="./img/networks/youtube.svg">
            </div>
            <span class="desktop-view">YouTube</span>
          </li>
        </ul>
        <p>А также другие популярные <span class="bold">социальные сети</span></p>
        <div class="intro-btns">
          <button
          class="btns-primary request-modal"
          @click="showFormModal = true">
            <b>Отправить заявку</b><br>
            за 10 секунд
          </button>
          <button
          class="btns-secondary video-modal"
          @click="openVideoModal">
            <b>Обзор платформы</b><br>
            за 2 минуты
          </button>
        </div>
      </section>
    </div>
    <div class="platform">
      <div
      id="platform-frame"
      class="platform-frame">
        <div class="platform-frame-performance">
          <div class="platform-frame-performance-item side">
            <img
            src="./img/icon-park_telegram.svg"
            title="Телеграм"
            alt="Телеграм">
            <span>Более 250 000 источников</span>
          </div>
          <div class="platform-frame-performance-item">
            <img
            src="./img/stat.svg"
            title="Статистика"
            alt="Статистика">
            <span>Актульные цены, охваты, активности каналов</span>
          </div>
        </div>
        <div class="platform-frame-diagram">
          <img
          src="./img/diagram.svg"
          title="Диаграмма"
          alt="Диаграмма">
        </div>
      </div>
    </div>

    <section
    id="inside"
    ref="inside"
    class="platform-inside container">
      <img
      class="blur landing-inside"
      src="./img/background/blur.png">
      <h2>Что внутри</h2>
      <img
      class="blur"
      src="./img/background/blur.png">
      <ul class="platform-inside-list">
        <li class="platform-inside-list-item">
          <img
          src="./img/social-base.svg"
          title="База социальных сетей"
          alt="База социальных сетей">
          <h3>Крупнейшая база групп, каналов и аккаунтов</h3>
          <ul>
            <li><span>Более 250к источников</span></li>
            <li><span>Ежедневные обновления данных</span></li>
            <li><span>Проверка контента нейронными системами</span></li>
            <li><span>SJI рейтинг источников</span></li>
            <li>
              <span>Сохранение всех постов <br class="mobile-view">
                и реакций</span>
            </li>
          </ul>
        </li>
        <li class="platform-inside-list-item">
          <img
          src="./img/compilation.svg"
          title="База социальных сетей"
          alt="База социальных сетей">
          <h3>Создание и расшаривание подборок</h3>
          <ul>
            <li>
              <span>Создавайте подборки <br class="mobile-view">
                под свои проекты</span>
            </li>
            <li>
              <span>Отправляйте коллегам <br class="mobile-view">
                и партнерам</span>
            </li>
            <li>
              <span>Отслеживайте важные <br class="mobile-view">
                и любимые каналы</span>
            </li>
          </ul>
        </li>
        <li class="platform-inside-list-item">
          <img
          src="./img/chart.svg"
          title="Запуск и мониторинг рекламных кампаний"
          alt="Запуск и мониторинг рекламных кампаний">
          <h3>Запуск и мониторинг рекламных кампаний</h3>
          <ul>
            <li><span>Запускайте вручную кампании через владельцев</span></li>
            <li><span>Запускайте автоматически через платформу</span></li>
            <li><span>Выгружайте полную статистику по кампании</span></li>
          </ul>
        </li>
      </ul>
      <img
      class="arrow arrow-down"
      src="./img/background/arrows/arrow-bottom.svg">

      <h2>
        Примеры <br class="mobile-view">
        <mark>медиа-планов</mark>
      </h2>
      <div class="platform-compilation-container">
        <div class="platform-compilation">
          <div class="compilation-example">
            <div class="compilation-deliver">
              <img
              src="./img/dron.svg"
              title="Подборка каналов"
              alt="Подборка каналов">
            </div>
          </div>
        </div>
        <div class="platform-compilation-performance">
          <div class="platform-frame-performance-item compilation">
            <img
            src="./img/check.svg"
            title="Актуальные показатели сразу по всем каналам"
            alt="✔︎">
            <span>Актуальные показатели по всем источникам</span>
          </div>
          <div class="platform-frame-performance-item compilation">
            <img
            src="./img/check.svg"
            title="Список рекомендованных подборок"
            alt="✔︎">
            <span>Список рекомендованных подборок</span>
          </div>
        </div>
      </div>
    </section>
    <section
    id="posibilities"
    ref="posibilities"
    class="container">
      <div class="platform-analitycs">
        <h2>
          Глубокая аналитика<br class="mobile-view">
          на основе<br>
          <mark>нейронных сетей</mark>
        </h2>
        <img
        class="platform-analitycs-blur blur"
        src="./img/background/blur.png">
        <div class="platform-analitycs-list">
          <img
          class="platform-analitycs-safety"
          src="./img/safety.svg"
          title="Brand-Safety"
          alt="Brand-Safety">
          <div class="platform-analitycs-item platform-analitycs-item-left">
            <h4>18+ контент</h4>
            <span>Анализируем посты <br class="mobile-view">
              на наличие материалов <br>
              для взрослых</span>
            <img
            class="buble buble-left"
            src="./img/buble.svg">
          </div>
          <div class="platform-analitycs-item platform-analitycs-item-right">
            <h4>Цензура</h4>
            <span>Анализируем посты <br class="mobile-view">на наличие мата
            </span>
            <img
            class="buble buble-right"
            src="./img/buble.svg">
          </div>
          <div class="platform-analitycs-item platform-analitycs-item-lower">
            <h4>Жестокость</h4>
            <span>Анализируем посты <br class="mobile-view">
              на наличие насилия <br class="mobile-view">
              и жестоких материалов</span>
            <img
            class="buble buble-bottom"
            src="./img/buble.svg">
          </div>
          <img
          class="blur platform-analitycs-blur"
          src="./img/background/blur.png">
        </div>
      </div>
      <div class="platform-big">
        <div class="platform-big-item platform-big-item-first">
          <h3><mark>Мониторинг конкурентов</mark></h3>
          <h4><b>Аналитика размещений конкурентов</b></h4>
          <p>
            Узнайте где размещаются конкуренты, найдите<br>
            рекламные посты <br class="mobile-view">
            и анализируйте
          </p>
          <div class="platform-big-item-img">
            <img
            src="./img/monitoring.svg"
            title="Мониторинг конкурентов"
            alt="Мониторинг конкурентов">
          </div>
        </div>
        <div class="platform-big-item platform-big-item-second">
          <h3><mark>Анализ аудитории</mark></h3>
          <h4><b>Пол, возраст, география, пересечение аудитории</b></h4>
          <p>
            Парсинг аудитории, аналитика пересечения<br class="desktop-view">
            подписчиков в каналах, количества ботов
          </p>
          <div class="platform-big-item-img">
            <img
            src="./img/analysis.svg"
            title="Анализ аудитории"
            alt="Анализ аудитории">
          </div>
        </div>
      </div>
    </section>
    <div class="country-partners container">
      <h2>
        Наши <br class="mobile-view">
        <mark>партнеры</mark>
      </h2>
      <div class="country-partners__back">
        <div class="country-partners__logo-block">
          <img
          src="@/assets/logos/social-data-logo.svg"
          class="country-partners__logo"
          alt="логотип SocialJet">
          <span class="country-partners__x">X</span>
          <img
          src="./img/mediagate-logo.png"
          class="country-partners__logo country-partners__logo_mediagate"
          alt="логотип Mediagate">
        </div>
        <span class="country-partners__text">
          По вопросам размещения рекламы в регионах ближневосточного зарубежья
          (Казахстан, Узбекистан, Таджикистан, Киргизия, Армения, Грузия)
        </span>
      </div>
      <button
      class="country-partners__button"
      @click="sendEmail('client@mediagate.agency')">
        client@mediagate.agency
      </button>
    </div>
    <div class="platform-partners">
      <section class="container">
        <h2>С нами <mark>работают</mark></h2>
        <div class="platform-partners-radio-wrapper">
          <div
          class="radio-block"
          :class="partnersBlock === 'brands' ? 'btns-primary-right' : 'btns-primary-left'" />
          <button
          id="agencies"
          :class="partnersBlock === 'agency' && 'btns-on'"
          type="button"
          @click="partnersBlock = 'agency'">
            Рекламные агентства
          </button>
          <button
          id="brands"
          type="button"
          :class="partnersBlock === 'brands' && 'btns-on'"
          @click="partnersBlock = 'brands'">
            Бренды
          </button>
        </div>
        <div
        class="platform-partners-list platform-partners-agencies"
        :class="partnersBlock === 'agency' ? 'partners-active' : 'partners-hidden hidden'">
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/MGCom.png"
            title="MGCom"
            alt="MGCom">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/Adlabs.png"
            title="Adlabs"
            alt="Adlabs">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/Realweb.png"
            title="Realweb"
            alt="Realweb">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/SberMarketing.png"
            title="Сбер Маркетинг"
            alt="Сбер Маркетинг">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/OMD.png"
            title="OMD"
            alt="OMD">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/MIG.png"
            title="Media InstinctGroup"
            alt="Media InstinctGroup">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/iProspect.png"
            title="iProspect"
            alt="iProspect">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/PeopleAndScreens.png"
            title="people & screens"
            alt="people & screens">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/PRT.png"
            title="PRT"
            alt="PRT">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/agencies/UMA.png"
            title="United Media Agency"
            alt="United Media Agency">
          </div>
        </div>
        <div
        class="platform-partners-list platform-partners-agencies"
        :class="partnersBlock === 'brands' ? 'partners-active' : 'partners-hidden hidden'">
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Premier.png"
            title="Premier"
            alt="Premier">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/СберЗвук.png"
            title="СберЗвук"
            alt="СберЗвук">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Huawei.svg"
            title="Huawei"
            alt="Huawei"
            width="89"
            height="101">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Okko.png"
            title="Okko"
            alt="Okko">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Iherb.png"
            title="IHerb"
            alt="IHerb">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Vkusvil.png"
            title="Вкусвилл"
            alt="Вкусвилл">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Логотип_телеканала_«Москва_24».png"
            title="Москва 24"
            alt="Москва 24">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Mega.png"
            title="MEGA"
            alt="MEGA">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/Hyundai.png"
            title="Hyundai"
            alt="Hyundai">
          </div>
          <div class="platform-partners-item">
            <img
            src="./img/partners/brands/KIA.png"
            title="KIA"
            alt="KIA">
          </div>
        </div>
      </section>
    </div>
    <section class="container platform-access">
      <h2>
        Получить доступ <br class="mobile-view">
        <mark>к платформе</mark>
      </h2>
      <img
      class="arrow arrow-right arrow-right-bottom"
      src="./img/background/arrows/arrow-bottom.svg">
      <img
      class="buble buble-bottom-left"
      src="./img/buble.svg">

      <div class="platform-big">
        <div class="platform-big-item platform-big-item-blue">
          <div class="item-text">
            <h3>Как рекламодатель</h3>
            <p>
              Если вы маркетолог, владелец компании<br class="desktop-view">
              или представитель рекламного агентства ‒
              <br class="desktop-view">
              отправьте заявку и менеджер свяжется с вами<br
              class="desktop-view">
              для предоставления доступа
            </p>
          </div>
          <button
          class="request-modal"
          @click="showFormModal = true">
            <mark>Отправить заявку</mark>
          </button>
        </div>
        <div class="platform-big-item platform-big-item-blue">
          <div class="item-text">
            <h3>Как владелец источника</h3>
            <p>
              Если вы владелец канала или аккаунта<br class="desktop-view">
              в любой соц.сети с более 300 подписчиками ‒<br
              class="desktop-view">
              добавляйте источник и получайте заявки<br class="desktop-view">
              на рекламу, анализируйте данные<br class="desktop-view">
              и предоставляйте отчеты
            </p>
          </div>
          <button @click="goTo('https://t.me/sj_welcome_bot', blank = true)">
            <span>Подключить источник</span>
          </button>
        </div>
      </div>
    </section>
    <section
    id="contacts"
    ref="contacts"
    class="platform-questions container">
      <div class="address">
        <div class="address-item">
          <h2>Остались вопросы?</h2>
          <p>
            Свяжись с нами в мессенджерах, соц.сетях<br>
            или по почте
            <a
            href="mailto:partners@socialjet.pro"><mark>partners@socialjet.pro</mark></a>
          </p>
          <div class="social">
            <a
            class="social-icon social-icon-address"
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/socialjet"><img
            src="./img/networks/light-blue-icons/blue-tg.png"
            alt="Telegram"
            title="Telegram"></a>
            <a
            class="social-icon social-icon-address"
            target="_blank"
            rel="noopener noreferrer"
            href="https://vk.com/socialjet"><img
            src="./img/networks/light-blue-icons/blue-vk.png"
            alt="ВКонтакте"
            title="ВКонтакте"></a>
            <a
            class="social-icon social-icon-address"
            target="_blank"
            rel="noopener noreferrer"
            href="https://zen.yandex.ru/id/6023a5d824c236744ae451e3"><img
            src="./img/networks/light-blue-icons/blue-zen.png"
            alt="Яндекс Дзен"
            title="Яндекс Дзен"></a>
          </div>
        </div>
        <div class="address-item address-item-map">
          <p class="hidden-mobile">
            23001, г. Москва, ул. Большая Садовая, дом 5,<br>
            корпус 1, офис 515
          </p>
          <div
          class="map"
          style="position: relative; overflow: hidden">
            <a
            href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
            style="color: #eee; font-size: 12px; position: absolute; top: 0px">Москва</a>
            <a
            href="https://yandex.ru/map-widget/v1/?um=constructor%3A8b7cc583f4f282024a1fa8f61a461e861110841df903927adc47e8ccce1ae9b6&amp;source=constructor"
            style="
                color: #eee;
                font-size: 12px;
                position: absolute;
                top: 14px;
              ">4-й Лесной переулок, 4 — Яндекс.Карты</a>
            <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A8b7cc583f4f282024a1fa8f61a461e861110841df903927adc47e8ccce1ae9b6&amp;source=constructor"
            style="
                position: relative;
                width: 100%;
                height: 100%;
                border: none;
              " />
          </div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="container">
        <div class="footer-main">
          <div class="social social-logo">
            <div class="social__logo">
              <a
              class="header-wrap"
              href="https://socialjet.pro">
                <img
                src="@/assets/logos/social-data-logo.svg"
                class="footer-logo footer-logo-socialdata"
                alt="логотип SocialJet"
                width="121"
                height="58">
              </a>
              <span class="footer-logo-x">X</span>
              <img
              src="@/assets/logos/mediagate-logo-black.svg"
              class="footer-logo"
              alt="логотип Mediagate"
              width="121">
            </div>
            <span class="footer-logo__text">
              По вопросам размещения рекламы в регионах ближневосточного зарубежья
              (Казахстан, Узбекистан, Таджикистан, Киргизия, Армения, Грузия)
              <a href="mailto:client@mediagate.agency"><mark>client@mediagate.agency</mark></a>
            </span>
          </div>
          <div class="social social-footer">
            <div class="social-footer-icons">
              <p class="desktop-view">
                Мы в социальных сетях:
              </p>
              <a
              class="social-icon social-icon-footer"
              href="https://t.me/socialjet"><img
              src="./img/networks/transparent-icons/transp-tg.png"
              title="Telegram"
              alt="Telegram"
              width="24"
              height="24"></a>

              <a
              class="social-icon social-icon-footer"
              href="https://vk.com/socialjet"><img
              src="./img/networks/transparent-icons/transp-vk.png"
              title="vk.com"
              alt="vk.com"
              width="25"
              height="25"></a>
              <a
              class="social-icon social-icon-footer"
              href="https://zen.yandex.ru/id/6023a5d824c236744ae451e3"><img
              src="./img/networks/transparent-icons/transp-zen.png"
              title="Yandex Zen"
              alt="Yandex Zen"
              width="24"
              height="24"></a>
            </div>
          </div>
          <div class="social footer-contacts">
            <div class="">
              <p>
                23001, г. Москва, ул. Большая Садовая,<br>
                дом 5, корпус 1, офис 515
              </p>
              <a
              class="phone"
              href="tel:+74957685865">+7 (495) 768 58 65</a><br>
              <a
              class="highlight"
              href="mailto:partners@socialjet.pro"
              target="_blank">partners@socialjet.pro</a>
            </div>
          </div>
        </div>
        <div class="footer-copyrights">
          <div class="footer-copyright">
            © 2015–2021 Socialjet
          </div>

          <div class="footer-docs">
            <a
            href="https://socialjet.pro/terms"
            target="_blank"
            rel="noopener noreferrer"><mark>Политика конфиденциальности</mark></a>
          </div>
          <div class="footer-docs">
            <a
            href="https://socialjet.pro/u_agreement"
            target="_blank"
            rel="noopener noreferrer"><mark>Публичная оферта</mark></a>
          </div>
        </div>
      </div>
    </footer>

    <transition name="fade">
      <a
      v-if="showUpButton"
      class="to-top"
      aria-label="Наверх"
      @click="scrollTo('header')" />
    </transition>

    <TJModal
    :show-modal="showFormModal"
    @close="showFormModal = false">
      <template #modal-content>
        <LandingForm @close="showFormModal = false" />
      </template>
    </TJModal>

    <TJModal
    :show-modal="showVideoModal"
    iframe
    @close="showVideoModal = false">
      <template #modal-content>
        <iframe
        :width="getVideoWidth"
        :height="getVideoHeight"
        src="https://www.youtube.com/embed/9MB7C29ibtY?autoplay=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen />
      </template>
    </TJModal>
  </div>
</template>

<script>
import LandingForm from '@/components/MainLanding/LandingForm.vue';

export default {
  name: 'MainLanding',
  components: {
    LandingForm,
  },
  data: () => ({
    scrollPosition: 0,
    showUpButton: false,
    showFormModal: false,
    showVideoModal: false,
    partnersBlock: 'agency',
  }),
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollTo(element) {
      this.$refs[element].scrollIntoView({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      this.showUpButton = (window.scrollY > 1000);
    },
    goTo(link, blank = false) {
      if (blank) {
        window.open(link);
        return;
      }
      window.location.href = link;
    },
    openVideoModal() {
      this.showVideoModal = true;
    },
    sendEmail(email) {
      window.open(`mailto:${email}`, '_self');
    },
  },
  computed: {
    getVideoWidth() {
      return window.innerWidth * 0.6;
    },
    getVideoHeight() {
      return window.innerWidth > 576 ? window.innerHeight * 0.6 : 200;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  max-width: 100%;
  overflow-x: hidden;
}

button a {
  color: black;
  text-decoration: none;
  font-size: 11px;
}

button.btns-secondary {
  font-size: 18px;
  color: #00aee8;
}

button,
a {
  cursor: pointer;
}

button:hover,
a:hover {
  opacity: 0.7;
}

.mobile-view {
  display: none;
}

.landing {
  background: url("./img/background/background-plane.svg");
  background-size: cover;
}

.landing-inside {
  position: absolute;
  z-index: -999;
  bottom: 50%;
}

.container {
  max-width: 1350px;
  margin: 0 auto;
}

h2 {
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
  font-family: "Ubuntu";
  text-align: center;
}

a {
  -webkit-text-decoration-color: #00aee8;
  text-decoration-color: #00aee8;
  color: #00aee8;
}

a.phone {
  color: black;
  text-decoration: none;
}

.hidden-desktop {
  display: none;
}

.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 26px;
}

.navbar-logo {
  margin-top: -13px;
}

.navbar-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  gap: 25px;
  padding-top: 5px;
}

.navbar-items li {
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.navbar-items li a {
  color: black;
  text-decoration: none;
}

.navbar button {
  padding: 15px 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  border: 2px solid #00aee860;
  border-radius: 82px;
}

.navbar button a {
  font-size: 16px;
}

.navbar button img {
  margin-right: 14px;
}

.section-intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-intro p {
  margin-top: 30px;
  color: #597f8d;
}

.intro-description {
  position: relative;
  margin-top: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.intro-description h1 {
  margin-top: 74px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 28px;
  line-height: 160%;
}

.intro-description h3 {
  margin-bottom: 54px;
  font-size: 20px;
  line-height: 160%;
}

.intro-networks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
}

.intro-networks-item {
  width: 254px;
  padding: 23px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  background: #ffffff;
  -webkit-box-shadow: 0px 16px 46px -5px rgba(115, 156, 207, 0.17),
    inset 0px 0px 15px #ffffff;
  box-shadow: 0px 16px 46px -5px rgba(115, 156, 207, 0.17),
    inset 0px 0px 15px #ffffff;
  border-radius: 11px;
}

.intro-networks-item span {
  color: #00aee8;
}

.intro-networks-item-logo {
  width: 54px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.67);
  -webkit-box-shadow: 0px 8.1px 18px rgba(109, 169, 244, 0.12),
    inset 0px 0px 13.5px #ffffff;
  box-shadow: 0px 8.1px 18px rgba(109, 169, 244, 0.12),
    inset 0px 0px 13.5px #ffffff;
  border-radius: 34.2px;
}

.intro-networks-item-logo img {
  width: 27px;
  height: 27px;
}

.intro-btns {
  margin-top: 70px;
  margin-bottom: 45px;
}

.intro-btns button:hover {
  outline: none;
}

.intro-btns button {
  font-size: 18px;
  padding: 20px 72px;
  border-radius: 73px;
}

.btns-primary {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#52d7f8),
    to(#00aee8)
  );
  background: linear-gradient(to right, #52d7f8, #00aee8);
  -webkit-box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  color: white;
  border-width: 0;
}

.btns-secondary {
  background: linear-gradient(93.6deg, #fafdff 0.63%, #eefaff 101.83%);
  border: 2px solid #bae8f7;
  -webkit-box-shadow: 0px 17px 37px -8px rgba(66, 144, 247, 0.21);
  box-shadow: 0px 17px 37px -8px rgba(66, 144, 247, 0.21);
  margin-left: 30px;
}

.platform {
  margin-top: 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.platform-frame {
  background: url("./img/frame-big.png") no-repeat;
  border-radius: 16px;
  background-size: contain;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1228px;
  height: 763px;
  position: relative;
  margin-bottom: 200px;
}

.platform-frame-performance {
  position: absolute;
  right: -1%;
  bottom: 12%;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

.platform-frame-performance-item {
  background: white;
  border: 1px solid #d8f2fa;
  -webkit-box-shadow: 0px 23.7785px 47.5569px -13.44px rgba(115, 156, 207, 0.25),
    inset 0px 0px 15.5077px #ffffff;
  box-shadow: 0px 23.7785px 47.5569px -13.44px rgba(115, 156, 207, 0.25),
    inset 0px 0px 15.5077px #ffffff;
  border-radius: 16.5415px;
  color: #00aee8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 336px;
  height: 96px;
  gap: 18px;
  padding: 24px 25px;
  margin-bottom: 13px;
}

.platform-frame-performance-item.compilation {
  width: 360px;
  height: 104px;
}

.platform-frame-diagram {
  background: white;
  -webkit-box-shadow: 0px 23px 73px -13px rgba(109, 169, 244, 0.44),
    inset 0px 0px 15px #ffffff;
  box-shadow: 0px 23px 73px -13px rgba(109, 169, 244, 0.44),
    inset 0px 0px 15px #ffffff;
  border-radius: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-item-align: end;
  align-self: end;
  width: 98px;
  height: 98px;
  margin-bottom: 130px;
}

.platform-inside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.platform-inside-list {
  margin-top: 70px;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  gap: 16px;
  margin-bottom: 166px;
}

.platform-inside-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 420px;
  background: rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: inset 0px 0px 13.6016px #ffffff;
  box-shadow: inset 0px 0px 13.6016px #ffffff;
  border-radius: 23.5762px;
  padding: 60px 38px 60px 43px;
}

.platform-inside-list-item img {
  margin-bottom: 32px;
}

.platform-inside-list-item h3 {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
}

.platform-inside-list-item ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
}

.platform-inside-list-item li {
  padding-left: 24px;
  padding-right: 15px;
  font-size: 15px;
  line-height: 150%;
  color: #00aee8;
  text-align: left;
}

.platform-inside-list-item li::marker {
  content: "•";
  font-size: 18px;
}

.platform-inside-list-item li span {
  color: #597f8d;
}

.platform-compilation {
  margin-top: 70px;
  position: relative;
}

.platform-compilation-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 100px;
}

.platform-compilation-performance {
  margin-bottom: auto;
  margin-top: 150px;
}

.platform-compilation .compilation-example {
  position: relative;
  display: block;
  width: 860px;
  height: 553px;
  background: url("./img/compilation-example.png") no-repeat;
  background-size: contain;
  margin-bottom: 170px;
}

.platform-compilation .compilation-deliver {
  width: 100px;
  height: 100px;
  padding: 15px;
  background-color: #00aee811;
  z-index: -999;
  position: absolute;
  top: 74%;
  left: 102%;
  -webkit-box-shadow: 0px 16.649px 52.8425px -9.41031px #00aee8a4,
    inset 0px 0px 10.858px #ffffff;
  box-shadow: 0px 16.649px 52.8425px -9.41031px #00aee8a4,
    inset 0px 0px 10.858px #ffffff;
  opacity: 0.8;
  border-radius: 13.0297px;
  -webkit-transform: rotate(-16deg);
  transform: rotate(-16deg);
}

.platform-compilation .compilation-deliver img {
  -webkit-transform: rotate(16deg);
  transform: rotate(16deg);
}

.platform-analitycs {
  position: relative;
}

.platform-analitycs h2 {
  text-align: center;
}

.platform-analitycs-list {
  margin-top: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 116px;
  position: relative;
}

.platform-analitycs-safety {
  position: absolute;
  left: 32%;
  bottom: 25%;
  z-index: 1;
}

.platform-analitycs-item {
  position: relative;
  display: block;
  background: rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0px 18px 50px -10px rgba(66, 144, 247, 0.18);
  box-shadow: 0px 18px 50px -10px rgba(66, 144, 247, 0.18);
  border-radius: 30px;
  width: 515px;
  height: 174px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 40px;
}

.platform-analitycs-item h4 {
  color: #00aee8;
  margin-bottom: 16px;
}

.platform-analitycs-item span {
  font-size: 16px;
}

.platform-analitycs-item img {
  position: absolute;
}

.platform-analitycs-item-left {
  margin-bottom: 70px;
}

.platform-analitycs-item-lower {
  margin: auto;
}

.platform-analitycs-blur {
  position: absolute;
  left: -60%;
  top: -50%;
}

.platform-big {
  margin-top: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 45px;
}

.platform-big-item {
  position: relative;
  width: 49%;
  max-width: 637px;
  height: 554px;
  padding: 60px;
  border-radius: 53px;
}

.platform-big-item-first {
  background-image: radial-gradient(at bottom left, #00b2e85d, #00b2e805);
}

.platform-big-item-second {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#97ddf685),
    to(#07b1e909)
  );
  background-image: linear-gradient(to left, #97ddf685, #07b1e909);
}

.platform-big-item-blue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: white;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#41d2f7),
    to(#0fb9e4)
  );
  background: linear-gradient(to right, #41d2f7, #0fb9e4);
  -webkit-box-shadow: 0px 34px 49px -28px rgba(0, 174, 232, 0.349);
  box-shadow: 0px 34px 49px -28px rgba(0, 174, 232, 0.349);
  border-radius: 53px;
}

.platform-big-item-blue h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  margin-bottom: 40px;
}

.platform-big-item-blue p {
  font-size: 20px;
  line-height: 160%;
}

.platform-big-item-blue button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  -webkit-box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.47);
  box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.47);
  border-radius: 73px;
  border-width: 0;
  padding-top: 24px;
  padding-bottom: 21px;
  width: 517px;
  font-weight: 600;
  font-size: 20px;
}

.platform-big-item-blue button a,
.platform-big-item-blue mark {
  font-size: 20px;
}

.platform-big-item-blue button:hover {
  opacity: 0.8;
  outline: none;
}

.platform-big-item-blue button mark {
  font-weight: 500;
}

.platform-big-item h3 {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
}

.platform-big-item h4 {
  margin-top: 40px;
  margin-bottom: 24px;
  font-size: 18px;
}

.platform-big-item-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 46%;
  left: 50%;
  background: #ffffff;
  border-radius: 266.602px;
}

.platform-big-item img {
  position: absolute;
  top: 40%;
  left: 50%;
  display: block;
}

.platform-partners {
  margin-top: 180px;
  padding-top: 150px;
  padding-bottom: 150px;
  background: url("./img/background/wave.svg") no-repeat;
  background-size: cover;
}

.platform-partners-radio-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 15px;
  border-radius: 80px;
  padding: 10px;
  background: white;
  max-width: 520px;
  margin: 60px auto;
  height: 80px;
  padding: 0 18px;
}

.platform-partners-radio-wrapper button {
  border: none;
  background: none;
  width: 240px;
  height: 50px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 2s;
  transition-duration: 2s;
  -webkit-transition-delay: 0s, 1s;
  transition-delay: 0s, 1s;
  -webkit-transition-timing-function: cubic-bezier(0.1, 0.8, 0.5, 2), linear;
  transition-timing-function: cubic-bezier(0.1, 0.8, 0.5, 2), linear;
}

.platform-partners-radio-wrapper button:hover {
  opacity: 1;
}

@-webkit-keyframes changeRadioRight {
  0% {
    left: 15px;
  }
  10% {
    left: 0px;
  }
  100% {
    left: 250px;
  }
}

@keyframes changeRadioRight {
  0% {
    left: 15px;
  }
  10% {
    left: 0px;
  }
  100% {
    left: 250px;
  }
}

@-webkit-keyframes changeRadioLeft {
  0% {
    left: 250px;
  }
  10% {
    left: 270px;
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  100% {
    left: 15px;
  }
}

@keyframes changeRadioLeft {
  0% {
    left: 250px;
  }
  10% {
    left: 270px;
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  100% {
    left: 15px;
  }
}

.btns-primary-right {
  -webkit-animation: changeRadioRight 0.5s;
  animation: changeRadioRight 0.5s;
  left: 250px;
}

.btns-primary-left {
  -webkit-animation: changeRadioLeft 0.5s;
  animation: changeRadioLeft 0.5s;
  left: 15px;
}

.radio-block {
  position: absolute;
  z-index: 2;
  width: 256px;
  height: 60px;
  border-radius: 73px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#52d7f8),
    to(#00aee8)
  );
  background: linear-gradient(to right, #52d7f8, #00aee8);
  -webkit-box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  color: white;
  border-width: 0;
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.platform-partners-radio-wrapper .btns-on {
  color: white;
  z-index: 3;
}

.platform-partners-radio-wrapper .btns-off {
  color: #597f8d;
}

.platform-partners h2 {
  margin-bottom: 60px;
}

.platform-partners-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px;
}

.partners-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes dissapear {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: -webkit-box;
    display: flex;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    display: none;
  }
}

@keyframes dissapear {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    display: none;
  }
}

@-webkit-keyframes arrive {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes arrive {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.partners-hidden {
  -webkit-animation: dissapear 0.3s ease-in-out forwards;
  animation: dissapear 0.3s ease-in-out forwards;
}

.partners-active {
  -webkit-animation: arrive 0.3s ease-in-out forwards;
  animation: arrive 0.3s ease-in-out forwards;
}

.platform-partners-item {
  width: 250px;
  height: 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: inset 0px 0px 13.6016px #ffffff;
  box-shadow: inset 0px 0px 13.6016px #ffffff;
  border-radius: 16px;
}

.platform-partners-item img {
  max-width: 185px;
  max-height: 111px;
}

.platform-access {
  margin-top: 150px;
  position: relative;
}

.platform-access h2 {
  margin-bottom: 100px;
}

.platform-questions {
  margin-top: 200px;
}

.blur {
  z-index: -999;
  position: absolute;
  background: rgba(7, 176, 233, 0.15);
  -webkit-filter: blur(348px);
  filter: blur(348px);
  bottom: 70%;
}

.blur.inside {
  bottom: 65%;
  right: 50%;
  background: rgba(7, 176, 233, 0.25);
}

mark {
  background-color: inherit;
  color: #00aee8;
  font-weight: inherit;
}

.buble-left {
  top: 22%;
  left: 84%;
}

.buble-right {
  width: 45px;
  bottom: 60%;
  left: -2%;
}

.buble-bottom {
  bottom: 47%;
  left: 50%;
}

.buble-bottom-left {
  position: absolute;
  left: -20%;
  top: 12%;
  width: 300px;
}

.arrow-down {
  position: absolute;
  top: 40%;
}

.arrow-right {
  position: absolute;
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
}

.arrow-right-top {
  right: 270px;
  bottom: 150px;
}

.arrow-right-bottom {
  top: 0.5%;
  left: 91%;
}

.address {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 100px;
}

.address-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 600px;
  min-height: 410px;
  margin-bottom: 80px;
}

.address-item h2 {
  margin-bottom: 35px;
}

.address-item p {
  margin-bottom: 24px;
}

.address-item .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}

.address-item .social img {
  width: 52px;
  height: 52px;
}

.address-item-map {
  width: 580px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.address-phone {
  font-weight: bold;
  display: block;
  margin-bottom: 6px;
}

.address-text {
  text-align: left;
}

.social-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 8px;
}

.social-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: inline-block;
}

.social-icon:hover {
  opacity: 0.7;
}

.social-icon-footer {
  margin: 0 13px;
  display: block;
}

.social-icon-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #00aee8;
  border-radius: 50%;
  padding: 8px 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin: 0 10px 10px 0;
}

.map {
  width: 100%;
  border-radius: 26px;
  height: 300px;
}

.social-footer {
  display: block;
}

.social-footer p {
  margin-bottom: 30px;
}

.social-icon {
  display: inline-block;
  margin: 0 32px 30px 0;
}

.social-icon-footer {
  display: inline-block;
}

.social-icon-address {
  width: 52px;
  height: 52px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 24px 23px 0 0;
}

.menu-footer {
  margin: 24px 0;
  padding: 0;
  list-style: none;
}

.menu-li {
  margin: 0 0 0.7em;
}

.menu-footer {
  margin: 24px 0;
  padding: 0;
  list-style: none;
}

.menu-li {
  margin: 0 0 0.7em;
}

.footer {
  border-radius: 79px 79px 0 0;
  padding: 60px;
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
  background: #e9f6fb57;
}

.footer .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer .social-logo {
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include between-elements {
    margin-bottom: 00px;
  }
  @include desktop-screen {
    @include between-elements {
      margin-bottom: 30px;
    }
  }
}

.footer .footer-copyright,
.footer .footer-docs {
  opacity: 0.4;
}

.footer .footer-copyrights {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 1240px;
  margin-right: 15%;
  width: 87%;
  margin-left: 2%;
}

.footer .footer-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer .footer-main .social-logo {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.footer .footer-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  line-height: 150%;
  margin-top: 70px;
  max-width: 1150px;
}

.footer .footer-contacts p {
  margin-bottom: 30px;
  line-height: 150%;
}

.footer .footer-contacts a {
  display: block;
}

.hidden {
  display: none !important;
}

.to-top {
  position: fixed;
  left: 20px;
  bottom: 50px;
  display: block;
  width: 60px;
  height: 60px;
  border: none;
  background: url("./img/arrow-up.png") no-repeat center/contain;
  cursor: pointer;
  z-index: 100;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.modal-content {
  position: relative;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px auto;
  border-radius: 36px;
  min-height: 560px;
}

.modal-content-video {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 36px;
  height: 100vh;
}

.modal-content-form {
  width: 410px;
}

.modal-content h3 {
  margin: 20px 0;
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: black;
}

.modal-content p {
  text-align: center;
}

.modal-content img {
  margin: auto;
}

.modal-content span {
  z-index: 999;
  font-size: 44px;
  display: block;
  flex-basis: 100%;
  text-align: end;
  margin-top: 6px;
  margin-right: 7px;
}

.modal-content form {
  width: 316px;
  padding-right: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
  color: #738185;
}

.modal-content form .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.modal-content form .form-group input {
  padding: 15px 10px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.25);
  width: 291px;
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}

.input-error {
  border: 2px solid red !important;
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.modal-content form .form__radiogroup {
  margin-top: 15px;
}

.form__radio {
  margin-right: 5px;
}

.modal-content form button {
  font-size: 20px;
  font-size: 18px;
  padding: 20px 72px;
  border-radius: 73px;
  width: 290px;
  background: #00aee8;
  margin-top: 40px;
}

.modal-content .video {
  border-radius: 16px;
  margin: 0 9px;
}

.modal-content .form-message {
  text-align: center;
  padding: 25px;
  margin-top: -200px;
}

.modal-content .form-message__title {
  font-weight: 600;
  margin: 25px auto;
  text-align: center;
}

.modal-content .form-message__img {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-right: 20px;
}

.modal-content .form-message__text {
  margin-top: 25px;
}

.form-loading {
  width: 200px;
  height: 200px;
  margin: auto;
  padding: 0px 52px;
}

.form-loading img {
  width: 100px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: -20px;
}

@media (max-width: 1350px) {
  .landing {
    max-width: 100%;
  }
  .container {
    max-width: 90%;
  }
  h1 {
    font-size: 1.5em;
    text-align: center;
  }
  .navbar-items li {
    font-size: 70%;
  }
  .navbar-enter {
    padding: 0.5rem 0.8rem;
  }
  .navbar-enter a {
    font-size: 70%;
  }
  .intro-description-logo {
    width: 29%;
  }
  .intro-networks {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .intro-networks-item {
    width: 25%;
    padding: 1rem 1.5rem;
  }
  .intro-networks-item span {
    font-size: 12px;
  }
  .platform-frame {
    width: 91%;
    margin-bottom: 0;
  }
  .platform-frame-diagram {
    position: absolute;
    bottom: 20%;
    margin-bottom: inherit;
    width: 7.5%;
    height: 10%;
    padding: 3em;
  }
  .platform-frame-performance {
    bottom: 25%;
  }
  .platform-inside-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .platform-inside-list-item {
    background: rgba(92, 210, 253, 0.15);
  }
  .arrow-right-top {
    width: 8%;
    right: 11%;
    bottom: 56%;
  }
  .arrow-down {
    top: 1%;
    left: 68%;
  }
  .platform-compilation .compilation-example {
    width: 40em;
    height: 28em;
  }
  .platform-compilation .compilation-example .compilation-deliver {
    right: -9%;
    width: 80px;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .platform-compilation .compilation-example .compilation-deliver img {
    width: 60px;
    height: 60px;
  }
  .platform-compilation-container {
    gap: 60px;
  }
  .platform-compilation-performance {
    margin-top: 120px;
    margin-bottom: auto;
  }
  .platform-analitycs-item {
    width: 35%;
    height: 2%;
  }
  .platform-big {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .platform-big-item {
    width: 65%;
  }
  .platform-big-item-img {
    width: 26.5%;
    left: 80%;
  }
  .platform-big-item-img img {
    right: 10%;
    left: -130%;
  }
  .platform-partners-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .address {
    gap: 0;
  }
  .address-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .address .social {
    gap: 10px;
  }
  .footer-main {
    font-size: 12px;
  }
  .footer {
    padding: 20px;
  }
  .footer .footer-copyrights {
    max-width: 90%;
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  h2 {
    font-size: 48px;
  }
  .navbar-items li {
    font-size: 10px;
  }
  .intro-networks-item {
    padding: 1em 1.2em;
  }
  .intro-description h3 {
    text-align: center;
  }
  .intro-networks-item-logo {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .intro-networks {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .intro-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 25px;
  }
  .intro-btns button {
    margin: 0;
  }
  .platform-frame {
    width: 75%;
    height: 500px;
  }
  .platform-frame-performance {
    bottom: 45%;
    padding: 1.2em;
    font-size: 15px;
  }
  .platform-frame-diagram {
    bottom: 45%;
  }
  .arrow-down {
    left: 78%;
  }
  .platform-compilation-container {
    gap: 0;
  }
  .platform-compilation .compilation-example {
    width: 400px;
    height: 300px;
  }
  .platform-compilation .compilation-example .compilation-deliver {
    display: none;
  }
  .platform-frame-performance-item.compilation {
    width: 300px;
  }
  .arrow-right-bottom {
    top: 5.5%;
    left: 75%;
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  .platform-analitycs-list {
    margin-top: 20px;
  }
  .platform-analitycs-safety {
    width: 250px;
    bottom: 25%;
  }
  .platform-analitycs-item {
    width: 35%;
    height: 175px;
  }
  .platform-analitycs-item span {
    font-size: 12px;
  }
  .platform-analitycs-item-left {
    margin-bottom: 0;
  }
  .platform-big-item-img img {
    left: -220px;
  }
  .platform-big-item-blue p {
    font-size: 18px;
  }
  .platform-big-item-blue button {
    width: 270px;
  }
  .arrow-down {
    top: 0;
    left: 73%;
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  .platform-big-item-blue .blur,
  .buble,
  .platform-analitycs-blur,
  .landing-inside,
  .blur {
    display: none;
  }
  .hidden-mobile {
    display: none;
  }
  .hidden-desktop {
    display: block;
  }
  .social.social-logo {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 30px;
  }
  .footer-copyrights {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
  h2 {
    font-size: 32px;
  }
  .navbar .navbar-logo img {
    width: 100px;
  }
  button.navbar-enter {
    padding: 8px 10px;
  }
  button.navbar-enter a {
    font-size: 7px;
  }
  button.navbar-enter img {
    width: 10px;
  }
  .address-item p {
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
  .footer-copyrights div {
    font-size: 10px;
  }
  .arrow {
    width: 100px;
    height: 100px;
  }
  .social.footer-contacts {
    font-size: 10px;
  }
  .platform-big-item {
    height: 400px;
    padding: 20px;
  }
  .platform-big-item .item-text p,
  .platform-big-item button {
    font-size: 14px;
  }
  .platform-big-item-img {
    left: 0;
  }
  .platform-big-item-img img {
    width: 150px;
    left: 214%;
    top: 90px;
  }
  .platform-questions.container {
    margin-top: 40px;
  }
  .address {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .address-item {
    margin-bottom: 0;
    min-height: 300px;
  }
  .platform-analitycs-item {
    width: 200px;
    height: 20%;
  }
  .platform-analitycs-item h4 {
    font-size: 11px;
  }
  .platform-analitycs-item span {
    font-size: 9px;
  }
  .platform-analitycs-safety {
    width: 200px;
  }
  .address-item.address-item-map {
    min-height: 330px;
    width: 420px;
  }
  .platform-compilation .compilation-example {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
  }
  .platform-compilation-performance {
    margin-top: 0;
    margin-bottom: 0;
  }
  .platform-frame-performance-item.compilation {
    width: 250px;
    font-size: 12px;
  }
}

@media screen and (max-width: 575px) {
  h2 {
    font-size: 32px;
    line-height: 125%;
  }
  h2 mark {
    font-size: inherit;
  }
  .container {
    max-width: 95%;
  }
  .mobile-logo {
    width: 121px;
    height: 58px;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
  .navbar .navbar-logo img {
    width: 81px;
    height: 40px;
  }
  button.navbar-enter {
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-width: 3px;
  }
  .section-intro p {
    font-size: 12px;
  }
  .intro-description {
    margin-top: 30px;
  }
  .intro-description h1 {
    margin-top: 30px;
    font-size: 15px;
    margin-bottom: 12px;
    line-height: 160%;
    font-weight: 600;
  }
  .intro-description-logo {
    width: 210px;
    height: 70px;
  }
  .navbar-list {
    background: url("./img/mobile/menu.svg");
    width: 40px;
    height: 11px;
    cursor: pointer;
  }
  .navbar-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 33px;
  }
  button.navbar-enter img {
    width: 16px;
    height: 16px;
    margin-right: 0;
  }
  .menu {
    display: none;
    z-index: -10;
    padding: 0;
    margin: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    position: absolute;
    right: -2px;
    z-index: 8;
    background-color: white;
    margin-top: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 7px;
    text-align: center;
  }
  .menu ul {
    list-style: none;
  }
  @-webkit-keyframes openIn {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @keyframes openIn {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  .menu-active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
    background: rgba(0, 174, 232, 0.98);
    -webkit-animation: openIn;
    animation: openIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    padding-top: 4px;
    top: -15px;
    left: -1px;
    width: 106%;
    height: 300px;
    padding: 16px;
  }
  .menu-active-top {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .menu-active-top button {
    background: url("./img/mobile/menu-close.svg") no-repeat center;
    border: none;
  }
  .menu-active-top-wrapper {
    display: block;
    margin-top: -8px;
  }
  .menu-active ul {
    width: 100%;
    list-style: none;
    height: 198px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 26px;
  }
  .menu-item {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .menu-item a {
    color: white;
    text-decoration: none;
    font-size: 19px;
  }
  .arrow-right-top {
    right: 1%;
    bottom: 66%;
    width: 48px;
    -webkit-transform: rotate(-28deg);
    transform: rotate(-28deg);
  }
  .intro-description-logo {
    width: 210px;
    height: 70px;
  }
  .intro-description h3 {
    font-size: 14px;
    line-height: 160%;
  }
  .intro-btns button {
    padding: 18px 61px;
  }
  .intro-networks-item {
    width: 54px;
    height: 54px;
    padding: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .intro-networks-item-logo {
    -webkit-box-shadow: 0px 8.1px 18px rgba(109, 169, 244, 0.12),
      inset 0px 0px 13.5px #ffffff;
    box-shadow: 0px 8.1px 18px rgba(109, 169, 244, 0.12),
      inset 0px 0px 13.5px #ffffff;
    width: 34px;
    height: 34px;
  }
  .intro-networks-item img {
    width: 40%;
  }
  .intro-networks-item img.intro-networks-item-logo-ok {
    height: 14px;
  }
  .landing {
    background: url("./img/mobile/mobile-background.svg") no-repeat center;
    background-size: cover;
  }
  .platform-frame-diagram {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    padding: 1em;
    bottom: 16%;
    left: 31%;
  }
  .platform-frame-diagram img {
    width: 16px;
    height: 16px;
  }
  .platform-frame {
    height: 304px;
    width: 350px;
    margin-bottom: 230px;
    background: url("./img/mobile/frame-big-mobile.png") contain;
  }
  .platform-frame-performance {
    padding: 0;
  }
  .platform-frame .platform-frame-performance {
    bottom: -47%;
  }
  .platform-frame .platform-frame-performance div {
    width: 340px;
  }
  .platform-inside-list-item {
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: 60px 26px 46px 60px rgba(15, 185, 228, 0.17);
    box-shadow: 60px 26px 46px 60px rgba(15, 185, 228, 0.17);
  }
  .blur {
    display: none;
  }
  .arrow-down {
    top: 71%;
    left: 40%;
  }
  .to-top {
    width: 35px;
    height: 35px;
    bottom: 32px;
  }
  .platform-inside-list-item {
    width: 340px;
  }
  .platform-compilation-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .platform-compilation-container .platform-compilation {
    margin-top: 50px;
  }
  .platform-compilation-container .platform-compilation .compilation-example {
    background: url("./img/mobile/compilation-example-mobile.png") no-repeat;
    background-size: contain;
    width: 346px;
    height: 232px;
  }
  .platform-compilation-container
    .platform-compilation
    .compilation-example
    .compilation-deliver {
    display: block;
    width: 40px;
    height: 40px;
    padding: 7px;
    right: -2%;
    left: 62%;
    top: -20%;
    -webkit-transform: rotate(-33deg);
    transform: rotate(-33deg);
  }
  .platform-compilation-container
    .platform-compilation
    .compilation-example
    .compilation-deliver
    img {
    width: 26px;
    height: 26px;
  }
  .platform-frame-performance-item.compilation {
    width: 340px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-shadow: 10px rgba(15, 185, 228, 0.17);
    box-shadow: 10px rgba(15, 185, 228, 0.17);
  }
  .platform-frame-performance-item.compilation span {
    display: block;
    max-width: 240px;
    font-size: 16px;
  }
  .platform-analitycs h2 {
    line-height: 37px;
    margin-top: 100px;
  }
  .platform-analitycs-list {
    margin-top: 200px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px;
  }
  .platform-analitycs .platform-analitycs-item {
    width: 340px;
    -webkit-box-shadow: 10px 7px 20px 10px rgba(66, 196, 247, 0.164);
    box-shadow: 10px 7px 20px 10px rgba(66, 196, 247, 0.164);
  }
  .platform-analitycs .platform-analitycs-item h4 {
    font-size: 20px;
  }
  .platform-analitycs .platform-analitycs-item span {
    font-size: 16px;
  }
  .platform-analitycs-safety {
    width: 197px;
    height: 197px;
    left: 25%;
    right: 25%;
    bottom: 90%;
    z-index: -999;
  }
  .platform-analitycs-blur {
    display: block;
    bottom: 50%;
    width: 400px;
  }
  .platform-big {
    margin-top: 50px;
  }
  .platform-big-item {
    width: 330px;
    padding-bottom: 14px;
  }
  .platform-big-item h3 {
    margin-top: 40px;
    font-size: 20px;
    text-align: left;
  }
  .platform-big-item h4 {
    font-size: 16px;
    margin-top: 20px;
    font-weight: 600;
  }
  .platform-big-item p {
    font-size: 16px;
  }
  .platform-big-item-blue {
    height: 425px;
    padding-bottom: 50px;
    border-radius: 30px;
  }
  .platform-big-item-blue h3 {
    text-align: center;
    text-shadow: none;
  }
  .platform-big-item-blue p {
    font-size: 16px;
  }
  .platform-big-item-blue button {
    width: 301px;
    font-size: 20px;
    padding: 18px;
  }
  .platform-partners {
    padding-top: 70px;
    margin-top: 50px;
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .platform-partners-radio-wrapper {
    width: 100%;
    max-width: 340px;
    height: 60px;
    padding: 0 0 0 8px;
  }
  .platform-partners-radio-wrapper button {
    font-size: 13px;
    width: 160px;
    height: 45px;
  }
  .radio-block {
    left: 7px;
    width: 160px;
    height: 46px;
  }
  @-webkit-keyframes changeRadioRight {
    0% {
      left: 6px;
    }
    10% {
      left: 0px;
    }
    100% {
      left: 174px;
    }
  }
  @keyframes changeRadioRight {
    0% {
      left: 6px;
    }
    10% {
      left: 0px;
    }
    100% {
      left: 174px;
    }
  }
  @-webkit-keyframes changeRadioLeft {
    0% {
      left: 174px;
    }
    10% {
      left: 180px;
    }
    100% {
      left: 6px;
    }
  }
  @keyframes changeRadioLeft {
    0% {
      left: 174px;
    }
    10% {
      left: 180px;
    }
    100% {
      left: 6px;
    }
  }
  .btns-primary-right {
    left: 174px;
  }
  .btns-primary-left {
    left: 6px;
  }
  .platform-partners section {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .platform-partners-item {
    width: 150px;
    height: 80px;
  }
  .platform-partners-item img {
    max-width: 115px;
    max-height: 37px;
  }
  .arrow-right-bottom {
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
    width: 63px;
    height: 76px;
    left: 81%;
  }
  .platform-access {
    margin-top: 55px;
    border-radius: 30px;
  }
  .platform-access h2 {
    margin-bottom: 0;
  }
  .platform-access button {
    font-size: 20px;
  }
  .address-item {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    min-height: auto;
  }
  .address-item h2 {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .address-item .social {
    margin-top: 0;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .address-item .social a {
    width: 60px;
    height: 40px;
    margin-right: 3px;
  }
  .address-item .social img {
    width: 65px;
    height: 60px;
  }
  .footer {
    text-align: center;
    margin-top: 40px;
  }
  .footer .footer-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer .highlight {
    font-size: 12px;
  }
  .footer .social.social-logo {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 500px;
    margin-top: 15px;
    gap: 15px;
    height: auto;
    width: 100%;
  }
  .footer .social.social-logo .header-wrap {
    -webkit-box-ordinal-group: -1;
    -ms-flex-order: -2;
    order: -2;
  }
  .footer .social.social-logo .social.footer-contacts {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    font-size: 12px;
    height: auto;
  }
  .footer .social.social-logo .social-footer {
    height: auto;
  }
  .footer .social.social-logo .social-footer-icons a {
    margin: 0 15px;
  }
  .footer .social-icon {
    margin: 15px;
  }
  .footer .footer-copyrights {
    max-width: 100%;
    margin: 25px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: auto;
  }
  .footer .footer-copyrights div {
    margin-bottom: 15px;
    font-size: 12px;
  }
}
.bold {
  font-weight: 600;
}

.social__logo {
  display: flex;
  align-items: center;
  @include desktop-screen {
    margin-left: 30px;
  }
  @include between-elements {
    margin-right: 20px;
    @include desktop-screen {
      margin-right: 50px;
    }
  }
}

.footer-logo-socialdata {
  margin-top: -23px;
  @include desktop-screen {
    margin-top: -35px;
  }
}

.footer-logo {
  @include desktop-screen {
    transform: scale(1.5);
  }
}

.footer-logo__text {
  max-width: 440px;
  @include desktop-screen {
    font-size: 16px;
    // margin-left: -30px;
  }
}

.footer-logo-x {
  color: #D7D7D7;
  font-size: 14px;
  @include desktop-screen {
    font-size: 16px;
  }
}

/////// COUNTRY-PARNTERS
.country-partners {
  margin-top: 150px;
  position: relative;
}

.country-partners__back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding: 48px 0px 91px;
  background: rgba(223, 242, 255, 0.16);
  border: 1px solid #EBF6FE;
  box-shadow: 0px 23px 73px -13px rgba(179, 225, 242, 0.35), inset 0px 0px 15px #FFFFFF;
  border-radius: 26px;
  text-align: center;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  @include between-elements {
    margin-bottom: 45px;
  }
}

.country-partners__logo-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include tablet-screen {
    flex-direction: row;
    align-items: flex-end;
    @include between-elements {
    margin-right: 20px;
    margin-bottom: 0px;
  }
  }
}

.country-partners__logo {
  width: 250px;
}

.country-partners__x {
  display: none;
  @include tablet-screen {
    display: inline;
    font-size: 26px;
    color: #D7D7D7;
  }
}

.country-partners__text {
  max-width: 859px;
  line-height: 170%;
  font-size: 14px;
  @include tablet-screen {
    font-size: 20px;
  }
}

.country-partners__button {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#52d7f8),
    to(#00aee8)
  );
  background: linear-gradient(to right, #52d7f8, #00aee8);
  -webkit-box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.215);
  color: white;
  border-width: 0;
  font-size: 18px;
  padding: 20px;
  height: 84px;
  border-radius: 73px;
  position: absolute;
  margin-left: auto;
  width: 320px;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -40px;
  box-shadow: 0px 17px 37px -12px rgba(66, 144, 247, 0.47);
}
</style>
