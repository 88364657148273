<template>
  <div
  class="admin-filters"
  @click="openMenu">
    <div class="admin-filters__title">
      <span class="admin-filters__text admin-filters__text_second">
        {{ $t('admin_filters') }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues"
        class="admin-filters__title-active" />
      </transition>
    </div>
    <span
    class="admin-filters__text">
      {{ getLabel }}
    </span>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      class="admin-filters__menu">
        <div class="admin-filters__content">
          <div class="admin-filters__container admin-filters__container_row">
            <TJCheckbox
            :checked.sync="onlyBadGroupsValue" />
            <span class="admin-filters__text admin-filters__text_second">
              {{ $t('hidden_channels_only') }}
            </span>
          </div>
          <div class="admin-filters__container admin-filters__container_row">
            <TJCheckbox
            :checked.sync="getIsWrapping" />
            <span class="admin-filters__text admin-filters__text_second">
              Накрученные каналы
            </span>
          </div>
          <div class="admin-filters__container admin-filters__container_row">
            <TJCheckbox
            :checked.sync="getIsBadUrl" />
            <span class="admin-filters__text admin-filters__text_second">
              С битой ссылкой
            </span>
          </div>
          <div class="admin-filters__container admin-filters__container_row">
            <TJCheckbox
            :checked.sync="postSaleBiggerThanBuyValue" />
            <span class="admin-filters__text admin-filters__text_second">
              Себестоимость выше прайса
            </span>
          </div>

          <div class="admin-filters__container">
            <span class="admin-filters__text">
              In SocialJet
            </span>
            <div class="admin-filters__switch">
              <div
              v-for="param in simpleParams"
              :key="param.value"
              class="admin-filters__switch-item"
              :class="inSocialJetValue == param.value && 'admin-filters__switch-item-active'"
              @click="changeValue('inSocialJet', 'inSocialJetValue', param.value)">
                <span>{{ param.name }}</span>
              </div>
            </div>
          </div>
          <div class="admin-filters__container">
            <span class="admin-filters__text">
              {{ $t('in_black_list') }}
            </span>
            <div class="admin-filters__switch">
              <div
              v-for="param in simpleParams"
              :key="param.value"
              class="admin-filters__switch-item"
              :class="inBlackListValue == param.value && 'admin-filters__switch-item-active'"
              @click="changeValue('inBlackList', 'inBlackListValue', param.value)">
                <span>{{ param.name }}</span>
              </div>
            </div>
          </div>
          <div class="admin-filters__container">
            <span class="admin-filters__text">
              {{ $t('blogger') }}
            </span>
            <div class="admin-filters__switch">
              <div
              v-for="param in simpleParams"
              :key="param.value"
              class="admin-filters__switch-item"
              :class="isBloggerValue == param.value && 'admin-filters__switch-item-active'"
              @click="changeValue('isBlogger', 'isBloggerValue', param.value)">
                <span>{{ param.name }}</span>
              </div>
            </div>
          </div>
          <div class="admin-filters__container">
            <span class="admin-filters__text">
              {{ $t('payment_type') }}
            </span>
            <div
            v-for="payment in paymentTypes"
            :key="payment.value"
            class="admin-filters__interval">
              <label class="admin-filters__interval">
                <input
                v-model="paymentTypeValue"
                type="radio"
                :value="payment.value"
                @change="$emit(`update:paymentType`, paymentTypeValue)">
                <span class="admin-filters__text admin-filters__text_second">
                  {{ payment.name }}
                </span>
              </label>
            </div>
          </div>
          <div class="admin-filters__container">
            <span class="admin-filters__text">
              Botname
            </span>
            <div
            v-for="bot in botnames"
            :key="bot.value"
            class="admin-filters__interval">
              <label class="admin-filters__interval">
                <input
                v-model="botNameValue"
                type="radio"
                :value="bot.value"
                class="admin-filters__radio"
                @change="$emit(`update:botName`, botNameValue)">
                <span class="admin-filters__text admin-filters__text_second">
                  {{ bot.name }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <span
        class="admin-filters__text admin-filters__text_second"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import groupsData from '@/assets/groupsData.json';

export default {
  name: 'AdminFilters',
  props: {
    inSocialJet: {
      type: [String, Boolean],
      default: null,
    },
    inBlackList: {
      type: [String, Boolean],
      default: null,
    },
    isBlogger: {
      type: [String, Boolean],
      default: null,
    },
    paymentType: {
      type: [String, Boolean],
      default: null,
    },
    botName: {
      type: [String, Boolean],
      default: null,
    },
    onlyBadGroups: {
      type: [String, Boolean],
      default: null,
    },
    postSaleBiggerThanBuy: {
      type: [String, Boolean],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    warnings: {
      type: [Number, String, Array, Boolean],
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    inSocialJetValue: null,
    inBlackListValue: null,
    isBloggerValue: null,
    paymentTypeValue: null,
    paymentTypes: [
      {
        value: null,
        name: 'Все',
      },
      {
        value: '1',
        name: 'ИП',
      },
      {
        value: '2',
        name: 'СЗ',
      },
      {
        value: '3',
        name: 'КЭШ',
      },
      {
        value: '4',
        name: 'Другое',
      },
      {
        value: '5',
        name: 'OOO',
      },
      {
        value: '7',
        name: 'Sollar',
      },
    ],
    botNames: null,
    botNameValue: null,
    selectedWarnings: null,
    warningsTypes: [
      {
        value: '7',
        name: 'С накруткой',
      },
      {
        value: '2',
        name: 'Битая ссылка',
      },
    ],
  }),
  created() {
    this.botnames = groupsData.botNames;

    this.inSocialJetValue = this.inSocialJet;
    this.inBlackListValue = this.inBlackList;
    this.isBloggerValue = this.isBlogger;
    this.botNameValue = this.botName;
    this.paymentTypeValue = this.paymentType;

    this.selectedWarnings = this.warnings === null
      ? []
      : [...this.warnings];
  },
  methods: {
    resetAll() {
      this.inSocialJetValue = null;
      this.inBlackListValue = null;
      this.isBloggerValue = null;
      this.paymentTypeValue = null;
      this.botNameValue = null;
      this.selectedWarnings = [];

      const values = ['inSocialJet', 'inBlackList', 'isBlogger', 'paymentType', 'botName', 'onlyBadGroups', 'postSaleBiggerThanBuy', 'warnings'];
      values.forEach((item) => {
        this.$emit(`update:${item}`, null);
      });
    },
    openMenu() {
      this.isOpen = true;
    },
    onClickOutside() {
      this.isOpen = false;
    },
    changeValue(key, name, value) {
      if (this[name] === value) {
        return;
      }
      this[name] = value;
      this.$emit(`update:${key}`, value);
    },
  },
  computed: {
    onlyBadGroupsValue: {
      get() {
        return this.onlyBadGroups;
      },
      set(value) {
        this.$emit('update:onlyBadGroups', value || null);
      },
    },
    postSaleBiggerThanBuyValue: {
      get() {
        return this.postSaleBiggerThanBuy;
      },
      set(value) {
        this.$emit('update:postSaleBiggerThanBuy', value || null);
      },
    },
    getIsWrapping: {
      get() {
        return this.selectedWarnings.includes('7');
      },
      set(value) {
        this.selectedWarnings = value ? ['7', ...this.selectedWarnings] : this.selectedWarnings.filter((item) => item !== '7');
        this.$emit('update:warnings', this.selectedWarnings.length ? this.selectedWarnings : null);
      },
    },
    getIsBadUrl: {
      get() {
        return this.selectedWarnings.includes('2');
      },
      set(value) {
        this.selectedWarnings = value ? ['2', ...this.selectedWarnings] : this.selectedWarnings.filter((item) => item !== '2');
        this.$emit('update:warnings', this.selectedWarnings.length ? this.selectedWarnings : null);
      },
    },
    isNotEmptyValues() {
      return [
        this.inSocialJetValue,
        this.isBloggerValue,
        this.inBlackListValue,
        this.paymentTypeValue,
        this.botNameValue,
        this.onlyBadGroupsValue,
        this.postSaleBiggerThanBuyValue,
      ].filter((item) => item !== null).length + this.selectedWarnings.length;
    },
    getLabel() {
      return this.isNotEmptyValues ? `${this.$t('selected')} (${this.isNotEmptyValues})` : this.$t('not_selected');
    },
    simpleParams() {
      return [
        { value: null, name: this.$t('all') },
        { value: 'true', name: this.$t('yes') },
        { value: 'false', name: this.$t('no') },
      ];
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.resetAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-filters {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 50px;
  position: relative;
}

.admin-filters__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.admin-filters__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.admin-filters__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
}

.admin-filters__menu {
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
}

.admin-filters__content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 480px;
  width: 380px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.admin-filters__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_row {
    flex-direction: row;
    align-items: center;
    @include between-elements {
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }
}

.admin-filters__switch {
  display: flex;
  height: 25px;
  width: 180px;
  outline: 1px solid $border-color-primary;
  @include between-elements {
    border-right: 1px solid $border-color-primary;
  }
  cursor: pointer;
  &-active {
    color: $primary-white;
    background: $primary-blue;
  };
}

.admin-filters__switch-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 12px;
  color: $primary-black;
  &-active {
    color: $primary-white;
    background: $primary-blue;
  };
}

.admin-filters__interval {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.admin-filters__radio {
  margin: 0px;
}
</style>
