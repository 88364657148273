<template>
  <div class="edit-collection">
    <span
    class="edit-collection__header">
      {{ labelForEdit }}
    </span>
    <div
    class="edit-collection__content">
      <!-- EDIT-->
      <input
      v-if="!isDelete"
      v-model.trim="nameNewCollection"
      type="text"
      class="edit-collection__input"
      :placeholder="$t('new_name')">
      <!-- DELETE -->
      <span v-if="isDelete">{{ textForDetele }}</span>
    </div>
    <button
    class="edit-collection__button"
    :class="isDisabledButton && !isDelete && 'edit-collection__button_disabled'"
    @click="saveAction">
      {{ labelForSaveButton }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'EditCollection',
  props: {
    isDelete: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    nameNewCollection: '',
  }),
  methods: {
    saveAction() {
      const { name, id } = this.getCurrentCollection;

      if (this.isDelete) {
        this.$store.dispatch('collections/deleteCompilation', id)
          .then(() => {
            this.$store.commit('collections/setShowCollectionGroups', false);
            this.$emit('close');
            this.$store.dispatch('app/setSnackBar', { text: this.$t('Compilation has been deleted', { compilation: name }) });
          })
          .catch(() => {
            this.$emit('close');
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('An error occurred when deleting the collection. Reload the page and try again'),
                error: true,
              });
          });
      } else {
        this.$store.dispatch('collections/renameCompilation', { id, name: this.nameNewCollection })
          .then(() => {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('The compilation has been renamed') });
          })
          .catch(() => {
            this.$store.dispatch('app/setSnackBar',
              {
                text: this.$t('An error occurred when changing the name of the collection. Reload the page and try again'),
                error: true,
              });
          });
      }
    },
  },
  computed: {
    isDisabledButton() {
      return this.nameNewCollection === '';
    },
    labelForEdit() {
      return this.isDelete
        ? this.$t('Deleting a compilation')
        : `${this.$t('Enter a new name for the compilation')} "${this.getCurrentCollection.name}"`;
    },
    textForDetele() {
      return `${this.$t('Are you sure you want to delete a compilation')} "${this.getCurrentCollection.name}"?`;
    },
    getCurrentCollection() {
      return this.$store.getters['collections/getCurrentCollection'];
    },
    labelForSaveButton() {
      return this.isDelete
        ? this.$t('delete')
        : this.$t('save');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-collection {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  @include between-elements {
    margin-bottom: 30px;
  }
}

.edit-collection__header {
  font-size: 22px;
  color: $primary-black;
  max-width: 400px;
}

.edit-collection__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.edit-collection__button {
  cursor: pointer;
  width: 296px;
  height: 52px;
  font-size: 16px;
  outline: none;
  border: none;
  color: $primary-white;
  background: $primary-blue;
  border-radius: 40px;
  &_disabled {
    pointer-events: none;
    background: $primary-lightgray;
  }
}

.edit-collection__input {
  min-width: 300px;
  max-width: 500px;
  padding: 7px 40px 7px 16px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  font-size: 14px;
  &:focus {
    outline: none;
  }
}
</style>
