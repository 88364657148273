<template>
  <div class="compilation-screen">
    <MainGroups
    mode="noauth"
    :groups-data="getCollectionGroups" />
  </div>
</template>

<script>
import MainGroups from '../../components/MainGroups/MainGroups.vue';

export default {
  name: 'CollectionsScreen',
  components: {
    MainGroups,
  },
  data: () => ({
  }),
  computed: {
    getCollectionGroups() {
      return this.$store.getters['collections/getCurrentCollection'].groups;
    },
  },
};
</script>

<style lang="scss" scoped>
.compilation-screen {
  display: flex;
  flex-direction: column;
  max-width: 1805px;
  margin: 0px auto;
}
</style>
