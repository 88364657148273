<template>
  <div class="clients">
    <div
    v-if="getClientsData.length && !isLoading"
    class="clients-wrapper">
      <div class="clients__actions">
        <span class="clients__text">Выбрать все</span>
        <TJIcon
        name="settings"
        class="clients__text" />
      </div>
      <div
      ref="clientsContent"
      class="clients__content">
        <div class="clients__elements">
          <ClientsHeaders />
          <ClientElement
          v-for="client in getClientsData"
          ref="clientElement"
          :key="client.id"
          :client="client"
          @openModal="openModal"
          @closeAllElements="closeAllElements" />
        </div>
      </div>
    </div>
    <span
    v-else
    class="clients__text clients__text_light">
      {{ isLoading ? 'Загрузка списка клиентов' : 'Список клиентов пуст' }}
    </span>
    <TJ-Modal
    :show-modal="showModal"
    @close="closeModal">
      <template #modal-content>
        <AddClientModal
        :client="currentClient"
        :type="typeModal"
        @close="closeModal" />
      </template>
    </TJ-Modal>
  </div>
</template>

<script>
import ClientsHeaders from './components/ClientsHeaders.vue';
import ClientElement from './components/ClientElement.vue';
import AddClientModal from '../AddClientModal.vue';

export default {
  name: 'Clients',
  components: { ClientsHeaders, ClientElement, AddClientModal },
  data: () => ({
    isLoading: false,
    showModal: false,
    typeModal: null,
    currentClient: null,
  }),
  created() {
    this.isLoading = true;
    this.loadClientsData();
  },
  methods: {
    closeAllElements() {
      // закрыть все подмню во всех клиентах
      this.$refs.clientElement.forEach((item) => { item.closeUsers(); });
    },
    openModal(props) {
      this.typeModal = props.type;
      this.currentClient = props.client;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.typeModal = null;
      this.currentClient = null;
    },
    loadClientsData() {
      this.$store.dispatch('clients/getClientsData').finally(() => { (this.isLoading = false); });
    },
  },
  computed: {
    getClientsData() {
      return this.$store.getters['clients/getClientsData'];
    },
  },

};
</script>

<style lang="scss" scoped>
.clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow: auto;
  background: white;
  border-radius: 12px;
}

.clients-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: auto;
  background: white;
  border-radius: 12px;
}

.clients__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.clients__content {
  overflow: auto;
  height: 100%;
}

.clients__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.clients__text {
  font-size: 14px;
  color: #575757;
  &_light {
    color: $primary-lightgray;
  }
}
</style>
