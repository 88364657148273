<template>
  <div
  class="group-name">
    <a
    :href="getGroupLink()"
    target="_blank"
    class="group-name__avatar-block pointer">
      <img
      class="group-name__avatar"
      :class="[
        !hideDetails && 'group-name__avatar_full',
        isBannedGroup() || getIsWarning() ? 'group-name__avatar_banned': '',
      ]"
      :src="group.avatar ? group.avatar : require('@/assets/svg/empty-avatar.svg')">
      <img
      class="group-name__social-icon"
      :class="!hideDetails && 'group-name__social-icon_full'"
      :src="require(`@/assets/svg/groups-icons/${group.social}-icon.svg`)">
    </a>
    <div
    class="group-name__block"
    :class="!hideDetails && 'group-name__block_full'">
      <a
      :href="getGroupLink()"
      target="_blank"
      :title="group.name"
      class="group-name__text group-name__text_name"
      :class="!hideDetails && 'group-name__text_full'">
        {{ group.name }}
      </a>
      <span
      v-if="group.categories"
      :title="getCategories()"
      class="group-name__text group-name__text_category"
      :class="!hideDetails && 'group-name__text_full'">
        {{ getCategories() }}
      </span>
      <span
      v-if="!hideDetails && group.description && group.description.length"
      class="group-name__text group-name__text_desc"
      :class="!hideDetails && 'group-name__text_full'"
      :title="group.description"
      @click="routeToCurrentGroupLink">
        {{ group.description }}
      </span>
      <div
      class="group-name__icons-block"
      :class="!hideDetails && 'group-name__icons-block_full'">
        <img
        v-if="isBannedGroup()"
        v-title="getBannedTitle()"
        class="group-name__icon"
        :src="require(`@/assets/svg/icons/ban-icon.svg`)"
        alt="ban">

        <img
        v-if="getIsWarning()"
        v-title="getIsWarning()"
        class="group-name__icon"
        :src="require(`@/assets/svg/icons/warn-icon-red.svg`)"
        alt="ban">

        <img
        v-if="getAllWarnings()"
        v-title="getAllWarnings()"
        class="group-name__icon"
        :src="require(`@/assets/svg/icons/warn-icon.svg`)"
        alt="warnings">

        <div
        v-if="isErrorEr()"
        v-title="`${$t('Suspicious ER, possible cheating')}`"
        class="group-name__icon-block group-name__icon-block_warning flex-center">
          <span
          class="group-name__text_er">ER!</span>
        </div>
      </div>
      <img
      class="group-name__link-icon pointer"
      :class="hover && 'group-name__link-icon_show'"
      src="@/assets/svg/icons/link-icon.svg"
      @click="routeToCurrentGroupLink">
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupName',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  methods: {
    routeToCurrentGroupLink() {
      window.open(this.group.url);
    },
    routeToCurrentGroup() { // deprecated, удалить если не требуется
      this.$store.commit('group/setCurrentGroup', this.group);
      this.$router.push({ name: 'group', params: { social: this.group.social, id: this.group.id } });
    },
    getGroupLink() {
      return `https://data.socialjet.pro/group/${this.group.social}/${this.group.id}`;
    },
    getCategories() {
      return this.group.categories.map((category) => this.$t(category.name)).join(', ');
    },
    isBannedGroup() {
      return this.group.status === 3;
    },
    getBannedTitle() {
      return this.getBanReasons()
        ? `${this.$t('The channel is banned')}. ${this.$t('Cause')}: ${this.getBanReasons()}`
        : this.$t('The channel is banned');
    },
    getAllWarnings() {
      // Ворнинги строкой через запятую
      if (!this.group?.inspectReport?.warnings.length) {
        return false;
      }
      // NEED TRANSLATE
      // необходимо удалить из ворнингов ER и накрутку, так как их выводим в других иконках
      const warnings = this.group.inspectReport?.warnings.filter((item) => item.code !== 5 && item.code !== 7).map((item) => item.message).join(', ');
      return warnings;
    },
    getIsWarning() {
      // проверка на наличие в warnings элементов связанных с накруткой тгстат, телеметр
      const isWarning = this.group.inspectReport?.warnings?.find((item) => item.code === 7);
      return isWarning ? 'Канал заподозрен в накрутке' : false;
    },
    isErrorEr() {
      const badEr = this.group.er && this.group.er > 40;
      const warningEr = this.group.inspectReport?.warnings?.find((item) => item.code === 5);
      return warningEr || badEr;
    },
    getBanReasons() {
      // Cheatings + причины бана строкой через запятую
      if (!this.group?.inspectReport?.cheatings.length && !this.group.badReason) {
        return false;
      }
      const cheatings = this.group.inspectReport?.cheatings.map((item) => item.message).slice(0, 2) || [];
      if (this.group.badReason && this.group.badReason.length) {
        const badreason = this.group.badReason.split(', ');
        cheatings.unshift(...badreason);
      }
      const reasons = new Set(cheatings);
      return Array.from(reasons).join(',');
    },
  },
};
</script>

<style lang="scss" scoped>
.group-name {
  display: flex;
  align-items: center;
  width: 150px;
  height: 69px;
  z-index: 10;
  @include tablet-screen {
    margin-left: 0px;
    width: 422px;
  }
  &_full {
    height: 124px;
  }
  &:hover {
    .group-name__link-icon {
      opacity: 1;
    }
  }
}

.group-name__avatar-block {
  position: relative;
}

.group-name__block {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
  overflow: hidden;
  text-decoration: none;
  @include between-elements {
    margin-bottom: 2px;
  }
  &_full {
    @include between-elements {
      margin-bottom: 5px;
    }
  }
  @include tablet-screen {
    width: 100%;
    margin-left: 0px;
  }
}

.group-name__avatar {
  display: none;
  @include tablet-screen {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0px 22px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 50%;
    transition: 0.2s;
  }
  &_full {
    margin: 0px 17px;
    width: 50px;
    height: 50px;
    transition: 0.2s;
  }
  &_banned {
    border: 2px solid $primary-red;
  }
}

.group-name__social-icon {
  display: none;
  @include tablet-screen {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -4px;
    left: 45px;
    border-radius: 50%;
    box-shadow: -3px 3px 7px -1px rgb(46 50 50 / 50%);
    transition: 0.2s;
  }
  &_full {
    width: 20px;
    height: 20px;
    top: -5px;
    left: 50px;
    transition: 0.2s;
  }
}

.group-name__text {
  font-size: 14px;
  color: $primary-gray;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 300px;
  padding-right: 30px;
  &_full {
    -webkit-line-clamp: 2;
  }
  &_name {
    color: $primary-blue;
    text-decoration: none;
  }
  &_desc {
    font-size: 12px;
    color: $primary-gray;
  }
  &_category {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_er {
    font-size: 9px;
    color: $primary-white;
  }
}

.group-name__icons-block {
  display: none;
  @include tablet-screen {
    display: flex;
    align-items: center;
    z-index: 2;
    @include between-elements {
      margin-right: 8px;
    }
  }
}

.group-name__icon-block {
  width: 20px;
  height: 15px;
  border-radius: 5px;
  background: $primary-lightred;
  &_warning {
    border-radius: 3px;
    width: 25px;
    height: 18px;
    background: $primary-yellow;
  }
}

.group-name__icon {
  width: 18px;
  height: 18px;
}

.group-name__link-icon {
  display: none;
  @include tablet-screen {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: 0.3s;
  }
}
</style>
