<template>
  <div class="group-comment">
    <span
    v-title="!isEmptyData && comment"
    :class="isAdmin && 'pointer'"
    class="group-comment__text group-comment__text_light"
    @click="openEditor">
      {{ isEmptyData ? '—' : comment }}
    </span>
    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-comment__editor">
        <div class="group-comment__editor-element">
          <textarea
          v-model="commentValue"
          :placeholder="$t('Enter a comment')"
          class="group-comment__input" />
        </div>
        <TJButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupComment',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    comment: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showEditor: false,
    commentValue: null,
  }),
  methods: {
    openEditor() {
      if (!this.isAdmin) {
        return;
      }
      this.commentValue = this.comment;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.commentValue = null;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        comment: this.commentValue === '' ? null : this.commentValue,
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.comment = this.commentValue === '' ? null : this.commentValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    isEmptyData() {
      return this.comment === null || this.comment === '';
    },
  },
};
</script>

<style lang="scss" scoped>
.group-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  min-height: 50px;
  position: relative;
}

.group-comment__text {
  font-size: 14px;
  padding: 0px 10px;
  color: $primary-gray;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-comment__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.group-comment__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.group-comment__input {
  padding: 5px 10px;
  min-height: 150px;
  border: 1px solid $primary-lightgray;
  border-radius: 8px;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

</style>
