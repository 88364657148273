<template>
  <div class="group-header">
    <div class="group-header__group">
      <div class="group-header__about">
        <img
        :src="group.avatar ? group.avatar : require('@/assets/svg/empty-avatar.svg')"
        class="group-header__avatar">
        <div class="group-header__name-block">
          <div class="group-header__name">
            <p class="group-header__text group-header__text_title">
              {{ group.name }}
            </p>
            <div class="group-header__rating-block">
              <div
              class="group-header__link pointer"
              @click="routeToCurrentGroupLink">
                <p class="group-header__text group-header__text_link">
                  {{ group.url }}
                </p>
              </div>
              <div
              v-title="$t('rating_sji_info')"
              class="group-header__rating"
              :class="getContentScoreClass() && ['group-header__rating_' + getContentScoreClass()]">
                <span
                class="group-header__text group-header__text_rating"
                :class="getContentScoreClass() && ['group-header__text_' + etContentScoreClass()]">
                  {{ getContentScoreLabel() }}
                </span>
              </div>
            </div>
          </div>
          <span>
            <span
            ref="description"
            class="group-header__text group-header__text_desc"
            :class="showFullDesc && 'group-header__text_desc_full'"
            @click="showFullDesc = !showFullDesc">
              {{ group.description || 'Описание отсутствует' }}
            </span>
            <span
            v-if="showMoreButton"
            class="group-header__text group-header__text_more"
            @click="showFullDesc = !showFullDesc">
              {{ showFullDesc ? 'Свернуть' : 'Показать полностью' }}
            </span>
          </span>
        </div>
      </div>
      <GroupInfo :group="group" />
    </div>
    <GroupActionsBar :group="group" />
  </div>
</template>

<script>
import GroupInfo from './components/GroupInfo.vue';
import GroupActionsBar from './components/GroupActionsBar.vue';

export default {
  name: 'GroupHeader',
  components: { GroupInfo, GroupActionsBar },
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showFullDesc: false,
    showMoreButton: false,
    cropDescription: null,
  }),
  mounted() {
    this.showMoreButton = this.group.description.length > 250 || (this.group.description.length > 80 && window.innerWidth < 480);
  },
  methods: {
    routeToCurrentGroupLink() {
      if (!this.group.url) return;
      window.open(this.group.url);
    },
    getContentScoreLabel() {
      const score = this.group.contentAnalysis?.cleanContentScore;
      return score ? parseFloat(score).toFixed(1) : '—';
    },
    isEmptyRating() {
      return this.getContentScoreLabel === '—';
    },
    getContentScoreClass() {
      if (this.isEmptyRating) return false;
      const score = this.group.contentAnalysis?.cleanContentScore;
      if (score <= 4) {
        return 'red';
      }
      if (score <= 7) {
        return 'yellow';
      }
      return 'green';
    },
  },
};
</script>

<style lang="scss" scoped>
.group-header {
  width: 100%;
  background: $primary-white;
  border-radius: 20px;
  padding: 20px;
  @include tablet-screen {
    padding: 40px;
  }
}

.group-header__group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #D8F2FA;
  padding-bottom: 40px;
  @include max-screen {
    padding-bottom: 58px;
    justify-content: space-between;
    flex-direction: row;
  }
}

.group-header__about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include desktop-screen {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    max-width: 100%;
  }
}

.group-header__avatar {
  width: 220px;
  height: 220px;
  border-radius: 100%;
  margin-bottom: 20px;
  @include tablet-screen {
    margin-right: 40px;
    margin-bottom: 0px;
  }
}

.group-header__name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @include desktop-screen {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (min-width: 1500px) {
    flex-wrap: nowrap;
  }
}

.group-header__rating-block {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
  @include between-elements {
    margin-right: 10px;
  }
  @include desktop-screen {
    margin-left: 20px;
  }
}

.group-header__link {
  height: 40px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #66CEF1;
  border-radius: 33px;
  width: 40vw;
  @include desktop-screen {
    max-width: 200px;
  }
}

.group-header__name-block {
  width: 100%;
  @include desktop-screen {
    max-width: 700px;
    text-align: left;
  }
}

.group-header__rating {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 10px;
  border: 2px solid #6AB16F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  &_red {
    border: 2px solid $primary-red;
  }
  &_yellow {
    border: 2px solid $primary-yellow;
  }
  &_green {
    border: 2px solid #6AB16F;
  }
}

.group-header__text {
  font-size: 14px;
  &_title {
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    margin-top: 10px;
    max-width: 450px;
  }
  &_link {
    color: $primary-darkblue;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &_rating {
    color: #6AB16F;
    font-weight: 600;
  }
  &_desc {
    cursor: default;
    line-height: 170%;
    font-size: 16px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &_full {
      display: block;
      -webkit-line-clamp: none;
    }
  }
  &_more {
    color: $primary-blue;
    cursor: pointer;
  }
  &_red {
    color: $primary-red;
  }
  &_yellow {
    color: $primary-yellow;
  }
  &_green {
    color: #6AB16F;
  }
}
</style>
