<template>
  <div
  class="favorites pointer"
  :class="isShowFavoriteGroups && 'favorites_show-favorites'"
  @click="toggleFavorites">
    <span
    class="favorites__text"
    :class="isShowFavoriteGroups && 'favorites__text_show-favorites'">
      {{ $t('favourites') }}
    </span>
    <span
    class="favorites__text favorites__text_light"
    :class="isShowFavoriteGroups && 'favorites__text_show-favorites'">
      {{ getFavorites.length }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'Favorites',
  props: {
    isShowFavoriteGroups: {
      type: Boolean,
      default: false,
    },
    isShowCollectionGroups: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // FUTURE Позже убрать удаление старых избранных
    localStorage.removeItem('favoriteGroups');
    this.$store.dispatch('collections/getUserFavoritesList');
  },
  methods: {
    toggleFavorites() {
      if (this.isShowFavoriteGroups) {
        this.$store.commit('collections/setShowFavoriteGroups', false);
        return;
      }
      if (this.isShowCollectionGroups) {
        this.$store.commit('collections/setShowCollectionGroups', false);
      }
      this.$store.commit('collections/setShowFavoriteGroups', true);
    },
  },
  computed: {
    getFavorites() {
      return this.$store.getters['collections/getFavoriteGroupsData'];
    },
  },
};
</script>

<style lang="scss" scoped>
.favorites {
  width: 130px;
  height: 30px;
  border: 1px solid $border-color-primary;
  box-sizing: border-box;
  border-radius: 6px;
  background: $primary-white;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_show-favorites {
    background: $primary-blue;
  }
}

.favorites__text {
  font-size: 13px;
  color: $primary-gray;
  &_light {
    color: $primary-lightgray;
  }
  &_show-favorites {
    color: $primary-white;
  }
}

</style>
