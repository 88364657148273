import Vue from 'vue';

// UI
import TJIcon from '../components/UI/TJ-Icon.vue';
import TJCheckbox from '../components/UI/TJ-Checkbox.vue';
import TJButton from '../components/UI/TJ-Button.vue';
import TJModal from '../components/UI/TJ-Modal.vue';
import TJSnackbar from '../components/UI/TJ-Snackbar.vue';
import DataRangeSlider from '../components/UI/DataRangeSlider.vue';
import DataCheckbox from '../components/UI/DataCheckbox.vue';
import DataRadio from '../components/UI/DataRadio.vue';

// Components
import MainHeader from '../components/MainHeader/MainHeader.vue';
import AppLoader from '../components/AppLoader.vue';
// Layouts

// use
const appComponents = () => {
  Vue.component('TJIcon', TJIcon);
  Vue.component('TJCheckbox', TJCheckbox);
  Vue.component('TJButton', TJButton);
  Vue.component('TJModal', TJModal);
  Vue.component('TJSnackbar', TJSnackbar);
  Vue.component('DataRangeSlider', DataRangeSlider);
  Vue.component('DataCheckbox', DataCheckbox);
  Vue.component('DataRadio', DataRadio);
  Vue.component('MainHeader', MainHeader);
  Vue.component('AppLoader', AppLoader);
};

export default appComponents;
