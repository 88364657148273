<template>
  <div class="groups-header">
    <div class="groups-header__checkbox">
      <DataCheckbox
      v-model="allGroupsChecked" />
    </div>
    <div
    v-for="header in getHeadersList"
    :key="header.id"
    :style="{ order: getOrder(header.value) }"
    class="groups-header__element"
    :class="header.value === 'name' && 'groups-header__element_name'">
      <div
      v-if="Array.isArray(header.value)"
      class="groups-header__element_double">
        <div
        class="groups-header__element_double"
        @click="changeSort(header.sort[0])">
          <span
          v-title="!header.sort ? `${$t('Sorting by this field is not available')}` : ''"
          class="groups-header__text"
          :class="[
            header.sort[0] === getGroupsSortBy && 'groups-header__text_selected',
            header.sort[0] && 'pointer'
          ]">
            {{ $t(header.value[0]) }}
          </span>
          <TJIcon
          :size="12"
          name="south"
          :arrow-open="getGroupsSortDir === 'asc' && header.sort[0] === getGroupsSortBy"
          class="groups-header__icon"
          :class="[
            header.sort[0] === getGroupsSortBy && 'groups-header__icon_selected',
            header.sort[0] && 'pointer'
          ]" />
        </div>
        <span class="groups-header__text">/</span>
        <div
        class="groups-header__element_double"
        @click="changeSort(header.sort[1])">
          <span
          v-title="!header.sort ? `${$t('Sorting by this field is not available')}` : ''"
          class="groups-header__text"
          :class="[
            header.sort[1] === getGroupsSortBy && 'groups-header__text_selected',
            header.sort[1] && 'pointer'
          ]">
            {{ $t(header.value[1]) }}
          </span>
          <TJIcon
          :size="12"
          name="south"
          :arrow-open="getGroupsSortDir === 'asc' && header.sort[1] === getGroupsSortBy"
          class="groups-header__icon"
          :class="[
            header.sort[1] === getGroupsSortBy && 'groups-header__icon_selected',
            header.sort[1] && 'pointer'
          ]" />
        </div>
      </div>

      <div
      v-else
      class="groups-header__element_double"
      @click="changeSort(header.sort)">
        <span
        v-title="!header.sort ? `${$t('Sorting by this field is not available')}` : ''"
        class="groups-header__text"
        :class="[
          header.sort === getGroupsSortBy && 'groups-header__text_selected',
          header.sort && 'pointer'
        ]">
          {{ $t(header.value) }}
        </span>
        <TJIcon
        v-if="header.info"
        v-title="`${$t(header.info)}`"
        :size="14"
        name="info_outline"
        class="groups-header__text"
        :class="header.sort === getGroupsSortBy && 'groups-header__text_selected'" />
        <TJIcon
        :size="12"
        name="south"
        :arrow-open="getGroupsSortDir === 'asc' && header.sort === getGroupsSortBy"
        class="groups-header__icon"
        :class="[
          header.sort === getGroupsSortBy && 'groups-header__icon_selected',
          header.sort && 'pointer'
        ]" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupsHeaders',
  props: {
    selectedType: {
      type: String,
      default: null,
    },
    groupsData: {
      type: Array,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    isHiddenMode: {
      type: [Boolean, String],
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    selectedGroups: {
      type: Array,
      default: null,
    },
    columns: {
      type: Array,
      default: null,
    },
  },
  methods: {
    changeSort(value) {
      if (!value) {
        return;
      }
      if (this.isShowCollectionGroups) {
        if (this.getGroupsSortBy === value) {
          const newDir = this.getGroupsSortDir === 'asc' ? 'desc' : 'asc';
          this.$store.commit('collections/setSortDir', newDir);
        } else {
          this.$store.commit('collections/setSortDir', 'desc');
        }

        this.$store.commit('collections/setSortBy', value);

        this.$store.dispatch('collections/getCurrentCompilation',
          {
            isNew: true,
            id: this.getCurrentCollection.id,
            name: this.getCurrentCollection.name,
            noauth: this.mode === 'noauth',
            isLoaded: this.getCurrentCollection.isLoaded,
            shareToken: this.getCurrentCollection.shareToken,
          }).then(() => this.$emit('scrollToTop'));
        return;
      }
      if (this.getGroupsSortBy === value) {
        const newDir = this.getGroupsSortDir === 'asc' ? 'desc' : 'asc';
        this.$store.commit('groups/setSortDir', newDir);
      } else {
        this.$store.commit('groups/setSortDir', 'desc');
      }

      this.$store.commit('groups/setSortBy', value);

      this.$store.commit('groups/setLoadPage', 1);
      this.$store.commit('groups/setGroupsData', []);
      this.$store.dispatch('groups/loadGroupsData');
    },
    getOrder(element) {
      return this.columns.findIndex((item) => item.value === element);
    },
  },
  computed: {
    allGroupsChecked: {
      get() {
        return this.isAllSelected;
      },
      set(value) {
        if (!value) {
          // если нажимаем сбросить, то удаляем из выбранных только текущие каналы на странице
          this.$store.commit('collections/setSelectedAllGroups', value);
          const groupsToUnselected = this.selectedGroups.filter((item) => !this.groupsData.some((el) => el.id === item.id && el.name === item.name));
          this.$store.commit('collections/setSelectedGroups', groupsToUnselected);
        } else {
          // если нажимаем выбрать все, то добавляем в выбранные каналы со страницы, за исключением тех, которые уже есть в выбранных
          const groupsToSelected = this.groupsData.filter((item) => !this.selectedGroups.some((el) => el.id === item.id && el.name === item.name));
          this.$store.commit('collections/setSelectedGroups', [...this.selectedGroups, ...groupsToSelected]);
        }
      },
    },
    isAllSelected() {
      return this.groupsData.every((item) => this.selectedGroups.some((el) => el.name === item.name));
    },
    getHeadersList() {
      const list = this.isAdmin ? this.columns.filter((header) => header.show) : this.columns.filter((header) => !header.admin && header.show);
      const filteredList = this.isShowCollectionGroups ? list : list.filter((item) => item.value !== 'margin');
      return !this.isHiddenMode ? filteredList.filter((item) => item.value !== 'bad_reason') : filteredList;
    },
    getGroupsSortBy() {
      return this.isShowCollectionGroups
        ? this.$store.getters['collections/getGroupsSortBy']
        : this.$store.getters['groups/getGroupsSortBy'];
    },
    getGroupsSortDir() {
      return this.isShowCollectionGroups
        ? this.$store.getters['collections/getGroupsSortDir']
        : this.$store.getters['groups/getGroupsSortDir'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
    getCurrentCollection() {
      return this.$store.getters['collections/getCurrentCollection'];
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
  background: white;
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-top: 1px solid $primary-group-border;
  border-bottom: 1px solid $primary-group-border;
  min-width: 100%;
}

.groups-header__checkbox {
  position: sticky;
  background: white;
  top: 0px;
  left: 0px;
  padding-left: 20px;
  z-index: 2;
  height: 20px;
  width: 40px;
  @include tablet-screen {
    width: 124px;
  }
}

.groups-header__elements {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.groups-header__element_double {
  display: flex;
  align-items: center;
  justify-content: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.groups-header__element {
  width: 180px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  &_name {
    position: sticky;
    left: 40px;
    width: 160px;
    padding-left: 10px;
    justify-content: flex-start;
    top: 0px;
    text-align: left;
    z-index: 1;
    background: white;
    @include tablet-screen {
      left: 124px;
      padding-left: 0px;
      width: 295px;
    }
  }
}

.groups-header__text {
  color: $primary-lightgray;
  font-size: 13px;
  &_selected {
    color: $primary-gray;
    font-weight: 600;
  }
}

.groups-header__icon {
  color: $primary-lightgray;
  &_selected {
    color: $primary-gray;
  }
}

</style>
