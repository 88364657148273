<template>
  <div class="tariff-mini-burn">
    <div class="tariff-mini-burn__content">
      <div class="tariff-mini-burn__container">
        <div class="tariff-mini-burn__ok-icon" />
        <span class="tariff-mini-burn__text">Commit</span>
      </div>
      <div class="tariff-mini-burn__container">
        <span class="tariff-mini-burn__text">Доступ до 01.12.22</span>
      </div>
      <div
      class="tariff-mini-burn__container tariff-mini-burn__container_noborder"
      style="text-align: flex-end">
        <span class="tariff-mini-burn__text">Комиссия</span>
        <span class="tariff-mini-burn__text tariff-mini-burn__text_percent">25%</span>
      </div>
    </div>
    <TJIcon
    name="chevron_right"
    class="tariff-mini-burn__icon"
    @click.native="changeTariffBar" />
  </div>
</template>

<script>
export default {
  name: 'TariffMiniBurn',
  methods: {
    changeTariffBar() {
      this.$emit('setCurrentComponent', 'full');
      this.$store.commit('app/setShowFullTariffRate', true);
      // this.$router.push({ name: 'tariffs' });
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-mini-burn {
  position: absolute;
  top: 60px;
  right: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px 10px;
  background: white;
  width: 460px;
  border-radius: 10px;
  border: 1px solid #D8F2FA;
}

.tariff-mini-burn__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 8px;
  }
  &:after {
    content: '';
    height: 19px;
    margin: 0px 15px;
    width: 1px;
    background: #DBDBDB;
  }
  &_noborder {
    &:after {
      margin: 0px;
      width: 0px;
    }
  }
}

.tariff-mini-burn__text {
  font-size: 14px;
  line-height: 16px;
  color: #7B7B7B;
  &_percent {
    color: #6AB16F;
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;
  }
}

.tariff-mini-burn__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tariff-mini-burn__ok-icon {
  width: 20px;
  height: 20px;
  background: url('../assets/check-ok.svg');
}

.tariff-mini-burn__icon {
  color: #008FBF;
  cursor: pointer;
}
</style>
