import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    groupsForModerate: [],
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalGroups: null,
    totalPageCounts: null,
  },
  getters: {
    getGroupsForModerate: (state) => state.groupsForModerate,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getTotalGroups: (state) => state.totalGroups,
    getCurrentPage: (state) => state.loadParams.page,
    getIsLastPage: (state) => +state.loadParams.page === +state.totalPageCounts + 1,
  },
  mutations: {
    setGroupsForModerate: (state, value) => { state.groupsForModerate = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setTotalGroups: (state, value) => { state.totalGroups = value; },
  },
  actions: {
    getModerateGroups({ commit, state }, params) {
      if (params.reset) {
        commit('setGroupsForModerate', []);
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }

      return api.get('/moderate', { params: { status: params.status, ...state.loadParams } })
        .then((response) => {
          const { data } = response.data;

          if (params.reset) {
            commit('setGroupsForModerate', data);
          } else {
            commit('setGroupsForModerate', [...state.groupsForModerate, ...data]);
          }

          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalGroups', response.headers['X-Pagination-Total-Count']);

          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- getModerateGroups; Error -', error);
          return Promise.reject(error);
        });
    },
    setModerateGroupApprove({ commit }, { id, value }) {
      return api.patch(`/moderate/${value ? 'apply' : 'reject'}/${id}`)
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('setGroupIsLoading', false);
          console.warn('Error in action -- setModerateGroupApprove --; Error -', error);
          return Promise.reject(error);
        });
    },
  },
};
