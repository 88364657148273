<template>
  <div class="social-switcher">
    <div
    v-for="social in getTypesOfGroups"
    :key="social.value"
    :class="social.value === selectedType && 'social-switcher__item_active'"
    class="social-switcher__item"
    @click="changeSelectedTab(social.value)">
      <img
      class="social-switcher__social-icon"
      :class="social.value === selectedType && 'social-switcher__social-icon_active'"
      :src="require(`@/assets/svg/groups-icons/${social.value}-icon.svg`)">
      <span
      :class="social.value === selectedType && 'social-switcher__social-title_active'"
      class="social-switcher__social-title">
        {{ $t(social.value) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialSwitcher',
  props: {
    selectedType: {
      type: [String, Number],
      default: null,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeSelectedTab(value) {
      if (this.selectedType === value) return;
      if (this.disable) return;

      if (this.isShowFavoriteGroups || this.isShowCollectionGroups) {
        this.$store.commit('collections/setShowCollectionGroups', false);
      }
      this.$store.commit('collections/resetAllShow');
      this.$store.commit('groups/setSelectedTypeOfGroups', value);

      const query = { group: value };
      this.$router.push({ path: this.$router.currentPath, query });
      this.$store.dispatch('groups/loadGroupsData', { reset: true, aggregate: true });
    },
  },
  computed: {
    getTypesOfGroups() {
      return this.$store.getters['groups/getTypesOfGroups'];
    },
    isShowFavoriteGroups() {
      return this.$store.getters['collections/getShowFavoriteGroups'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>

.social-switcher {
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: -6px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    padding: 0;
  }
}

.social-switcher__item {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0px 20px 6px 20px;
  transition: 0.1s;
  cursor: pointer;
  &_active {
    background: $primary-white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
}

.social-switcher__social-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  &_active {
    width: 26px;
    height: 26px;
  }
}

.social-switcher__social-title {
  color: #575757;
  font-size: 14px;
  transition: 0.1s;
  &_active {
    color: $primary-black;
    font-weight: 500;
    font-size: 16px;
  }
}

</style>
