<template>
  <div class="header flex-center">
    <div class="header__container">
      <div class="header__info">
        <DataBurgerButton
        :is-open="getOpenSidebar"
        class="header__sidebar-button"
        @handler="toggleSideBar" />
        <div @click.prevent="goToMain">
          <a href="https://data.socialjet.pro">
            <transition
            name="fade"
            mode="out-in">
              <img
              v-if="getIsBannerCountries"
              :key="getIsBannerCountries"
              class="header__logo pointer"
              :class="getIsBannerCountries && 'header__logo_mediagate'"
              :src="require('@/assets/logos/social-data-mediagate-logo.png')">
              <img
              v-else
              :key="getIsBannerCountries"
              class="header__logo pointer"
              :src="require('@/assets/logos/social-data-logo.svg')">
            </transition>
          </a>
        </div>
        <div
        v-if="showSearchBlock"
        class="header__search">
          <img
          class="header__search-icon"
          :src="require('@/assets/svg/search-icon.svg')"
          @click="showSearch = !showSearch">
          <input
          v-if="showSearch || isDesktop"
          v-model="nameOrUrl"
          class="header__search-input"
          :placeholder="$t('search_any_channels')">
          <transition name="fade">
            <TJIcon
            v-if="nameOrUrl"
            name="close"
            class="header__reset-icon"
            :size="14"
            pointer
            @click.native="setSearch(null)" />
          </transition>
        </div>
        <div
        v-if="getNewGroupsCount"
        class="header__groups-stats">
          <span class="header__text">
            {{ $t('new_channels') }}
          </span>
          <transition
          name="fade"
          :duration="100"
          mode="out-in">
            <div
            :key="switchStats"
            class="header__groups-stats header__groups-stats_count pointer"
            @click="switchStats = !switchStats">
              <span
              class="header__text header__text_gray pointer">
                {{ getNewGroupsCount }}
              </span>
              <TJIcon
              name="expand_more"
              pointer
              :size="18" />
            </div>
          </transition>
        </div>
      </div>
      <div class="header__info">
        <div
        v-if="mode !== 'noauth' && showSendButton"
        class="header__send-button"
        @click="openSendCompilationModal">
          <span class="header__text header__text_stats">
            {{ `+ ${$t('send_a_request2')}` }}
          </span>
        </div>
        <div
        v-if="isDesktop"
        class="header__line" />
        <LangSelector
        v-if="mode !== 'noauth'"
        class="header__lang" />
        <div
        v-if="isDesktop && mode !== 'noauth'"
        class="header__line" />
        <Profile
        v-if="mode !== 'noauth'"
        :user-info="getUserInfo"
        @goToMain="goToMain" />
        <span
        v-if="mode === 'noauth'"
        class="header__text header__text_noauth pointer"
        @click="showFormModal = true">{{ $t('Get access') }}</span>
      </div>
      <TJModal
      v-if="mode === 'noauth'"
      :show-modal="showFormModal"
      @close="showFormModal = false">
        <template #modal-content>
          <LandingForm @close="showFormModal = false" />
        </template>
      </TJModal>
    </div>
    <transition
    name="fade"
    mode="out-in">
      <Banner
      v-if="isShowBanner"
      class="header__banner"
      @close="closeBanner" />
    </transition>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy';
import debounce from 'lodash/debounce';
import LandingForm from '@/components/MainLanding/LandingForm.vue';
import DataBurgerButton from '@/components/UI/DataBurgerButton.vue';
import Profile from './components/Profile.vue';
import LangSelector from './components/LangSelector.vue';
import Banner from './components/Banner.vue';

export default {
  name: 'MainHeader',
  components: {
    Profile, LangSelector, LandingForm, Banner, DataBurgerButton,
  },
  props: {
    mode: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    switchStats: false,
    showSearch: false,
    showLogo: true,
    searchInput: null,
    debounceUpdate: null,
    showFormModal: false,
    showBanner: false,
  }),
  created() {
    if (this.isDesktop) {
      this.showSearch = false;
    }
    if (this.mode !== 'noauth') {
      this.$store.dispatch('groups/getTotalNewGroups');
    }
    this.searchInput = this.getSearchParams.nameOrUrl;
    this.debounceUpdate = debounce((value) => {
      this.setSearch(value);
    }, 600);
    this.showBanner = localStorage.getItem('showCountryBanner') || true;
  },
  methods: {
    setSearch(value) {
      const params = pickBy({ ...this.getSearchParams, nameOrUrl: value }, (param) => param !== null);
      const query = { group: this.getSelectedTypeOfGroups, ...params };
      this.$router.push({ name: 'main-groups', query });
      this.$store.commit('collections/resetAllShow');
      this.$store.commit('groups/setLoadPage', 1);
      this.$store.commit('groups/setGroupsSearchParams', { ...this.getSearchParams, nameOrUrl: value });
      this.$store.commit('groups/setGroupsData', []);
      this.$store.dispatch('groups/loadGroupsData', { aggregate: true });
    },
    goToMain() {
      this.$store.commit('collections/resetAllShow');
      this.$store.commit('collections/setShowCollectionGroups', false);
      this.$store.commit('groups/setInitial', true);
      this.$store.commit('groups/resetGroupsSearchParams');
      this.$store.commit('groups/setUserIdForGroups', null);

      if (this.$route.name !== 'main-groups') {
        this.$router.push({ name: 'main-groups' });
      }
    },
    openSendCompilationModal() {
      const modalParams = { name: 'SendCollection' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    closeBanner() {
      this.showBanner = false;
    },
    toggleSideBar(value) {
      this.$store.commit('app/setShowSidebar', value);
    },
  },
  computed: {
    nameOrUrl: {
      get() {
        return this.getSearchParams.nameOrUrl;
      },
      set(value) {
        this.debounceUpdate(value);
      },
    },
    showSendButton() {
      return this.$route.name === 'main-groups' || this.$route.name === 'group';
    },
    showSearchBlock() {
      return this.$route.name === 'main-groups';
    },
    getSearchParams() {
      return this.$store.getters['groups/getGroupsSearchParams'];
    },
    getUserInfo() {
      return this.$store.getters['auth/getUserInfo'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getNewGroupsCount() {
      if (!this.$store.getters['groups/getTotalNewGroups']) {
        return false;
      }
      return this.switchStats
        ? `+${this.setNumberSpaces(this.$store.getters['groups/getTotalNewGroups']?.lastDay.total || 0)} ${this.$t('per_day')}`
        : `+${this.setNumberSpaces(this.$store.getters['groups/getTotalNewGroups']?.lastMonth.total || 0)} ${this.$t('per_month')}`;
    },
    isDesktop() {
      return window.innerWidth > 480;
    },
    isShowBanner() {
      return this.showBanner && this.getIsBannerCountries;
    },
    getIsBannerCountries() {
      return this.$store.getters['groups/getIsBannerCountries'];
    },
    getOpenSidebar() {
      return this.$store.getters['app/getShowSidebar'];
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: $primary-white;
  height: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 16;
  @include desktop-screen {
    padding-left: 60px;
  }
  box-shadow: 0px 2px 3px -2px rgba(0, 174, 232, 0.16);
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 2560px;
  padding: 0px 30px;
  height: 100%;
  background: white;
  z-index: 3;
}

.header__logo {
  width: 90px;
  margin-bottom: 18px;
  &_mediagate {
    margin-bottom: 0px;
  }
}

.header__sidebar-button {
  display: block;
  padding-right: 10px;
  margin-bottom: 10px;
  @include desktop-screen {
    display: none;
  }
}
.header__info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
    @include tablet-screen {
      margin-right: 20px;
    }
  }
}

.header__search {
  display: none;
  @include tablet-screen {
    display: flex;
    align-items: center;
    width: 190px;
  }
  @include between-elements {
    margin-right: 10px;
  }
}

.header__groups-stats {
  display: none;
  @include between-elements {
    margin-right: 5px;
  }
  @include max-screen {
    display: flex;
    align-items: center;
    cursor: default;
  }
  &_count {
    margin-left: 5px;
  }
}

.header__send-button {
  display: none;
  @include tablet-screen {
    background: $primary-blue;
    padding: 0px 10px 0px 10px;
    height: 24px;
    min-width: 160px;
    border-radius: 8px;
    color: $primary-white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.header__text {
  font-size: 12px;
  line-height: 12px;
  color: $primary-lightgray;
  &_stats {
    color: $primary-white;
    font-weight: 400;
  }
  &_gray {
    color: $primary-gray;
  }
  &_noauth {
    color: $primary-gray;
    font-size: 14px;
    margin-right: 20px;
  }
}

.header__search-input {
  border: none;
  outline: none;
  width: 140px;
  line-height: 14px;
  font-size: 12px;
  &::placeholder {
    color: $primary-lightgray;
  }
  @include tablet-screen {
    width: 100%;
  }
}

.header__reset-icon {
  color: $primary-lightgray;
  margin-top: 2px;
}

.header__line {
  width: 0px;
  border-right: 1px solid rgb(226, 226, 226);
  height: 30px;
}

.header__lang {
  display: none;
  @include tablet-screen {
    display: flex;
    padding-left: 8px;
  }
}

.header__search-icon {
  width: 16px;
  height: 16px;
}

.header__banner {
  position: absolute;
  top: 70px;
  left: 0px;
}

.header__mini-burn {
  position: absolute;
  bottom: -37px;
  right: 20px;
}

</style>
