<template>
  <div
  class="group-postcount"
  :class="isEmptyData && 'group-postcount_empty'">
    <div
    v-if="!isEmptyData"
    :class="isAdmin && 'pointer'"
    class="group-postcount__content"
    @click="openEditor">
      <div class="group-postcount__element">
        <span
        class="group-postcount__text"
        :class="posts.countLast24h === null && 'group-postcount__text_light'">
          {{ getPostCount(posts.countLast24h) }}</span>
        <span class="group-postcount__text_light">{{ $t('per_day2') }}</span>
      </div>
      <div
      v-if="!hideDetails"
      class="group-postcount__element">
        <span
        class="group-postcount__text"
        :class="posts.countLastWeek === null && 'group-postcount__text_light'">{{ getPostCount(posts.countLastWeek) }}</span>
        <span class="group-postcount__text_light">{{ $t('per7days_2') }}</span>
      </div>
      <div class="group-postcount__element">
        <span
        class="group-postcount__text"
        :class="posts.countLastMonth === null && 'group-postcount__text_light'">{{ getPostCount(posts.countLastMonth) }}</span>
        <span class="group-postcount__text_light">{{ $t('per30days_2') }}</span>
      </div>
    </div>
    <span
    v-if="isEmptyData"
    :class="isAdmin && 'pointer'"
    class="group-postcount__text_light"
    @click="openEditor">—</span>

    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-postcount__editor">
        <!-- TRANSLATE -->
        <div class="group-postcount__editor-element">
          <span class="group-postcount__text_light">
            Посты за 24ч
          </span>
          <input
          v-model="posts24Value"
          type="number"
          placeholder="Укажите кол-во постов"
          class="group-postcount__input">
        </div>
        <!-- TRANSLATE -->
        <div class="group-postcount__editor-element">
          <span class="group-postcount__text_light">
            Посты за неделю
          </span>
          <input
          v-model="posts7DaysValue"
          type="number"
          placeholder="Укажите кол-во постов"
          class="group-postcount__input">
        </div>
        <!-- TRANSLATE -->
        <div class="group-postcount__editor-element">
          <span class="group-postcount__text_light">
            Посты за месяц
          </span>
          <input
          v-model="postsMonthValue"
          type="number"
          placeholder="Укажите кол-во постов"
          class="group-postcount__input">
        </div>
        <!-- TRANSLATE -->
        <TJButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'GroupPostCount',
  props: {
    posts: {
      type: Object,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    posts24Value: null,
    posts7DaysValue: null,
    postsMonthValue: null,
  }),
  methods: {
    getPostCount(count) {
      return count === null ? '—' : this.setNumberSpaces(count);
    },
    openEditor() {
      if (!this.isAdmin) {
        return;
      }
      this.posts24Value = this.posts.countLast24h;
      this.posts7DaysValue = this.posts.countLastWeek;
      this.postsMonthValue = this.posts.countLastMonth;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.posts24Value = null;
      this.posts7DaysValue = null;
      this.postsMonthValue = null;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveChanges() {
      const newData = {
        postsStatistic: {
          countLast24h: this.posts24Value === '' ? null : this.posts24Value,
          countLastWeek: this.posts7DaysValue === '' ? null : this.posts7DaysValue,
          countLastMonth: this.postsMonthValue === '' ? null : this.postsMonthValue,
        },
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.postsStatistic.countLast24h = this.posts24Value === '' ? null : this.posts24Value;
          this.group.postsStatistic.countLastWeek = this.posts7DaysValue === '' ? null : this.posts7DaysValue;
          this.group.postsStatistic.countLastMonth = this.postsMonthValue === '' ? null : this.postsMonthValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    isEmptyData() {
      return [this.posts.countLast24h, this.posts.countLastWeek, this.posts.countLastMonth].every((item) => item === null || item === undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-postcount {
  display: flex;
  align-items: flex-start;
  padding-left: 45px;
  min-height: 50px;
  width: 180px;
  position: relative;
  &_empty {
    padding-left: 0px;
    align-items: center;
    justify-content: center;
  }
}

.group-postcount__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 50px;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-postcount__text {
  font-size: 14px;
  margin-right: 5px;
  color: $primary-black;
  &_small {
    font-size: 12px;
    margin-right: 5px;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.group-postcount__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  min-width: 200px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-postcount__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-postcount__input {
  padding: 5px 10px;
  border: 1px solid $primary-lightgray;
  border-radius: 8px;
  font-size: 12px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
}
</style>
