<template>
  <div
  class="data-checkbox"
  :class="[
    getChecked && 'data-checkbox_checked',
    disabled && 'disabled',
    !nochange && 'pointer',
    !nochange && 'hover'
  ]"
  @click="toggleCheck">
    <transition>
      <TJIcon
      :name="getIconName ? getIconName : ''"
      :size="16"
      :class="getChecked && 'data-checkbox__icon_show'"
      class="data-checkbox__icon" />
    </transition>
  </div>
</template>

<script>

export default {
  name: 'DataCheckbox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    nochange: {
      type: Boolean,
      default: false,
    },
    withNull: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCheck() {
      if (this.nochange) return; // чекбокс без возможности клика

      if (this.withNull && this.value === null) {
        this.$emit('input', true);
        return;
      }
      if (this.value) {
        this.$emit('input', false);
        return;
      }
      this.$emit('input', this.withNull ? null : true);
    },
  },
  computed: {
    getIconName() {
      if (this.withNull && this.value === null) return false;
      if (this.withNull) {
        return this.value === true ? 'done' : 'close';
      }
      return 'done';
    },
    getChecked() {
      if (this.withNull) {
        return this.value === true || this.value === false;
      }
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-checkbox {
  width: 18px;
  height: 18px;
  border: 1px solid $primary-blue;
  border-radius: 5px;
  position: relative;
  transition: 0.2s;
  overflow: hidden;
  &_checked {
    transition: 0.2s;
    background: $primary-blue;
  }
}

.data-checkbox__icon {
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 16px;
  color: $primary-white;
  width: 16px;
  height: 16px;
  &_show {
    animation: enter 0.2s forwards;
  }
}

.hover {
  &:hover {
    box-shadow: 0px 0px 1px 2px rgba(rgb(26, 144, 199), 0.2);
  }
}

@keyframes enter {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
