<template>
  <div
  class="group-analytics">
    <span class="group-analytics__title">Статистика по каналу</span>
    <div class="group-analytics__container">
      <GroupAnSubscribers
      :group="group"
      :is-loading="isLoading"
      :chart-data="subscribersStatistics" />
      <GroupAnPosts
      :group="group"
      :is-loading="isLoading"
      :chart-data="postsStatistics" />
      <GroupAnViews
      :group="group"
      :is-loading="isLoading"
      :chart-data="viewsStatistics"
      class="group-analytics__container_last" />
    </div>
    <span class="group-analytics__title">Стоимость</span>
    <GroupAnalyticsPrice
    class="group-analytics__price"
    :is-loading="isLoading"
    :group="group"
    :price-statistics="priceStatistics" />
  </div>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import GroupAnSubscribers from './components/GroupAnSubscribers.vue';
import GroupAnPosts from './components/GroupAnPosts.vue';
import GroupAnViews from './components/GroupAnViews.vue';
import GroupAnalyticsPrice from './components/GroupAnalyticsPrice.vue';

export default {
  name: 'GroupAnalytics',
  components: {
    GroupAnSubscribers, GroupAnPosts, GroupAnViews, GroupAnalyticsPrice,
  },
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    priceStatistics: [],
    subscribersStatistics: [],
    viewsStatistics: [],
    postsStatistics: [],
  }),
  created() {
    this.getDataForStatistics();
    this.getDataForPriceStatistic();
  },
  methods: {
    getDataForPriceStatistic() {
      const shareToken = this.$route.query.shareToken || null;

      const params = {
        params: {
          groupIdentity: { id: this.group.id, social: this.group.social },
          propertiesFilter: ['postPrice.sell'],
        },
        shareToken,
      };

      this.$store.dispatch('group/getPriceHistory', params)
        .then((data) => {
          const items = data.map((item, index) => {
            const price = item.changes.find((change) => change.propertyPath === 'postPrice.sell');
            return {
              id: index,
              date: new Date(item.dateTime.date).getTime(),
              fulldate: this.moment(item.dateTime.date).format('DD MMM YYYY'),
              value: price.newValue ? Math.trunc(price.newValue) : null,
              oldValue: price.oldValue ? Math.trunc(price.oldValue) : null,
              user: item.user?.email || item.botName || null,
            };
          });
          // uniqBy - убираем дубликаты по датам (выпилить, если это будет делать сервер)
          // reverse чтобы в уникальных оставалась последняя указанная на день цена
          const filtered = uniqBy(items.reverse(), (item) => item.fulldate).reverse();
          this.priceStatistics = [...filtered];
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при загрузке статистики', error: true });
        });
    },
    getDataForStatistics() {
      const shareToken = this.$route.query.shareToken || null;
      this.isLoading = true;

      const params = {
        groupId: this.group.id,
        social: this.group.social,
        shareToken,
      };

      this.$store.dispatch('group/getGroupStatistic', params)
        .then((data) => {
          // SubscribersData
          const subscribersData = data.map((item) => ({
            date: new Date(item.dateTime.date).getTime(),
            fulldate: item.dateTime.date,
            value: item.subscribersStatistic ? item.subscribersStatistic.count : null,
          }));
          this.subscribersStatistics = subscribersData.filter((item) => item.value !== null);

          // ViewsData
          const viewsStatistics = data.map((item) => ({
            date: new Date(item.dateTime.date).getTime(),
            fulldate: item.dateTime.date,
            value: item.viewsStatistic ? item.viewsStatistic.countLast24h : null,
          }));
          this.viewsStatistics = viewsStatistics.filter((item) => item.value !== null);

          // PostsData
          const postsStatistics = data.map((item) => ({
            date: new Date(item.dateTime.date).getTime(),
            fulldate: item.dateTime.date,
            value: item.postsStatistic ? item.postsStatistic.countLast24h : null,
          }));
          this.postsStatistics = postsStatistics.filter((item) => item.value !== null);
          //
          this.isLoading = false;
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка при загрузке статистики', error: true });
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.group-analytics {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  @include desktop-screen {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.group-analytics__title {
  font-size: 20px;
  font-weight: bold;
  @include desktop-screen {
    font-size: 28px;
  }
}

.group-analytics__container {
  margin-top: 30px;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: 1fr;
  margin-bottom: 30px;
  @include max-screen {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 60px;
    grid-gap: 40px 20px;
  }
  &_last {
    @include max-screen {
      grid-column: 1 / 3;
  }

  }
}

.group-analytics__price {
  margin-top: 30px;
  @include desktop-screen {
    margin-top: 30px;
  }
}
</style>
