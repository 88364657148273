<template>
  <div class="tj-button">
    <button
    :title="text"
    class="tj-button__button"
    :disabled="disabled"
    :style="{
      'width': width === 'full' ? '100%' : width + 'px',
      'height': height ? height + 'px' : '40px'
    }"
    :class="{
      'tj-button__button_outlined': type === 'outlined',
      'tj-button__button_danger': type === 'danger',
      'tj-button__button_text': type === 'text',
      'tj-button__button_disabled': disabled,
    }"
    @click="click">
      {{ text }}
      <span
      v-if="showRipple"
      class="tj-button__ripple" />
    </button>
  </div>
</template>

<script>

export default {
  name: 'TJButton',
  props: {
    text: {
      type: String,
      required: true,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    width: {
      type: [String, Number],
      default: 180,
    },
    height: {
      type: [String, Number],
      default: 40,
    },
  },
  data: () => ({
    showRipple: false,
  }),
  methods: {
    click() {
      this.setRipple();
      this.$emit('handler');
    },
    setRipple() {
      this.showRipple = true;
      setTimeout(() => {
        this.showRipple = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>

.tj-button__button {
  width: 180px;
  cursor: pointer;
  font-size: 14px;
  padding: 0px 20px;
  outline: none;
  border: none;
  color: $primary-white;
  background: $primary-blue;
  border-radius: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  &_disabled {
    pointer-events: none;
    background: $primary-lightgray;
  }
  &_outlined {
    background: $primary-white;
    color: $primary-black;
    border: 1px solid $primary-lightgray;
  }
  &_danger {
    background: $primary-red;
  }
  &_text {
    padding: 12px 20px;
    background: none;
    color: $primary-blue;
    border: none;
    outline: none;
  }
}

.tj-button__ripple {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 38%;
  width: 30%;
  height: 100%;
  transform: scale(0);
  animation: ripple 300ms linear;
  background-color: rgba(255, 255, 255, 0.3);
}

@keyframes ripple {
  to {
    transform: scale(5);
    opacity: 0;
  }
}
</style>
