<template>
  <div
  class="categories-filter"
  @click="openMenu">
    <div class="categories-filter__title">
      <span class="categories-filter__text categories-filter__text_title">
        {{ $t('categories') }}
      </span>
      <transition name="fade">
        <div
        v-if="selectValue.length || withoutCategory"
        class="categories-filter__title-active" />
      </transition>
    </div>
    <span
    v-if="!showSearchInput"
    class="categories-filter__text">
      {{ getLabelForSelect }}
    </span>
    <div
    v-if="showSearchInput"
    class="categories-filter__input-block">
      <input
      id="search-input"
      ref="searchInput"
      v-model.trim="searchInputValue"
      class="categories-filter__input"
      autocomplete="off"
      :placeholder="$t('search')">
      <transition name="fade">
        <TJIcon
        v-if="searchInputValue"
        name="close"
        class="categories-filter__icon"
        :size="16"
        pointer
        @click.native="searchInputValue = ''" />
      </transition>
    </div>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      class="categories-filter__menu">
        <TJIcon
        v-if="selectValue.length || setWithoutCategory"
        v-title="`${$t('reset_all')}`"
        name="close"
        class="categories-filter__all-reset-icon"
        pointer
        :size="16"
        @click.native="resetAll" />
        <div
        v-for="category in getFilteredCategories"
        :key="category.value"
        class="categories-filter__category-block">
          <span
          :class="[
            getSelected(category) && 'categories-filter__text_category_active',
            category.title && 'categories-filter__text_category_title'
          ]"
          class="categories-filter__text categories-filter__text_category"
          @click.stop="changeValue(category)">
            {{ category.title ? category.name : $t(category.name) }}
          </span>
          <span
          v-if="!category.title && category.value !== null"
          class="categories-filter__text_light">{{ getCategoryCount(category.value) }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';

export default {
  name: 'CategoriesFilter',
  props: {
    reset: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
      required: true,
    },
    categoriesCount: {
      type: Array,
      default: null,
    },
    withoutCategory: {
      type: [Boolean, String],
      default: null,
    },
    selectedCategories: {
      type: [Number, String, Array, Boolean],
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    selectValue: null,
    searchInputValue: '',
    showSearchInput: false,
    setWithoutCategory: null,
  }),
  created() {
    this.initCategories();
  },
  methods: {
    resetAll() {
      this.searchInputValue = '';
      this.setWithoutCategory = null;
      this.selectValue = [];
    },
    onClickOutside(event) {
      if (this.preventClickOutside) {
        this.preventClickOutside = false;
        return;
      }
      if (event.target.className === 'categories-filter__input' || event.target.className.includes('categories-filter__icon')) return;

      window.removeEventListener('mousedown', this.onMouseDown);

      this.showSearchInput = false;
      this.showSearchInput = false;
      this.searchInputValue = '';
      this.isOpen = false;

      if (isEqual(this.selectedCategories, this.selectValue)) {
        return;
      }
      this.$emit('update:withoutCategory', this.setWithoutCategory);
      this.$emit('update:selectedCategories', this.selectValue.length ? this.selectValue : null);
    },
    initCategories() {
      this.setWithoutCategory = this.withoutCategory;
      this.selectValue = this.selectedCategories === null
        ? []
        : [...this.selectedCategories];
    },
    openMenu() {
      if (this.isOpen) {
        return;
      }
      // слушаем событие клика на стороннем элементе
      // чтобы v-click-outside не срабатывал при выделении инпута
      window.addEventListener('mousedown', this.onMouseDown);

      this.initCategories();
      this.showSearchInput = true;
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    changeValue(category) {
      if (category.value === 'Without category') {
        this.setWithoutCategory = this.setWithoutCategory ? null : true;
        return;
      }
      if (category.value === null) {
        this.selectValue = [];
        this.isOpen = false;
        this.$emit('update:selectedCategories', null);
        this.$emit('update:withoutCategory', null);
        return;
      }

      if (this.selectValue.includes(category.value)) {
        this.selectValue = this.selectValue.filter((el) => el !== category.value);
      } else {
        this.selectValue.push(category.value);
      }
    },
    getSelected(category) {
      if (category.value === 'Without category') {
        return this.setWithoutCategory;
      }
      return this.selectValue.includes(`${category.value}`);
    },
    onMouseDown(mouseEvent) {
      const inputs = ['search-input'];
      this.preventClickOutside = inputs.includes(mouseEvent.target.id);
    },
    getCategoryCount(value) {
      if (value === 'Without category') {
        const category = this.categoriesCount ? this.categoriesCount.find((item) => item.categoryId === null) : null;
        return category ? category.totalCount : '—';
      }
      const category = this.categoriesCount ? this.categoriesCount.find((item) => +item.categoryId === +value) : null;
      return category ? category.totalCount : '—';
    },
  },
  computed: {
    getLabelForSelect() {
      return (this.selectValue === null || this.selectValue.length === 0) && !this.setWithoutCategory
        ? this.$t('all')
        : `${this.$t('selected')} (${this.setWithoutCategory ? this.selectValue.length + 1 : this.selectValue.length})`;
    },
    getCategoriesData() {
      const categories = [...this.categories].sort((a, b) => {
        if (b.name === 'all' || b.name === 'Without category') return 1;
        if (a.name === 'all' || a.name === 'Without category') return -1;
        return this.$t(a.name).localeCompare(this.$t(b.name));
      });

      const arrayWithAlphabet = [];

      categories.forEach((item) => {
        const firstWord = this.$t(item.name)[0].toUpperCase();
        if (item.value === null || item.value === 'Without category') {
          arrayWithAlphabet.push(item);
          return;
        }
        const title = {
          value: firstWord,
          title: true,
          name: firstWord,
        };
        if (!arrayWithAlphabet.filter((category) => category.value === firstWord).length) {
          arrayWithAlphabet.push(title);
          arrayWithAlphabet.push(item);
          return;
        }
        arrayWithAlphabet.push(item);
      });

      return arrayWithAlphabet;
    },
    getFilteredCategories() {
      return this.getCategoriesData.filter((item) => this.$t(item.name).toUpperCase().includes(this.searchInputValue.toUpperCase()));
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.initCategories();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.categories-filter {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 50px;
  cursor: pointer;
}

.categories-filter__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.categories-filter__category-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  margin-right: 30px;
}

.categories-filter__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.categories-filter__input {
  outline: none;
  border: none;
  width: 120px;
  margin-right: 5px;
  background: $primary-white;
  &::placeholder {
    color: #565656;
    font-size: 14px;
  }
}

.categories-filter__input-block {
  display: flex;
  align-items: center;
  width: 150px;
}

.categories-filter__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
    font-weight: 400;
  }
  &_title {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
    max-width: 150px;
  }
  &_category {
    font-size: 12px;
    margin: 3px 20px 3px 0px;
    &_active {
      color: $primary-blue;
    }
    &_title {
      color: $primary-gray;
      font-size: 16px;
      width: 12px;
      font-weight: 600;
      padding: 5px 0px;
    }
  }
}

.categories-filter__menu {
  position: absolute;
  z-index: 10;
  background-color: $primary-white;
  top: 80px;
  left: 0;
  border-radius: 12px;
  width: 100%;
  height: calc(100vh - 300px);
  max-height: 600px;
  padding: 10px;
  padding-left: 40px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  overflow: scroll;
  cursor: auto;
}

.categories-filter__all-reset-icon {
  position: absolute;
  left: 13px;
  top: 13px;
  color: $primary-lightgray;
}

.categories-filter__icon {
  color: #9B9B9B;
}
</style>
