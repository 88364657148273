<template>
  <div class="group-statistic">
    <div
    v-for="element in data"
    :key="element.title"
    class="group-statistic__element">
      <span class="group-statistic__text group-statistic__text_bold">
        {{ element.value }}
      </span>
      <span class="group-statistic__text">
        {{ element.title }}
      </span>
      <span class="group-statistic__text group-statistic__text_small">
        {{ element.desc }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupStatistic',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    data: null,
  }),
  created() {
    // NEED TRANSLATE
    this.data = [
      {
        title: 'Изменение подписчиков',
        desc: 'за месяц',
        value: this.getMonthGrow(),
      },
      {
        title: 'ER',
        desc: 'За день, среднее',
        value: this.getGroupEr(),
      },
      {
        title: 'Постов',
        desc: 'В месяц / сутки',
        value: this.getDayMonthPosts(),
      },
      {
        title: 'CPC',
        desc: 'Стоимость за клик',
        value: this.getGroupCpc(),
      },
      {
        title: 'Охват 1 поста',
        desc: 'за сутки',
        value: this.getViews(),
      },
    ];
  },
  methods: {
    getMonthGrow() {
      if (!this.group.subscribersStatistic.monthGrow) {
        return '—';
      }
      return this.isNegativeCount(this.group.subscribersStatistic.monthGrow)
        ? `-${this.getLabelForCount(this.group.subscribersStatistic.monthGrow)}`
        : `+${this.getLabelForCount(this.group.subscribersStatistic.monthGrow)}`;
    },
    getGroupEr() {
      return this.group.er ? `${this.group.er}%` : '—';
    },
    getGroupCpc() {
      return this.group.cpc || '—';
    },
    isNegativeCount(count) {
      return +count < 0;
    },
    getPostCount(count) {
      return count === null ? '—' : `~${this.setNumberSpaces(count)}`;
    },
    getLabelForCount(count) {
      if (count === null) {
        return '—';
      }
      count = Math.abs(count);

      if (count > 999 && count < 100000) {
        return `${parseFloat((count / 1000).toFixed(1))}k`;
      }
      if (count >= 100000 && count < 1000000) {
        return `${(count / 1000).toFixed(0)}k`;
      }
      if (count >= 1000000) {
        return `${parseFloat((count / 1000000).toFixed(1))}m`;
      }
      return count;
    },
    getDayMonthPosts() {
      const month = this.getPostCount(this.group.postsStatistic.countLastMonth);
      const day = this.getPostCount(this.group.postsStatistic.countLast24h);
      return `${month} / ${day}`;
    },
    getViews() {
      return this.group.viewsStatistic.countLast24h
        ? this.setNumberSpaces(this.group.viewsStatistic.countLast24h)
        : '—';
    },
  },
};
</script>

<style lang="scss" scoped>
.group-statistic {
  width: 100%;
  background: $primary-blue;
  border-radius: 20px;
  margin: 32px 0px 60px;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  justify-items: stretch;
  grid-gap: 30px;
  align-items: stretch;
  @media screen and (min-width: 1500px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    padding: 50px;
    grid-gap: 50px;
  }
}

.group-statistic__element {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop-screen {
    align-items: flex-start;
  }
}

.group-statistic__text {
  color: white;
  font-size: 14px;
  font-weight: 600;
  @media screen and (min-width: 1500px) {
    font-size: 16px;
  }
  &_bold {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    @media screen and (min-width: 1500px) {
      font-size: 36px;
    }
  }
  &_small {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.8;
    @media screen and (min-width: 1500px) {
      font-size: 14px;
    }
  }
}
</style>
