<template>
  <div
  id="app">
    <AppLoader
    v-if="isLoading" />
    <AppModals />
    <TJSnackbar
    :show-snackbar="showAppSnackBar"
    :text="getAppSnackBarText"
    :is-error="getAppSnackBarError"
    :close-timeout="getAppSnackBarTimeout"
    @close="closeSnackBar" />

    <!-- landing-layout -->
    <div v-if="isLanding">
      <router-view
      :key="getLocale" />
    </div>

    <!-- main-layout -->
    <div v-else>
      <MainHeader :mode="!isAuthUser ? 'noauth' : ''" />
      <TariffsRate v-if="$route.name !== 'tariffs' && !isLoading && false" />
      <div class="app__content">
        <Sidebar v-if="isAuthUser" />
        <router-view
        :key="getLocale"
        class="app__pages"
        :class="showTarifRate && 'app__pages_with-top-padding'" />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar/Sidebar.vue';
import AppModals from './components/AppModals/AppModals.vue';
import TariffsRate from './components/TariffsRate/TariffsRate.vue';

export default {
  components: { Sidebar, AppModals, TariffsRate },
  name: 'App',
  created() {
    const locale = this.getLocale === 'ru' ? 'ru' : 'en';
    this.moment.locale(locale);
  },
  methods: {
    closeSnackBar() {
      this.$store.commit('app/setAppSnackBarTimeout', 2500);
      this.$store.dispatch('app/setSnackBar', { text: null, error: false });
    },
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    isLoading() {
      return this.$store.getters['groups/getGroupsIsLoading'] || this.$store.getters['collections/getCollectionsIsLoading'];
    },
    showAppSnackBar() {
      return this.getAppSnackBarText !== null;
    },
    getAppSnackBarText() {
      return this.$store.getters['app/getAppSnackBarText'];
    },
    getAppSnackBarError() {
      return this.$store.getters['app/getAppSnackBarError'];
    },
    getAppSnackBarTimeout() {
      return this.$store.getters['app/getAppSnackBarTimeout'];
    },
    isLanding() {
      return this.$route.name === 'main-landing' || this.$route.name === 'publishers';
    },
    isAuthUser() {
      const auth = this.$store.getters['auth/getUserInfo'];
      return !!auth;
    },
    showTarifRate() {
      return false;
      // return this.$store.getters['app/getShowFullTariffRate'];
    },
  },
  watch: {
    getLocale(newValue) {
      const locale = newValue === 'ru' ? 'ru' : 'en';
      this.moment.locale(locale);
    },
  },
};
</script>

<style lang="scss">
.app {
  min-height: 100vh;
  height: 100%;
}

.app__content {
  display: flex;
  background: $main-background;
  min-height: calc(100vh - 70px);
}

.app__pages {
  width: 100vw;
  max-width: 2500px;
  margin: 0px auto;
  padding-top: 0px;
  transition: 0.2s;
  &_with-top-padding {
    padding-top: 80px;
  }
  @include desktop-screen {
    width: calc(100vw - 85px);
  }
}
</style>
