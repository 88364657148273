<template>
  <div class="groups-moderation">
    <div
    v-if="!isEmptyGroups"
    ref="groupsContent"
    class="groups-moderation__content"
    @scroll="onScroll">
      <div
      class="groups-moderation__elements"
      :class="isLoadingGroups && 'groups-moderation__elements_loading'">
        <GroupsHeaders />
        <GroupElement
        v-for="group in groups"
        :key="group.id"
        :group="group"
        @approveGroup="approveGroup" />
      </div>
    </div>
    <div
    v-if="isEmptyGroups || isLoadingGroups"
    class="groups-moderation__elements-empty">
      <span class="groups-moderation__text_light">
        {{ isLoadingGroups ? 'Загрузка каналов...' : 'Нет каналов для модерации' }}
      </span>
    </div>
  </div>
</template>

<script>
import GroupsHeaders from './GroupsHeaders.vue';
import GroupElement from './GroupElement.vue';

export default {
  name: 'GroupsModeration',
  components: { GroupsHeaders, GroupElement },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    isLoadingGroups: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onScroll() {
      if (this.isLastPage || this.isLoadingGroups) {
        return;
      }

      const position = this.$refs.groupsContent.scrollTop;
      const { clientHeight } = this.$refs.groupsContent;

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.groupsContent.scrollHeight);
      if (endScroll) {
        this.$emit('getModerateGroups', { reset: false });
      }
    },
    approveGroup(value, id) {
      const newGroups = this.groups.filter((item) => item.id !== id);
      this.$store.dispatch('moderate/setModerateGroupApprove', { value, id })
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: value ? 'Изменение цены подтверждено' : 'Изменение цены отклонено' });
          this.$store.commit('moderate/setGroupsForModerate', newGroups);
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Что-то пошло не так. Перезагрузите страницу и попробуйте снова', error: true });
        });
    },
  },
  computed: {
    isEmptyGroups() {
      return this.groups.length === 0;
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    isLastPage() {
      return this.$store.getters['moderate/getIsLastPage'];
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-moderation {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex: 1;
  overflow: auto;
  position: relative;
  @include tablet-screen {
    margin: 20px;
  }
}

.groups-moderation__content {
  background: white;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.groups-moderation__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  transition: 0.2s;
  &_loading {
    opacity: 0.4;
    transition: 0.3s;
  }
}

.groups-moderation__elements-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
}

.groups-moderation__text {
  color: $primary-black;
  font-size: 14px;
  &_light {
    color: $primary-lightgray;
  }
}

</style>
