<template>
  <div class="group-name-editor">
    <div class="group-name-editor__header-block">
      <span class="group-name-editor__text group-name-editor__text_title">{{ $t('editing_a_channel') }}</span>
    </div>
    <div class="group-name-editor__content">
      <div class="group-name-editor__elements">
        <!-- Название -->
        <div
        class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('name2') }}</span>
          <input
          v-model.trim="groupName"
          :placeholder="$t('enter_new_name')"
          class="group-name-editor__input">
          <span
          v-show="!groupName.length"
          class="group-name-editor__text group-name-editor__text_error">{{ $t('this_is_a_required_field') }}</span>
        </div>
        <!-- Ссылка -->
        <div
        class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('link') }}</span>
          <input
          v-model.trim="groupLink"
          :placeholder="$t('specify_the_correct_link')"
          class="group-name-editor__input">
          <span
          v-show="!groupLink.length"
          class="group-name-editor__text group-name-editor__text_error">{{ $t('this_is_a_required_field') }}</span>
        </div>
        <!-- Накрутка -->
        <!-- NEED TRANSLATE -->
        <div class="group-name-editor__element group-name-editor__element_row">
          <TJCheckbox
          :checked.sync="groupIsWarning" />
          <span class="group-name-editor__text group-name-editor__text_desc">
            Пометить канал как накрученный
          </span>
        </div>
        <!-- Категории -->
        <div
        class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">{{ $t('categories') }}</span>
          <!-- <span
          v-show="!groupCategories.length"
          class="group-name-editor__text group-name-editor__text_error">{{ $t('it_is_necessary_to_add_at_least_1_category') }}</span> -->
          <div
          v-for="category in groupCategories"
          :key="category.name"
          class="group-name-editor__category">
            <span
            class="group-name-editor__text group-name-editor__text_light">
              - {{ $t(category.name) }}
            </span>
            <TJIcon
            name="close"
            :size="16"
            pointer
            @click.native="removeCategory(category.value)" />
          </div>
          <div class="group-name-editor__add-category pointer">
            <span
            v-show="!showAddCategory"
            class="group-name-editor__text group-name-editor__text_add"
            @click="showAddCategory = true">+ {{ $t('add_new_category') }}</span>
            <select
            v-show="showAddCategory"
            v-model="newCategory"
            class="group-name-editor__input"
            @change="setNewCategory">
              <option
              value="null"
              disabled
              selected>
                {{ $t('Select a new category') }}
              </option>
              <option
              v-for="option in groupsData"
              :key="option.value"
              :value="option">
                {{ $t(option.name) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Описание -->
      <div class="group-name-editor__elements">
        <div
        class="group-name-editor__element">
          <span class="group-name-editor__text group-name-editor__text_desc">
            {{ $t('description') }}
          </span>
          <textarea
          v-model.trim="groupDesc"
          :placeholder="$t('Enter a description')"
          class="group-name-editor__input group-name-editor__input_text" />
        </div>
      </div>
    </div>
    <div class="group-name-editor__button-block">
      <TJButton
      :text="$t('save')"
      :disabled="disabledSaveButton"
      @handler="saveGroup" />
      <TJButton
      :text="$t('cancel')"
      type="outlined"
      @handler="$emit('close')" />
    </div>
  </div>
</template>

<script>
import groupsData from '@/assets/groupsData.json';

export default {
  name: 'GroupNameEditor',
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    groupName: null,
    groupLink: null,
    groupDesc: null,
    groupCategories: null,
    showAddCategory: false,
    newCategory: null,
    groupIsWarning: null,
  }),
  created() {
    this.groupName = this.group.name;
    this.groupLink = this.group.url;
    this.groupDesc = this.group.description;
    this.groupCategories = this.group.categories.map((category) => ({ name: category.name, value: category.id }));
    this.groupsData = groupsData.categories.slice(2);
    this.groupIsWarning = this.group.inspectReport?.warnings?.some((item) => item.code === 7) || false;
  },
  methods: {
    saveGroup() {
      const newData = {
        name: this.groupName,
        url: this.groupLink,
        description: this.groupDesc,
        categories: this.groupCategories.map((category) => ({ id: category.value, name: category.name })),
        categoriesId: this.groupCategories.map((category) => category.value),
      };

      const saveGroupData = this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData });
      const savegroupWarnings = this.$store.dispatch(`groups/${this.groupIsWarning ? 'addWarning' : 'removeWarning'}`,
        {
          id: this.group.id,
          social: this.group.social,
          codeNumber: 7,
        });

      Promise.all([saveGroupData, savegroupWarnings])
        .then(() => {
          this.group.name = this.groupName;
          this.group.url = this.groupLink;
          this.group.description = this.groupDesc;
          this.group.categories = this.groupCategories.map((category) => ({ id: category.value, name: category.name }));

          const arrayWarnings = this.group.inspectReport?.warnings;

          if (this.groupIsWarning) {
            this.group.inspectReport.warnings.push({
              code: 7,
              message: 'Заподозрен в накрутке',
            });
          } else if (arrayWarnings) {
            this.group.inspectReport.warnings = this.group.inspectReport.warnings.filter((item) => item.code !== 7);
          }

          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated') });
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
    removeCategory(id) {
      this.groupCategories = this.groupCategories.filter((item) => item.value !== id);
    },
    setNewCategory() {
      const isSelectedCategory = this.groupCategories.find((item) => item.value === this.newCategory.value);
      if (isSelectedCategory) {
        this.showAddCategory = false;
        return;
      }
      this.groupCategories.push(this.newCategory);
      this.newCategory = null;
      this.showAddCategory = false;
    },
  },
  computed: {
    disabledSaveButton() {
      return !this.groupName || !this.groupLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-name-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.group-name-editor__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px;
}

.group-name-editor__text {
  color: $primary-black;
  font-size: 16px;
  &_title {
    font-size: 22px;
  }
  &_desc {
    font-size: 14px;
  }
  &_error {
    font-size: 12px;
    color: $primary-red;
    position: absolute;
    top: 60px;
    left: 0px;
  }
  &_add {
    font-size: 14px;
    color: $primary-blue;
  }
  &_light {
    color: rgb(99, 99, 99);
    font-size: 14px;
  }
}

.group-name-editor__add-category {
  display: flex;
  align-items: center;
  height: 40px;
}

.group-name-editor__content {
  display: flex;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 40px;
  }
}

.group-name-editor__elements {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 15px;
  }
}

.group-name-editor__element {
  display: flex;
  flex-direction: column;
  position: relative;
  &_row {
    flex-direction: row;
    @include between-elements {
      margin-right: 10px;
    }
  }
}

.group-name-editor__category {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.group-name-editor__button-block {
  padding-top: 40px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-name-editor__input {
  margin-top: 5px;
  padding: 0px 10px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  width: 30vw;
  min-width: 200px;
  max-width: 300px;
  font-size: 14px;
  height: 40px;
  &:focus {
    outline: none;
    border-color: $border-color-focus;
  }
  &:hover {
    outline: none;
  }
  &_text {
    min-height: 200px;
    max-height: 200px;
    min-width: 300px;
    padding: 10px;
  }
}

.group-name-editor__button {
  cursor: pointer;
  padding: 10px 40px;
  font-size: 14px;
  outline: none;
  border: none;
  color: $primary-white;
  background: $primary-blue;
  border-radius: 40px;
  &_outline {
    background: $primary-white;
    color: $primary-black;
    border: 1px solid $primary-lightgray;
  }
  &_new {
    width: 180px;
    padding: 5px 0px;
  }
  &_disabled {
    pointer-events: none;
    background: $primary-lightgray;
  }
}

</style>
