<template>
  <div vlass="app-modals">
    <TJModal
    :show-modal="!!getCurrentModal.name"
    :modal-style="getCurrentModal.styles"
    v-bind="getCurrentModal.modalProps"
    @close="closeAppModal">
      <template #modal-content>
        <component
        :is="getCurrentModal.name"
        v-bind="getCurrentModal.props"
        @close="closeAppModal" />
      </template>
    </TJModal>
  </div>
</template>

<script>
import SendCollection from '@/components/MainGroups/components/GroupActions/components/SendCollection.vue';
import GroupEditorModal from './modals/GroupEditorModal.vue';
import GroupApproveModal from './modals/GroupApproveModal.vue';
import ExportGroupsModal from './modals/ExportGroupsModal.vue';
import EditCollectionModal from './modals/EditCollectionModal.vue';
import SaveCollectionModal from './modals/SaveCollectionModal.vue';

export default {
  data: () => ({}),
  components: {
    GroupEditorModal,
    GroupApproveModal,
    SendCollection,
    ExportGroupsModal,
    EditCollectionModal,
    SaveCollectionModal,
  },
  methods: {
    closeAppModal() {
      this.$store.commit('app/setCurrentModal', false);
    },
  },
  computed: {
    getCurrentModal() {
      return this.$store.getters['app/getCurrentModal'] || {};
      // получаем из стейта объект модалки
      // name = название компонента, встраиваемого в модалку
      // props = объект с пропсами для компонента
      // styles = объект с стилями для самой модалки (ширина, высота)
      // modalProps - объект с пропсами для самой модалки
    },
  },
};
</script>

<style>

</style>
