<template>
  <div
  class="client-element"
  :class="isOpen && 'client-element_open'">
    <div class="client-element__row">
      <div
      class="client-element__column client-element__column_name"
      @click="toggleUsers">
        <div class="client-element__name-text">
          <span
          class="client-element__text client-element__text_name">
            {{ client.name }}
          </span>
          <span
          class="client-element__text client-element__text_light">
            До 01.01.2023
          </span>
          <TJIcon
          name="expand_more"
          :size="24"
          :style="!client.employees.length && 'display: none'"
          class="client-element__text_icon"
          :arrow-open="isOpen" />
        </div>
      </div>

      <!-- Запросы -->
      <div
      class="client-element__column"
      style="width: 150px">
        <span class="client-element__text">
          —
        </span>
      </div>

      <!-- Кампании -->
      <div
      class="client-element__column"
      style="width: 150px">
        <span class="client-element__text">
          —
        </span>
        <img
        v-title="'Информация о способах получения доступа'"
        src="@/assets/svg/icons/info.svg"
        class="client-element__info-icon">
      </div>

      <!-- Потрачено за мес/всего -->
      <div
      class="client-element__column"
      style="width: 200px">
        <span class="client-element__text">
          —
        </span>
      </div>

      <!-- Комиссия -->
      <div
      class="client-element__column client-element__column_noborder"
      style="width: 175px">
        <span class="client-element__text">
          —
        </span>
        <img
        src="../../../assets/menu.svg"
        class="client-element__menu-icon"
        @click="showMenu = true">
        <div
        v-if="showMenu"
        v-click-outside="closeMenu"
        class="client-element__menu">
          <span
          class="client-element__menu-element"
          @click="openModal('addUser')">Добавить пользователя</span>
          <span
          class="client-element__menu-element"
          @click="openModal('editClient')">Редактировать</span>
          <!-- <span
          class="client-element__menu-element"
          @click="openModal('deleteClient')">Удалить</span> -->
        </div>
      </div>
    </div>
    <div
    v-if="isOpen && client.employees.length"
    class="client-element__users">
      <UserElement
      v-for="user in client.employees"
      :key="user.id"
      :user="user" />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-self-import
import UserElement from './UserElement.vue';

export default {
  name: 'ClientElement',
  components: { UserElement },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isOpen: false,
    showMenu: false,
  }),
  methods: {
    toggleUsers() {
      if (this.isOpen) {
        this.isOpen = false;
        return;
      }
      this.$emit('closeAllElements');
      this.isOpen = true;
    },
    closeMenu() {
      this.showMenu = false;
    },
    closeUsers() {
      this.isOpen = false;
    },
    openModal(type) {
      this.$emit('openModal', { type, client: this.client });
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.client-element {
  min-width: 100%;
  border-bottom: 1px solid $primary-group-border;
  height: 70px;
  &_open {
    height: 100%;
  }
}

.client-element__users {
  min-width: 100%;
  background: white;
}

.client-element__row {
  display: flex;
  align-items: center;
  min-width: 100%;
  background: white;
  top: 0px;
  left: 0px;
  height: 69px;
}

.client-element__column {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  background: white;
  position: relative;
  &:after {
    content: '';
    height: 30px;
    width: 1px;
    background: $primary-group-border;
    position: absolute;
    right: 0;
    top: 10;
  }
  &_noborder {
    &:after {
      background: transparent;
    }
  }
  &_name {
    flex-direction: row;
    justify-content: flex-start;
    position: sticky;
    left: 0px;
    z-index: 1;
    padding-left: 20px;
    cursor: pointer;
    &:hover {
      .client-element__text_icon {
        opacity: 1;
      }
    }
    @include tablet-screen {
      width: 400px;
    }
  }
}

.client-element__name-text {
  display: flex;
  flex-direction: column;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.client-element__text {
  font-size: 14px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_name {
    width: 200px;
    font-size: 16px;
    color: $primary-blue;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 12px;
  }
  &_icon {
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 25px;
    transition: 0.2s;
    color: rgba($primary-lightgray, 0.5);
  }
}

.client-element__menu-icon {
  margin-left: 30px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 18px;
  padding: 10px;
}

.client-element__info-icon {
  margin-left: 10px;
  cursor: pointer;
}

.client-element__menu {
  min-width: 210px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: -40px;
  z-index: 2;
}

.client-element__menu-element {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba($primary-lightgray, 0.05);
  }
}
</style>
