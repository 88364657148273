<template>
  <div class="send-collection">
    <span class="send-collection__text send-collection__text_label">{{ $t('send_an_order') }}</span>
    <div
    v-if="selectedGroups.length"
    class="send-collection__stats">
      <GroupStats
      :selected-groups="selectedGroups"
      is-send />
    </div>
    <div class="send-collection__date-block">
      <div class="send-collection__block">
        <span class="send-collection__text">{{ $t('start_date') }}</span>
        <input
        v-model="startDate"
        class="send-collection__date-input"
        type="date">
      </div>
      <div class="send-collection__block">
        <span
        v-if="!showEndDate"
        class="send-collection__text send-collection__text_showdate"
        @click="showEndDate = true">{{ $t('add_end_date') }}</span>
        <span
        v-if="showEndDate"
        class="send-collection__text">{{ $t('end_date') }}</span>
        <input
        v-if="showEndDate"
        v-model="endDate"
        class="send-collection__date-input"
        type="date">
      </div>
    </div>
    <div class="send-collection__block">
      <span class="send-collection__text">{{ $t('brief') }}</span>
      <textarea
      v-model="brief"
      resize="none"
      class="send-collection__brief"
      :placeholder="$t('provide_additional_information_if_necessary')" />
    </div>
    <span
    v-if="errorEmptyGroups"
    class="send-collection__text_error">{{ errorEmptyGroups }}</span>
    <div class="send-collection__button-block">
      <TJButton
      :text="$t('send_an_order')"
      @handler="sendCollection" />
      <TJButton
      :text="$t('cancel')"
      type="outlined"
      @handler="close" />
    </div>
  </div>
</template>

<script>
import GroupStats from './GroupsStats.vue';

export default {
  name: 'SendCollection',
  components: { GroupStats },
  props: {
    selectedGroups: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showEndDate: false,
    brief: '',
    startDate: null,
    endDate: null,
    errorEmptyGroups: null,
  }),
  methods: {
    close() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      this.brief = null;
      this.errorEmptyGroups = null;
    },
    sendCollection() {
      const groupIdentities = this.selectedGroups.map((group) => ({
        id: group.id,
        social: group.social,
      }));
      const collection = {
        groupIdentities,
        brief: this.brief,
        dateStart: this.startDate,
        dateEnd: this.endDate,
      };
      if (groupIdentities.length === 0 && this.errorEmptyGroups === null) {
        this.errorEmptyGroups = this.$t('you_have_not_selected_any_channel_send_an_empty_order');
        return;
      }
      this.$store.dispatch('collections/sendCollection', collection)
        .then(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', { text: this.$t('successfully_the_selection_has_been_sent') });
        })
        .catch(() => {
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_while_sending_refresh_the_page_and_try_again'),
              error: true,
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-collection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  @include between-elements {
    margin-bottom: 20px;
  }
}

.send-collection__stats {
  width: 100%;
  min-height: 80px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-blue;
  border-radius: 12px;
}

.send-collection__text {
  font-size: 14px;
  color: $primary-black;
  &_label {
    font-size: 20px;
  }
  &_showdate {
    width: 100px;
    text-align: center;
    line-height: 15px;
    font-size: 12px;
    cursor: pointer;
  }
  &_error {
    font-size: 14px;
    color: $primary-red;
  }
}

.send-collection__block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.send-collection__date-block {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 20px;
  }
}

.send-collection__date-input {
  padding: 5px 10px;
  outline: none;
  border-radius: 8px;
  border: 1px solid $primary-lightgray;
}

.send-collection__brief {
  resize: none;
  outline: none;
  border: 1px solid $primary-lightgray;
  height: 90px;
  padding: 10px;
}

.send-collection__button-block {
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}
.send-collection__button {
  cursor: pointer;
  padding: 10px 40px;
  font-size: 14px;
  outline: none;
  border: none;
  color: $primary-white;
  background: $primary-blue;
  border-radius: 40px;
  &_outline {
    background: $primary-white;
    color: $primary-black;
    border: 1px solid $primary-lightgray;
  }
}
</style>
