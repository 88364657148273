<template>
  <div class="group-analytics-price">
    <!-- NEED TRANSLATE -->
    <span class="group-analytics-price__text group-analytics-price__text_title">Стоимость поста</span>
    <div
    class="group-analytics-price__container"
    :class="!priceStatistics.length && 'group-analytics-price__container_empty'">
      <div
      class="group-analytics-price__content">
        <div class="group-analytics-price__price-block">
          <div
          v-for="item in priceData"
          :key="item.name"
          class="group-analytics-price__price-element"
          :class="!item.main && 'group-analytics-price__price-element_second'">
            <span
            class="group-analytics-price__text"
            :class="item.main && 'group-analytics-price__text_white'">
              {{ item.name }}
            </span>
            <span
            class="group-analytics-price__text_title"
            :class="item.main ? 'group-analytics-price__text_white' : 'group-analytics-price__text_blue'">
              {{ item.value }}
            </span>
          </div>
        </div>
        <GroupPriceChart
        v-if="priceStatistics.length"
        smoothed
        class="group-analytics-price__chart"
        :with-brush="isDesktop"
        :chart-data="priceStatistics" />
        <div
        v-else
        class="group-analytics-price__empty">
          <img
          v-if="isLoading"
          class="group-analytics-price__loader"
          src="@/assets/images/rocket.gif">
          <p
          class="group-analytics-price__text group-analytics-price__text_small"
          :class="isLoading && 'group-analytics-price__text_light'">
            {{ isLoading ? 'Загрузка истории изменений цены...' : 'История изменений цены недоступна' }}
          </p>
        </div>
      </div>

      <div
      v-if="priceStatistics.length"
      class="group-analytics-price__history">
        <span class="group-analytics-price__text">История изменений цены</span>
        <div class="group-analytics-price__history-elements">
          <div
          v-for="element in priceStatistics"
          :key="element.id"
          class="group-analytics-price__history-element">
            <div class="group-analytics-price__history-date">
              <img src="@/screens/GroupScreen/assets/CalendarBlank.svg">
              <span class="group-analytics-price__text">
                {{ element.fulldate }}
              </span>
            </div>
            <div class="group-analytics-price__history-element-price">
              <span class="group-analytics-price__text_history-price group-analytics-price__text_history-price-left">
                {{ getHistoryValue(element.oldValue) }}
              </span>
              <TJIcon
              name="arrow_forward"
              class="group-analytics-price__text group-analytics-price__text_history-price-left"
              :size="20" />
              <span class="group-analytics-price__text_history-price group-analytics-price__text_blue">
                {{ getHistoryValue(element.value) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupPriceChart from '@/components/MainGroups/components/Group/components/GroupPriceChart.vue';

export default {
  name: 'GroupAnalyticsPrice',
  components: { GroupPriceChart },
  props: {
    group: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    priceStatistics: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    priceData: null,
    historyData: [],
    currencySymbols: [
      {
        name: '₽',
        value: 'rub',
      },
      {
        name: 'USD',
        value: 'usd',
      },
      {
        name: 'KZT',
        value: 'kzt',
      },
      {
        name: 'UZS',
        value: 'uzs',
      },
    ],
  }),
  created() {
    this.createPriceData();
  },
  methods: {
    createPriceData() {
      // NEED TRANSLATE
      this.priceData = [
        { name: 'Актуальная стоимость', value: this.getPrice(), main: true },
        { name: 'CTR рекламных постов', value: this.group.ctr || '—', main: false },
        { name: 'СРС - Средняя стоимость клика', value: this.group.cpc ? `${this.group.cpc} ${this.getCurrencySymbol()}` : '—', main: false },
      ];
    },
    getCurrencySymbol() {
      const currency = this.$store.getters['groups/getCurrency'];
      return this.currencySymbols.find((item) => item.value === currency).name;
    },
    getPrice() {
      if (this.group.postPrice.sell === null) {
        return '—';
      }
      return `${this.setNumberSpaces(Math.round(this.group.postPrice.sell))} ${this.getCurrencySymbol()}`;
    },
    getHistoryValue(value) {
      // FUTURE валюта ?
      return `${this.setNumberSpaces(Math.round(value))} ₽`;
    },
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 1080;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-analytics-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  padding: 32px 32px 45px;
  border-radius: 20px;
  box-shadow: 0px 4px 23px rgba(0, 174, 232, 0.12);
  width: 100%;
}

.group-analytics-price__text {
  font-size: 14px;
  &_title {
    font-size: 22px;
    font-weight: 700;
  }
  &_light {
    font-size: 14px;
    color: $primary-lightgray;
  }
  &_white {
    color: white;
  }
  &_blue {
    color: $primary-blue;
  }
  &_history-date {
    font-size: 12px;
    color: $primary-lightgray;
    min-width: 80px;
    @include desktop-screen {
      font-size: 16px;
      min-width: 100px;
    }
  }
  &_history-price {
    font-size: 14px;
    text-align: right;
    color: $primary-blue;
    font-weight: 600;
    @include desktop-screen {
      font-size: 22px;
    }
  }
  &_history-price-left {
    font-size: 14px;
    text-align: left;
    color: #7B7B7B;
    @include desktop-screen {
      font-size: 22px;
    }
  }
}

.group-analytics-price__container {
  display: block;
  margin-top: 25px;
  width: 100%;
  @include max-screen {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 30px;
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: 2fr 1fr;
  }
  &_empty {
    grid-template-columns: 1fr;
  }
}

.group-analytics-price__content {
  display: flex;
  flex-direction: column;
}

.group-analytics-price__price-block {
  width: 100%;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  grid-template-rows: 3;
  @include tablet-screen {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1;
  }
}

.group-analytics-price__price-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  padding: 16px;
  background: $primary-blue;
  border-radius: 20px;
  @include tablet-screen {
    align-items: flex-start;
    justify-content: space-between;
  }
  &_second {
    background: #F0F9FC;
  }
}

.group-analytics-price__history {
  display: none;
  @include tablet-screen {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 25px;
    max-height: 160px;
  }
  @include desktop-screen {

  }
  @include max-screen {
    margin-top: 0px;
    max-height: 430px;
  }
}

.group-analytics-price__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 88px;
  border-radius: 20px;
  margin-top: 20px;
  background: white;
  border: 1px solid #D8F2FA;
  padding: 0px 20px;
  text-align: center;
  @include desktop-screen {
    height: 300px;
  }
  @include between-elements {
    margin-bottom: 20px;
  }
}

.group-analytics-price__loader {
  display: none;
  @include desktop-screen {
    display: block;
    width: 50px;
    height: 50px;
  }
}

.group-analytics-price__history-elements {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  margin-top: 15px;
  padding-right: 5px;
  @include between-elements {
    margin-bottom: 10px;
  }
  @include desktop-screen {
    margin-top: 30px;
    @include between-elements {
      margin-bottom: 20px;
    }
  }
}

.group-analytics-price__history-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #D8F2FA;
  @include desktop-screen {
    padding-bottom: 20px;
  }
}

.group-analytics-price__history-date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-analytics-price__history-element-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include between-elements {
    margin-right: 20px;
  }
}

.group-analytics-price__chart {
  margin-top: 20px;
  height: 320px;
  width: 100%;
}
</style>
