<template>
  <transition name="fade">
    <div
    v-if="showSnackbar"
    class="tj-snackbar">
      <div
      class="tj-snackbar__snackbar"
      :class="isError && 'tj-snackbar__snackbar_error'">
        <div class="tj-snackbar__content">
          <span>{{ text }}</span>
        </div>
        <TJIcon
        name="close"
        :size="20"
        class="tj-snackbar__icon"
        @click.native="$emit('close')" />
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'TJSnackbar',
  props: {
    showSnackbar: {
      type: Boolean,
      default: false,
    },
    closeTimeout: {
      type: Number,
      default: 2500,
    },
    text: {
      type: String,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showSnackbar() {
      setTimeout(() => {
        this.$emit('close');
      }, this.closeTimeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.tj-snackbar {
  position: fixed;
  display: flex;
  justify-content: center;
  left:50%;
  top:10px;
  transform:translate(-50%);
  z-index: 20;
}

.tj-snackbar__snackbar {
  width: 30vw;
  min-width: 300px;
  max-width: 500px;
  height: 100%;
  min-height: 60px;
  color: $primary-white;
  font-size: 14px;
  background: $primary-blue;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  &_error {
    background: $primary-red;
  }
}

.tj-snackbar__content {
  padding: 10px 40px 10px 20px;
}

.tj-snackbar__icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

</style>
