import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    initial: true,
    selectedGroups: [],
    groupsSelectedData: [],
    groupsFavoriteData: [],
    collectionsData: [],
    collectionsList: [],
    collectionSelected: [],
    selectedAllGroups: false,
    //
    currentCollectionId: null,
    isShowSelected: false,
    isShowFavorite: false,
    isShowCollection: false,
    isLoading: false,
    loadCompilation: [],
    loadParams: {
      'page-size': 50,
      page: 1,
    },
    totalPageCount: null,
    compilationInfo: null,

    sortDir: 'desc',
    sortBy: 'subscribersCount',
  },
  getters: {
    getCollectionsIsLoading: (state) => state.isLoading,
    //
    getTotalPageCounts: (state) => state.totalPageCount,
    getCurrentPage: (state) => state.loadParams.page,
    //
    getSelectedGroups: (state) => state.selectedGroups,
    getSelectedGroupsData: (state) => state.groupsSelectedData,
    getFavoriteGroupsData: (state) => state.groupsFavoriteData,
    getCollectionsData: (state) => state.collectionsData,
    getCollectionsList: (state) => state.collectionsList,
    //
    getCurrentCollectionId: (state) => state.currentCollectionId,
    getCurrentCollection: (state) => state.collectionsData.find((collection) => collection.id === state.currentCollectionId),
    getLoadCompilation: (state) => state.loadCompilation,
    //
    getShowSelectedGroups: (state) => state.isShowSelected,
    getShowFavoriteGroups: (state) => state.isShowFavorite,
    getShowCollectionGroups: (state) => state.isShowCollection,
    //
    getGroupsSortBy: (state) => state.sortBy,
    getGroupsSortDir: (state) => state.sortDir,
    //
    getSelectedAllGroups: (state) => state.selectedAllGroups,
  },
  mutations: {
    setCollectionsIsLoading: (state, value) => { state.isLoading = value; },
    setGroupsSelectedData: (state, value) => { state.groupsSelectedData = value; },
    setSelectedGroups: (state, value) => { state.selectedGroups = value; },
    setTotalPageCount: (state, value) => { state.totalPageCount = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    //
    setGroupsFavoriteData: (state, value) => { state.groupsFavoriteData = value; },
    setCollectionsData: (state, value) => { state.collectionsData = value; },
    setCollectionsList: (state, value) => { state.collectionsList = value; },
    setCurrentCollectionId: (state, value) => { state.currentCollectionId = value; },
    setCompilationInfo: (state, value) => { state.compilationInfo = value; },
    setLoadCompilation: (state, value) => { state.loadCompilation = value; },
    setSelectedAllGroups: (state, value) => { state.selectedAllGroups = value; },
    //
    setSortBy: (state, value) => { state.sortBy = value; },
    setSortDir: (state, value) => { state.sortDir = value; },
    //
    setShowSelectedGroups: (state, value) => { state.isShowSelected = value; },
    setShowFavoriteGroups: (state, value) => {
      if (value) {
        state.collectionSelected = state.selectedGroups;
        state.isShowCollection = false;
        state.isShowSelected = false;
        state.selectedGroups = [];
      } else {
        state.selectedGroups = state.collectionSelected;
        state.collectionSelected = [];
      }
      state.isShowFavorite = value;
    },
    setShowCollectionGroups: (state, value) => {
      if (value) {
        // перед открытием подборки сохраняем в буфер ранее выбранные каналы
        state.collectionSelected = state.selectedGroups;
        state.selectedGroups = [];
        state.isShowFavorite = false;
      } else {
        // если в буфере есть выбранные каналы, возвращаем их
        if (state.collectionSelected.length) {
          state.selectedGroups = state.collectionSelected;
        }
        state.currentCollectionId = null;
        state.collectionSelected = [];
        state.loadCompilation = [];
        state.collectionsData = [];
        state.sortDir = 'desc';
        state.sortBy = 'subscribersCount';
      }
      state.isShowCollection = value;
    },
    resetAllShow: (state) => {
      state.isShowSelected = false;
      state.isShowFavorite = false;
      state.isShowCollection = false;
    },
  },
  actions: {
    getUserFavoritesList({ commit }) {
      return api.get('/favourites')
        .then(({ data: { data } }) => {
          commit('setGroupsFavoriteData', data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getUserFavoritesList --; Error -', error);
          return Promise.reject();
        });
    },

    addGroupsToFavorites({ state, commit }, groups) {
      const newFavorites = groups.filter((group) => !state.groupsFavoriteData.some((item) => item.id === group.id && item.name === group.name));

      if (!newFavorites.length) {
        return Promise.resolve();
      }

      const groupsToAdd = {
        groupIdentities: newFavorites.map((item) => ({
          id: item.id,
          social: item.social,
        })),
      };

      return api.post('/favourites', groupsToAdd)
        .then(() => {
          commit('setGroupsFavoriteData', [...newFavorites, ...state.groupsFavoriteData]);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- addGroupsToFavorite --; Error -', error);
          return Promise.reject();
        });
    },

    removeGroupsFromFavorites({ state, commit }, groups) {
      const groupsToRemove = {
        groupIdentities: groups.map((item) => ({
          id: item.id,
          social: item.social,
        })),
      };
      return api.post('/favourites/delete-groups', groupsToRemove)
        .then(() => {
          const newFavorites = state.groupsFavoriteData.filter((group) => !groups.some((item) => item.id === group.id && item.name === group.name));
          commit('setGroupsFavoriteData', newFavorites);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- removeGroupsFromFavorites --; Error -', error);
          return Promise.reject();
        });
    },

    removeGroupsFromCompilation({ state, getters, commit }, { id, groups }) {
      const groupsToDelete = {
        groupIdentities: groups.map((item) => ({
          id: item.id,
          social: item.social,
        })),
      };
      return api.post(`/compilations/${id}/delete-groups`, groupsToDelete)
        .then(() => {
          const newGroups = getters.getCurrentCollection.groups.filter((group) => !groups.some((item) => item.id === group.id && item.name === group.name));

          const newCollections = state.collectionsData.map((item) => {
            if (item.id === state.currentCollectionId) {
              item.groups = [...newGroups];
              return item;
            }
            return item;
          });

          commit('setSelectedGroups', []);
          commit('setCollectionsData', newCollections);

          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- removeGroupsFromCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    addGroupsToCompilation({ commit }, { id, groups }) {
      const groupsToAdd = {
        groupIdentities: groups.map((item) => ({
          id: item.id,
          social: item.social,
        })),
      };
      return api.post(`/compilations/${id}/add-groups`, groupsToAdd)
        .then(() => Promise.resolve())
        .catch((error) => {
          commit('setCollectionsIsLoading', false);
          console.warn('Error in action -- addGroupsToCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    createNewCompilation({ state, commit }, { name, groups }) {
      commit('setCollectionsIsLoading', true);
      const groupIdentities = groups.map((group) => ({ id: group.id, social: group.social }));
      const compilation = { name, groupIdentities };

      return api.post('/compilations', compilation)
        .then(({ data: { data } }) => {
          commit('setCollectionsList', [data, ...state.collectionsList]);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- createNewCompilation --; Error -', error);
          return Promise.reject();
        })
        .finally(() => {
          commit('setCollectionsIsLoading', false);
        });
    },

    deleteCompilation({ state, commit }, id) {
      return api.delete(`/compilations/${id}`)
        .then(() => {
          const newCompilationList = state.collectionsList.filter((compilation) => compilation.id !== id);
          commit('setCollectionsList', newCompilationList);
          commit('setCollectionsIsLoading', false);
          return Promise.resolve();
        })
        .catch((error) => {
          commit('setCollectionsIsLoading', false);
          console.warn('Error in action -- createNewCompilation --; Error -', error);
          return Promise.reject();
        });
    },

    renameCompilation({ commit }, { id, name }) {
      commit('setCollectionsIsLoading', true);
      const compilation = {
        name,
      };
      return api.put(`/compilations/${id}`, compilation)
        .then(() => Promise.resolve())
        .catch((error) => {
          console.warn('Error in action -- createNewCompilation --; Error -', error);
          return Promise.reject();
        })
        .finally(() => {
          commit('setCollectionsIsLoading', false);
        });
    },

    addedLoadGroupsInCollection({ state, commit }, groups) {
      const newCollections = state.collectionsData.map((item) => {
        if (item.id === state.currentCollectionId) {
          item.groups = [...item.groups, ...groups];
          return item;
        }
        return item;
      });
      commit('setCollectionsData', newCollections);
    },

    getUserCompilationsList({ state, commit }) {
      if (!state.initial) return false;

      return api.get('/compilations')
        .then(({ data: { data } }) => {
          state.initial = false;
          commit('setCollectionsList', [...state.collectionsList, ...data.reverse()]);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getUserCompilationsList --; Error -', error);
          return Promise.reject();
        })
        .finally(() => {
          commit('setCollectionsIsLoading', false);
        });
    },

    sendCollection({ commit }, groups) {
      return api.post('/placement', groups)
        .then(() => {
          commit('setSelectedGroups', []);
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- sendCollection --; Error -', error);
          return Promise.reject();
        });
    },

    async getCurrentCompilation({
      state, commit, dispatch, rootState,
    }, params) {
      commit('setCollectionsIsLoading', true);

      const headers = {};
      const { currency } = rootState.groups;
      if (currency !== 'rub') {
        headers.currency = currency;
      }

      commit('setShowSelectedGroups', false);

      if (!state.isShowCollection) {
        commit('setShowCollectionGroups', true);
      }

      if (params.isNew) {
        commit('setSelectedGroups', []);
        commit('setLoadCompilation', []);
        commit('setTotalPageCount', null);
        commit('setLoadPage', 1);
        commit('setCompilationInfo', {
          id: params.id, name: params.name, shareToken: params.shareToken, isLoaded: params.isLoaded,
        });
        commit('setSelectedAllGroups', false);
      }

      return api.get(`/compilations/${state.compilationInfo.id}/positions`, {
        params: {
          ...state.loadParams, shareToken: state.compilationInfo.shareToken, sortBy: state.sortBy, sortDirection: state.sortDir,
        },
        headers,
      })
        .then(async (response) => {
          const groups = response.data.data.map((group) => group.group);

          let compilationName = `C - ${state.compilationInfo.id}`;

          // загружаем инфо о подборке для извлечения названия (если подборка загружаемая не из списка подборок)
          if (params.isNew && params.isLoaded) {
            await api.get(`/compilations/${state.compilationInfo.id}`, {
              params: {
                shareToken: state.compilationInfo.shareToken,
              },
            })
              .then(({ data: { data } }) => {
                compilationName = data[0].name.includes('noname') ? compilationName : data[0].name;
              });
          }

          const compilation = {
            name: compilationName, id: state.compilationInfo.id, isLoaded: params.isLoaded, groups, shareToken: state.compilationInfo.shareToken,
          };

          if (params.isNew && state.compilationInfo.isLoaded) {
            commit('setLoadCompilation', [...state.loadCompilation, ...response.data.data]);
            commit('setCollectionsData', [compilation, ...state.collectionsData]);
            commit('setCurrentCollectionId', state.compilationInfo.id);
            dispatch('getCompilationSummary', state.compilationInfo.shareToken);
          }

          if (!params.isNew && state.compilationInfo.isLoaded) {
            commit('setLoadCompilation', [...state.loadCompilation, ...response.data.data]);
            dispatch('addedLoadGroupsInCollection', groups);
          }

          if (params.isNew && !state.compilationInfo.isLoaded) {
            commit('setCollectionsData', [compilation, ...state.collectionsData]);
            commit('setCurrentCollectionId', state.compilationInfo.id);
          }

          if (!params.isNew && !state.compilationInfo.isLoaded) {
            dispatch('addedLoadGroupsInCollection', groups);
          }

          commit('setLoadPage', state.loadParams.page + 1);
          commit('setTotalPageCount', response.headers['x-pagination-page-count']);

          return Promise.resolve(response);
        })

        .catch((error) => {
          commit('setShowCollectionGroups', false);
          console.warn('Error in action getCompilations; Error -', error);
          return Promise.reject(error);
        })
        .finally(() => {
          commit('setCollectionsIsLoading', false);
        });
    },

    shareCollection({ commit }, collection) {
      return api.post('/compilations/create-and-share', collection)
        .then(({ data: { data } }) => {
          commit('setCollectionsIsLoading', false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('setCollectionsIsLoading', false);
          console.warn('Error in action shareCollection; Error -', error);
          return Promise.reject(error);
        });
    },

    getCompilationSummary({ state, commit, rootState }, shareToken) {
      const headers = {};
      const { currency } = rootState.groups;
      if (currency !== 'rub') {
        headers.currency = currency;
      }

      const params = {
        compilationId: state.compilationInfo.id,
        summaryTypes: [
          'countSelectedGroups',
          'sumPostPriceSell',
          'sumPostPriceSale',
          'sumPostPriceBuy',
          'sumSubscribersCount',
          'sumViewsLast24hCount',
          'sumViewsStatisticCount',
          'sumTradeOffer',
        ],
      };
      return api.post('/summary/calculate', params, { headers, params: { shareToken } })
        .then(({ data: { data } }) => {
          const newCollections = state.collectionsData.map((item) => {
            if (item.id === state.currentCollectionId) {
              item.stats = { ...data };
              return item;
            }
            return item;
          });
          commit('setCollectionsData', newCollections);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action getCompilationSummary; Error -', error);
          return Promise.reject(error);
        });
    },

    changeCollectionPrice({ commit }, collectionData) {
      commit('setCollectionsIsLoading', true);
      return api.post('/compilations/trade-offer', collectionData)
        .then(({ data: { data } }) => {
          commit('setCollectionsIsLoading', false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          commit('setCollectionsIsLoading', false);
          console.warn('Error in action changeCollectionPrice; Error -', error);
          return Promise.reject(error);
        });
    },

  },
};
