<template>
  <div class="export-groups">
    <div class="export-groups__header-block">
      <span class="export-groups__text export-groups__text_title">{{ getExportTitle }}</span>
      <span class="export-groups__text export-groups__text_subtitle">{{ $t('select_the_required_columns_to_export') }}</span>
    </div>
    <div class="export-groups__items">
      <div
      v-for="column in selectedColumns"
      :key="column.value"
      class="export-groups__item">
        <TJCheckbox
        :checked.sync="column.set" />
        <span class="export-groups__text export-groups__text_subtitle">{{ $t(column.name) }}</span>
      </div>
    </div>
    <div class="export-groups__line" />
    <div class="export-groups__additional">
      <div class="export-groups__select">
        <span
        class="export-groups__text export-groups__text_touch"
        @click="setAll(true)">{{ $t('select_all') }}</span>
        <span
        class="export-groups__text export-groups__text_touch"
        @click="setAll(false)">{{ $t('reset_all') }}</span>
      </div>
      <div
      v-if="isAdmin && !exportCollection"
      class="export-groups__select">
        <span
        v-title="`${$t('All channels of the social network you are on will be exported')}`"
        class="export-groups__text export-groups__text_touch export-groups__text_small"
        @click="exportAllGroups">{{ $t('export_all_filtered_channels') }}</span>
        <TJIcon
        v-title="`${$t('All channels of the social network you are on will be exported')}`"
        name="help_outline"
        class="export-groups__text_small"
        :size="16"
        pointer />
      </div>
    </div>
    <span
    v-show="lengthFieldsError || lenghtSelectedError"
    class="export-groups__text export-groups__text_error">
      {{ getErrorText }}
    </span>
    <div class="export-groups__button-block">
      <TJButton
      :text="$t('export2')"
      @handler="exportSelectedGroups" />
      <TJButton
      :text="$t('cancel')"
      type="outlined"
      @handler="close" />
    </div>
    <AppLoader
    v-if="loading" />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ExportGroups',
  props: {
    mode: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    columnsTemplate: [
      {
        name: 'name2',
        value: 'name',
        set: true,
      },
      {
        name: 'link',
        value: 'url',
        set: true,
      },
      {
        name: 'avatar',
        value: 'avatar',
        set: true,
        admin: true,
      },
      {
        name: 'description',
        value: 'description',
        set: true,
      },
      {
        name: 'categories',
        value: 'categories',
        set: true,
      },
      {
        name: 'subscribers_count',
        value: 'subscribersCount',
        set: true,
      },
      {
        name: 'views_24h',
        value: 'viewsCountLast24h',
        set: true,
      },
      {
        name: 'views_48h',
        value: 'viewsCountLast48h',
        set: true,
      },
      {
        name: 'views_72h',
        value: 'viewsCountLast72h',
        set: true,
      },
      {
        name: 'price_sell',
        value: 'postPriceSell',
        set: true,
      },
      {
        name: 'price_sale',
        value: 'postPriceSale',
        set: true,
        admin: true,
      },
      {
        name: 'price_buy',
        value: 'postPriceBuy',
        set: true,
        admin: true,
      },
      {
        name: 'cpm',
        value: 'cpm',
        set: true,
      },
      {
        name: 'posts_day',
        value: 'postsCountLast24h',
        set: true,
      },
      {
        name: 'posts_week',
        value: 'postsCountLastWeek',
        set: true,
      },
      {
        name: 'posts_month',
        value: 'postsCountLastMonth',
        set: true,
      },
      {
        name: 'er',
        value: 'er',
        set: true,
      },
      {
        name: 'male_percent',
        value: 'subscribersMalePercent',
        set: true,
      },
      {
        name: 'female_percent',
        value: 'subscribersFemalePercent',
        set: true,
      },
      {
        name: 'contacts',
        value: 'contact',
        set: true,
        admin: true,
      },
      {
        name: 'comment',
        value: 'comment',
        set: true,
        admin: true,
      },
      {
        name: 'monthly_subscriber_growth',
        value: 'subscribersMonthGrow',
        set: true,
      },
      {
        name: 'payment_type',
        value: 'paymentType',
        set: true,
        admin: true,
      },
      {
        name: 'status',
        value: 'status',
        set: true,
        admin: true,
      },
      {
        name: 'in_socialjet',
        value: 'inSocialjet',
        set: true,
        admin: true,
      },
      {
        name: 'in_archived',
        value: 'isArchived',
        set: true,
        admin: true,
      },
      {
        name: 'blogger',
        value: 'isBlogger',
        set: true,
        admin: true,
      },
      {
        name: 'tags',
        value: 'tags',
        set: true,
        admin: true,
      },
      {
        name: 'bot_name',
        value: 'botName',
        set: true,
        admin: true,
      },
      {
        name: 'update',
        value: 'updatedAt',
        set: true,
      },
      {
        name: 'Date of manual price update',
        value: 'postManualUpdatedAt',
        set: true,
        admin: true,
      },
      {
        name: 'post_price_updater',
        value: 'postPriceUpdater',
        set: true,
        admin: true,
      },
      {
        name: 'advertising_label',
        value: 'advertisingLabel',
        set: true,
        // admin: true,
      },
      {
        name: 'Type of placement',
        value: 'placement',
        set: true,
        // admin: true,
      },
      {
        name: 'location',
        value: 'subscribersCountries',
        set: true,
        admin: true,
      },
      {
        name: 'city',
        value: 'subscribersCities',
        set: true,
        admin: true,
      },
      {
        name: 'cpc',
        value: 'cpc',
        set: true,
      },
      {
        name: 'robot',
        value: 'roboticity',
        set: true,
      },
      {
        name: 'Age',
        value: 'subscribersAge',
        set: true,
      },
      {
        name: 'interests',
        value: 'interests',
        set: true,
      },
    ],
    selectedColumns: null,
    loading: false,
    lengthFieldsError: false,
    lenghtSelectedError: false,
  }),
  created() {
    // FUTURE старые копии колонок удалить
    localStorage.removeItem('savedExportColumns230922');
    localStorage.removeItem('savedExportColumns211022');
    localStorage.removeItem('savedExportColumns221022');
    //
    const savedColumns = JSON.parse(localStorage.getItem('savedExportColumns251022'));

    if (this.isAdmin) {
      this.selectedColumns = savedColumns || cloneDeep(this.columnsTemplate);
    } else {
      const notAdminColumns = this.columnsTemplate.filter((item) => !item.admin);
      this.selectedColumns = savedColumns || notAdminColumns;
    }
  },
  methods: {
    close() {
      if (this.loading) return;
      this.$emit('close');
    },
    setAll(value) {
      this.selectedColumns.forEach((item) => {
        item.set = value;
      });
    },
    resetErrors() {
      this.lengthFieldsError = false;
      this.lenghtSelectedError = false;
    },
    saveColumns() {
      localStorage.setItem('savedExportColumns251022', JSON.stringify(this.selectedColumns));
    },
    exportSelectedGroups() {
      this.resetErrors();
      this.saveColumns();

      const groupIdentities = this.getSelectedGroups.map((group) => ({ id: group.id, social: group.social }));

      if (!groupIdentities.length) {
        this.lenghtSelectedError = true;
        return;
      }

      const collectionId = this.exportCollection?.id;

      const fields = this.selectedColumns.filter((item) => item.set).map((item) => item.value);
      if (!fields.length) {
        this.lengthFieldsError = true;
        return;
      }
      this.loading = true;

      const params = {};

      if (this.mode === 'noauth' || collectionId) {
        params.shareToken = this.$route.query.shareToken;
        params.compilationId = collectionId;
        params.fields = fields;
        params.groupIdentities = this.getSelectedAllGroups ? null : groupIdentities;
      } else {
        params.fields = fields;
        params.groupIdentities = groupIdentities;
      }

      this.$store.dispatch('groups/exportGroups', params)
        .then(() => {
          this.loading = false;
          this.$store.commit('collections/setSelectedAllGroups', false);
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Successfully. Open the downloaded file') });
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('export_error_reload_the_page_and_try_again'),
              error: true,
            });
          this.$emit('close');
        });
    },
    exportAllGroups() {
      this.resetErrors();
      this.saveColumns();
      const fields = this.selectedColumns.filter((item) => item.set).map((item) => item.value);
      if (!fields.length) {
        this.lengthFieldsError = true;
        return;
      }

      const params = {
        fields,
        ...this.getSearchParams,
      };

      this.loading = true;
      this.$store.dispatch('groups/exportGroups', params)
        .then(() => {
          this.loading = false;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Successfully. Open the downloaded file'), error: false });
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('export_error_reload_the_page_and_try_again'),
              error: true,
            });
          this.$emit('close');
        });
    },
  },
  computed: {
    getSelectedGroups() {
      return this.$store.getters['collections/getSelectedGroups'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getSearchParams() {
      return this.$store.getters['groups/getGroupsSearchParams'];
    },
    getErrorText() {
      if (this.lengthFieldsError) {
        return this.$t('to_export_you_must_select_at_least_1_column');
      }
      return this.$t('to_export_you_must_select_at_least_1_channel');
    },
    getExportTitle() {
      return this.exportCollection
        ? `${this.$t('export_compilation')} ${this.exportCollection.name}`
        : this.$t('export_channels');
    },
    getSelectedAllGroups() {
      return this.$store.getters['collections/getSelectedAllGroups'];
    },
    exportCollection() {
      if (this.isShowCollectionGroups) {
        return this.$store.getters['collections/getCurrentCollection'];
      }
      return null;
    },
    isAdmin() {
      return this.$store.getters['auth/getIsAdmin'];
    },
    isShowCollectionGroups() {
      return this.$store.getters['collections/getShowCollectionGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.export-groups {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @include desktop-screen {
    width: 900px;
  }
}

.export-groups__header-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
}

.export-groups__text {
  color: $primary-black;
  font-size: 14px;
  &_title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  &_touch {
    cursor: pointer;
  }
  &_error {
    color: $primary-red;
  }
  &_small {
    font-size: 12px;
    color: $primary-lightgray;
  }
}

.export-groups__items {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 10px;
  max-height: 480px;
  @include between-elements {
    margin-bottom: 5px;
  }
  @include desktop-screen {
    flex-direction: column;
      flex-wrap: wrap;
  }
}

.export-groups__item {
  display: flex;
  width: 270px;
  align-items: center;
  margin-right: 10px;
  @include between-elements {
    margin-right: 10px;
  }
}

.export-groups__button-block {
  padding-top: 20px;
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.export-groups__line {
  height: 0;
  border: none;
  border-top: 1px solid rgb(212, 212, 212);
}

.export-groups__additional {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.export-groups__select {
  display: flex;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.export-group__loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  border-radius: 12px;
}
</style>
