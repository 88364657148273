var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeOutside),expression:"closeOutside"}],staticClass:"sidebar",class:_vm.getOpenSidebar && 'sidebar_open'},[_c('div',{staticClass:"sidebar-wrapper",class:_vm.getOpenSidebar && 'sidebar-wrapper_open'},[_c('div',{staticClass:"sidebar__menu"},[_c('div',{staticClass:"sidebar__pages"},_vm._l((_vm.getLinksData),function(page){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(!page.link && 'Скоро'),expression:"!page.link && 'Скоро'",modifiers:{"right":true}}],key:page.id,staticClass:"sidebar__page",class:!page.link && 'sidebar__page_disabled',on:{"click":function($event){return _vm.goTo(page)}}},[_c('img',{staticClass:"sidebar__icon",class:!page.link && 'sidebar__icon_disabled',attrs:{"src":require(`./assets/${_vm.$route.name === page.link ? `${page.icon}-active` : page.icon}.svg`)}}),_c('span',{staticClass:"sidebar__text",class:[
            _vm.$route.name === page.link && 'sidebar__text_active',
            !page.link && 'sidebar__text_disabled'
          ]},[_vm._v(" "+_vm._s(page.name)+" ")])])}),0),_c('div',{staticClass:"sidebar__helps"},_vm._l((_vm.helps),function(page){return _c('div',{directives:[{name:"title",rawName:"v-title",value:(!page.link && 'Скоро'),expression:"!page.link && 'Скоро'"}],key:page.id,staticClass:"sidebar__page",class:!page.link && 'sidebar__page_disabled',on:{"click":function($event){return _vm.goTo(page)}}},[_c('img',{staticClass:"sidebar__icon",class:!page.link && 'sidebar__icon_disabled',attrs:{"src":require(`./assets/${_vm.$route.name === page.link ? `${page.icon}-active` : page.icon}.svg`)}}),_c('span',{staticClass:"sidebar__text",class:[
            _vm.$route.name === page.link && 'sidebar__text_active',
            !page.link && 'sidebar__text_disabled'
          ]},[_vm._v(" "+_vm._s(page.name)+" ")])])}),0)]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar__tarifs"},[_c('div',{staticClass:"sidebar__tarif-title"},[_c('img',{staticClass:"sidebar__icon",attrs:{"src":require("./assets/rate.svg")}}),_c('span',{staticClass:"sidebar__text"},[_vm._v(" Тариф ")])]),_c('div',{staticClass:"sidebar__tarif"},[_c('span',{staticClass:"sidebar__text sidebar__text_white sidebar__text_bold"},[_vm._v("Скоро!")]),_c('span',{staticClass:"sidebar__text sidebar__text_white sidebar__text_small"},[_vm._v("Большое обновление SocialData")]),_c('div',{staticClass:"sidebar__tarif-button"},[_c('span',{staticClass:"sidebar__text sidebar__text_white sidebar__text_small"},[_vm._v("Подробнее")]),_c('div',{staticClass:"sidebar__update-info"},[_c('div',{staticClass:"sidebar__update-info-check"},[_c('img',{attrs:{"src":require("./assets/check-icon.png")}})]),_c('span',{staticClass:"sidebar__text sidebar__text_bold"},[_vm._v("В ближайшее время будут добавлены новые разделы:")]),_c('div',{staticClass:"sidebar__update-info-block"},[_c('p',{staticClass:"sidebar__text"},[_vm._v(" 1. Ваши запросы ")]),_c('p',{staticClass:"sidebar__text"},[_vm._v(" 2. Ваши избранные подборки и каналы ")]),_c('p',{staticClass:"sidebar__text"},[_vm._v(" 3. Созданные и запущенные рекламные кампании ")]),_c('p',{staticClass:"sidebar__text"},[_vm._v(" 4. Доступы, тарифы и роли для агентств ")])])])])])])
}]

export { render, staticRenderFns }