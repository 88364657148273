import NotFound from '../screens/NotFound/NotFound.vue';
import MainScreen from '../screens/MainScreen/MainScreen.vue';
import GroupScreen from '../screens/GroupScreen/GroupScreen.vue';
import MainLanding from '../screens/MainLanding/MainLanding.vue';
import CompilationScreen from '../screens/CompilationScreen/CompilationScreen.vue';
import Login from '../screens/LoginScreen/LoginScreen.vue';
import Publishers from '../screens/Publishers/Publishers.vue';
import ImportsScreen from '../screens/ImportsScreen/ImportsScreen.vue';
import MyGroupsScreen from '../screens/MyGroupsScreen/MyGroupsScreen.vue';
import GroupsModerationScreen from '../screens/GroupsModerationScreen/GroupsModerationScreen.vue';
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen.vue';
import ClientsScreen from '../screens/ClientsScreen/ClientsScreen.vue';
import TariffsScreen from '../screens/TariffsScreen/TariffsScreen.vue';

import store from '../store/index';

const routes = [
  {
    name: 'main-landing',
    path: '/',
    component: MainLanding,
    beforeEnter: (from, to, next) => {
      if (localStorage.getItem('userToken')) {
        const query = { group: 'telegram' };
        next({ name: 'main-groups', query });
      } else {
        next();
      }
    },
  },
  {
    name: 'main-groups',
    path: '/groups',
    component: MainScreen,
    beforeEnter: (from, to, next) => {
      if (!store.state.groups.initial) {
        next();
        return;
      }

      const {
        group, compilation, shareToken, ...searchParams
      } = from.query;

      if (searchParams) {
        const currentParams = Object.assign(store.getters['groups/getGroupsSearchParams'], searchParams);

        if (typeof currentParams.categoriesId === 'string') {
          currentParams.categoriesId = [currentParams.categoriesId];
        }
        if (typeof currentParams.subscriberCountries === 'string') {
          currentParams.subscriberCountries = [currentParams.subscriberCountries];
        }
        if (typeof currentParams.inspectReportWarningCodes === 'string') {
          currentParams.inspectReportWarningCodes = [currentParams.inspectReportWarningCodes];
        }
        if (typeof currentParams.interestsId === 'string') {
          currentParams.interestsId = [currentParams.interestsId];
        }
        store.commit('groups/setGroupsSearchParams', currentParams);
      }
      store.commit('collections/setSelectedGroups', []);
      store.commit('groups/setSelectedTypeOfGroups', group || 'telegram');

      store.dispatch('groups/loadGroupsData', { reset: true, aggregate: true }).then(() => {
        if (compilation) {
          store.dispatch('collections/getCurrentCompilation', {
            id: compilation, shareToken, isNew: true, isLoaded: true,
          }).then(() => {
            next();
          })
            .catch(() => {
              store.commit('app/setAppSnackBarTimeout', 4500);
              store.dispatch('app/setSnackBar', {
                text: 'Произошла ошибка при загрузке подборки',
                error: true,
              });
              next({ name: 'main-groups' });
            });
        } else {
          next();
        }
      });
    },
  },
  {
    name: 'group',
    path: '/group/:social/:id',
    component: GroupScreen,
  },
  {
    name: 'compilation',
    path: '/compilation',
    component: CompilationScreen,
    beforeEnter: (from, to, next) => {
      const { id, shareToken } = from.query;
      if (!shareToken && !localStorage.getItem('userToken')) {
        next({ name: 'main-landing' });
        return;
      }

      if (localStorage.getItem('userToken')) {
        const query = { group: 'telegram', compilation: id, shareToken };
        next({ name: 'main-groups', query });
        return;
      }

      store.dispatch('collections/getCurrentCompilation', {
        id, shareToken, isNew: true, isLoaded: true, noauth: true,
      })
        .then(() => {
          next();
        })
        .catch(() => {
          // если подборка не загружена
          next({ name: 'main-landing' });
          store.commit('app/setAppSnackBarTimeout', 4500);
          store.dispatch('app/setSnackBar', {
            text: 'При загрузке подборки произошла ошибка. Проверьте ссылку (ID подборки и токен)',
            error: true,
          });
        });
    },
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: (from, to, next) => {
      if (localStorage.getItem('userToken')) {
        next({ name: 'main-groups' });
      } else {
        next();
      }
    },
  },
  {
    name: 'imports-screen',
    path: '/imports',
    component: ImportsScreen,
    beforeEnter: (from, to, next) => {
      // FUTURE вывести проверку на адмна в middleware
      const isAdmin = store.getters['auth/getIsAdmin'];
      if (!isAdmin) {
        next({ name: 'main-groups' });
      } else {
        next();
      }
    },
  },
  {
    name: 'my-groups',
    path: '/my-groups',
    component: MyGroupsScreen,
  },
  {
    name: 'not-found',
    path: '*',
    component: NotFound,
  },
  {
    name: 'publishers',
    path: '/publishers',
    component: Publishers,
  },
  {
    name: 'moderation',
    path: '/moderation',
    component: GroupsModerationScreen,
    beforeEnter: (from, to, next) => {
      // FUTURE вывести проверку на адмна в middleware
      const isAdmin = store.getters['auth/getIsAdmin'];
      if (!isAdmin) {
        next({ name: 'main-groups' });
      } else {
        next();
      }
    },
  },
  {
    name: 'profile',
    path: '/profile',
    component: ProfileScreen,
  },
  {
    name: 'clients',
    path: '/clients',
    component: ClientsScreen,
    beforeEnter: (from, to, next) => {
      // FUTURE вывести проверку на адмна в middleware
      const isAdmin = store.getters['auth/getIsAdmin'];
      if (!isAdmin) {
        next({ name: 'main-groups' });
      } else {
        next();
      }
    },
  },
  {
    name: 'tariffs',
    path: '/tariffs',
    component: TariffsScreen,
  },
];

export default routes;
