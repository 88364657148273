<template>
  <div class="import-editor">
    <span class="import-editor__text import-editor__text_title">{{ $t('editing_a_import') }}</span>
    <!-- Название -->
    <input
    v-model.trim="newImportName"
    :placeholder="$t('enter_new_name')"
    class="import-editor__input">

    <div class="import-editor__button-block">
      <TJButton
      :text="$t('save')"
      :disabled="isDisabledSaveButton"
      @handler="saveImport" />
      <TJButton
      :text="$t('cancel')"
      type="outlined"
      @handler="$emit('close')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportEditor',
  props: {
    element: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    newImportName: null,
  }),
  created() {
    this.newImportName = this.element.name;
  },
  methods: {
    saveImport() {
      const importData = { id: this.element.id, name: this.newImportName };
      this.$store.dispatch('imports/importUpdate', importData)
        .then(() => {
          this.element.name = this.newImportName;
          this.$emit('close');
          this.$store.dispatch('app/setSnackBar', {
            text: this.$t('Data updated'),
            error: false,
          });
        })
        .catch(() => {
          this.$emit('close');
          this.$store.commit('app/setAppSnackBarTimeout', 4500);
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
  },
  computed: {
    isDisabledSaveButton() {
      return !this.newImportName;
    },
  },
};
</script>

<style lang="scss" scoped>
.import-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include between-elements {
    margin-bottom: 80px;
  }
}

.import-editor__text {
  color: $primary-black;
  font-size: 14px;
  &_title {
    font-size: 22px;
  }
}

.import-editor__button-block {
  display: flex;
  @include between-elements {
    margin-right: 20px;
  }
}

.import-editor__input {
  padding: 0px 10px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  background: $primary-white;
  box-sizing: border-box;
  width: 30vw;
  min-width: 200px;
  max-width: 300px;
  font-size: 14px;
  height: 40px;
  &:focus {
    outline: none;
    border-color: $border-color-focus;
  }
  &:hover {
    outline: none;
  }
}

</style>
