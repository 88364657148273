import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/axios';
import axios from 'axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    initial: true,
    userImportsData: [],
    allImportsData: [],
    currentImportId: null,
    loadParams: {
      'page-size': 100,
      page: 1,
    },
    totalPageCounts: null,
    groupNameOrUrl: null,
  },
  getters: {
    getUserImportsData: (state) => state.userImportsData,
    getAllImportsData: (state) => state.allImportsData,
    getCurrentImportId: (state) => state.currentImportId,
    getInitial: (state) => state.initial,
    getTotalPageCounts: (state) => state.totalPageCounts,
    getCurrentPage: (state) => state.loadParams.page,
  },
  mutations: {
    setUserImportsData: (state, value) => { state.userImportsData = value; },
    setAllImportsData: (state, value) => { state.allImportsData = value; },
    setCurrentImportId: (state, value) => { state.currentImportId = value; },
    setInitial: (state, value) => { state.initial = value; },
    setLoadPage: (state, value) => { state.loadParams.page = value; },
    setTotalPageCounts: (state, value) => { state.totalPageCounts = value; },
    setGroupNameOrUrl: (state, value) => { state.groupNameOrUrl = value; },
  },
  actions: {
    importGroups({ commit }, importFile) {
      const token = localStorage.getItem('userToken');
      return api.post('/import-groups', importFile, {
        headers: {
          'X-API-KEY': token,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(({ data: { data } }) => {
          commit('setCurrentImportId', data.id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- importGroups --; Error -', error);
          return Promise.reject(error);
        });
    },
    getImports({ state, commit }) {
      return api.get('/import-groups', {
        params: {
          groupNameOrUrl: state.groupNameOrUrl,
        },
      })
        .then(({ data: { data } }) => {
          commit('setUserImportsData', data);
          commit('setInitial', false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.warn('Error in action -- getImports --; Error -', error);
          return Promise.reject(error);
        });
    },
    getAllImports({ state, commit }, params) {
      const token = localStorage.getItem('userToken');

      if (params.reset) {
        commit('setLoadPage', 1);
        commit('setTotalPageCounts', null);
      }

      return axios.get('https://data-api.socialjet.pro/api-admin/v1/import-groups', {
        params: { groupNameOrUrl: state.groupNameOrUrl, ...state.loadParams },
        headers: {
          'X-API-KEY': token,
        },
      })
        .then((response) => {
          const imports = response.data.data;

          if (params.reset) {
            commit('setAllImportsData', imports);
          } else {
            commit('setAllImportsData', [...state.allImportsData, ...imports]);
          }

          commit('setTotalPageCounts', response.headers['x-pagination-page-count']);
          commit('setLoadPage', state.loadParams.page + 1);
          return Promise.resolve(imports);
        })
        .catch((error) => {
          console.warn('Error in action -- getAllImports --; Error -', error);
          return Promise.reject(error);
        });
    },
    importUpdate({ state }, importData) {
      const token = localStorage.getItem('userToken');
      return api.put(`/import-groups/${importData.id}`, { name: importData.name }, {
        headers: {
          'X-API-KEY': token,
        },
      })
        .then(() => {
          state.currentImportId = null;
          return Promise.resolve();
        })
        .catch((error) => {
          console.warn('Error in action -- importUpdate --; Error -', error);
          return Promise.reject(error);
        });
    },
  },

};
