<template>
  <div class="import-element">
    <div class="import-element__column import-element__column_name">
      <span class="import-element__text import-element__text_name">
        {{ getImportName }}
      </span>
      <span
      v-if="getImportDate(element.createdAt.date)"
      class="import-element__text_light">
        {{ getImportDate(element.createdAt.date) }}
      </span>
      <TJIcon
      name="edit"
      class="import-element__edit-icon"
      :size="14"
      pointer
      @click.native="showEditModal = true" />
    </div>

    <div
    v-if="isAllImports"
    class="import-element__column import-element__column_row import-element__column_user">
      <span
      class="import-element__text import-element__text_light">
        {{ getUser }}
      </span>
    </div>

    <div
    class="import-element__column import-element__column_row"
    :class="getSourceLink && 'pointer'"
    @click="openSource">
      <span
      class="import-element__text_light">
        {{ getSource }}
      </span>
      <TJIcon
      v-if="getSourceLink"
      class="import-element__text_light"
      name="file_download"
      :size="14" />
    </div>

    <div class="import-element__column import-element__column_row">
      <span
      class="import-element__text_small"
      :class="[
        element.status === '2' && 'import-element__text_ok',
        element.status === '3' && 'import-element__text_error',
      ]">
        {{ getStatus }}
      </span>
      <TJIcon
      v-if="element.status === '3' || isEmpty"
      v-title="element.status === '3' ? 'Документ не был обработан' : 'Пустой импорт'"
      class="import-element__icon pointer"
      :class="element.status === '3' && 'import-element__icon_error'"
      name="help_outline"
      :size="14"
      @click.native="setShowResultsModal" />
    </div>

    <div class="import-element__column">
      <span class="import-element__text_small">
        {{ element.countTotal && element.status === '2' ? element.countTotal : '—' }}
      </span>
    </div>

    <div class="import-element__column">
      <span class="import-element__text_small">
        {{ element.countCreated && element.status === '2' ? element.countCreated : '—' }}
      </span>
    </div>

    <div class="import-element__column">
      <span class="import-element__text_small">
        {{ element.countUpdated && element.status === '2' ? element.countUpdated : '—' }}
      </span>
    </div>

    <div class="import-element__column import-element__column_row">
      <span
      class="import-element__text_small"
      :class="element.countFailed !== '0' && 'import-element__text_error'">
        {{ element.countFailed && element.status === '2' ? element.countFailed : '—' }}
      </span>
      <TJIcon
      v-if="getErrors"
      class="import-element__text_error pointer"
      name="help_outline"
      :size="14"
      @click.native="setShowResultsModal" />
    </div>

    <div
    class="import-element__column import-element__column_row pointer"
    @click="share(open = false)">
      <span
      class="import-element__text_light"
      :class="isEmptyImport && 'import-element__text_disabled'">{{ $t('copy') }}</span>
      <TJIcon
      name="content_copy"
      :size="14"
      class="import-element__text_light" />
    </div>

    <div
    class="import-element__column pointer">
      <span
      class="import-element__text_name-small"
      :class="isEmptyImport && 'import-element__text_disabled'"
      @click="share(open = true)">{{ $t('Open compilation') }}</span>
    </div>

    <TJModal
    :show-modal="showResultsModal"
    @close="showResultsModal = false">
      <template #modal-content>
        <div class="import-element__results">
          <div class="import-element__results-item">
            <TJIcon
            name="check_circle_outline"
            :size="40"
            class="import-element__icon" />
            <span class="import-element__text">{{ `${$t('added')} - ${element.countCreated}` }}</span>
          </div>
          <div class="import-element__results-item">
            <TJIcon
            name="error_outline"
            :size="40"
            class="import-element__icon_warning" />
            <span class="import-element__text">{{ `${$t('update')} - ${element.countUpdated}` }}</span>
          </div>
          <div class="import-element__results-item">
            <TJIcon
            name="highlight_off"
            :size="40"
            class="import-element__icon_error" />
            <span class="import-element__text">{{ `${$t('not_added')} - ${element.countFailed}` }}</span>
          </div>

          <div
          v-if="element.warnings.length"
          class="import-element__results">
            <span class="import-element__text">{{ $t('warnings') + ':' }}</span>
            <div class="import-element__errors">
              <div
              v-for="(error, index) in element.warnings"
              :key="error.index + index"
              class="import-element__error">
                <span class="import-element__text import-element_small">{{ `${$t('cell')} ${error.index ? error.index : ''}` }} - </span>
                <span class="import-element__text import-element__text_small">{{ error.text }}</span>
              </div>
            </div>
          </div>
          <div
          v-if="element.errors.length"
          class="import-element__results">
            <span class="import-element__text">{{ $t('import_errors') + ':' }}</span>
            <div class="import-element__errors">
              <div
              v-for="(error, index) in element.errors"
              :key="error.index + index"
              class="import-element__error">
                <span class="import-element__text import-element__text_small">{{ `${$t('cell')} ${error.index ? error.index : ''}` }} - </span>
                <span class="import-element__text import-element__text_small">{{ error.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </TJModal>

    <TJModal
    :show-modal="showEditModal"
    @close="showEditModal = false">
      <template #modal-content>
        <ImportEditor
        :element="element"
        @close="showEditModal = false" />
      </template>
    </TJModal>
  </div>
</template>

<script>
import ImportEditor from './ImportEditor.vue';

export default {
  name: 'ImportElement',
  components: { ImportEditor },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
    isAllImports: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showResultsModal: false,
    showEditModal: false,
  }),
  methods: {
    share(open = false) {
      const results = this.element.createdGroupsIdentities.concat(this.element.updatedGroupsIdentities);

      if (!results.length) {
        this.$store.dispatch('app/setSnackBar', { text: this.$t('It is impossible to form a compilation'), error: true });
        return;
      }

      const collectionForShare = {
        name: `Compilation ${new Date().getTime()}`,
        groupIdentities: results,
      };
      this.$store.dispatch('collections/shareCollection', collectionForShare)
        .then((data) => {
          const { id } = data.compilation;
          const { shareToken } = data;
          if (id && shareToken) {
            // this.shareLink = `https://data.socialjet.pro/compilation?id=${id}&shareToken=${shareToken}`;
            this.shareLink = `https://socialjet.tech/kn1XZb?id=${id}&shareToken=${data.shareToken}`;
            if (open) {
              window.open(this.shareLink);
              return;
            }
            this.copyToBuffer(this.shareLink);
            this.$store.dispatch('app/setSnackBar', { text: this.$t('the_link_is_copied_to_the_clipboard') });
          }
        });
    },
    getImportDate(date) {
      if (!date) return false;
      return this.moment(date).format('LLL');
    },
    openSource() {
      if (!this.getSourceLink) return;
      window.open(this.getSourceLink);
    },
    setShowResultsModal() {
      if (this.element.status === '3') return;
      this.showResultsModal = true;
    },
  },
  computed: {
    getImportName() {
      return this.element.name ? this.element.name : `Импорт ${this.element.id}`;
    },
    getStatus() {
      if (this.element.status === '2') {
        return this.$t('Success');
      }
      if (this.element.status === '3') {
        return this.$t('Error');
      }
      if (this.element.status === '4') {
        return this.$t('Empty import');
      }
      return this.$t('Expectation');
    },
    getSource() {
      if (!this.element.importerName) return this.$t('no_data_available');
      return this.element.importerName[0].toUpperCase() + this.element.importerName.substring(1);
    },
    getUser() {
      return this.element.user?.email ? this.element.user.email : `ID - ${this.element.userId}`;
    },
    getSourceLink() {
      if (!this.element.source) {
        return false;
      }
      const server = 'https://data-api.socialjet.pro/tmp/';
      return this.element.importerName === 'excel'
        ? `${server}${this.element.source.split('tmp')[1]}`
        : this.element.source;
    },
    isEmptyImport() {
      return !this.element.createdGroupsIdentities.concat(this.element.updatedGroupsIdentities).length;
    },
    isEmpty() {
      return this.element.status === '2' && this.isEmptyImport && this.element.countFailed === '0';
    },
    getErrors() {
      return this.element.errors && this.element.errors.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.import-element {
  display: flex;
  align-items: center;
  min-width: 100%;
  height: 60px;
  border-bottom: 1px solid $primary-group-border;
  @include between-elements {
    border-right: 1px solid $primary-group-border;
  }
  &:hover {
    background: #fcfcfc;
    .import-element__column {
      background: #fcfcfc;
    }
  .import-element__edit-icon {
    opacity: 1;
  }
  }
}

.import-element__column {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px 10px;
  &_name {
    left: 0px;
    padding-left: 20px;
    position: sticky;
    z-index: 1;
    background: white;
    align-items: flex-start;
    padding-right: 20px;
    @include tablet-screen {
      width: 250px;
    }
  }
  &_row {
    flex-direction: row;
    @include between-elements {
    margin-right: 5px;
    margin-bottom: 0px;
    }
  }
  &_user {
    width: 220px;
  }
}

.import-element__edit-icon {
  display: none !important;
  @include desktop-screen {
    display: block !important;
    position: absolute;
    top: 12px;
    right: 15px;
    color: $primary-lightgray;
    transition: 0.2s;
    opacity: 0;
  }
}

.import-element__results {
  margin-top: 20px;
}

.import-element__results-item {
  display: flex;
  align-items: center;
  height: 40px;
  @include between-elements {
    margin-right: 20px;
  }
}

.import-element__errors {
  max-height: 300px;
  margin-top: 5px;
  overflow: auto;
  padding-right: 50px;
  @include between-elements {
    border-bottom: 1px solid rgb(228, 228, 228);
    padding: 3px 0px;
  }
}

.import-element__icon {
  color: $primary-green;
  &_error {
    color: $primary-red;
  }
  &_warning {
    color: rgb(236, 207, 78);
  }
}

.import-element__edit-input {
  outline: none;
  height: 24px;
  border: none;
  max-width: 220px;
  &:focus {
    outline: none;
  }
}

.import-element__text {
  font-size: 14px;
  color: $primary-gray;
  text-align: left;
  word-break: break-all;
  &_name {
    color: $primary-blue;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 5px;
  }
  &_name-small {
    color: $primary-blue;
    font-size: 12px;
  }
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_small {
    font-size: 12px;
  }
  &_disabled {
    color: $primary-lightgray;
    opacity: 0.5;
  }
  &_ok {
    color: $primary-green;
  }
  &_error {
    color: $primary-red;
  }
}

</style>
