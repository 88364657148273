<template>
  <div class="group-geo">
    <div
    class="group-geo__content"
    :class="[
      isEmptyData && 'group-geo_empty',
      isAdmin && 'pointer'
    ]"
    @click="openEditor"
    @mouseover="showCities = true"
    @mouseleave="showCities = false">
      <div
      v-if="getCountriesData.length"
      class="group-geo__countries">
        <div
        v-for="(country, index) in getCountriesData"
        :key="country.name"
        class="group-geo__element">
          <img
          class="group-geo__avatar"
          :src="getCurrentCountryIcon(country.value)
            ? require(`@/assets/svg/flags/${getCurrentCountryIcon(country.value)}.svg`)
            : require('@/assets/svg/empty-avatar.svg')
          "
          alt="avatar">
          <span
          class="group-geo__text"
          :class="index !== 0 && 'group-geo__text_light'">
            {{ country.name }}
          </span>
        </div>
      </div>
      <transition name="fade">
        <div
        v-if="showCities && getCitiesData.length"
        class="group-geo__cities">
          <div
          v-for="city in getCitiesData"
          :key="city.name"
          class="group-geo__element">
            <img
            class="group-geo__avatar"
            :src="getCurrentCountryIcon(city.value)
              ? require(`@/assets/svg/flags/${getCurrentCountryIcon(city.value)}.svg`)
              : require('@/assets/svg/empty-avatar.svg')
            "
            alt="avatar">
            <span class="group-geo__text group-geo__text_small">
              {{ city.name }}
            </span>
          </div>
        </div>
      </transition>
      <div v-if="!getCountriesData.length">
        <span class="group-geo__text_light">{{ $t('location') + ':' }}</span>
        <span class="group-geo__text_light"> — </span>
      </div>
      <div v-if="!getCitiesData.length">
        <span class="group-geo__text_light">{{ $t('city') + ':' }}</span>
        <span class="group-geo__text_light"> — </span>
      </div>
    </div>
    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside"
      class="group-geo__editor pointer">
        <div class="group-geo__editor-element">
          <span class="group-geo__text">{{ $t('location') }}</span>
          <div
          v-for="country in newCountries"
          :key="country.location"
          class="group-geo__editor-element group-geo__editor-element_row">
            <TJIcon
            name="close"
            class="group-geo__text_small"
            :size="14"
            @click.native="deleteCountry(country.location)" />
            <span class="group-geo__text group-geo__text_small">{{ country.location }}</span>
          </div>
          <div
          v-if="!showSelect && newCountries.length < 3"
          class="group-geo__editor-element group-geo__editor-element_row pointer"
          @click="showSelect = true">
            <TJIcon
            name="add"
            :size="14"
            class="group-geo__text_light" />
            <!-- NEED TRANSLATE -->
            <span class="group-geo__text group-geo__text_light">Добавить страну</span>
          </div>
          <select
          v-if="showSelect"
          v-model="selectedCountry"
          class="group-geo__input"
          @change="addCountry">
            <option
            v-for="option in countriesFlags"
            :key="option.value">
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="group-geo__editor-element">
          <span class="group-geo__text">{{ $t('city') }}</span>
          <div
          v-for="city in newCities"
          :key="city.location"
          class="group-geo__editor-element group-geo__editor-element_row">
            <TJIcon
            name="close"
            class="group-geo__text_small"
            :size="14"
            @click.native="deleteCity(city.location)" />
            <span class="group-geo__text group-geo__text_small">{{ city.location }}</span>
          </div>
          <div
          v-if="!showCityInput && newCities.length < 3"
          class="group-geo__editor-element group-geo__editor-element_row pointer"
          @click="showCityInput = true">
            <TJIcon
            name="add"
            :size="14"
            class="group-geo__text_light" />
            <!-- NEED TRANSLATE -->
            <span class="group-geo__text group-geo__text_light">Добавить город</span>
          </div>
          <div
          v-if="showCityInput"
          class="group-geo__editor-element group-geo__editor-element_row pointer">
            <input
            v-model.trim="cityInput"
            placeholder="Введите название"
            class="group-geo__input"
            @keyup.enter="addCity">
            <TJIcon
            name="add"
            :size="14"
            class="group-geo__text_light"
            @click.native="addCity" />
          </div>
        </div>
        <TJButton
        :text="$t('save')"
        type="text"
        @handler="saveChanges" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupAudience',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    cities: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    // FUTURE добавить все постсовесткие страны
    countriesFlags: [
      { value: 'russia', name: 'Россия' },
      { value: 'ukrain', name: 'Украина' },
      { value: 'kazakhstan', name: 'Казахстан' },
      { value: 'azerbaijan', name: 'Азербайджан' },
      { value: 'belarus', name: 'Беларусь' },
      { value: 'armenia', name: 'Армения' },
      { value: 'bulgaria', name: 'Болгария' },
      { value: 'china', name: 'Китай' },
      { value: 'england', name: 'Великобритания' },
      { value: 'estonia', name: 'Эстония' },
      { value: 'finland', name: 'Финляндия' },
      { value: 'georgia', name: 'Грузия' },
      { value: 'germany', name: 'Германия' },
      { value: 'latvia', name: 'Латвия' },
      { value: 'turkey', name: 'Турция' },
      { value: 'usa', name: 'Соединённые Штаты Америки' },
      { value: 'uzbekistan', name: 'Узбекистан' },
      { value: 'iran', name: 'Иран' },
      { value: 'kyrgyzstan', name: 'Киргизия' },
      { value: 'moldavia', name: 'Молдавия' },
      { value: 'litva', name: 'Литва' },
      { value: 'tajikistan', name: 'Таджикистан' },
      { value: 'australia', name: 'Австралия' },
      { value: 'india', name: 'Индия' },
      { value: 'poland', name: 'Польша' },
      { value: 'brazil', name: 'Бразилия' },
      { value: 'mexico', name: 'Мексика' },
      { value: 'belgium', name: 'Бельгия' },
      { value: 'italy', name: 'Италия' },
    ],
    showCities: false,
    showEditor: false,
    showSelect: false,
    newCountries: null,
    selectedCountry: null,
    showCityInput: false,
    cityInput: '',
    newCities: null,
  }),
  methods: {
    getCurrentCountryIcon(country) {
      const countryIcon = this.countriesFlags.find((item) => item.name === country);
      return countryIcon ? countryIcon.value : false;
    },
    openEditor() {
      if (!this.isAdmin || this.showEditor) return;
      this.newCountries = this.countries ? [...this.countries.filter((item) => item.location !== null)] : [];
      this.newCities = this.cities ? [...this.cities.filter((item) => item.location !== null)] : [];
      this.showEditor = true;
    },
    addCountry() {
      if (this.newCountries.find((item) => item.location === this.selectedCountry)) {
        return;
      }
      const newcountry = { location: this.selectedCountry, value: null };
      this.newCountries.push(newcountry);
      this.showSelect = false;
    },
    addCity() {
      if (this.newCities.find((item) => item.location === this.cityInput)) {
        return;
      }
      const newcity = { location: this.cityInput, value: null };
      this.newCities.push(newcity);
      this.showCityInput = false;
      this.cityInput = '';
    },
    deleteCountry(country) {
      this.newCountries = this.newCountries.filter((item) => item.location !== country);
    },
    deleteCity(city) {
      this.newCities = this.newCities.filter((item) => item.location !== city);
    },
    saveChanges() {
      const newData = {
        subscribersStatistic: {
          countries: this.newCountries,
          cities: this.newCities,
        },
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.subscribersStatistic.countries = this.newCountries;
          this.group.subscribersStatistic.cities = this.newCities;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('An error occurred while updating the data'),
              error: true,
            });
        });
    },
    closeEditor() {
      this.showEditor = false;
      this.cityInput = '';
      this.showSelect = false;
      this.selectedCountry = null;
      this.showCityInput = false;
    },
    onClickOutside() {
      this.closeEditor();
    },
  },
  computed: {
    getCountriesData() {
      if (!this.countries) return [];

      const countries = this.countries.filter((country) => country.location !== null).map((item, index) => ({
        name: `${this.$t(item.location)}${item.value ? `: ${Math.round(item.value)}%` : ''}`,
        value: item.location,
        index,
      })).slice(0, 3);

      return this.hideDetails ? countries.slice(0, 1) : countries;
    },
    getCitiesData() {
      if (!this.cities) return [];

      return this.cities.filter((city) => city.location !== null).map((item) => ({
        name: `${this.$t(item.location)}${item.value ? `: ${Math.round(item.value)}%` : ''}`,
        value: item.location,
      })).slice(0, 5);
    },
    isEmptyData() {
      return !this.getCountriesData.length && !this.getCitiesData.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-geo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 50px;
  width: 180px;
  padding-left: 30px;
  position: relative;
  &_empty {
    padding-left: 0px;
    align-items: center;
  }
}

.group-geo__text {
  font-size: 14px;
  color: $primary-gray;
  white-space: nowrap;
  overflow: hidden;
  width: 120px;
  text-overflow: ellipsis;
  &_small {
    font-size: 12px;
  }
  &_light {
    font-size: 12px;
    margin-right: 4px;
    color: $primary-lightgray;
  }
  &_error {
    font-size: 10px;
    color: $primary-red;
  }
}

.group-geo__empty {
  padding-left: 20px;
}

.group-geo__cities {
  position: absolute;
  top: 0px;
  left: 140px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  padding: 10px;
  z-index: 1;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-geo__countries {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-geo__element {
  display: flex;
  align-items: center;
  &_other {
    flex-direction: column;
  }
}

.group-geo__avatar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

.group-geo__editor {
  position: absolute;
  top: 0px;
  left: 25px;
  width: 220px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-geo__editor-element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
  &_row {
    flex-direction: row;
    align-items: center;
    @include between-elements {
    margin-bottom: 0px;
    margin-right: 5px;
  }
  }
}

.group-geo__input {
  border: 1px solid $primary-lightgray;
  font-size: 12px;
  padding: 2px 5px;
  width: 160px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_small {
    width: 38px;
    padding: 1px 5px;
  }
  &_error {
    border: 1px solid $primary-red;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
